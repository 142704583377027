import { Buffer } from 'buffer';
import { ungzip } from 'pako';
import { useState, useEffect, useCallback, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { DEFAULT_OPTIONS } from 'api/websockets/constants';
import { wsUrl } from './constants';

function useKlineDataWs({ initSubs = [] } = {}) {
	const [subs, setSubs] = useState(initSubs || []);
	const [subsToAdd, setSubsToAdd] = useState(subs);
	const [subsToRemove, setSubsToRemove] = useState([]);
	const [messages, setMessages] = useState({});
	const didUnmount = useRef(false);
	const { sendMessage, lastMessage, readyState } = useWebSocket(
		wsUrl,
		{
			...DEFAULT_OPTIONS('useKlineDataWs'),
			shouldReconnect: (closeEvent) => didUnmount.current === false,
			share: true,
		}
	);

	useEffect(() => {
		return () => {
			didUnmount.current = true;
		}
	}, []);

	// Log which pairs need to be subscribed to, removed, and reset main pairs state
	const setSubsProxy = useCallback((newSubs = []) => {
		const dedupedNewSubs = [...new Set(newSubs)];

		setSubsToRemove(
			subs.filter(p => !dedupedNewSubs.includes(p))
		);

		setSubsToAdd(
			dedupedNewSubs.filter(np => !subs.includes(np))
		);

		setSubs(dedupedNewSubs);
	}, [subs, setSubs]);

	// Subscribe and remove subscriptions to pairs
	useEffect(() => {
		if (ReadyState[readyState] === 'OPEN') {
			if (subsToRemove.length > 0) {
				// console.log('REMOVING', subsToRemove.join(', '));
				[...new Set(subsToRemove)].forEach(p => sendMessage(JSON.stringify({
					id: p,
					reqType: 'unsub',
					dataType: `market.kline.${p}`,
				})));

				setSubsToRemove([]);
			}

			if (subsToAdd.length > 0) {
				// console.log('SUBSCRIBING', subsToAdd.join(', '));
				[...new Set(subsToAdd)].forEach(p => sendMessage(JSON.stringify({
					id: p,
					reqType: 'sub',
					dataType: `market.kline.${p}`,
				})));
			}
		} else if (ReadyState[readyState] === 'CONNECTING') {
			setSubsToAdd(subs);
		}
	}, [subs, subsToRemove, subsToAdd, readyState, sendMessage]);

	// Some weird issue on mobile when browser is closed causes readyState
	// to stay 'OPEN' but the subs dissapear. So we never go through to
	// connecting state which would re-sub. Solution: re-sub every 2 seconds.
	useEffect(() => {
		const interval = setInterval(() => {
			setSubsToAdd([...new Set(subs)]);
		}, 2000);

		return () => clearInterval(interval);
	}, [subs]);

	// Decompress data and store it
	useEffect(() => {
		if (lastMessage?.data) {
			const fr = new FileReader();

			fr.onload = () => {
				const decompressed = ungzip(new Uint8Array(fr.result));
				const asStr = Buffer.from(decompressed.buffer).toString();

				if (asStr.toLowerCase() === 'ping') {
					// console.log('RESPONDING TO PING');
					sendMessage('Pong');
				} else {
					const { id, msg, dataType, data } = (JSON.parse(asStr)) || {};
					if (id || msg === "") {
						// Subscribe/unsubscribe success; msg will be empty string
						// console.log({id, msg});
					} else {
						// Data came in, handle
						const pair = dataType.split('market.kline.')[1];
						const latestKline = data?.klineInfosVo?.[0];

						if (pair && latestKline) {
							setMessages(prev => ({
								...prev,
								[pair]: latestKline,
							}));
						}
					}
				}
			};

			fr.readAsArrayBuffer(lastMessage?.data);
		}
	}, [
		lastMessage?.data,
		sendMessage
	]);

	return [
		messages,
		setSubsProxy,
		ReadyState[readyState]
	];
};

export default useKlineDataWs;
