import { useCallback } from 'react';
import useIsMobile from 'hooks/useIsMobile';

export default function useHandleChartClick({
	pricePrecision,
	side,
	symbol,
	currentPrice,
	origQty,
	action,
	type,
	dispatchContext,
} = {}) {
	const isMobile = useIsMobile();

	return useCallback(
		({ param, chartRef, candlestickSeries, priceOverride }) => {
	  	if (!priceOverride && param.point.y < 0) return;

	  	const price = priceOverride || Number(
	  		candlestickSeries.current.coordinateToPrice(param.point.y).toFixed(pricePrecision)
	  	);

	  	const actionOverride = action && isMobile ? action : side === 'short'
				? price <= currentPrice ? 'CLOSE' : 'OPEN'
				: price >= currentPrice ? 'CLOSE' : 'OPEN';

	  	dispatchContext({
	  		type: 'updateTentativeOrder',
	  		payload: {
		  		orderId: Date.now().toString(),
		  		symbol: symbol,
	  			action: actionOverride,
		  		actionShort: actionOverride.substring(0,2) === 'CL' ? 'TP' : actionOverride.substring(0,2),
		  		side: side.toUpperCase(),
		  		type: type && isMobile ? type : 'LIMIT',
		  		price,
		  		avgPrice: 0,
		  		origQty: origQty || 0,
		  		executedQty: 0,
		  		isTentativeOrder: true,
	  		},
	  	});
	  }, [
	  	pricePrecision,
	  	side,
	  	symbol,
	  	dispatchContext,
	  	currentPrice,
	  	origQty,
			action,
			type,
			isMobile,
	  ]
	);
};
