import React, { useState, useEffect, useMemo, useRef } from "react";
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { LineStyle } from 'lightweight-charts';
import Card from "react-bootstrap/Card";
import Toggle from "components/Toggle";
import CandlestickChart from "components/CandlestickChart";
import Spinner from "components/Spinner";
import LongShortRatioChart from "components/LongShortRatioChart";
import { supportedSymbols } from 'constants/bingxSwaps';
import { useGetBingxSwapsKlineHistoryQuery } from 'api/client';
import useKlineDataWs from 'api/websockets/bingxSwaps/useKlineDataWs';
import { commaFormat } from 'util/numbers';

const TIME_TYPES = {
	'1m': '1',
	'5m': '5',
	'15m': '15',
	'30m': '30',
	'1h': '60',
	'4h': '240',
	'6h': '360',
	'12h': '720',
	'D': '1D',
};
const WS_TIME_TYPES = {
	'1': '1min',
	'5': '5min',
	'15': '15min',
	'30': '30min',
	'60': '1hour',
	'240': '4hour',
	'360': '6hour',
	'720': '12hour',
	'1D': '1day',
};

const getEndTs = () => +dayjs().add(1, 'd');

function PriceChart({
	symbol,
	avgPrice,
	breakEvenPrice,
	activePositionSide,
	pendingOrders,
	customLines = [],
	handleChartClick
}) {
	const chartContainerRef = useRef();
	const startTs = useRef(+dayjs().startOf('d').subtract(8, 'd'));
	const [endTs, setEndTs] = useState(getEndTs());
	const [activeTimeType, setActiveTimeType] = useState('5');
	const decimals = supportedSymbols[symbol.split('-')[0]]?.decimals || 2;

	const {
		data: klineHistory = [],
		isLoading: klineHistoryIsLoading,
		isFetching: klineHistoryIsFetching,
	} = useGetBingxSwapsKlineHistoryQuery({
		symbol: symbol,
		type: activeTimeType,
		startTs: startTs.current,
		endTs: endTs,
	});

	const getWsSubscribeStr = timeType => `${symbol}.${WS_TIME_TYPES[timeType]}`
	const [latestKlineMessages = {}, setLatestKlineSub] = useKlineDataWs({ initSubs: [getWsSubscribeStr(activeTimeType)] });
	const latestKline = latestKlineMessages[getWsSubscribeStr(activeTimeType)] || {};

	const lastHistoryTs = useMemo(() => sortBy(klineHistory, 'ts').reverse()[0]?.ts, [klineHistory]);
	const latestKlinePrice = useMemo(() => {
		const precision = Math.pow(10, decimals);
		return latestKline?.close && Math.round(latestKline?.close * precision)/precision;
	}, [decimals, latestKline?.close]);

	// Check every minute if we need to refetch klineData based on
	// the selected time interval toggle
	useEffect(() => {
		const interval = setInterval(() => {
			const chartMinsInterval = activeTimeType === '1D' ? 1440 : Number(activeTimeType);
			const minsDeltaFromLastHistoryTs = (new Date().getTime() - lastHistoryTs)/1000/60;

			if (minsDeltaFromLastHistoryTs >= chartMinsInterval * 2) {
				setEndTs(getEndTs());
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [activeTimeType, lastHistoryTs]);

	const priceLines = useMemo(() => {
		const priceLines = [];

		avgPrice && priceLines.push({
			title: 'Entry',
			price: avgPrice,
			color: '#2962ff',
			lineStyle: LineStyle.LargeDashed,
		});

		breakEvenPrice && priceLines.push({
			title: 'Break Even',
			price: breakEvenPrice,
			lineStyle: LineStyle.Solid,
			color: !latestKlinePrice ? 'green'
				: breakEvenPrice === latestKlinePrice ? 'orange'
				: activePositionSide === 'Short'
					? breakEvenPrice >= latestKlinePrice ? 'green' : 'red'
					: breakEvenPrice <= latestKlinePrice ? 'green' : 'red'
		});

		pendingOrders.forEach(po => {
			priceLines.push({
				title: `${po.action.slice(0,2)} ${activePositionSide}: ${commaFormat(po.entrustVolume - po.filledVolume, undefined, '-')}`,
				price: po.entrustPrice,
				color: po.filledVolume > 0 ? 'orange' : po.action === 'Open' ? 'red' : 'green',
			});
		});

		priceLines.push(...customLines);

		return priceLines;
	}, [avgPrice, breakEvenPrice, pendingOrders, activePositionSide, latestKlinePrice, customLines]);

	return klineHistoryIsLoading ? <Spinner className="py-3 text-muted w-100" /> : (
		<>
			<Toggle
				ops={Object.keys(TIME_TYPES)}
				active={op => TIME_TYPES[op] === activeTimeType}
				setActive={op => {
					const newTimeType = TIME_TYPES[op];
					setActiveTimeType(newTimeType);
					setLatestKlineSub([getWsSubscribeStr(newTimeType)]);
					setEndTs(+dayjs());
				}}
				className="mb-2 w-fit"
			/>
			<Card
				ref={chartContainerRef}
				className="shadow"
				style={{overflow: 'hidden'}}
			>
				<Card.Body
					className="p-0"
					style={{overflow: 'hidden'}}
				>
					<CandlestickChart
						containerRef={chartContainerRef}
						data={klineHistory}
						latestData={!klineHistoryIsFetching && latestKline}
						priceLines={priceLines}
						height={560}
						intervalInMin={activeTimeType === '1D' ? 1440 : Number(activeTimeType)}
						handleChartClick={handleChartClick}
						decimals={decimals}
					/>
				</Card.Body>
			</Card>
			<LongShortRatioChartWrapper
				symbol={symbol}
				activeTimeType={activeTimeType}
			/>
		</>
  )
};

export default PriceChart;

const LongShortRatioChartWrapper = ({symbol, activeTimeType}) => {
	const chartContainerRef = useRef();

	return (
		<Card
			ref={chartContainerRef}
			className="shadow"
			style={{overflow: 'hidden'}}
		>
			<Card.Body
				className="p-0"
				style={{overflow: 'hidden'}}
			>
				<LongShortRatioChart
					containerRef={chartContainerRef}
					symbol={symbol.replace('-', '')}
					period={`${activeTimeType}m`}
				/>
			</Card.Body>
		</Card>
	);
};
