import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { DEFAULT_OPTIONS } from 'api/websockets/constants';
import useHandleSubs from 'api/websockets/bingxSwaps/hooks/useHandleSubs';
import { bingxSwapsApiSlice } from 'api/client';
import { dispatch as dispatchStore } from 'redux/store';
import { wsUrlV2 } from './constants';
import { ListenKey } from './ListenKey';
const jsonParseBigIntString = require('json-bigint')({ storeAsString: true });

// Pass which events you want to subscribe to

/** EVENT TYPES
 * listenKeyExpired
 * ACCOUNT_UPDATE: position changes and account information updates
 * ORDER_TRADE_UPDATE: new order created, order has new deal, or new status change
 * ACCOUNT_CONFIG_UPDATE: when leverage or margin mode of trading pair changes
 */

// Pass events as comma separated string of events you're interested in;
function useAccountDataWs(events = '') {
	const [listenKey, setListenKey] = useState(null);
	const [messages, setMessages] = useState({});
	const [lastMessageDataStr, setLastMessageDataStr] = useState(null);
	const dataTypeRegex = useMemo(() => new RegExp(events.split(',').join('|'), 'i'), [events]);
	const didUnmount = useRef(false);
	const { sendMessage, lastMessage, readyState } = useWebSocket(
		`${wsUrlV2}?listenKey=${listenKey}`,
		{
			...DEFAULT_OPTIONS('useAccountDataWs'),
			shouldReconnect: (closeEvent) => didUnmount.current === false,
			share: true,
		}
	);

	useEffect(() => () => didUnmount.current = true, []);

	const getListenKey = useCallback((_listenKey) => {
		setListenKey(_listenKey);
	}, []);

	useEffect(() => {
		ListenKey.subscribe(getListenKey);

		return () => {
			didUnmount.current = true;
			ListenKey.unsubscribe(getListenKey);
		}
	}, [getListenKey]);

	useHandleSubs({
		readyState,
		sendMessage,
		dataTypeRegex,
		lastMessageData: lastMessage?.data,
		lastMessageDataStr,
		setLastMessageDataStr,
	});

	// Main logic to get data
	useEffect(() => {
		if (lastMessageDataStr) {
			const {
				id,
				msg,
				e: event,
				// E: eventTime,
				listenKey,
				a: accountUpdate = {},
				o: orderTradeUpdate = {},
				ac: accountConfig = {},
			} = (jsonParseBigIntString.parse(lastMessageDataStr) || {});

			const eventMap = {
				'listenKeyExpired': {
					value: listenKey,
					format: (key) => key,
				},
				'ACCOUNT_UPDATE': {
					value: accountUpdate,
					format: formatAccountUpdate,
				},
				'ORDER_TRADE_UPDATE': {
					value: orderTradeUpdate,
					format: formatOrderTradeUpdate,
					cacheUpdate: updateOrdersCache,
				},
				'ACCOUNT_CONFIG_UPDATE': {
					value: accountConfig,
					format: formatAccountConfigUpdate,
				},
			};

			if (id || msg === "") {
				// Subscribe/unsubscribe success; msg will be empty string
				// console.log({id, msg});
			} else {
				if (
					event &&
					dataTypeRegex.test(event)
				) {
					const formattedEvent = eventMap[event]?.format?.(eventMap[event]?.value);

					setMessages(prev => ({
						...prev,
						[event]: formattedEvent,
					}));

					if (typeof eventMap[event]?.cacheUpdate === 'function') {
						eventMap[event].cacheUpdate(formattedEvent);
					}
				}
			}
		}
	}, [
		lastMessageDataStr,
		dataTypeRegex,
	]);

	return [
		messages,
		ReadyState[readyState]
	];
};

export default useAccountDataWs;

/* FORMATTERS */
/** @accountUpdate{}; event = ACCOUNT_UPDATE
 * m: event launch reason
 * B: balance information[]
 	* a: asset name (USDT)
 	* wb: wallet balance (int)
 	* cw: wallet balance excluding isolated margin (int)
 	* bc: wallet balance change amount (int)
 * P: trade info []
 	* s: trading pair (LINK-USDT)
 	* pa: position amt (int)
 	* ep: entry price (int)
 	* up: unrealized profit and loss of positions (int)
 	* mt: margin mode (cross/isolated)
 	* iw: if is isolated position, the position margin (int)
 	* ps: position direction (SHORT/LONG)
*/
function formatAccountUpdate(update = {}) {
	const {
		m: eventReason,
		B: balanceInfo = [],
		P: tradeInfo = [],
	} = update;

	function formatBalanceInfo(info) {
		const {
			a: assetName,
			wb: walletBalance,
			cw: walletBalanceExcludingIoslatedMargin,
			bc: walletBalanceChange,
		} = info;

		return {
			assetName,
			walletBalance: Number(walletBalance || 0) || 0,
			walletBalanceExcludingIoslatedMargin: Number(walletBalanceExcludingIoslatedMargin || 0) || 0,
			walletBalanceChange: Number(walletBalanceChange || 0) || 0,
		};
	};

	function formatTradeInfo(info) {
		const {
			s: symbol,
			pa: positionAmt,
			ep: price,
			up: positionPnl,
			mt: marginMode,
			iw: isolatedPositionMargin,
			ps: positionSide,
		} = info;

		return {
			symbol,
			positionAmt: Number(positionAmt || 0) || 0,
			price: Number(price || 0) || 0,
			positionPnl: Number(positionPnl || 0) || 0,
			marginMode,
			isolatedPositionMargin: Number(isolatedPositionMargin || 0) || 0,
			positionSide,
		};
	};

	return {
		eventReason,
		balanceInfo: balanceInfo.map(formatBalanceInfo),
		tradeInfo: tradeInfo.map(formatTradeInfo),
	}
};

/** @orderTradeUpdate{}; event = ORDER_TRADE_UPDATE
 * s: trading pair (LINK-USDT)
 * c: client custom order ID
 * i: orderId
 * S: order direction (SELL/BUY)
 * o: order type (MARKET/LIMIT)
 * q: order quantity (int)
 * p: order price (int)
 * ap: order average price (int)
 * x: specific execution type of this event (ex. TRADE)
 * X: current status of order (ex. FILLED)
 * N: fee asset type (USDT)
 * n: handling fee (int)
 * T: transaction time (int)
 * wt: trigger price type (MARK_PRICE, CONTRACT_PRICE (latest price), INDEX_PRICE)
 * ps: position direction (LONG/SHORT)
 * rp: transaction achieves profit and loss (int)
 * z: order filled accumulated quantity (int)
 */
function formatOrderTradeUpdate(update = {}) {
	const {
		s: symbol,
		c: clientOrderId,
		i: orderId,
		S: side,
		o: type,
		q: origQty,
		p: price,
		ap: avgPrice,
		x: executionType,
		X: status,
		N: feeAsset,
		n: fee,
		T: transactionTime,
		wt: workingType,
		ps: positionSide,
		rp: pnl,
		z: executedQty,
	} = update;

	return {
		symbol,
		clientOrderId,
		orderId,
		side,
		type,
		origQty: Number(origQty || 0) || 0,
		price: Number(price || 0) || 0,
		avgPrice: Number(avgPrice || 0) || 0,
		executionType,
		status,
		feeAsset,
		fee: Number(fee || 0) || 0,
		transactionTime,
		workingType,
		positionSide,
		pnl: Number(pnl || 0) || 0,
		executedQty: Number(executedQty || 0) || 0,
		action: (positionSide === 'SHORT' && side === 'BUY') || (positionSide === 'LONG' && side === 'SELL') ? 'CLOSE' : 'OPEN',
		actionShort: (positionSide === 'SHORT' && side === 'BUY') || (positionSide === 'LONG' && side === 'SELL') ? /stop/i.test(type) ? 'SL' : 'TP' : 'OP',
	};
};

/** @accountConfig{}; event = ACCOUNT_CONFIG_UPDATE
 * s: trading pair (LINK-USDT)
 * l: long position leverage (int)
 * S: short position leverage (int)
 * mt: margin mode (cross/isolated)
 */
function formatAccountConfigUpdate(update = {}) {
	const {
		s: symbol,
		l: longLeverage,
		S: shortLeverage,
		mt: marginType,
	} = update;

	return {
		symbol,
		longLeverage: Number(longLeverage || 0) || 0,
		shortLeverage: Number(shortLeverage || 0) || 0,
		marginType,
	};
};

/* CACHE UPDATERS */
function updateOrdersCache(order) {
	const endpoint = 'getPendingOrdersV2';
	const orderTypes = ['LIMIT', 'STOP_MARKET', 'TAKE_PROFIT', 'TRIGGER_LIMIT'];

	if (
		orderTypes.includes(order.type)
	) {
		const possibleArgs = [
			undefined,
			{
				symbol: order.symbol
			}, {
				symbol: order.symbol,
				side: order.positionSide.toLowerCase()
			}
		];

		switch(order.status) {
			case 'NEW':
				possibleArgs.forEach(
					args => {
						dispatchStore(
							bingxSwapsApiSlice.util.updateQueryData(endpoint, args, (orderDrafts) => {
								if (typeof orderDrafts?.push === 'function') {
									// console.log('[PUSHING ORDER]', order);
									orderDrafts?.push?.(order);
								}
							})
						)
					}
				);
				break;
			case 'PARTIALLY_FILLED':
				possibleArgs.forEach(
					args => {
						dispatchStore(
							bingxSwapsApiSlice.util.updateQueryData(endpoint, args, (orderDrafts) => {
								const orderInDraftIdx = orderDrafts?.findIndex?.(od => od?.orderId === order?.orderId);

								if (orderInDraftIdx >= 0) {
									// console.log(`[UPDATING ORDER]`, order);
									orderDrafts[orderInDraftIdx] = order;
								}
							})
						)
					}
				);
				break;
			case 'FILLED':
			case 'CANCELED':
				possibleArgs.forEach(
					args => {
						dispatchStore(
							bingxSwapsApiSlice.util.updateQueryData(endpoint, args, (orderDrafts) => {
								const orderInDraftIdx = orderDrafts?.findIndex?.(od => od?.orderId === order?.orderId);

								if (orderInDraftIdx >= 0) {
									// console.log(`[REMOVING ORDER]`, order);
									orderDrafts[orderInDraftIdx] = null;
								}
							})
						)
					}
				);
				break;
			default:
				break;
		}
	}
};
