import React from "react";
import Meta from "components/Meta";
import PositionSectionNew from "components/PositionSectionNew";
import { requireAuth } from "util/auth";

function PositionPage(props) {
  return (
    <>
      <Meta title="Position" />
      <PositionSectionNew
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Position"
      	subtitle=""
        headerSize={4}
      />
    </>
  );
}

export default requireAuth(PositionPage);
