// import queryConstructor from 'util/queryConstructor';

// Mutations
function getBacktestReport({symbols, exchangesFutures, exchangesSpot, startTime, endTime, accountValueStart} = {}) {
	return {
		url: `getBacktestReport`,
		method: 'POST',
		body: { symbols, exchangesFutures, exchangesSpot, startTime, endTime, accountValueStart },
	}
};

const handlers = (builder) => ({
	// Mutations
	getBacktestReport: builder.mutation({
		query: getBacktestReport,
	}),
});

export default handlers;
