import React from "react";
import Meta from "components/Meta";
import OrdersSection from "components/OrdersSection";
import { requireAuth } from "util/auth";

function OrdersPage(props) {
  return (
    <>
      <Meta title="Orders" />
      <OrdersSection
        bg="light"
        textColor="dark"
        size="sm"
        title="Orders"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(OrdersPage);
