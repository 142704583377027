export default function bullishEngulfing(data = [], color = 'green') {
	const marker = {
		position: "belowBar",
    color: color,
    shape: "arrowUp",
    text: "BullEng"
	};

	for (let i = 0; i < data.length; i++) {
		if (i >= 3) {
			const prevThreeAreDown =
				data?.[i-3]?.open > data?.[i-3]?.close &&
				data?.[i-2]?.open > data?.[i-2]?.close &&
				data?.[i-1]?.open > data?.[i-1]?.close;

			if (prevThreeAreDown) {
				const current = data[i];
				const prev = data[i-1];

				const { open, close } = current || {};
				const { open: prevOpen, close: prevClose } = prev || {};

				const isBullishEngulfing =
					close > open &&
					close >= prevOpen &&
					prevClose >= open &&
					close - open > prevOpen - prevClose;

				if (isBullishEngulfing) {
					current.bullishEngulfing = true;
					current.color = color;
					current.borderColor = color;
					current.wickColor = color;
					current.marker = {
						...marker,
						time: current.time,
					}
				}
			}
		}
	}

	return data;
};
