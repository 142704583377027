import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import sumBy from 'lodash/sumBy';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import CryptoIcon from 'components/CryptoIcon';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import Spinner from 'components/Spinner';
import { useGetBacktestReportMutation } from 'api/client';
import { FundingContext, RESULTS_LIMIT } from 'components/FundingSection/constants';
import useGetReportStats from 'components/FundingSection/hooks/useGetReportStats';
import { commaFormat, currencyFormat, removeZerosAfterDecimal, millifyCurrency } from 'util/numbers';

export default function Report(props) {
	const [resultsLimit, setResultsLimit] = useState(RESULTS_LIMIT);
	const [report, setReport] = useState({
		pnl: 0,
		apr: 0,
		maxDrawdown: 0,
		accountValueStart: 0,
		trades: [],
	});

	const { symbols, futuresExchanges, spotExchanges, accountValueStart, startTime, endTime, runReport, dispatchContext } = useContext(FundingContext);

	// MUTATIONS
	const [getBacktestReport, getBacktestReportResult] = useGetBacktestReportMutation();

	useEffect(() => {
		if (getBacktestReportResult.isSuccess || getBacktestReportResult.isError) {
			setTimeout(() => {
				getBacktestReportResult.reset();
				dispatchContext({ type: 'runReport', payload: false });
			}, 500);
		}

		if (getBacktestReportResult.isSuccess) {
			setReport(getBacktestReportResult.data);
		}

		if (getBacktestReportResult.isError) {
			// setShowOrderError(getBacktestReportResult.error);
			console.error('useGetBacktestReportMutation error', getBacktestReportResult.error);
		}
		if (getBacktestReportResult.isSuccess) {
			// setShowOrderSuccess(`${getBacktestReportResult?.data?.successCount}/${getBacktestReportResult?.data?.ordersCount} orders ${isOpeningOrders ? 'opened' : 'closed'}`);
		}
	}, [
		getBacktestReportResult,
		dispatchContext,
	]);

	useEffect(() => {
		if (runReport) {
			setResultsLimit(RESULTS_LIMIT);

			getBacktestReport({
				symbols,
				exchangesFutures: futuresExchanges,
				exchangesSpot: spotExchanges,
				startTime,
				endTime,
				accountValueStart,
			});
		}
	}, [
		runReport,
		getBacktestReport,
		symbols,
		futuresExchanges,
		spotExchanges,
		accountValueStart,
		startTime,
		endTime,
	]);

	const stats = useGetReportStats({
		accountValueStart: report?.accountValueStart,
		pnl: report?.pnl,
		apr: report?.apr,
		maxDrawdown: report?.maxDrawdown,
		trades: report?.trades,
	});

	return (
		<Card className="Report shadow">
			<Card.Header className="px-2.5 py-2">
				<span className="fw5">Report</span>
			</Card.Header>
			{/* Stats */}
			<Card.Body className="bg-gray-100 border-bottom">
				<Row>
					{stats.map((stat, idx) => (
						<StatCard
							key={idx}
							{...stat}
						/>
					))}
				</Row>
			</Card.Body>
			{/* Trade details */}
			<Card.Body>
				{runReport ? (
					<Flex justify="center">
						<Spinner />
					</Flex>
				) : report.trades.length > 0 ? (
					<Flex direction="column">
						{report.trades.slice(0, resultsLimit).map((trade, idx, arr) => (
							<TradeRow
								key={idx}
								tradeIdx={report.trades.length - idx}
								{...trade}
							/>
						))}
						<Button
							variant="outline-primary"
							onClick={() => setResultsLimit(prev => prev + RESULTS_LIMIT)}
							disabled={report.trades.length - resultsLimit <= 0}
						>
							Show {RESULTS_LIMIT} more ({commaFormat(Math.max(report.trades.length - resultsLimit, 0))} hidden)
						</Button>
					</Flex>
				) : (
					<Flex
						direction="column"
						justify="center"
						align="center"
						className="p-0.5"
					>
						<span className="text-warning fw5 f-rem-1.25">No trades</span>
						<span className="text-gray-600">Select symbols/exchanges and click run report</span>
					</Flex>
				)}
			</Card.Body>
		</Card>
	);
};

const StatCard = (props) => (
	<Col
		xs={12}
		md={6}
		lg={3}
	>
		<Card className="shadow">
			<span className="f-rem-0.85 fw6 text-gray-600 pl-2.5 pt-1.5 text-uppercase">{props.label}</span>
			<Flex
				justify="center"
				align="center"
				className="p-2 pb-3 f-rem-1.2"
			>
				{props.value}
			</Flex>
		</Card>
	</Col>
);

const TradeRow = (props) => {
	return (
		<Flex
			direction="column"
			className="TradeRow mb-4"
		>
			<Flex
				justify="between"
				className="mb-1.5"
			>
				<Flex>
					<span className="fw6 text-gray-700">{dayjs(props.timestamp).format('MM-DD-YY @ hh:mm A')}</span>
					<SlashDivider />
					<span>Avg funding: <span className="fw5">{props.avgFundingRate.toFixed(3)}%</span></span>
					<SlashDivider />
					<span className="fw5 text-gray-500">(#{commaFormat(props.tradeIdx)}; {commaFormat(props.trades.length)} trade{props.trades.length > 1 ? 's' : ''})</span>
				</Flex>
				<span className="text-gray-600 fw5">
					Cum PnL: <span className={getPosNegClassName(props.pnl)}>{currencyFormat(props.cumPnl, '$0.00')} <small>({props.pnl >= 0 ? '+' : ''}{currencyFormat(props.pnl, '$0.00')})</small></span>
				</span>
			</Flex>
			<div className="scroll-x">
				{props.trades.map((tradeInfo, idx) => (
					<>
						<Table
							key={idx}
							className={clsx('table-stripe border-left border-right border-bottom responsive', {
								'mt-2': idx > 0
							})}
						>
							<thead>
								<tr>
									<th>(#{commaFormat(idx + 1)}) Symbol/Exchange</th>
									<th>Type/Side</th>
									<th>Vol Opened/Avail</th>
									<th>Open/Close</th>
									<th>∆ Value</th>
									<th>Funding Rate</th>
									<th>Value at Funding</th>
									<th>Funding Earned</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								{tradeInfo.trades.map((trade, idx) => (
									<tr key={idx}>
										{/* Symbol/Exchange */}
										<td>
											<Flex align="center">
												<CryptoIcon
													symbol={trade.symbol}
							  	  			width={18}
							  	  			height={18}
							  	  			className="mr-1 show-shadow"
												/>
												<span className="fw5">{trade.symbol}</span>
												<SlashDivider />
												<span className="text-gray-600">{trade.exchange}</span>
											</Flex>
										</td>
										{/* Type/Side */}
										<td className="text-capitalize">
											<span>{trade.type.toLowerCase()}</span> <span className="fw4 text-gray-600">({trade.direction.toLowerCase()})</span>
										</td>
										{/* Vol Opened/Avail */}
										<td>
											<span>{millifyCurrency(trade.open.volumeUsd, false, { precision: 2 })}</span>
											<SlashDivider />
											<span className="text-gray-500">{millifyCurrency(trade.volumeUsdAvailable, false, { precision: 2 })}</span>
										</td>
										{/* Open/Close */}
										<td>
											<span className="text-gray-700">{removeZerosAfterDecimal(currencyFormat(trade.open.price, '$0.00', 18))}</span>
											<SlashDivider />
											<span>{removeZerosAfterDecimal(currencyFormat(trade.close.price, '$0.00', 18))}</span>
										</td>
										{/* ∆ Value */}
										<td className={getPosNegClassName(trade.volumeUsdDelta)}>
											<span>{currencyFormat(trade.volumeUsdDelta)} <span className="fw4">({trade.priceDeltaPerc.toFixed(2)}%)</span></span>
										</td>
										{/* Funding Rate */}
										<td>
											<span
												className={clsx({
													'text-gray-500': !trade.fundingRate
												})}
											>
												{trade.fundingRate ? `${(trade.fundingRate * 100).toFixed(3)}%` : '-'}
											</span>
										</td>
										{/* Value at Funding */}
										<td>
											<span
												className={clsx({
													'text-gray-500': !trade.volumeUsdAtFundingTime
												})}
											>
												{trade.volumeUsdAtFundingTime ? millifyCurrency(trade.volumeUsdAtFundingTime, false, { precision: 2 }) : '-'}
											</span>
										</td>
										{/* Funding Earned */}
										<td
											className={clsx({
												'text-success': trade.fundingFee,
												'text-gray-500': !trade.fundingFee,
											})}
										>
											<span>{trade.fundingFee ? currencyFormat(trade.fundingFee) : '-'}</span>
										</td>
										{/* Total */}
										<td>
											<span
												className={getPosNegClassName(trade.pnl)}
											>
												{currencyFormat(trade.pnl)}
											</span>
										</td>
									</tr>
								))}
								{/* Totals */}
								<tr>
									{/* Symbol/Exchange */}
									<td>
										<span className="fw6">Totals</span>
									</td>
									{/* Type/Side */}
									<td>
										<span className="text-gray-500">-</span>
									</td>
									{/* Vol Opened/Avail */}
									<td>
										<span className="text-gray-500">-</span>
									</td>
									{/* Open/Close */}
									<td>
										<span className="text-gray-500">-</span>
									</td>
									{/* ∆ Value */}
									<td className={`fw5 ${getPosNegClassName(sumBy(tradeInfo.trades, 'volumeUsdDelta'))}`}>
										<span>{currencyFormat(sumBy(tradeInfo.trades, 'volumeUsdDelta'))} <span className="fw4">({sumBy(tradeInfo.trades, 'priceDeltaPerc').toFixed(2)}%)</span></span>
									</td>
									{/* Funding Rate */}
									<td>
										<span className="fw5">{(sumBy(tradeInfo.trades, 'fundingRate') * 100).toFixed(3)}%</span>
									</td>
									{/* Value at Funding */}
									<td>
										<span className="text-gray-500">-</span>
									</td>
									{/* Funding Earned */}
									<td>
										<span className="text-success fw5">{currencyFormat(sumBy(tradeInfo.trades, 'fundingFee'))}</span>
									</td>
									{/* Total */}
									<td>
										<span className={`fw6 ${getPosNegClassName(tradeInfo.pnl)}`}>{currencyFormat(tradeInfo.pnl)}</span>
									</td>
								</tr>
							</tbody>
						</Table>
					</>
				))}
			</div>
		</Flex>
	);
};

const getPosNegClassName = number => number >= 0 ? 'text-success' : 'text-danger';
