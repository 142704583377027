import React from 'react';
import clsx from 'clsx';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faBezierCurve } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';
import Chart from 'components/Analyze/widgets/MACD/Chart';
import macdFormula from 'components/Analyze/widgets/MACD/formula';
import { KLINE_LIMIT } from 'components/Analyze/constants';

function MACD(props) {
	const { klineData = [] } = props;
	const macdSeries = macdFormula(klineData.slice(KLINE_LIMIT - 75));

	const {
		isMacdHeadingDown,
		isMacdHeadingUp,
		isSignalHeadingDown,
		isSignalHeadingUp,
		isHistogramHeadingDown,
		isHistogramHeadingUp,
	} = macdSeries?.prediction[macdSeries?.prediction?.length - 1] || {};

	const recommendation = isMacdHeadingDown && isSignalHeadingDown && isHistogramHeadingDown ? RECOMMENDATION_MAP.SHORT
		: isMacdHeadingUp && isSignalHeadingUp && isHistogramHeadingUp ? RECOMMENDATION_MAP.LONG
			: RECOMMENDATION_MAP.WAIT;

	return (
		<Card className="Widget MACD shadow-sm">
			<Card.Header>
				<span className="title">MACD</span>
				<FontAwesomeIcon icon={recommendation.summaryIcon} className={`icon ${recommendation.color}`}/>
			</Card.Header>
			<Card.Body>
				<Chart series={macdSeries} />
			</Card.Body>
			<Card.Footer>
				<span className="recommendation-label">MACD</span>
				<FontAwesomeIcon
					icon={isMacdHeadingDown || isMacdHeadingUp ? faBezierCurve : faClock}
					rotation={isMacdHeadingUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isMacdHeadingDown,
						'text-success': isMacdHeadingUp,
						'text-gray-600': !isMacdHeadingDown && !isMacdHeadingUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">Signal</span>
				<FontAwesomeIcon
					icon={isSignalHeadingDown || isSignalHeadingUp ? faBezierCurve : faClock}
					rotation={isSignalHeadingUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isSignalHeadingDown,
						'text-success': isSignalHeadingUp,
						'text-gray-600': !isSignalHeadingDown && !isSignalHeadingUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">Histogram</span>
				<FontAwesomeIcon
					icon={isHistogramHeadingDown || isHistogramHeadingUp ? faBezierCurve : faClock}
					rotation={isHistogramHeadingUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isHistogramHeadingDown,
						'text-success': isHistogramHeadingUp,
						'text-gray-600': !isHistogramHeadingDown && !isHistogramHeadingUp,
					})}
				/>
			</Card.Footer>
		</Card>
	);
};

export default MACD;


const RECOMMENDATION_MAP = {
	SHORT: {
		summaryIcon: faArrowDown,
		color: 'text-danger',
		text: 'short',
	},
	LONG: {
		summaryIcon: faArrowUp,
		color: 'text-success',
		text: 'long',
	},
	WAIT: {
		summaryIcon: faClock,
		color: 'text-gray-600',
		text: 'wait',
	},
};
