import { useEffect, useMemo, useCallback, useRef } from 'react';
import { usePlaceBulkOrdersV2Mutation } from 'api/client';
import { calculateStopLoss } from 'util/trades';

export default function usePlaceBulkOrders({
  positionsRefetch,
  setShowOrderError,
  setShowOrderSuccess,
  isOpeningOrders,
  positionsFiltered,
  amountPerOrder,
  closePerOrder,
  latestPrices,
  allContracts,
  preventKillOrder,
	stopLossValue,
}) {
  const [placeBulkOrders, placeBulkOrdersResult] = usePlaceBulkOrdersV2Mutation();
  const retried = useRef(false);

  useEffect(() => {
		if (placeBulkOrdersResult.isSuccess || placeBulkOrdersResult.isError) {
      positionsRefetch();

      const erroredSymbols = Object.entries(placeBulkOrdersResult?.data?.orderResultsBySymbol || {}).map(
        ([symbol, result]) => !result ? symbol : null
      ).filter(
        o => o
      );

      if (retried.current || erroredSymbols.length === 0) {
        retried.current = false;
        setTimeout(() => {
          placeBulkOrdersResult.reset();
        }, 500);
      } else {
        retried.current = true;

        const retryOrders = placeBulkOrdersResult?.originalArgs?.orders?.filter(
          o => erroredSymbols.includes(o.symbol)
        );
        
        if (retryOrders.length > 0) {
          placeBulkOrders({orders: retryOrders});
        }
      }
		}

		if (placeBulkOrdersResult.isError) {
			setShowOrderError(placeBulkOrdersResult.error);
			console.error('usePlaceBulkOrdersV2Mutation error', placeBulkOrdersResult.error);
		}
		if (placeBulkOrdersResult.isSuccess) {
			setShowOrderSuccess(`${placeBulkOrdersResult?.data?.successCount}/${placeBulkOrdersResult?.data?.ordersCount} orders ${isOpeningOrders ? 'opened' : 'closed'}`);
		}
	}, [
		placeBulkOrdersResult,
		positionsRefetch,
		isOpeningOrders,
    setShowOrderError,
    setShowOrderSuccess,
    placeBulkOrders,
	]);

  // Summarized loading state
  const placeBulkOrdersResultIsUnfinished = useMemo(() => {
		return placeBulkOrdersResult?.isLoading || placeBulkOrdersResult?.isError || placeBulkOrdersResult?.isSuccess;
	}, [
		placeBulkOrdersResult?.isLoading,
		placeBulkOrdersResult?.isError,
		placeBulkOrdersResult?.isSuccess,
	]);

  const handlePlaceBulkOrders = useCallback((action = 'Open', { closeToMin = false, positionsOverride = [] } = {}) => {
		const getVolumeToClose = (position) => {
			return (closePerOrder[getSymbol(position?.symbol)]/100) * Number(position?.positionAmt);
		};

		const getCloseToMin = (position) => {
			const c = allContracts.find(c => c.symbol === position.symbol);
			const toClose = Number(position.availableAmt) - c.tradeMinQuantity;
			return toClose;
		};

		if (!placeBulkOrdersResultIsUnfinished) {
			const orders = (positionsOverride.length > 0 ? positionsOverride : positionsFiltered).filter(
				p => action === 'Open' ? amountPerOrder[getSymbol(p?.symbol)] > 0
					: closePerOrder[getSymbol(p?.symbol)] > 0
			).map(p => {
				const currentPrice = Number(latestPrices[p?.symbol]?.price);
				const notionalOrderValue = amountPerOrder[getSymbol(p?.symbol)];
				const isShort = p.positionSide === 'SHORT';
				const stopLossPrice = action === 'Open' && stopLossValue ? calculateStopLoss(currentPrice, notionalOrderValue, stopLossValue, isShort) : 0;
				const avgPrice = Number(p.avgPrice);

				return {
					symbol: p.symbol,
					type: 'MARKET',
					side: isShort
						? action === 'Open' ? 'SELL'
							: 'BUY'
						: action === 'Open' ? 'BUY'
							: 'SELL',
					positionSide: p.positionSide,
					quantity: action === 'Open'
						// To open, get the amountToOpenUSDT / currentSymbolPriceUSDT
						? notionalOrderValue / currentPrice
						// To close, perc * amountInCurrency
						: closeToMin ? getCloseToMin(p)
							: preventKillOrder ? Math.min(getCloseToMin(p), getVolumeToClose(p))
								: getVolumeToClose(p),
					...action === 'Open' && stopLossValue ? {
						stopLossPrice,
						stopLossGuaranteed: isShort ? stopLossPrice > avgPrice : stopLossPrice < avgPrice,
					} : {},
				}
			}).filter(
				o => o.quantity > 0
			);

			return placeBulkOrders({orders});
		}
	}, [
		positionsFiltered,
		amountPerOrder,
		closePerOrder,
		placeBulkOrdersResultIsUnfinished,
		placeBulkOrders,
		latestPrices,
		allContracts,
		preventKillOrder,
		stopLossValue,
	]);

  // Auto retry failed order once
  useEffect(() => {

  }, [
    placeBulkOrdersResult,
  ])

  return {
    placeBulkOrders: handlePlaceBulkOrders,
    placeBulkOrdersResult,
    placeBulkOrdersResultIsUnfinished,
  };
};

function getSymbol(symbol) {
	return symbol?.split('-')[0];
};