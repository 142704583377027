import React from 'react';
import Container from "react-bootstrap/Container";
import Orders from 'components/Orders';
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";

function OrdersSection(props) {
	return (
		<Section
		  bg={props.bg}
		  textColor={props.textColor}
		  size={props.size}
		  bgImage={props.bgImage}
		  bgImageOpacity={props.bgImageOpacity}
		  className="OrdersSection"
		>
		  <Container fluid className="px-lg-4 px-xl-5">
		  	<SectionHeader
		  	  title={props.title}
		  	  subtitle={props.subtitle}
		  	  size={3}
		  	  className="text-center"
		  	/>
		  	<Orders />
		  </Container>
		</Section>
	);
};

export default OrdersSection;
