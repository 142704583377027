import axios from 'axios';
import { bingxApiUri } from 'api/client/constants';
import supabase from 'util/supabase';

let listenKey = null;
let listeners = [];

export const ListenKey = {
	updateListenKey(key) {
		listenKey = key;
		emitKeyUpdate(key);
	},
	subscribe(listener) {
		listeners = [...listeners, listener];

		if (listenKey) {
			listener(listenKey);
		}
	},
	unsubscribe(listener) {
		listeners = listeners.filter(l => l !== listener);
	},
	getKey() {
		getListenKey();
		return listenKey;
	},
};

function emitKeyUpdate(key) {
	for (let listener of listeners) {
    listener(key);
  }
};

function getListenKey() {
	const { access_token } = supabase.auth.session() || {};

	if (access_token) {
		axios({
			method: 'GET',
			url: `${bingxApiUri}/bingxSwaps/getWsListenKey`,
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Expires': '0',
				'authorization': access_token,
			},
		}).then(
			res => res?.data
		).then(
			newListenKey => {
				if (newListenKey !== listenKey) {
					ListenKey.updateListenKey(newListenKey);
				}

				setTimeout(getListenKey, 1000 * 60 * 60);// Ping every 60 minutes
			}
		).catch(
			err => {
				console.error('getListenKey', err);
				setTimeout(getListenKey, 1000 * 2);
			}
		);
	};
}
getListenKey();
