import queryConstructor from 'util/queryConstructor';

function getOrderbookSnapshot({symbol, type, limit, interval} = {}) {
	const queryParams = queryConstructor({symbol, type, limit, interval});
	return `getOrderbookSnapshot?${queryParams}`;
};

function getFundingRateHistoryOptions() {
	return `getFundingRateHistoryOptions`;
};

function getFundingRateHistory({symbol, exchange, startTime, endTime, limit} = {}) {
	const queryParams = queryConstructor({symbol, exchange, startTime, endTime, limit});
	return `getFundingRateHistory?${queryParams}`;
};

function getKlineHistory({symbol, exchange, type, interval, startTime, endTime, limit} = {}) {
	const queryParams = queryConstructor({symbol, exchange, type, interval, startTime, endTime, limit});
	return `getKlineHistory?${queryParams}`;
};

const handlers = (builder) => ({
	getOrderbookSnapshot: builder.query({
		query: getOrderbookSnapshot,
		keepUnusedDataFor: 1,
	}),

	getFundingRateHistoryOptions: builder.query({
		query: getFundingRateHistoryOptions,
		keepUnusedDataFor: 60,
	}),

	getFundingRateHistory: builder.query({
		query: getFundingRateHistory,
		keepUnusedDataFor: 1,
	}),

	getKlineHistory: builder.query({
		query: getKlineHistory,
		keepUnusedDataFor: 1,
	}),
});

export default handlers;
