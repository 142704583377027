import React from 'react';
import './styles.scss';

const FALLBACK = 'https://raw.githubusercontent.com/spothq/cryptocurrency-icons/master/svg/color/generic.svg';

function CryptoIcon(props) {
	const {
		symbol: _symbol = '',
		className = '',
		...rest
	} = props;

	const symbol = _symbol?.split?.('-USD')?.[0]?.toLowerCase?.();
	// const url = `https://cryptofonts.com/img/icons/${symbol}.svg`;
	const url = `https://static-app.bb-os.com/icon/${symbol.toUpperCase?.()}.png`;

	return (
		<img
			className={`CryptoIcon ${className}`.trim()}
			alt={`${symbol} icon`}
			src={url}
			onError={({ currentTarget }) => {
				currentTarget.onerror = null; //prevents looping
				currentTarget.src = FALLBACK;
			}}
			{...rest}
		/>
	);
};

export default CryptoIcon;
