import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import millify from 'millify';
import Countdown from 'react-countdown';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import { useGetFundingRateV2Query } from 'api/client';
import useMarketDepthWs from 'api/websockets/bingxSwaps/useMarketDepthWs';
import { POLLING_INTERVAL } from 'constants/bingxSwaps';
import { currencyFormat, commaFormat, millifyCurrency } from 'util/numbers';
import { PositionContext } from 'components/PositionSectionNew/constants';

function PositionSummary(props) {
	const { symbol, side, position, pricePrecision } = useContext(PositionContext);
	const symbolPure = symbol?.split?.('-')?.[0];

	/*** QUERIES ***/
	/*** Funding rate ***/
	const {
		data: fundingRate = {},
	} = useGetFundingRateV2Query({
		symbol: symbol,
	}, {
		pollingInterval: POLLING_INTERVAL,
	});

	/* WEBSOCKETS */
	/** Latest prices webhook **/
	const [
  	latestPrices = {},
  	setMarketDepthSubs,
  ] = useMarketDepthWs([symbol]);
  useEffect(
  	() => setMarketDepthSubs([symbol]),
  	// eslint-disable-next-line
  	[symbol]
  );

  const currentPrice = latestPrices?.[symbol]?.price || 0;

  // Show symbol and latest price in tab
	useEffect(() => {
		const title = `$${currentPrice.toFixed(pricePrecision)} | ${symbol}`;
		document.title = title;
	}, [
		symbol,
		currentPrice,
		pricePrecision,
	]);

	/*** POSITION ***/
	const {
		availableAmt = 0,
		positionAmt = 0,
		avgPrice = 0,
		breakEvenPrice = 0,
		realisedProfit = 0,
		unrealizedProfit = 0,
		leverage = 0,
		liquidationPrice = 0,
	} = position;

	const size = currentPrice * positionAmt;
	const combinedPnl = realisedProfit + unrealizedProfit;

	// console.log(position);

	/*** FUNDING RATE ***/
	const {
		markPrice = 0,
		lastFundingRate = 0,
		nextFundingTime = 0,
	} = fundingRate;

	const isPayingFundingRate = side === 'short' ? lastFundingRate < 0 : lastFundingRate > 0;
	const fundingRateAmt = availableAmt * markPrice * lastFundingRate * (isPayingFundingRate ? -1 : 1);

	return (
		<Card className="PositionSummary shadow">
			<Card.Header className="d-flex justify-content-between py-2 px-3">
				<span className={clsx('fw5', {
					'text-danger': side === 'short',
					'text-success': side === 'long',
				})}>
					{symbolPure} {leverage}x
				</span>
				<span className={posOrNegCls(combinedPnl)}>{millifyCurrency(combinedPnl, false, { precision: 2 })}</span>
			</Card.Header>
			<Card.Body className="py-2 px-3">
				{/*Size / Qty*/}
				<Flex className="item-wrapper">
					<span className="label">SIZE</span>
					<span className="value">{millifyCurrency(size)} <small className="text-gray-600">({millify(positionAmt)} {symbolPure})</small></span>
				</Flex>
				{/*Avg Price / Cur Price*/}
				<Flex className="item-wrapper">
					<span className="label">AVG<SlashDivider />CURRENT PRICE</span>
					<Flex className="value">
						<span>${commaFormat(avgPrice, pricePrecision, '0')}</span>
						<SlashDivider />
						<span
							className={posOrNegCls(
								side === 'short' ? avgPrice - currentPrice : currentPrice - avgPrice
							)}
						>
							${commaFormat(currentPrice, pricePrecision, '0')}
						</span>
					</Flex>
				</Flex>
				{/*Break Even / Liq Price*/}
				<Flex className="item-wrapper">
					<span className="label">BREAK EVEN<SlashDivider />LIQ PRICE</span>
					<Flex className="value">
						<span
							className={posOrNegCls(
								side === 'short' ? avgPrice - breakEvenPrice : breakEvenPrice - avgPrice
							)}
						>
							${commaFormat(breakEvenPrice, pricePrecision, '0')}
						</span>
						<SlashDivider />
						<span>${commaFormat(liquidationPrice, pricePrecision, '0')}</span>
					</Flex>
				</Flex>
				{/*Real / Unreal PNL*/}
				<Flex className="item-wrapper">
					<span className="label">REAL<SlashDivider />UNREAL PNL</span>
					<Flex className="value">
						<span className={posOrNegCls(realisedProfit)}>{millifyCurrency(realisedProfit, false, { precision: 2 })}</span>
						<SlashDivider />
						<span className={posOrNegCls(unrealizedProfit)}>{millifyCurrency(unrealizedProfit, false, { precision: 2 })}</span>
					</Flex>
				</Flex>
				{/*Funding*/}
				<Flex className="item-wrapper">
					<Flex>
						<span className="label">FUNDING</span>
						{nextFundingTime && (
							<span className="label pl-1 fw4">
								(<Countdown
									date={nextFundingTime}
									renderer={({formatted}) => [
										formatted.hours,
										formatted.minutes,
										formatted.seconds
									].join(':')}
								/>)
							</span>
						)}
					</Flex>
					<Flex
						className={clsx('value', {
							'text-success': !isPayingFundingRate,
							'text-danger': isPayingFundingRate,
						})}
					>
						<span>{commaFormat(lastFundingRate * 100, 3)}% <small>({currencyFormat(fundingRateAmt)})</small></span>
					</Flex>
				</Flex>
			</Card.Body>
		</Card>
	);
};

export default PositionSummary;

const posOrNegCls = (value) => clsx({
	'text-success': value >= 0,
	'text-danger': value < 0,
});
