import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import sum from 'lodash/sum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from "react-bootstrap/Table";
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import ToastOnResult from 'components/ToastOnResult';
import {
	// Mutations
	useCancelOrdersV2Mutation,
} from 'api/client';
import { commaFormat, removeZerosAfterDecimal, millifyCurrency } from 'util/numbers';
import { PositionContext, OPEN_ORDERS_TABLE_HEADERS, ORDER_TYPE_COLOR } from 'components/PositionSectionNew/constants';

function OpenOrders(props) {
	const { symbol, position, openOrders } = useContext(PositionContext);
	const [showBody, setShowBody] = useState(false);

	/* DATA */
	const { positionAmt, positionValue } = position;

	const totalOrdersValue = sum(
		openOrders.map(
			po => (po.origQty || 0) * (po.price || 0)
		)
	) - sum(
		openOrders.map(
			po => (po.executedQty || 0) * (po.avgPrice || 0)
		)
	);

	/* MUTATIONS */
	/** Modify Orders Mutation **/
	const [cancelOrders, cancelOrdersResult] = useCancelOrdersV2Mutation();

	useEffect(() => {
		cancelOrdersResult.isError && console.error('useCancelOrdersV2Mutation error', cancelOrdersResult.error);
	}, [
		cancelOrdersResult,
	]);

	return (
		<>
			<Card className="OpenOrders shadow">
				<Card.Header
					className={clsx('d-flex flex-column py-2 px-3', {
						'border-bottom-0 overflow-hidden': showBody,
					})}
					onDoubleClick={() => setShowBody(prev => !prev)}
				>
					<Flex justify="between">
						<span className="pr-3">Open orders <small className="text-gray-600">({commaFormat(openOrders.length)} • {commaFormat(totalOrdersValue/positionValue*100, 2, '0')}%)</small></span>
						<Button
							variant="link"
							size="sm"
							className="p-0 ml-2 text-danger hvr-text-darken-15 text-decoration-none"
							disabled={cancelOrdersResult.isLoading || openOrders.length < 1}
							onClick={() => {
								if (window.confirm('Are you sure?')) {
									cancelOrders({
										orderIds: openOrders.map(
											order => order.orderId
										),
										symbol: symbol,
									})
								}
							}}
						>
							{cancelOrdersResult.isLoading ? (
								<FontAwesomeIcon icon={faSpinnerThird} spin={true} />
							) : (
								`Cancel all (${openOrders?.length})`
							)}
						</Button>
					</Flex>
					<Flex justify="between">
						<Flex className="small">
							<span className="fw5 text-gray-700">Opening: {millifyCurrency(totalOrdersValue)}</span>
							<SlashDivider />
							<span className="text-gray-600">{millifyCurrency(positionValue+totalOrdersValue)} new total</span>
						</Flex>
					</Flex>
				</Card.Header>
				<Card.Body
					className={clsx('p-0 scroll-x', {
						'd-none': showBody
					})}
				>
					<OrdersTable
						orders={openOrders}
						positionAmt={positionAmt}
						cancelOrders={cancelOrders}
						cancelOrdersResult={cancelOrdersResult}
					/>
				</Card.Body>
			</Card>

			{/*Cancel orders result*/}
			<ToastOnResult
				success={cancelOrdersResult.isSuccess ? 'Order(s) canceled' : null}
				error={cancelOrdersResult.error}
				resetSuccess={cancelOrdersResult.reset}
				resetError={cancelOrdersResult.reset}
			/>
		</>
	);
};

export default OpenOrders;


const OrdersTable = (props) => {
	const {
		orders = [],
		positionAmt,
		cancelOrders,
		cancelOrdersResult,
	} = props;

	return (
		<Table className="OrdersTable table-stripe">
			<thead>
				<tr>
					{OPEN_ORDERS_TABLE_HEADERS.map(
						header => (
							<th key={header} scope="col">{header}</th>
						)
					)}
				</tr>
			</thead>
			<tbody>
				{orders?.length > 0 ? (
					orders?.map(
						order => (
							<OrderRow
								key={order.orderId}
								order={order}
								cancelOrder={() => cancelOrders({
									orderIds: [order.orderId],
									symbol: order.symbol,
								})}
								cancelOrdersResult={cancelOrdersResult}
								positionAmt={positionAmt}
							/>
						)
					)
				) : (
					<tr>
						<td colSpan={OPEN_ORDERS_TABLE_HEADERS.length} className="text-center text-gray-700">
							No open orders found
						</td>
					</tr>
				)}
			</tbody>
		</Table>
	);
};

const OrderRow = (props) => {
	const { side, pricePrecision, mouseEnterPriceline, dispatchContext } = useContext(PositionContext);

	const {
		order,
		cancelOrder,
		cancelOrdersResult,
		positionAmt,
	} = props;

	const isCanceling = cancelOrdersResult?.originalArgs?.orderIds?.some?.(
		orderId => orderId === order?.orderId
	);

	return (
		<tr
			className={clsx('OrderRow OpenOrderRow', {
				'mouseEnterPriceline': mouseEnterPriceline === order.orderId,
				'isTentativeOrder': order.isTentativeOrder,
			})}
			onMouseEnter={() => dispatchContext({ type: 'mouseEnterOrder', payload: order.orderId })}
			onMouseLeave={() => dispatchContext({ type: 'mouseLeaveOrder' })}
		>
			{/*TYPE*/}
			<td
				className={ORDER_TYPE_COLOR[order.actionShort]}
			>
				{order.actionShort}
			</td>
			{/*PRICE*/}
			<td>${commaFormat(order.price, pricePrecision, '-')}</td>
			{/*FILLED/TOTAL*/}
			<td>
				<span
					className={clsx({
						'text-gray-600': order.executedQty <= 0,
						'text-warning': order.executedQty > 0,
					})}
				>
					{millifyCurrency(order.avgPrice * order.executedQty || 0)}
				</span>
				<SlashDivider />
				<span>{millifyCurrency(order.price * order.origQty)}</span>
			</td>
			{/*% OF POS*/}
			<td>
				<span className="text-gray-600">{removeZerosAfterDecimal(commaFormat(order.origQty / positionAmt * 100, 2, '0'))}%</span>
			</td>
			{/*NEW AVG PRICE*/}
			<td>
				<span
					className={clsx({
						'text-success': side === 'short' ? order.newAvgPricePositionAvgPriceDiff >= 0 : order.newAvgPricePositionAvgPriceDiff < 0,
						'text-danger': side === 'short' ? order.newAvgPricePositionAvgPriceDiff <= 0 : order.newAvgPricePositionAvgPriceDiff > 0,
					})}
				>
					{millifyCurrency(order.newAvgPrice, false, { precision: 2 })}
				</span>
				<span className="text-gray-600 pl-1">({millifyCurrency(order.newAvgPricePositionAvgPriceDiff, true, { precision: 2 })})</span>
			</td>
			{/*ACTIONS*/}
			<td>
				<Button
					size="xs"
					variant="outline-danger"
					disabled={order.isTentativeOrder || cancelOrdersResult.isLoading || isCanceling}
					onClick={cancelOrder}
				>
					<FontAwesomeIcon icon={isCanceling ? faSpinnerThird : faTimes} spin={isCanceling} />
				</Button>
			</td>
		</tr>
	);
};
