import React, { useState, useMemo } from "react";
import clsx from 'clsx';
import dayjs from 'dayjs';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flex from "components/Flex";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import SlashDivider from "components/SlashDivider";
import Spinner from "components/Spinner";
import Toggle from "components/Toggle";
import { supportedSymbols, POLLING_INTERVAL } from 'constants/bingxSwaps';
import { currencyFormat, commaFormat } from "util/numbers";
import {
	useGetAccountBalanceV2Query,
	useGetProfitAndLossQuery,
	useGetPositionsV2Query,
} from 'api/client';

const SYMBOLS = Object.keys(supportedSymbols);

function StatsSection(props) {
	const [showTotalPnl, setShowTotalPnl] = useState(false);
	const [activeSymbol, setActiveSymbol] = useState(SYMBOLS[0]);

	return (
		<>
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
			  <Container>
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>

			  	<Row className="justify-content-center">
			  		<Col xs={12} lg={5} xl={4}>
				  		<AccountBalanceCard />
			  		</Col>
			  		<Col xs={12} lg={7} xl={5} className="mt-3 mt-lg-0">
		  				<Card className="shadow">
		  					<Card.Header>
		  						<Flex
		  							direction="column"
		  							justify="between"
		  							align="start"
		  						>
			  						<span className="mb-1.5">Account PNL</span>
			  						<Flex justify="between" className="w-100">
			  							<Toggle
			  								ops={SYMBOLS}
			  								active={activeSymbol}
			  								setActive={symbol => {
			  									setShowTotalPnl(false);
			  									setActiveSymbol(symbol);
			  								}}
			  								className="mr-2"
			  								style={{
			  									maxWidth: 'calc(100% - 44px)',
			  									overflowX: 'scroll'
			  								}}
			  							/>
			  							<Button
			  								size="sm"
			  								disabled={showTotalPnl}
			  								onClick={() => setShowTotalPnl(true)}
			  								style={{width: '44px'}}
			  							>
			  								Run
			  							</Button>
			  						</Flex>
		  						</Flex>
		  					</Card.Header>
		  					<Card.Body className="px-3 py-2">
		  						{!showTotalPnl ? <span>Press run</span> : (
		  							<TotalPnlCard symbol={activeSymbol} />
		  						)}
		  					</Card.Body>
		  				</Card>
			  		</Col>
			  	</Row>
			  </Container>
			</Section>
		</>
	);
};

export default StatsSection;


export const AccountBalanceCard = (props) => {
	const {
		data: accountBalance = {},
		isLoading,
	} = useGetAccountBalanceV2Query({}, {
		pollingInterval: POLLING_INTERVAL
	});

	const {
		data: positions = [],
	} = useGetPositionsV2Query({
		includeCurrentPrice: false
	}, {
		pollingInterval: POLLING_INTERVAL,
	});

	const getColor = (val) => isNaN(val) || val === 0 ? ''
		: val < 0 ? 'text-danger' : 'text-success';

	const positionsTotalValue = useMemo(() => {
		return positions.reduce(
			(acc, p) => {
				const totalValue = (p.positionAmt * p.avgPrice) + (p.unrealizedProfit * (p.positionSide === 'SHORT' ? -1 : 1));
				acc += totalValue ?? 0;
				return acc;
			}, 0
		)
	}, [positions]);

	const items = useMemo(() => {
		const {
  		availableMargin = 0,
  		freezedMargin = 0,
  		usedMargin = 0,
  		realisedProfit = 0,
  		unrealizedProfit = 0,
  		balance = 0,
  		equity = 0,
  	} = accountBalance;

		const totalPnl = (realisedProfit + unrealizedProfit) || 0;
		const pnlPercentageOfBalance = Math.round(
			Math.abs(totalPnl) / (balance || 0) * 1000
		) / 10;

		const activeLeverage = positionsTotalValue / (balance || 1);
		const relativeLeverage = positionsTotalValue / (equity || 1);

		return {
			'Balance': {
				val: currencyFormat(balance, '-'),
			},
			'Total PNL': {
				val: (
					<Flex align="center">
						<small className="text-gray-600">({totalPnl < 0 ? '-' : ''}{pnlPercentageOfBalance}%)&nbsp;</small>
						<span>{currencyFormat(totalPnl, '-')}</span>
					</Flex>
				),
				className: getColor(totalPnl),
			},
			'Position Margin': {
				val: currencyFormat(usedMargin, '-'),
			},
			'Frozen Margin': {
				val: currencyFormat(freezedMargin, '-'),
			},
			'Avail Margin': {
				val: currencyFormat(availableMargin, '-'),
			},
			'Active Leverage': {
				val: (
					<Flex>
						<span
							className={clsx({
								'text-success': activeLeverage <= 10,
								'text-info': activeLeverage > 10 && activeLeverage <= 15,
								'text-warning': activeLeverage > 15 && activeLeverage <= 25,
								'text-danger': activeLeverage > 25,
								'fw6': activeLeverage >= 30,
							})}
						>
							{commaFormat(activeLeverage, 2)}x
						</span>
						<SlashDivider />
						<span
							className={clsx({
								'text-success': relativeLeverage <= 10,
								'text-info': relativeLeverage > 10 && relativeLeverage <= 15,
								'text-warning': relativeLeverage > 15 && relativeLeverage <= 25,
								'text-danger': relativeLeverage > 25,
								'fw6': relativeLeverage >= 30,
							})}
							style={{opacity: '0.7'}}
						>
							({commaFormat(relativeLeverage, 2)}x)
						</span>
					</Flex>
				),
			},
		};
	}, [
		accountBalance,
		positionsTotalValue
	]);

	return (
		<Card className="shadow">
			<Card.Header>
				<Flex justify="between" align="center">
					<span>Equity</span>
					{isLoading ? <Spinner className="text-muted"/> : (
						<span
							className={accountBalance?.equity > accountBalance?.balance ? 'text-success' : 'text-danger'}
						>
							{currencyFormat(accountBalance?.equity, '-')}
						</span>
					)}
				</Flex>
			</Card.Header>
  		<Card.Body className="px-3 py-2">
  			{Object.entries(items).map(([key, {val, className}]) => (
  				<Flex
  					key={key}
  					justify="between"
  					className="mb-1"
  				>
  					<span className="text-gray-700">{key}</span>
  					<span className={className}>{val}</span>
  				</Flex>
  			))}
  		</Card.Body>
  	</Card>
	);
};

const TotalPnlCard = ({symbol}) => {
	const {
		data: totalPnl = {},
		isLoading,
	} = useGetProfitAndLossQuery({
		symbol: `${symbol}-USDT`
	});

	const items = useMemo(() => {
		const days = dayjs(totalPnl?.endDate).diff(dayjs(totalPnl?.startDate), 'd');
		const pnl = totalPnl?.pnl;

		return {
			'Total PnL': {
				val: currencyFormat(pnl, '-'),
				className: !pnl || pnl === 0 ? ''
					: pnl > 0 ? 'text-success' : 'text-danger',
			},
			'Start Date': {
				val: totalPnl?.startDate ? dayjs(totalPnl?.startDate).format('MM-DD-YYYY') : '-',
			},
			'End Date': {
				val: totalPnl?.endDate ? dayjs(totalPnl?.endDate).format('MM-DD-YYYY') : '-',
			},
			'Total Days': {
				val: `${days} days`
			},
			'Proj Annual': {
				val: currencyFormat(pnl / days * 365, '-'),
			},
		}
	}, [totalPnl]);

	return isLoading ? <Spinner className="text-muted text-center w-100"/> : (
		Object.entries(items).map(([key, {val, className}]) => (
			<Flex
				key={key}
				justify="between"
				className="mb-1"
			>
				<span className="text-gray-700">{key}</span>
				<span className={className}>{val}</span>
			</Flex>
		))
	)
};
