import { useEffect, useMemo, useRef } from 'react';
import {
	useGetKlineDataV2Query,
} from 'api/client';
import useKlineDataWsV2 from 'api/websockets/bingxSwaps/useKlineDataWsV2';
import { PERIOD_TO_MINS_MAP } from 'components/PositionSectionNew/constants';

export default function useGetChartData({symbol, period}) {
	/* QUERIES */
	/** kLine data **/
	const {
		data: klineData = [],
		isLoading: klineDataIsLoading,
		isFetching: klineDataIsFetching,
		error: klineDataError,
		isError: klineDataIsError,
		refetch: klineDataRefetch,
	} = useGetKlineDataV2Query({
		symbol,
		period,
	});

	const lastCandle = klineData?.slice?.(-1)?.[0] || {};
	const lastCandleTime = lastCandle?.time;

	/** kline data refetch **/
	useRefetchKlineData({
		period,
		lastCandleTime,
		refetch: klineDataRefetch,
	});

	/** Latest kline webhook **/
	const latestKline = useGetLatestKline({
		period,
		symbol,
	});

  /** Inject latestKline{} into end of klineData[] **/
  const chartData = useMergeKlineData({
  	klineData,
  	klineDataIsLoading,
  	latestKline,
  	lastCandle,
  });

  return {
  	chartData,
  	chartDataIsLoading: klineDataIsLoading,
  	chartDataIsFetching: klineDataIsFetching,
  	currentPrice: latestKline?.close || 0,
		chartDataError: klineDataError,
		chartDataIsError: klineDataIsError,
  };
};


/**
 * Check every second if we need to refetch klineData
 * based on the selected time interval toggle
 */
function useRefetchKlineData(props) {
	const { period, lastCandleTime, refetch } = props;

	useEffect(() => {
		function checkRefetch() {
			const chartMinsInterval = PERIOD_TO_MINS_MAP[period];
			const now = new Date().getTime();
			const minsDeltaFromLastHistoryTs = (now - lastCandleTime)/1000/60;

			if (minsDeltaFromLastHistoryTs > chartMinsInterval) {
				refetch();
			}
		};

		const interval = setInterval(checkRefetch, 1000);
		checkRefetch();

		return () => clearInterval(interval);
	}, [
		period,
		lastCandleTime,
		refetch,
	]);
};

/* Retrieve the latest kline data from ws stream */
function useGetLatestKline(props) {
	const { symbol, period } = props;

	const klineWsStr = `${symbol}@kline_${period}`;
  const [latestKlineMessages, setSubs] = useKlineDataWsV2([klineWsStr]);
  const latestKline = latestKlineMessages[symbol];
  useEffect(
  	() => setSubs([klineWsStr]),
  	// eslint-disable-next-line
  	[klineWsStr]
  );

  return latestKline;
};

/** Make sense of/merge kline data as returned at start of interval
 * with the latest streamed kline from ws.
 * Handle instance where streamed kline has newer timestamp than
 * static kline data (i.e. streamed kline is next candle but static
 * hasn't finished refetching yet at start of new interval).
 */
function useMergeKlineData(props) {
	const {
		klineData = [],
		klineDataIsLoading,
		latestKline = {},
		lastCandle = {},
	} = props;

	const chartDataRef = useRef(klineData);

  const chartData = useMemo(() => {
  	const latestKlineTime = latestKline?.time;
		const lastCandleTime = lastCandle?.time;

  	if (
  		klineDataIsLoading ||
  		// !latestKlineTime ||
  		!lastCandleTime
  	) return [];

  	/*****/

		const penultimateKlineData = klineData?.slice?.(0, -1);
		let chartData;

		if (latestKlineTime) {
			// If streamed latestKlineTime is same as current static klineData's lastCandleTime,
			// then just merge the data AND store it for future use as the current state of the
			// candlestick chart.
			if (latestKlineTime > lastCandleTime) {
				// Merge streamed latestKline into last snapshot of candlestick data
				chartData = [
					...chartDataRef.current,
					latestKline,
				];
			} else { //latestKlineTime === lastCandleTime
				// Merge it
				chartData = [
					...penultimateKlineData,
					latestKline,
				];
				// Store it as 'final' representation of candlestick data
				chartDataRef.current = chartData;
			}
		} else {
			chartData = klineData;
		}

		return chartData;
  }, [
  	klineDataIsLoading,
  	klineData,
  	latestKline,
  	lastCandle,
  ]);

  return chartData;
};
