import React from 'react';
import Flex from "components/Flex";
import Spinner from 'components/Spinner';
import MACD from 'components/Analyze/widgets/MACD';
import RSI from 'components/Analyze/widgets/RSI';
import EMA from 'components/Analyze/widgets/EMA';
import STOCH from 'components/Analyze/widgets/STOCH';
import { useGetSpotKlineDataQuery, useGetSpotSymbolPriceTickerQuery } from 'api/client';
import { KLINE_LIMIT } from 'components/Analyze/constants';

function AnalyzeRow(props) {
	const { symbol, interval, widgets = [] } = props;

	const {
		data: klineData = [],
		isLoading: klineDataIsLoading,
	} = useGetSpotKlineDataQuery({
		symbol: `${symbol}usdt`.toUpperCase(),
		interval: interval,
		limit: KLINE_LIMIT,
	}, {
		pollingInterval: 60 * 1000,
	});

	const {
		data: priceTicker,
		// isLoading: priceTickerIsLoading,
	} = useGetSpotSymbolPriceTickerQuery({
		symbol: `${symbol}usdt`.toUpperCase(),
	}, {
		pollingInterval: 5 * 1000,
	});

	return (
		<Flex
			direction="column"
			className="AnalyzeRow"
		>
			<div className="interval-divider">
				<div className="line line-left" />
				<span className="interval">{interval}</span>
				<div className="line line-right" />
			</div>
			{klineDataIsLoading ? (
				<Flex justify="center" align="center" className="w-100 pt-1 pb-2">
					<Spinner className="text-gray-600" />
				</Flex>
			) : (
				<div className="WidgetsGrid">
					{widgets.map(w => (
						<WidgetMap
							key={w}
							widget={w}
							klineData={[
								...klineData,
								...[{
									...klineData[klineData?.length - 1] || {},
									close: priceTicker,
								}]
							]}
						/>
					))}
				</div>
			)}
		</Flex>
	);
};

export default AnalyzeRow;


const WidgetMap = (props) => (
	props.widget === 'RSI' ? (
		<RSI {...props} />
	) : props.widget === 'MACD' ? (
		<MACD {...props} />
	) : props.widget === 'EMA' ? (
		<EMA {...props} />
	) : props.widget === 'STOCH' ? (
		<STOCH {...props} />
	) : null
);
