export function calculateStopLoss(currentPrice, notionalOrderValue, stopLossValue, isShort = false) {
	// Calculate the number of shares you can buy
	const numberOfShares = notionalOrderValue / currentPrice;

	// Calculate the loss per share to stay within the max loss
	const lossPerShare = stopLossValue / numberOfShares;

	// Calculate the stop-loss price based on position type
	let stopLossPrice;
	if (isShort) {
    // For a short position, set stop loss above the current price
		stopLossPrice = currentPrice + lossPerShare;
	} else {
		// For a long position, set stop loss below the current price
		stopLossPrice = currentPrice - lossPerShare;
	}

	return stopLossPrice;
};