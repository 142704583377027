import { Stochastic } from 'technicalindicators';
import { fixedAsNumber } from 'util/numbers';

const PERIOD = 14;
const SIGNAL_PERIOD = 3;
const THRESHOLD_UPPER = 80;
const THRESHOLD_LOWER = 20;

export default function getStochLines(klineData = []) {
	const { kLine, dLine } = Stochastic.calculate({
		...klineData.reduce(
			(acc, item) => {
				acc.high.push(item.high);
				acc.low.push(item.low);
				acc.close.push(item.close);
				return acc;
			}, {
				high: [],
				low: [],
				close: []
			}
		),
		period: PERIOD, //d
		signalPeriod: SIGNAL_PERIOD, //k
	}).reduce(
		(acc, { k, d }, idx) => {
			acc.kLine.push({
				x: klineData[idx + SIGNAL_PERIOD]?.openTime,
				y: fixedAsNumber(k, 2, undefined),
			});

			acc.dLine.push({
				x: klineData[idx + SIGNAL_PERIOD]?.openTime,
				y: fixedAsNumber(d, 2, undefined),
			});

			return acc;
		}, {
			kLine: [],
			dLine: [],
		}
	);

	const prediction = kLine.map(
		({ x, y }, idx) => {
			const k = y;
			const twoAgoK = kLine[idx - 2]?.y;
			const prevK = kLine[idx - 1]?.y;
			const nextK = kLine[idx + 1]?.y;

			const d = dLine[idx];
			const twoAgoD = dLine[idx - 2]?.y;
			const prevD = dLine[idx - 1]?.y;
			const nextD = dLine[idx + 1]?.y;

			const kLineHistoryThreshold = kLine.slice(0, idx).reverse().map(
				({ y }) => ({
					isAboveThreshold: y >= THRESHOLD_UPPER,
					isBelowThreshold: y <= THRESHOLD_LOWER,
				})
			).filter(
				({ isAboveThreshold, isBelowThreshold }) => isAboveThreshold || isBelowThreshold
			);

			const dLineHistoryThreshold = dLine.slice(0, idx).reverse().map(
				({ y }) => ({
					isAboveThreshold: y >= THRESHOLD_UPPER,
					isBelowThreshold: y <= THRESHOLD_LOWER,
				})
			).filter(
				({ isAboveThreshold, isBelowThreshold }) => isAboveThreshold || isBelowThreshold
			);

			const isKSlopeDown = idx < 1 ? false
				: idx < kLine.length - 2 ? nextK - prevK < 0
					: k - twoAgoK < 0 && k <= prevK;
			const isKSlopeUp = idx < 1 ? false
				: idx < kLine.length - 2 ? nextK - prevK > 0
					: k - twoAgoK > 0 && k >= prevK;

			const isDSlopeDown = idx < SIGNAL_PERIOD ? false
				: idx < dLine.length - 2 ? nextD - prevD < 0
					: d - twoAgoD < 0 && d <= prevD;
			const isDSlopeUp = idx < SIGNAL_PERIOD ? false
				: idx < dLine.length - 2 ? nextD - prevD > 0
					: d - twoAgoD > 0 && d >= prevD;

			// The most recent threshold crossed was the lower one, current k is now above lower threshold, but still below 35
			const kIsHeadingUp = kLineHistoryThreshold?.[0]?.isBelowThreshold && k >= THRESHOLD_LOWER && k <= 35 && isKSlopeUp;
			// The most recent threshold crossed was the upper one, current k is now below upper threshold, but still greater than 65;
			const kIsHeadingDown = kLineHistoryThreshold?.[0]?.isAboveThreshold && k <= THRESHOLD_UPPER && k >= 65 && isKSlopeDown;

			// The most recent threshold crossed was the lower one, current k is now above lower threshold, but still below 35
			const dIsHeadingUp = dLineHistoryThreshold?.[0]?.isBelowThreshold && d >= THRESHOLD_LOWER && d <= 35 && isDSlopeUp;
			// The most recent threshold crossed was the upper one, current k is now below upper threshold, but still greater than 65;
			const dIsHeadingDown = dLineHistoryThreshold?.[0]?.isAboveThreshold && d <= THRESHOLD_UPPER && d >= 65 && isDSlopeDown;

			const isKBelowD = k - d < 0;

			return {
				kIsHeadingUp,
				kIsHeadingDown,
				dIsHeadingUp,
				dIsHeadingDown,
				isKBelowD,
			};
		}
	);

	return {
		kLine,
		dLine,
		prediction,
	};
};
