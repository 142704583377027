import React, { useState, useEffect, useMemo, useCallback } from "react";
import clsx from 'clsx';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Flex from "components/Flex";
import Spinner from 'components/Spinner';
import Toggle from "components/Toggle";
import {
	// Queries
	useGetFeeRateQuery,
	// Mutations
	usePlaceOrderMutation,
} from 'api/client';
import { getDefaultError } from 'util/error';
import { currencyFormat } from 'util/numbers';

const TRADE_TYPE_OPTIONS = ['Limit', 'Market'];
const ORDER_ACTION_OPTIONS = ['Open', 'Close'];

function CreateOrderPopup(props) {
	const {
		symbol,
		defaultPrice,
		positionSide,
		positionAvgPrice,
		currentPrice,
		pendingOrdersRefetch,
		availableVolumeToOpen,
		availableVolumeToClose,
		closePopup
	} = props;

	const [price, setPrice] = useState(roundToFiftieth(defaultPrice || 0));
	const [orderAction, setOrderAction] = useState(getOrderAction(positionSide, price, currentPrice));
	const [tradeType, setTradeType] = useState(TRADE_TYPE_OPTIONS[0]);
	const [volume, setVolume] = useState(5);
	const [placeOrderError, setPlaceOrderError] = useState(false);
	const [placeOrderSuccess, setPlaceOrderSuccess] = useState(false);
	const { data: feeRate = {} } = useGetFeeRateQuery();

	const profit = useMemo(() => {
		const fee = (volume * price * feeRate.maker)*-1;
		const isShort = positionSide === 'Short';
		const profit = orderAction !== 'Close' ? null
			: isShort ? (positionAvgPrice * volume) - (price * volume) - fee
				: (price * volume) - (positionAvgPrice * volume) - fee;

		return profit;
	}, [
		orderAction,
		volume,
		price,
		positionSide,
		positionAvgPrice,
		feeRate?.maker,
	]);

	// Place order mutation
	const [placeOrder, placeOrderResult] = usePlaceOrderMutation();

	useEffect(() => {
		if (placeOrderResult.isSuccess || placeOrderResult.isError) {
			placeOrderResult.reset();
			pendingOrdersRefetch();
		}

		if (placeOrderResult.isError) {
			setPlaceOrderError(placeOrderResult.error);
			console.error('usePlaceOrderMutation error', placeOrderResult.error);
		}
		if (placeOrderResult.isSuccess) {
			setPlaceOrderSuccess(true);
			setTimeout(closePopup, 500);
		}
	}, [placeOrderResult, pendingOrdersRefetch, closePopup]);

	const handlePlaceOrder = useCallback(() => {
		if (!placeOrderResult.isLoading) {
			const params = {
				symbol,
				side: getOrderSide(positionSide, price, currentPrice),
				price,
				volume,
				type: tradeType,
				action: orderAction,
			};

			return placeOrder(params);
		}
	}, [symbol, positionSide, price, currentPrice, volume, tradeType, orderAction, placeOrderResult?.isLoading, placeOrder]);

	return (
		<>
			{placeOrderError && (
				<Alert variant="danger">
					<Flex direction="column" align="center" className="w-100">
						<span>{getDefaultError(placeOrderError)}</span>
					</Flex>
				</Alert>
			)}
			{!placeOrderSuccess ? (
				<>
					<Flex direction="column" className="pt-3 px-3" style={{paddingBottom: '5rem'}}>
						<Flex justify="between">
							{/*Limit or market*/}
							<Toggle
								ops={TRADE_TYPE_OPTIONS}
								active={tradeType}
								setActive={setTradeType}
								className="w-fit"
							/>
							{/*Open or close order*/}
							<Toggle
								ops={ORDER_ACTION_OPTIONS}
								active={orderAction}
								setActive={setOrderAction}
								className="w-fit"
							/>
						</Flex>
						{/*Limit price*/}
						<Form.Group
							controlId="price"
							className="mt-3 mb-0"
						>
							<Flex justify="between" className="f-rem-0.85 fw-5 mb-1">
								<Form.Label className="mb-0 text-gray-700">Price</Form.Label>
								<span
									className="text-primary hvr-text-darken-10 pointer"
									onClick={() => {
										const priceModifier = positionSide === 'Short'
											? orderAction === 'Open' ? 0.05 : -0.05
											: orderAction === 'Open' ? -0.05 : 0.05;

										setPrice(roundToFiftieth(
											roundToFiftieth(currentPrice) + priceModifier)
										);
									}}
								>
									Closest price
								</span>
							</Flex>
							<InputGroup>
								<InputGroup.Text
									className="pointer"
									onClick={() => setPrice(prev => roundToFiftieth(prev - 0.05))}
								>
									-
								</InputGroup.Text>
								<Form.Control
									aria-label="Price (to the nearest 0.05 dollar)"
									value={tradeType === 'Market' ? 'Best market price' : price}
									type={tradeType === 'Market' ? "text" : "number"}
									step="1"
									onChange={e => setPrice(e.target.value)}
									className="text-center"
									disabled={tradeType === 'Market'}
								/>
								<InputGroup.Text
									className="pointer"
									onClick={() => setPrice(prev => roundToFiftieth(prev + 0.05))}
								>
									+
								</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						{/*Volume*/}
						<Form.Group controlId="volume" className="mt-3 mb-0">
							<Flex justify="between" className="f-rem-0.85 fw-5 mb-1">
								<Form.Label className="mb-0 text-gray-700">Volume</Form.Label>
								<span
									className="text-primary hvr-text-darken-10 pointer"
									onClick={() => setVolume(
										orderAction === 'Open' ? roundToFiftieth(availableVolumeToOpen)
											: roundToFiftieth(availableVolumeToClose)
									)}
								>
									All ({orderAction === 'Open' ? roundToFiftieth(availableVolumeToOpen) : roundToFiftieth(availableVolumeToClose)})
								</span>
							</Flex>
							<InputGroup>
								<InputGroup.Text
									className="pointer"
									onClick={() => setVolume(prev => prev - 1)}
								>
									-
								</InputGroup.Text>
								<Form.Control
									aria-label="Volume"
									value={volume}
									type="number"
									step="1"
									onChange={e => setVolume(e.target.value)}
									className="text-center"
								/>
								<InputGroup.Text
									className="pointer"
									onClick={() => setVolume(prev => prev + 1)}
								>
									+
								</InputGroup.Text>
							</InputGroup>
						</Form.Group>
						{/*Submit*/}
						<Button
							size="sm"
							className="mt-4"
							disabled={placeOrderResult.isLoading}
							onClick={handlePlaceOrder}
						>
							{placeOrderResult.isLoading ? <Spinner /> : 'Submit'}
						</Button>
					</Flex>
					{/*Footer*/}
					<Row className="no-gutters bg-light py-1 px-2 w-100" style={{position: 'absolute', bottom: 0}}>
						<Col>
							<Flex direction="column">
								<span className="f-rem-0.75 text-gray-700">Cur price</span>
								<span className="f-rem-0.95 text-gray-800">{currencyFormat(currentPrice)}</span>
							</Flex>
						</Col>
						<Col>
							<Flex direction="column" className="text-center">
								<span className="f-rem-0.75 text-gray-700">Order total</span>
								<span className="f-rem-0.95 text-gray-800">{currencyFormat(price*volume)}</span>
							</Flex>
						</Col>
						<Col>
							<Flex direction="column" className="text-right">
								<span className="f-rem-0.75 text-gray-700">Profit</span>
								<span
									className={clsx({
										'text-success': profit && profit >= 0,
										'text-danger': profit && profit < 0
									})}
								>
									{profit && profit >= 0 && '+'}{currencyFormat(profit, '-')}
								</span>
							</Flex>
						</Col>
					</Row>
				</>
			) : (
				<Alert variant="success">
					<Flex direction="column" align="center" className="w-100">
						<span>Order placed!</span>
						<span className="f-rem-0.85 fw-5 text-success text-darken-5">This will auto-close</span>
					</Flex>
				</Alert>
			)}
		</>
	);
};

export default CreateOrderPopup;


const roundToFiftieth = num => Math.round(
	(Math.round(num / 0.05) * 0.05) * 100
) / 100;

const getOrderSide = (positionSide, price, currentPrice) =>
	positionSide === 'Short'
		? price >= currentPrice ? 'Ask' : 'Bid'
		: price <= currentPrice ? 'Bid' : 'Ask';

const getOrderAction = (positionSide, price, currentPrice) =>
	positionSide === 'Short'
		? price >= currentPrice ? 'Open' : 'Close'
		: price <= currentPrice ? 'Close' : 'Open';
