import { useMemo } from 'react';

function useFormatOrderbook({
	asks = {},
	bids = {},
	maxLines = 10,
	grouping = 1,
	decimals = String(grouping || 1).split('.')[1]?.length || 0, //gets decimal count to auto set decimals if grouping is < 1
	qtyAsPrice = false,
} = {}) {
	const {
		asks: descendingAsks = [],
		bids: descendingBids = [],
		minAskPrice = 0,
		maxAskPrice = 0,
		minBidPrice = 0,
		maxBidPrice = 0,
		minAskQty = 0,
		maxAskQty = 0,
		minBidQty = 0,
		maxBidQty = 0,
		minPrice = 0,
		maxPrice = 0,
		minQty = 0,
		maxQty = 0,
	} = useMemo(() => {
		return formatOrderbook({asks, bids, maxLines, grouping, decimals, qtyAsPrice});
	}, [
		asks,
		bids,
		maxLines,
		grouping,
		decimals,
		qtyAsPrice,
	]);

	return {
		asks: descendingAsks,
		bids: descendingBids,
		minAskPrice,
		maxAskPrice,
		minBidPrice,
		maxBidPrice,
		minAskQty,
		maxAskQty,
		minBidQty,
		maxBidQty,
		minPrice,
		maxPrice,
		minQty,
		maxQty,
	};
};

export default useFormatOrderbook;


export function formatOrderbook({
	asks = {},
	bids = {},
	maxLines = 10,
	grouping = 1,
	decimals = String(grouping || 1).split('.')[1]?.length || 0, //gets decimal count to auto set decimals if grouping is < 1
	qtyAsPrice = false,
} = {}) {
	const allDescendingAsks = Object.entries(
		Object.entries(asks).reduce(
			(acc, [price, qty]) => {
				const priceRounded = (Math.round(Number(price) / grouping) * grouping).toFixed(decimals);
				acc[priceRounded] = (acc[priceRounded] || 0) + Number(qty);
				return acc;
			}, {}
		)
	).map(
		([price, qty]) => ({ price: Number(price), qty: !qtyAsPrice ? Number(qty) : Number(qty) * Number(price) })
	).sort(
		(a, b) => b.price - a.price //descending
	);

	const allDescendingBids = Object.entries(
		Object.entries(bids).reduce(
			(acc, [price, qty]) => {
				const priceRounded = (Math.round(Number(price) / grouping) * grouping).toFixed(decimals);
				acc[priceRounded] = (acc[priceRounded] || 0) + Number(qty);
				return acc;
			}, {}
		)
	).map(
		([price, qty]) => ({ price: Number(price), qty: !qtyAsPrice ? Number(qty) : Number(qty) * Number(price) })
	).sort(
		(a, b) => b.price - a.price //descending
	);

	const descendingAsks = allDescendingAsks.slice(maxLines * -1);
	const descendingBids = allDescendingBids.slice(0, maxLines);

	const minAskPrice = descendingAsks[0]?.price;
	const maxAskPrice = descendingAsks[descendingAsks.length - 1]?.price;
	const minBidPrice = descendingBids[descendingBids.length - 1]?.price;
	const maxBidPrice = descendingBids[0]?.price;

	const minAskQty = Math.min(...descendingAsks.map(a => a.qty));
	const maxAskQty = Math.max(...descendingAsks.map(a => a.qty));
	const minBidQty = Math.min(...descendingBids.map(a => a.qty));
	const maxBidQty = Math.max(...descendingBids.map(a => a.qty));

	const minPrice = Math.min(...[
		...descendingAsks.map(a => a.price),
		...descendingBids.map(b => b.price),
	]);
	const maxPrice = Math.max(...[
		...descendingAsks.map(a => a.price),
		...descendingBids.map(b => b.price),
	]);

	const minQty = Math.min(...[
		...descendingAsks.map(a => a.qty),
		...descendingBids.map(b => b.qty),
	]);
	const maxQty = Math.max(...[
		...descendingAsks.map(a => a.qty),
		...descendingBids.map(b => b.qty),
	]);

	return {
		asks: descendingAsks,
		bids: descendingBids,
		minAskPrice,
		maxAskPrice,
		minBidPrice,
		maxBidPrice,
		minAskQty,
		maxAskQty,
		minBidQty,
		maxBidQty,
		minPrice,
		maxPrice,
		minQty,
		maxQty,
	};
};
