import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CHART_COLORS, CHART_HEIGHT } from 'components/Analyze/constants';

function Chart(props) {
	const options = {
		title: false,
		credits: {
			endabled: false,
			color: '#212529',
		},
		chart: {
			plotBorderWidth: 0,
			backgroundColor: '#212529',
			spacing: [4, 8, 4, 4],
		},
		xAxis: {
			title: false,
			type: 'datetime',
	  	maxPadding: 0,
	  	minPadding: 0,
	  	visible: false,
		},
		yAxis: {
  		// title: false,
  		// gridLineWidth: 0,
  		// minPadding: 0,
  		// maxPadding: 0,
  		// min: 0,
  		// max: 100,
  		// labels: {
	  	// 	padding: 0,
	  	// 	style: {
	  	// 		color: '#ffffff',
	  	// 		fontSize: '5px',
	  	// 	}
	  	// },
	  	visible: false,
	  	maxPadding: 0,
  	},
  	tooltip: {
  		shared: true,
  	},
		series: [{
			type: 'line',
			name: 'SMA',
			color: CHART_COLORS.ORANGE,
			connectNulls: true,
  		showInLegend: false,
  		lineWidth: 0.75,
  		marker: {
	  		states: {
	  			hover: {
	  				radius: 2,
	  			}
	  		}
	  	},
			data: props.series.sma,
		}, {
			type: 'line',
			name: 'Long EMA',
			color: CHART_COLORS.RED,
			connectNulls: true,
  		showInLegend: false,
  		lineWidth: 0.75,
  		marker: {
	  		states: {
	  			hover: {
	  				radius: 2,
	  			}
	  		}
	  	},
			data: props.series.long,
		}, {
			type: 'line',
			name: 'Short EMA',
			color: CHART_COLORS.GREEN,
			connectNulls: true,
  		showInLegend: false,
  		lineWidth: 0.75,
  		marker: {
	  		states: {
	  			hover: {
	  				radius: 2,
	  			}
	  		}
	  	},
			data: props.series.short,
		}],
	};

	return (
		<div className="Chart EMA">
			<HighchartsReact
		    highcharts={Highcharts}
		    containerProps={{
					style: {
						height: CHART_HEIGHT,
					}
				}}
		    options={options}
		  />
		</div>
	);
};

export default Chart;
