import React, { useState, useContext, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import ToastOnResult from 'components/ToastOnResult';
import Toggle from 'components/Toggle';
import {
	// Mutations
	usePlaceOrderV2Mutation,
} from 'api/client';
import useMarketDepthWs from 'api/websockets/bingxSwaps/useMarketDepthWs';
import { MUTATION_RESET_DELAY } from 'constants/mutations';
import { millifyCurrency } from 'util/numbers';
import { PositionContext } from 'components/PositionSectionNew/constants';

const TYPE_OPS = ['MARKET', 'BBO'];
const PERCENT_OPS = [1, 5, 10, 15, 25, 50, 75, 100];

function QuickClose(props) {
	const { side, symbol, position } = useContext(PositionContext);
	const [orderType, setOrderType] = useState(TYPE_OPS[0]);
	const [orderPercentage, setOrderPercentage] = useState(null);

	/* WEBSOCKETS */
	const [
  	latestPrices = {},
  	setMarketDepthSubs,
  ] = useMarketDepthWs([symbol]);
  useEffect(
  	() => setMarketDepthSubs([symbol]),
  	// eslint-disable-next-line
  	[symbol]
  );

  /* MUTATIONS */
	/** Place order mutation **/
	const [placeOrder, placeOrderResult] = usePlaceOrderV2Mutation();

	useEffect(() => {
		placeOrderResult.isError && console.error('usePlaceOrderV2Mutation error', placeOrderResult.error);
	}, [
		placeOrderResult,
		orderType,
	]);

	/* DATA */
  const { positionAmt, avgPrice: positionAvgPrice } = position;
  const priceSide = side === 'short' ? 'askPrice' : 'bidPrice';
  const currentPrice = latestPrices?.[symbol]?.[priceSide] || 0;
  const valueAvailableToClose = positionAmt * currentPrice;
  const notionalValueToClose = (orderPercentage / 100) * positionAmt * currentPrice;
  const qtyToClose = notionalValueToClose / currentPrice;

  const isInProfit = (side === 'short' && positionAvgPrice >= currentPrice) ||
  	(side === 'long' && currentPrice >= positionAvgPrice) ? 1 : -1;
  const profit = Math.abs(
		(positionAvgPrice * qtyToClose) - (currentPrice * qtyToClose)
	) * isInProfit;

	const placeOrderIsDisabled = placeOrderResult?.isLoading ||
		placeOrderResult?.isSuccess ||
		placeOrderResult?.isError ||
		!notionalValueToClose;

	/* CALLBACKS */
	const handlePlaceCloseOrder = useCallback(
		() => {
			if (!placeOrderIsDisabled) {
				const type = orderType === 'BBO' ? 'LIMIT' : orderType;

				const order = {
					symbol,
					type,
					side: side === 'short' ? 'BUY' : 'SELL',
					positionSide: side.toUpperCase(),
					quantity: qtyToClose,
					...type === 'LIMIT' ? {
						price: currentPrice,
					} : {},
				};

				return placeOrder({ order });
			}
		}, [
			side,
			symbol,
			placeOrderIsDisabled,
			orderType,
			currentPrice,
			placeOrder,
			qtyToClose,
		]
	);

	return (
		<>
			<Card className="QuickClose shadow">
				<Card.Header className="py-2 px-3 d-flex justify-content-between align-items-center">
					<span>Quick <span className="text-danger">Close</span></span>
					{placeOrderResult?.isLoading ? (
						<FontAwesomeIcon icon={faSpinnerThird} spin={true} className="text-muted" />
					) : placeOrderResult?.isSuccess ? (
						<FontAwesomeIcon icon={faCheck} className="text-success" />
					) : placeOrderResult?.isError ? (
						<FontAwesomeIcon icon={faTimes} className="text-danger" />
					) : null}
				</Card.Header>
				<Card.Body className="py-2.5 px-3">
					<Toggle
						ops={TYPE_OPS}
						active={orderType}
						setActive={setOrderType}
						opClassName="f-rem-0.85 fw5"
						activeClassName={clsx({
							'text-success': side === 'long' && orderType === 'MARKET',
							'text-danger': side === 'short' && orderType === 'MARKET',
							'text-info': orderType === 'BBO',
						})}
						className="mb-2.5"
					/>

					<Toggle
						className="scroll-x mb-3"
						opClassName="f-rem-0.85"
						label={
							<Flex justify="between" className="f-rem-0.85 fw5 mb-0.5">
								<span className="text-gray-700">% of Pos</span>
								<Flex>
									<span className="text-gray-700">{millifyCurrency(notionalValueToClose || 0)}</span>
									<SlashDivider />
									<span className="text-gray-600 text-right">{millifyCurrency(valueAvailableToClose || 0)}</span>
								</Flex>
							</Flex>
						}
						ops={PERCENT_OPS}
						opFormatDisplay={op => `${op}%`}
						isOpDisabled={(disabled, op) => {
							const value = (op / 100) * positionAmt * currentPrice;
							return placeOrderIsDisabled || value > valueAvailableToClose;
						}}
						isOpLoading={(loading, op) => {
							return placeOrderResult?.isLoading && op === orderPercentage;
						}}
						active={op => op === orderPercentage}
						setActive={op => setOrderPercentage(op)}
					/>
					{/* Place order */}
					<Button
						size="sm"
						variant={clsx({
							'success': placeOrderResult.isSuccess,
							'danger': placeOrderResult.isError,
							'outline-success': !placeOrderResult.isSuccess && !placeOrderResult.isError && isInProfit === 1,
							'outline-danger': !placeOrderResult.isSuccess && !placeOrderResult.isError && isInProfit === -1,
						})}
						block
						disabled={placeOrderIsDisabled}
						onClick={handlePlaceCloseOrder}
					>
						{placeOrderResult.isLoading ? (
							<FontAwesomeIcon icon={faSpinnerThird} spin={true} />
						) : placeOrderResult.isSuccess ? (
							<FontAwesomeIcon icon={faCheck} />
						) : placeOrderResult.isError ? (
							<FontAwesomeIcon icon={faTimes} />
						) : `Close ${millifyCurrency(notionalValueToClose || 0)} (${millifyCurrency(profit || 0, true)})`}
					</Button>
				</Card.Body>
			</Card>

			<ToastOnResult
				success={placeOrderResult.isSuccess ? 'Order closed' : null}
				error={placeOrderResult.error}
				resetSuccess={placeOrderResult.reset}
				resetError={placeOrderResult.reset}
				delay={MUTATION_RESET_DELAY}
			/>
		</>
	);
};

export default QuickClose;
