import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CHART_COLORS, CHART_HEIGHT } from 'components/Analyze/constants';

function Chart(props) {
	const options = {
		title: false,
		credits: {
			endabled: false,
			color: '#212529',
		},
		chart: {
			plotBorderWidth: 0,
			backgroundColor: '#212529',
			spacing: [4, 8, 4, 4],
		},
		xAxis: {
			title: false,
			type: 'datetime',
	  	maxPadding: 0,
	  	minPadding: 0,
	  	visible: false,
		},
		yAxis: {
  		// title: false,
  		// gridLineWidth: 0,
  		// minPadding: 0,
  		// maxPadding: 0,
  		// min: 0,
  		// max: 100,
  		// labels: {
	  	// 	padding: 0,
	  	// 	style: {
	  	// 		color: '#ffffff',
	  	// 		fontSize: '5px',
	  	// 	}
	  	// },
	  	visible: false,
	  	maxPadding: 0,
  	},
  	tooltip: {
  		shared: true,
  	},
		series: [{
			type: 'line',
			name: 'Lower Threshold',
			color: CHART_COLORS.LIGHT_GRAY,
			showInLegend: false,
			lineWidth: 0.25,
			dashStyle: 'Dash',
			enableMouseTracking: false,
			data: props.series.rsi.map(
				d => ({
					x: d.x,
					y: 30,
				})
			),
		}, {
			type: 'line',
			name: 'Fifty',
			color: CHART_COLORS.GRAY,
			showInLegend: false,
			lineWidth: 0.25,
			dashStyle: 'Dash',
			enableMouseTracking: false,
			data: props.series.rsi.map(
				d => ({
					x: d.x,
					y: 50,
				})
			),
		}, {
			type: 'line',
			name: 'Upper Threshold',
			color: CHART_COLORS.LIGHT_GRAY,
			showInLegend: false,
			lineWidth: 0.25,
			dashStyle: 'Dash',
			enableMouseTracking: false,
			data: props.series.rsi.map(
				d => ({
					x: d.x,
					y: 70,
				})
			),
		}, {
			type: 'line',
			name: 'RSI',
			color: CHART_COLORS.PURPLE,
			connectNulls: true,
  		showInLegend: false,
  		lineWidth: 0.75,
  		marker: {
	  		states: {
	  			hover: {
	  				radius: 2,
	  			}
	  		}
	  	},
			data: props.series.rsi,
		}, {
			type: 'line',
			name: 'SMA',
			color: CHART_COLORS.YELLOW,
			connectNulls: true,
  		showInLegend: false,
  		lineWidth: 0.75,
  		marker: {
	  		states: {
	  			hover: {
	  				radius: 2,
	  			}
	  		}
	  	},
			data: props.series.sma,
		}],
	};

	return (
		<div className="Chart RSI">
			<HighchartsReact
		    highcharts={Highcharts}
		    containerProps={{
					style: {
						height: CHART_HEIGHT,
					}
				}}
		    options={options}
		  />
		</div>
	);
};

export default Chart;
