import queryConstructor from 'util/queryConstructor';

function getSpotMarketDepth({symbol = '', limit = 1000} = {}) {
	const queryParams = queryConstructor({symbol: symbol.toUpperCase(), limit});
	return `api/v3/depth?${queryParams}`;
};

function getSpotKlineData({symbol = '', interval = '1m', startTime, endTime, limit}) {
	const queryParams = queryConstructor({symbol: symbol.toUpperCase(), interval, startTime, endTime, limit});
	return `api/v3/klines?${queryParams}`;
};

function getSpotSymbolPriceTicker({symbol = ''} = {}) {
	const queryParams = queryConstructor({symbol: symbol.toUpperCase()});
	return `api/v3/ticker/price?${queryParams}`;
};

const handlers = (builder) => ({
	getSpotMarketDepth: builder.query({
		query: getSpotMarketDepth,
		keepUnusedDataFor: 0,
	}),
	getSpotKlineData: builder.query({
		query: getSpotKlineData,
		keepUnusedDataFor: 0,
		transformResponse: (res) => {
			return res.map(
				kline => ({
					openTime: kline[0],
					open: Number(kline[1]),
					high: Number(kline[2]),
					low: Number(kline[3]),
					close: Number(kline[4]),
					volume: Number(kline[5]),
					closeTime: kline[6],
					quoteAssetVolume: Number(kline[7]),
					numberOfTrades: kline[8],
					takerBuyBaseAssetVolume: kline[9],
					takerBuyQuoteAssetVolume: kline[10],
				})
			).sort(
				(a, b) => a.openTime - b.openTime //ascending
			);
		}
	}),
	getSpotSymbolPriceTicker: builder.query({
		query: getSpotSymbolPriceTicker,
		keepUnusedDataFor: 0,
		transformResponse: (res) => {
			return Number(res.price);
		}
	}),
});

export default handlers;
