import React from "react";
import Meta from "components/Meta";
import PositionSection from "components/PositionSection";
import { requireAuth } from "util/auth";

function PositionPage(props) {
  return (
    <>
      <Meta title="Position" />
      <PositionSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Position"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(PositionPage);
