import React from 'react';
import clsx from 'clsx';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faBezierCurve } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';
import Chart from 'components/Analyze/widgets/EMA/Chart';
import emaFormula from 'components/Analyze/widgets/EMA/formula';
import { KLINE_LIMIT } from 'components/Analyze/constants';

function EMA(props) {
	const { klineData = [] } = props;

	const {
		shortEmaLine = [],
		longEmaLine = [],
		smaLine = [],
		prediction = [],
	} = emaFormula(klineData.slice(KLINE_LIMIT - 150));

	const {
		isShortEmaSlopeDown,
		isShortEmaSlopeUp,
		isLongEmaSlopeDown,
		isLongEmaSlopeUp,
		isShortBelowLong,
		isSmaSlopeDown,
		isSmaSlopeUp,
	} = prediction[prediction.length - 1] || {};

	const recommendation = isShortEmaSlopeDown && isLongEmaSlopeDown && isShortBelowLong ? RECOMMENDATION_MAP.SHORT
		: isShortEmaSlopeUp && isLongEmaSlopeUp && !isShortBelowLong ? RECOMMENDATION_MAP.LONG
			: RECOMMENDATION_MAP.WAIT;

	return (
		<Card className="Widget EMA shadow-sm">
			<Card.Header>
				<span className="title">EMA</span>
				<FontAwesomeIcon icon={recommendation.summaryIcon} className={`icon ${recommendation.color}`}/>
			</Card.Header>
			<Card.Body>
				<Chart series={{
					short: shortEmaLine,
					long: longEmaLine,
					sma: smaLine,
				}} />
			</Card.Body>
			<Card.Footer>
				<span className="recommendation-label">11 EMA</span>
				<FontAwesomeIcon
					icon={isShortEmaSlopeDown || isShortEmaSlopeUp ? faBezierCurve : faClock}
					rotation={isShortEmaSlopeUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isShortEmaSlopeDown,
						'text-success': isShortEmaSlopeUp,
						'text-gray-600': !isShortEmaSlopeDown && !isShortEmaSlopeUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">22 EMA</span>
				<FontAwesomeIcon
					icon={isLongEmaSlopeDown || isLongEmaSlopeUp ? faBezierCurve : faClock}
					rotation={isLongEmaSlopeUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isLongEmaSlopeDown,
						'text-success': isLongEmaSlopeUp,
						'text-gray-600': !isLongEmaSlopeDown && !isLongEmaSlopeUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">99 SMA</span>
				<FontAwesomeIcon
					icon={isSmaSlopeDown || isSmaSlopeUp ? faBezierCurve : faClock}
					rotation={isSmaSlopeUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isSmaSlopeDown,
						'text-success': isSmaSlopeUp,
						'text-gray-600': !isSmaSlopeDown && !isSmaSlopeUp,
					})}
				/>
			</Card.Footer>
		</Card>
	);
};

export default EMA;


const RECOMMENDATION_MAP = {
	SHORT: {
		summaryIcon: faArrowDown,
		color: 'text-danger',
		text: 'short',
	},
	LONG: {
		summaryIcon: faArrowUp,
		color: 'text-success',
		text: 'long',
	},
	WAIT: {
		summaryIcon: faClock,
		color: 'text-gray-600',
		text: 'wait',
	},
};
