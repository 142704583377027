import { useMemo } from 'react';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import { commaFormat, currencyFormat, removeZerosAfterDecimal } from 'util/numbers';

export default function useGetReportStats(props) {
	const {
		accountValueStart,
		pnl,
		apr,
		maxDrawdown,
		trades,
	} = props;

	return useMemo(() => {
		const pnlPerc = pnl / accountValueStart * 100 || 0;
		const maxDrawdownPerc = maxDrawdown / accountValueStart * 100 || 0;
		const positiveTrades = trades.filter(
			tr => tr.pnl >= 0
		).length;
		const negativeTrades = trades.filter(
			tr => tr.pnl < 0
		).length;

		return [
			{
				label: 'PnL',
				value: (
					<Flex
						align="end"
						className={getPosNegClassName(pnl)}
					>
						<span className="fw5">{currencyFormat(pnl)}</span>
						<span className="d-block ml-1 mb-1 f-rem-0.8">({removeZerosAfterDecimal(pnlPerc.toFixed(2))}%)</span>
					</Flex>
				),
			}, {
				label: 'APR',
				value: (
					<span className={`fw5 ${getPosNegClassName(pnl)}`}>{apr ? apr.toFixed(2) : '0'}%</span>
				),
			}, {
				label: 'Max Drawdown',
				value: (
					<Flex
						align="end"
						className={getPosNegClassName(maxDrawdown)}
					>
						<span className="fw5">{maxDrawdown !== Infinity ? currencyFormat(maxDrawdown, '$0.00') : '$0.00'}</span>
						<span className="d-block ml-1 mb-1 f-rem-0.8">({removeZerosAfterDecimal(maxDrawdownPerc.toFixed(2))}%)</span>
					</Flex>
				),
			}, {
				label: '+/- Trades',
				value: (
					<Flex className="fw5">
						<span className="text-success">{commaFormat(positiveTrades)}</span>
						<SlashDivider />
						<span className="text-danger">{commaFormat(negativeTrades)}</span>
					</Flex>
				),
			},
		];
	}, [
		accountValueStart,
		pnl,
		apr,
		maxDrawdown,
		trades,
	]);
};

const getPosNegClassName = number => number >= 0 ? 'text-success' : 'text-danger';
