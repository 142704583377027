import React, { useState, useEffect, useMemo, useCallback, createContext, useContext } from "react";
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Flex from "components/Flex";
import SortIcon from "components/SortIcon";
import Spinner from "components/Spinner";
import useIsMobile from "hooks/useIsMobile";

const headersContext = createContext();

function StripeTable(props) {
	const isMobile = useIsMobile();
	const {
		headers = [],
		defaultSortCol = '',
		defaultSortDir = '',
		data: _data = [],
		noneFoundText = 'No items found',
		isLoading,
		hasLocalSearch = false,
		search = '',
		searchKeys = [],
		hasLocalSort = false,
		sort: _sort,
		setSort: _setSort,
		sortDir: _sortDir,
		setSortDir: _setSortDir,
		handleRowClick,
		hover = true,
		responsive = true,
		className: tableClassName = 'table-stripe',
		useMobileTable = false,
		forceUseMobileTable = false,
	} = props;

	const attributes = {
		hover: !!hover || null,
		responsive: !!responsive || null,
	};

	const [sort, setSort] = useState(_sort || defaultSortCol);
	const [sortDir, setSortDir] = useState(_sortDir || defaultSortDir);

	useEffect(() => {
		typeof _setSort === 'function' && _setSort(sort);
		typeof _setSortDir === 'function' && _setSortDir(sortDir);
	}, [sort, sortDir, _setSort, _setSortDir]);

	const handleSortToggle = useCallback((e) => {
		const col = e.currentTarget.getAttribute('colname');
		const newState = sort !== col ? defaultSortDir
			: sortDir === 'DESC' ? 'ASC'
				: 'DESC';

		setSort(col);
		setSortDir(newState);
	}, [sort, sortDir, defaultSortDir]);

	const handleRowClickCallback = useCallback(item => {
		if (typeof handleRowClick === 'function') {
			return handleRowClick(item?.callbackValue || item?.id);
		}
	}, [handleRowClick]);

	const data = useMemo(() => {
		let searchedData = _data;

		if (hasLocalSearch && !!search) {
			const searchRegExp = new RegExp(search, 'i');

			searchedData = _data.filter(
				d => searchKeys.some(
					key => searchRegExp.test(d?.orig?.[key])
				)
			);
		}

		let sortedWithDir = searchedData;

		if (hasLocalSort) {
			const sorted = sortBy(searchedData, `orig.${sort}`);
			sortedWithDir = sortDir === 'DESC' ? sorted.reverse() : sorted;
		}

		return sortedWithDir;
	}, [_data, search, hasLocalSearch, searchKeys, hasLocalSort, sort, sortDir]);

	return (
		<headersContext.Provider value={headers}>
			{forceUseMobileTable || (isMobile && useMobileTable) ? (
				<MobileTable
					className={tableClassName}
					data={data}
					isLoading={isLoading}
					handleClick={handleRowClickCallback}
					noneFoundText={noneFoundText}
				/>
			) : (
				<Table
					className={tableClassName}
					{...attributes}
				>
					{(isLoading || data?.length > 0) && (
						<StripeTableHeader
							sort={sort}
							sortDir={sortDir}
							handleSortToggle={handleSortToggle}
						/>
					)}
					<StripeTableBody
						data={data}
						isLoading={isLoading}
						handleRowClick={handleRowClickCallback}
						noneFoundText={noneFoundText}
					/>
		    </Table>
			)}
    </headersContext.Provider>
	);
};

const MobileTable = ({className, data, isLoading, handleClick, noneFoundText}) => {
	const headers = useContext(headersContext);

	return isLoading ? (
		<Card className="shadow mt-2">
			<Spinner className="py-3 text-muted w-100" />
		</Card>
	) : data.length > 0 ? (
		<Flex direction="column">
			{data.map((item, i) => (
				<Card
					key={item.key || item.id || i}
					className={clsx('shadow', {
						'mt-2': i > 0
					})}
					onClick={() => handleClick(item)}
				>
					{item.title && (
						<Card.Header>
							{item.title}
						</Card.Header>
					)}
					<Card.Body>
						{item.values.map(({ value, className = '' }, idx) => (
							<Flex
								key={idx}
								direction="column"
								justify="between"
								className={clsx({
									'mt-2.5': idx > 0
								})}
							>
								<span
									className="f-rem-0.8 fw-5 text-gray-600 text-uppercase mb-0.5"
								>
									{headers[idx]?.name}
								</span>
								<span
									className={clsx('f-rem-1.1', {
										[className || '']: true,
									})}
								>
									{value}
								</span>
							</Flex>
						))}
					</Card.Body>
					{item.footer && (
						<Card.Footer>
							{item.footer}
						</Card.Footer>
					)}
				</Card>
			))}
		</Flex>
	) : (
		<Card className="shadow mt-2 p-3 text-center">
			{noneFoundText || 'No items found'}
		</Card>
	);
};

const StripeTableHeader = ({sort, sortDir, handleSortToggle}) => {
	const headers = useContext(headersContext);

	return (
		<thead>
		  <tr>
		  	{headers.map(h => (
		  		<th
		  			key={h.key}
		  			scope="col"
		  			colname={h.key}
		  			{...h.hasSort ? {
		  				className: 'hasSort',
			  			onClick: handleSortToggle
		  			} : {}}
		  		>
		  			<span className="mr-2">{h.name}</span>
		  			{!!h.hasSort && (
		  				<SortIcon direction={sort === h.key ? sortDir : 'hide'}/>
		  			)}
		  		</th>
		  	))}
			</tr>
		</thead>
	);
};

const StripeTableBody = ({data = [], isLoading, handleRowClick, noneFoundText}) => {
	const headers = useContext(headersContext);

	return (
		<tbody>
			{isLoading ? (
				<tr>
					<td colSpan={headers.length} className="text-center">
						<Spinner className="py-2 text-muted w-100" />
					</td>
				</tr>
			) : data.length > 0 ? (
				data.map((item, i) => (
					<StripeTableRow
						key={item.key || item.id || i}
						item={item}
						handleRowClick={handleRowClick}
					/>
				))
			) : (
				<tr>
					<td colSpan={headers.length} className="text-center">
						{noneFoundText || 'No items found'}
					</td>
				</tr>
			)}
		</tbody>
	);
};

const StripeTableRow = ({item, handleRowClick}) => {
	return (
		<tr onClick={() => handleRowClick(item)}>
			{item.values.map(({ value, className = '' }, idx) => (
				<td key={idx} className={className}>{value}</td>
			))}
		</tr>
	);
};

export default StripeTable;
