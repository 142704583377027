import millify from 'millify';

export function _parseFloat(value) {
  return parseFloat(String(value).replace(/,|\$/g, ''));
}

export function commaFormat(value, toFixed, ifIsNaN) {
  if (
    !isNaN(toFixed) &&
    (toFixed === 0 || !!toFixed)
  ) {
    toFixed = [toFixed, 18].sort((a, b) => a-b)[0]; //18 is max
  }

  const format = {
    minimumFractionDigits: toFixed || 0,
    maximumFractionDigits: toFixed || 0,
  };

  const applyFormat = toFixed === 0 || !!toFixed;
  const asComma = Intl.NumberFormat('en', applyFormat && format).format(_parseFloat(value));

  return isNaN(value) || asComma === 'NaN' ? ifIsNaN || asComma : asComma;
}

export function currencyFormat(value, ifIsNaN, decimals = 2) {
  const format = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };

  const asCurrency = Intl.NumberFormat('en', format).format(_parseFloat(value));

  return isNaN(value) || asCurrency === '$NaN' ? ifIsNaN || asCurrency : asCurrency;
}

export function fixedAsNumber(value, toFixed, ifIsNaN) {
  if (
    !isNaN(toFixed) &&
    (toFixed === 0 || !!toFixed)
  ) {
    toFixed = [toFixed, 18].sort((a, b) => a-b)[0]; //18 is max
  }

  const format = {
    maximumFractionDigits: typeof toFixed === 'number' ? toFixed : 2,
  };

  const asNumber = _parseFloat(Intl.NumberFormat('en', format).format(_parseFloat(value)));

  return isNaN(value) || asNumber === 'NaN' ? ifIsNaN || asNumber : asNumber;
}

export function compactNumber(value, ifIsNaN) {
  const compact = Intl.NumberFormat('en-US', {
    notation: "compact",
    maximumFractionDigits: 1
  }).format(_parseFloat(value));

  return isNaN(value) || compact === 'NaN' ? ifIsNaN || compact : compact;
}

export function removeZerosAfterDecimal(_str) {
  let str = typeof _str === 'string' ? _str : String(_str);
  const [int, dec = ''] = str.split('.');

  const decArr = dec.split('').reverse();
  let cleanDecArr = [];

  for (let idx = 0; idx < decArr.length; idx++) {
    const upTo = decArr[idx];

    if (upTo === '0') continue;
    else {
      cleanDecArr.push(...decArr.slice(idx));
      break;
    }
  };

  return [
    int,
    cleanDecArr.reverse().join(''),
  ].filter(
    o => o
  ).join('.');
};

export function millifyCurrency(int, includePlusSign = false, ops = {}) {
  const isNegative = int < 0;
  const isPositive = int > 0;
  const asMil = millify(Math.abs(int), {
    lowercase: true,
    ...ops,
  });

  return [
    ...includePlusSign && isPositive ? ['+']
      : isNegative ? ['-']
        : [],
    '$',
    asMil
  ].join('');
};
