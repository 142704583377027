import { TIME_INTERVAL_OPS, WIDGETS_OPS } from 'components/Analyze/constants';

export const configDefault = {
	timeIntervals: ['5m', '15m', '1h', '4h'],
	symbol: 'BTC',
	widgets: ['RSI', 'MACD', 'EMA', 'STOCH'],
};

export function configReducer(state, action) {
	let newState;

	switch(action.type) {
		case 'symbol':
			newState = {
				...state,
				symbol: action.payload,
			};
			break;

		case 'timeIntervals':
			newState = {
				...state,
				timeIntervals: (
					state.timeIntervals.includes(action.payload) ? state.timeIntervals.filter(
						t => t !== action.payload
					) : [...state.timeIntervals, action.payload]
				).sort(
					(a, b) => TIME_INTERVAL_OPS.indexOf(a) - TIME_INTERVAL_OPS.indexOf(b)
				)
			};
			break;

		case 'widgets':
			newState = {
				...state,
				widgets: (
					state.widgets.includes(action.payload) ? state.widgets.filter(
						t => t !== action.payload
					) : [...state.widgets, action.payload]
				).sort(
					(a, b) => WIDGETS_OPS.indexOf(a) - WIDGETS_OPS.indexOf(b)
				)
			};
			break;

		default:
			newState = { ...state };
	}

	return newState;
};
