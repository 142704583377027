import React, { useState, useEffect, useMemo, useCallback, useLayoutEffect, useRef, forwardRef, useImperativeHandle } from "react";
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';
import { createChart } from 'lightweight-charts';
import { EMA, SMA } from 'technicalindicators';
import { getLocalTs, DARK_THEME, LIGHT_THEME, BASE_OPTIONS, PRICE_LINE_OPTIONS } from "constants/lightweightCharts";
import { color } from 'constants/tradingview';
import useIsKeyPressed from 'hooks/useIsKeyPressed';
import useIsMobile from 'hooks/useIsMobile';
import { setHexOpacity } from 'util/color';
import patternDetection from './patternDetection';
import useHandleDrawTrendline from './hooks/useHandleDrawTrendline';
dayjs.extend(dayjsUtc);

const PATTERNS = ['bearishEngulfing', 'bullishEngulfing', 'doji', 'dragonflyDoji', 'gravestoneDoji', 'shootingStar'];
const DEFAULT_CHART_OPS = {
	marker: {
		labels: {
			'name': 'Marker Labels',
			'value': true,
			'toggle': 'toggleMarkerLabels',
			'mobile': true,
		},
		colors: {
			'name': 'Marker Colors',
			'value': true,
			'toggle': 'toggleMarkerColors',
			'mobile': true,
		},
	},
	trendline: {
		show: {
			'name': 'Trendlines',
			'value': true,
			'toggle': 'toggleTrendlines',
			'mobile': false,
		},
		remove: {
			'name': 'X Trendlines',
			'value': false,
			'toggle': 'removeTrendlines',
			'mobile': false,
		}
	},
};

function CandlestickChart(props, ref) {
	const selfRef = useRef();
	const [selfRefIsVisible, setSelfRefIsVisible] = useState(false);
	const chartRef = useRef();
	const candlestickSeries = useRef();
	const volumePredictionSeries = useRef();
	const volumeSeries = useRef();
	const shortEmaSeries = useRef();
	const longEmaSeries = useRef();
	const thirtyThreeEmaSeries = useRef();
	const twoHundredEmaSeries = useRef();
	const fiftyEmaSeries = useRef();
	const nintyNineSmaSeries = useRef();
	const previousLatestData = useRef();
	const priceLinesRecord = useRef([]);
	const trendlinesSeriesRecord = useRef([]);
	const activeTrendlineSeries = useRef();
	const invisibleTimeSeries = useRef();
	const isMobile = useIsMobile();

	const {
		containerRef,
		data: klineData = [],
		symbol,
		latestData,
		priceLines = [],
		height: chartHeight = 400,
		theme = 'dark',
		intervalInMin = 1,
		handleChartClick,
		handleCrosshairMove,
		decimals = 2,
		defaultChartOptions = {},
	} = props;

	const [chartOptions, setChartOptions] = useState(
		merge(DEFAULT_CHART_OPS, defaultChartOptions)
	);

	const { marker: markerOptions } = chartOptions;

	// Wait for first render to init chart so ref.current isn't null
	useEffect(() => {
		const chart = createChart(selfRef.current, {
			width: containerRef?.current?.clientWidth || 400,
			height: chartHeight,
			...theme === 'light' ? LIGHT_THEME : DARK_THEME,
			...BASE_OPTIONS,
		});

		const _twoHundredEmaSeries = chart.addLineSeries({
			color: color.white,
			lineWidth: 2,
			crosshairMarkerVisible: false,
			priceLineVisible: false,
			lastValueVisible: false,
		});
		const _fiftyEmaSeries = chart.addLineSeries({
			color: color.gray,
			lineWidth: 1,
			crosshairMarkerVisible: false,
			priceLineVisible: false,
			lastValueVisible: false,
		});
		const _nintyNineSmaSeries = chart.addLineSeries({
			color: color.orange,
			lineWidth: 1,
			crosshairMarkerVisible: false,
			priceLineVisible: false,
			lastValueVisible: false,
		});
		const _longEmaSeries = chart.addLineSeries({
			color: color.red,
			lineWidth: 1,
			crosshairMarkerVisible: false,
			priceLineVisible: false,
			lastValueVisible: false,
		});
		const _thirtyThreeEmaSeries = chart.addLineSeries({
			color: color.yellow,
			lineWidth: 1,
			crosshairMarkerVisible: false,
			priceLineVisible: false,
			lastValueVisible: false,
		});
		const _shortEmaSeries = chart.addLineSeries({
			color: color.green,
			lineWidth: 1,
			crosshairMarkerVisible: false,
			priceLineVisible: false,
			lastValueVisible: false,
		});
		const _volumePredictionSeries = chart.addHistogramSeries({
			priceFormat: {
				type: 'volume',
			},
			priceScaleId: '',
		});
		const _volumeSeries = chart.addHistogramSeries({
			priceFormat: {
				type: 'volume',
			},
			priceScaleId: '', // set as an overlay by setting a blank priceScaleId. An overlay doesn't make use of either the left or right price scale, and it's positioning is controlled by setting the scaleMargins property on the price scale options associated with the series.
		});
		_volumeSeries.priceScale().applyOptions({
			//set the positioning of the volume series
			scaleMargins: {
				top: 0.7, //highest point of the series will be 70% away from the top
				bottom: 0, //lowest point will be at the very bottom.
			},
		});
		const _candlestickSeries = chart.addCandlestickSeries({
			upColor: color.white,
			borderUpColor: color.white,
			wickUpColor: color.white,
			downColor: color.aqua,
			borderDownColor: color.aqua,
			wickDownColor: color.aqua,
			priceFormat: {
				type: 'price',
				precision: decimals,
				minMove: Number(Math.pow(10, decimals * -1).toFixed(decimals)),
			}
		});
		_candlestickSeries.priceScale().applyOptions({
			//set the positioning of the volume series
			scaleMargins: {
				top: 0.1, //highest point of the series will be 10% away from the top
				bottom: 0.4, //lowest point will be 40% away from the bottom
			},
		});
		// Used for drawing trendline
		const _activeTrendlineSeries = chart.addLineSeries({
			color: color.white,
			lineWidth: 1,
		});
		const _invisibleTimeSeries = chart.addLineSeries();

		setSelfRefIsVisible(true);
		chartRef.current = chart;
		twoHundredEmaSeries.current = _twoHundredEmaSeries;
		fiftyEmaSeries.current = _fiftyEmaSeries;
		nintyNineSmaSeries.current = _nintyNineSmaSeries;
		longEmaSeries.current = _longEmaSeries;
		thirtyThreeEmaSeries.current = _thirtyThreeEmaSeries;
		shortEmaSeries.current = _shortEmaSeries;
		volumePredictionSeries.current = _volumePredictionSeries;
		volumeSeries.current = _volumeSeries;
		candlestickSeries.current = _candlestickSeries;
		activeTrendlineSeries.current = _activeTrendlineSeries;
		invisibleTimeSeries.current = _invisibleTimeSeries;
	// eslint-disable-next-line
	}, []);

	// Update priceFormat on first series (which sets it for all series) on changed props.decimals
	useEffect(() => {
		if (twoHundredEmaSeries.current) {
			twoHundredEmaSeries.current.applyOptions({
				priceFormat: {
					type: 'price',
					precision: decimals,
					minMove: Number(Math.pow(10, decimals * -1).toFixed(decimals)),
				}
			});
		}
	}, [decimals]);

	// Add time countdown to next bar title
	useEffect(() => {
		const intv = setInterval(() => {
			const secToEndOfMin = String(60 - (new Date().getSeconds() % 60)).padStart(2, '0');
			const mins = intervalInMin <= 60 ? intervalInMin : 60;
			const minToEndOfMin = String(mins - (new Date().getMinutes() % mins) - 1).padStart(2, '0');
			const hrs = intervalInMin/60;
			const hrToEndOfHr = intervalInMin > 60 && String(hrs - (new Date().getUTCHours() % hrs) - 1).padStart(2, '0');

			const time = [hrToEndOfHr, minToEndOfMin, secToEndOfMin].filter(o => o).join(':');
			candlestickSeries.current.applyOptions({ title: time });
		}, 1000);

		return () => clearInterval(intv);
	}, [intervalInMin]);

	// Set initial candlestick and volume data and update on changes
	const memoizedKlineData = JSON.stringify(klineData);
	useEffect(() => {
		if (selfRefIsVisible) {
			const parsedKlineData = JSON.parse(memoizedKlineData) || [];

			const candlestickData = patternDetection(
				parsedKlineData.map(kl => ({
					...kl,
					time: getLocalTs(kl.time),
					readableTime: new Date(getLocalTs(kl.time) * 1000)
				})),
				PATTERNS,
				markerOptions,
			);

			candlestickSeries.current.setData(candlestickData);
			candlestickSeries.current.setMarkers(candlestickData.map(o => o.marker).filter(o => !!o));

			volumeSeries.current.setData(
				parsedKlineData.map(
					kl => ({
						value: kl.volume,
						time: getLocalTs(kl.time),
						color: kl.close >= kl.open ? setHexOpacity(color.green, 0.7) : setHexOpacity(color.red, 0.7),
					})
				)
			);

			// Setup predicted volume
			const periodLength = parsedKlineData[parsedKlineData.length - 1]?.time - parsedKlineData[parsedKlineData.length - 2]?.time;
			const nextPeriodEnd = parsedKlineData[parsedKlineData.length - 1]?.time + periodLength;
			const timeToNextPeriodEnd = nextPeriodEnd - Date.now();
			const percentRemainingToNextPeriodEnd = timeToNextPeriodEnd / periodLength;
			const percentToNextPeriodEnd = Math.min(100, (1 - percentRemainingToNextPeriodEnd) * 100);
			const predictedVolume = parsedKlineData[parsedKlineData.length - 1]?.volume * 100 / percentToNextPeriodEnd;

			volumePredictionSeries.current.setData(
				parsedKlineData.slice(-1).map(
					kl => ({
						value: predictedVolume,
						time: getLocalTs(kl.time),
						color: kl.close >= kl.open ? setHexOpacity(color.green, 0.4) : setHexOpacity(color.red, 0.4),
					})
				)
			);
		}
	}, [
		selfRefIsVisible,
		memoizedKlineData,
		markerOptions,
	]);

	const handleClearTrendlineSeries = useCallback(() => {
		trendlinesSeriesRecord.current.forEach(
			series => !!series && chartRef.current.removeSeries(series)
		);

		trendlinesSeriesRecord.current = [];
	}, []);

	// Set cached trendline data in cookies
	useEffect(() => {
		if (selfRefIsVisible && symbol) {
			// First remove any existing trendline series on symbol change
			handleClearTrendlineSeries();

			const cookieStr = `priceline-${symbol}`;
			const existingTrendlinesForSymbol = JSON.parse(Cookies.get(cookieStr) || '[]');

			existingTrendlinesForSymbol.forEach(
				trendline => {
					const newTrendlineSeries = chartRef.current.addLineSeries({
						color: color.aqua,
						lineWidth: 1,
						crosshairMarkerVisible: false,
						priceLineVisible: false,
						lastValueVisible: false,
					});

					newTrendlineSeries.setData(trendline);
					trendlinesSeriesRecord.current.push(newTrendlineSeries);
				}
			);
		}
	}, [
		selfRefIsVisible,
		symbol,
		handleClearTrendlineSeries,
	]);

	// Update latest data in chart;
	// NOT USED ANYMORE; just update whole data, like in components/PositionSectionNew/PriceChart
	useEffect(() => {
		if (
			!selfRefIsVisible ||
			!latestData ||
			previousLatestData.current?.close === latestData?.close
		) return;

		try {
			candlestickSeries.current.update({
				...latestData,
				time: getLocalTs(latestData.time),
			});

			previousLatestData.current = latestData;
		} catch(error) {
			console.error('Failed to update with latestData', error);
		}
	}, [latestData, selfRefIsVisible]);

	// Watch for priceLines in props
	const memoizedPriceLines = JSON.stringify(priceLines);
	useEffect(() => {
		if (selfRefIsVisible) {
			const priceLines = JSON.parse(memoizedPriceLines);

			priceLinesRecord.current = priceLines.map(
				pl => candlestickSeries.current.createPriceLine({
					...PRICE_LINE_OPTIONS,
					...pl,
				})
			);
		}

		return () => priceLinesRecord.current.forEach(
			pl => candlestickSeries.current.removePriceLine(pl)
		);
	}, [selfRefIsVisible, memoizedPriceLines]);

	// Show EMA's
	useEffect(() => {
		const parsedKlineData = JSON.parse(memoizedKlineData);
		if (parsedKlineData.length === 0) return;

		const [shortEMA, longEMA, thirtyThreeEMA, twoHundredEMA, fiftyEMA] = [11, 22, 33, 200, 50].map(period => {
			const ema = EMA.calculate({
				period,
				values: parsedKlineData.map(d => d.close),
			}).map(
				(ema, idx) => ({
					value: ema,
					time: getLocalTs(parsedKlineData[idx + period - 1]?.time),
				})
			);

			return ema;
		});

		const nintyNineSMA = SMA.calculate({
			period: 99,
			values: parsedKlineData.map(d => d.close),
		}).map(
			(sma, idx) => ({
				value: sma,
				time: getLocalTs(parsedKlineData[idx + 99 - 1]?.time),
			})
		);

		shortEmaSeries.current.setData(shortEMA);
		longEmaSeries.current.setData(longEMA);
		thirtyThreeEmaSeries.current.setData(thirtyThreeEMA);
		twoHundredEmaSeries.current.setData(twoHundredEMA);
		fiftyEmaSeries.current.setData(fiftyEMA);
		nintyNineSmaSeries.current.setData(nintyNineSMA);
	}, [memoizedKlineData]);

	const keysPressed = useIsKeyPressed();
	const isMetaKeyPressed = useMemo(() => !!keysPressed?.Meta, [keysPressed?.Meta]);

	const handleDrawTrendline = useHandleDrawTrendline({intervalInMin});

	// Subscribe to chart click
	useEffect(() => {
		const hasHandleChartClick = typeof handleChartClick === 'function';
		const handleChartClickProxy = (param) => {
			return (isMetaKeyPressed ? handleDrawTrendline.click : handleChartClick)({param, chartRef, candlestickSeries, activeTrendlineSeries, invisibleTimeSeries});
		};
		if (hasHandleChartClick || isMetaKeyPressed) chartRef.current.subscribeClick(handleChartClickProxy);

		const hasCrosshairMove = typeof handleCrosshairMove === 'function';
		const handleCrosshairMoveProxy = (param) => {
			return (isMetaKeyPressed ? handleDrawTrendline.move : handleCrosshairMove)({param, chartRef, candlestickSeries, activeTrendlineSeries, invisibleTimeSeries});
		};
		if (hasCrosshairMove || isMetaKeyPressed) chartRef.current.subscribeCrosshairMove(handleCrosshairMoveProxy);

		if (!isMetaKeyPressed) {
			handleDrawTrendline.recordLine({
				chartRef,
				trendlinesSeriesRecord,
				activeTrendlineSeries,
				invisibleTimeSeries,
			});
		}

		return () => {
			hasHandleChartClick && chartRef.current.unsubscribeClick(handleChartClickProxy);
			hasCrosshairMove && chartRef.current.unsubscribeCrosshairMove(handleCrosshairMoveProxy);
		}
	}, [
		handleChartClick,
		handleCrosshairMove,
		isMetaKeyPressed,
		handleDrawTrendline,
	]);


	// Watch for window resizes to resize chart
	// Watch for div height drag-to-resize
	useLayoutEffect(() => {
		const selfRefClone = selfRef?.current; //refs are gone by cleanup time

		const debounceHandleOnResize = debounce(() => {
			const width = containerRef?.current?.clientWidth || 400;
			const height = selfRefClone?.clientHeight || 560;
			chartRef.current?.resize?.(width, height);
		}, 200);

		window.addEventListener('resize', debounceHandleOnResize);
		new ResizeObserver(debounceHandleOnResize).observe(selfRefClone);

		return () => window.removeEventListener('resize', debounceHandleOnResize);
	// eslint-disable-next-line
	}, []);

	/* IMPERATIVE HANDLE */
	useImperativeHandle(ref, () => ({
		getChartOptions: () => Object.values(DEFAULT_CHART_OPS).reduce(
			(acc, item) => {
				Object.values(item).forEach(
					({ name, value, toggle, mobile }) => {
						if (!isMobile || !!mobile) {
							acc[name] = {
								value,
								toggle,
							}
						}
					}
				);

				return acc;
			}, {}
		),
		getChartOptionValueByName: (name) => {
			const byName = Object.values(chartOptions).reduce(
				(acc, item) => {
					Object.values(item).forEach(
						({ name, value, toggle }) => {
							acc[name] = {
								value,
								toggle,
							}
						}
					);

					return acc;
				}, {}
			);

			return byName[name]?.value;
		},
		toggleMarkerLabels: () => setChartOptions(prev => ({
			...prev,
			marker: {
				...prev.marker,
				labels: {
					...prev.marker.labels,
					value: !prev.marker.labels.value,
				}
			}
		})),
		toggleMarkerColors: () => setChartOptions(prev => ({
			...prev,
			marker: {
				...prev.marker,
				colors: {
					...prev.marker.colors,
					value: !prev.marker.colors.value,
				}
			}
		})),
		toggleTrendlines: () => {
			trendlinesSeriesRecord.current.forEach(
				series => series.applyOptions({ visible: !series.options().visible })
			);

			setChartOptions(prev => ({
				...prev,
				trendline: {
					...prev.trendline,
					show: {
						...prev.trendline.show,
						value: !prev.trendline.show.value,
					}
				}
			}))
		},
		removeTrendlines: () => {
			if (window.confirm('Are you sure')) {
				Cookies.remove(`priceline-${symbol}`);
				handleClearTrendlineSeries();
			}
		},

		getChartRef: () => chartRef.current,
		resetPriceScale: () => chartRef.current?.priceScale?.('right')?.applyOptions?.({autoScale : true}),
	}));

	return (
		<div
			ref={selfRef}
			style={{resize: 'vertical', overflow: 'auto'}}
		>
		</div>
	);
};

export default forwardRef(CandlestickChart);
