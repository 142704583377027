import { useGetPositionsV2Query } from 'api/client';
import { POLLING_INTERVAL } from 'constants/bingxSwaps';

export default function useGetPendingOrders({symbol, side} = {}) {
	/*** QUERIES ***/
	/** Position **/
	const {
		data: positions = [],
	} = useGetPositionsV2Query({
		symbol,
		side,
	}, {
		pollingInterval: POLLING_INTERVAL,
	});

	const position = positions?.[0] || {};
	const { positionAmt = 0, avgPrice = 0 } = position;

	/*** DATA ***/
	return {
		...position,
		positionValue: positionAmt * avgPrice,
	}
};
