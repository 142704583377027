import { ReadyState } from 'react-use-websocket';

export const connectionStatus = {
  [ReadyState.CONNECTING]: 'connecting', //0
  [ReadyState.OPEN]: 'open', //1
  [ReadyState.CLOSING]: 'closing', //2
  [ReadyState.CLOSED]: 'closed', //3
  [ReadyState.UNINSTANTIATED]: 'uninstantiated', //-1
};

export const DEFAULT_OPTIONS = (functionName) => ({
	shouldReconnect: () => true,
	reconnectAttempts: 50,
	reconnectInterval: 1000,
	retryOnError: true,
	onReconnectStop: (numberAttempted) => console.warn(`WS ${functionName} reconnect failed after ${numberAttempted} attempts`),
	onOpen: () => console.log(`WS ${functionName} OPENED`),
	onClose: () => console.log(`WS ${functionName} CLOSED`),
});
