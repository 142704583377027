import { RSI, SMA } from 'technicalindicators';
import { fixedAsNumber } from 'util/numbers';

const PERIOD = 14;
const THRESHOLD_UPPER = 70;
const THRESHOLD_LOWER = 30;

export default function getRsiLine(klineData = []) {
	const rsiLine = RSI.calculate({
		period: PERIOD,
		values: klineData.map(d => d.close),
	}).map(
		(rsi, idx) => ({
			x: klineData[idx + PERIOD]?.openTime,
			y: fixedAsNumber(rsi, 2, undefined),
		})
	);

	const smaLine = SMA.calculate({
		period: PERIOD,
		values: rsiLine.map(
			rsi => rsi.y,
		),
	}).map(
		(sma, idx) => ({
			x: rsiLine[idx + PERIOD]?.x,
			y: fixedAsNumber(sma, 2, undefined),
		})
	);

	const prediction = rsiLine.map(
		({ x, y }, idx) => {
			const rsi = y;

			const smaIdx = idx - PERIOD;
			const sma = smaLine[smaIdx]?.y;
			const twoAgoSma = smaLine[smaIdx - 2]?.y;
			const prevSma = smaLine[smaIdx - 1]?.y;
			const nextSma = smaLine[smaIdx + 1]?.y;

			const rsiHistoryThreshold = rsiLine.slice(0, idx).reverse().map(
				_rsi => ({
					isAboveThreshold: _rsi?.y >= THRESHOLD_UPPER,
					isBelowThreshold: _rsi?.y <= THRESHOLD_LOWER,
				})
			).filter(
				({ isAboveThreshold, isBelowThreshold }) => isAboveThreshold || isBelowThreshold
			);

			// The most recent threshold crossed was the lower one, current RSI is now above lower threshold, but still below 45
			const isHeadingUp = rsiHistoryThreshold?.[0]?.isBelowThreshold && rsi >= THRESHOLD_LOWER && rsi <= 45;
			// The most recent threshold crossed was the upper one, current RSI is now below upper threshold, but still greater than 55;
			const isHeadingDown = rsiHistoryThreshold?.[0]?.isAboveThreshold && rsi <= THRESHOLD_UPPER && rsi >= 55;

			const isSmaSlopeDown = idx < 1 ? false
				: idx < smaLine.length - 2 ? nextSma - prevSma < 0
					: sma - twoAgoSma < 0 && sma <= prevSma;
			const isSmaSlopeUp = smaIdx < 1 ? false
				: smaIdx < smaLine.length - 2 ? nextSma - prevSma > 0
					: sma - twoAgoSma > 0 && sma >= prevSma;

			const isRsaBelowSma = rsi - sma < 0;

			return {
				isHeadingUp,
				isHeadingDown,
				isSmaSlopeUp,
				isSmaSlopeDown,
				isRsaBelowSma,
			};
		}
	);

	return {
		rsiLine,
		smaLine,
		prediction,
	};
};
