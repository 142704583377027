import { useCallback, useRef} from 'react';
import useIsMobile from 'hooks/useIsMobile';

const CROSSHAIR_DEBOUNCE_TIME = 20;

export default function useHandleCrosshairMove({
	memoizedPriceLines,
	dispatchContext,
	pricePrecision,
} = {}) {
	const isMobile = useIsMobile();
	const crosshairMoveDebounceRef = useRef(Date.now() + CROSSHAIR_DEBOUNCE_TIME);
	const crosshairMoveTimeoutRef = useRef();

	return useCallback(
  	({param, chartRef, candlestickSeries}) => {
  		if (crosshairMoveDebounceRef.current <= Date.now()) {
  			crosshairMoveTimeoutRef.current = setTimeout(
  				() => {
  					if (!param?.point?.y || param?.point?.y < 0) {
				  		dispatchContext({ type: 'mouseLeavePriceline' });

				  		if (isMobile) {
				  			dispatchContext({ type: 'crosshairOnMobile', payload: null });
				  			dispatchContext({ type: 'removeTentativeOrder' });
				  		}

				  		return;
				  	};

				  	const price = Number(
				  		candlestickSeries.current.coordinateToPrice(param.point.y)?.toFixed?.(pricePrecision)
				  	);

						if (price) {
							if (isMobile) {
								dispatchContext({ type: 'crosshairOnMobile', payload: price });
							} else {
								const isNearPendingOrderPriceline = JSON.parse(memoizedPriceLines).find(
									pl => pl.type === 'order' &&
										(Math.abs(pl.price - price) / price) < 0.003
								);
	
								dispatchContext(isNearPendingOrderPriceline ? {
									type: 'mouseEnterPriceline',
									payload: isNearPendingOrderPriceline.order.orderId,
								} : {
									type: 'mouseLeavePriceline'
								});
							}
						}
  				},
  				CROSSHAIR_DEBOUNCE_TIME
  			);
  		} else {
  			clearTimeout(crosshairMoveTimeoutRef.current);
  		}

  		crosshairMoveDebounceRef.current = Date.now() + CROSSHAIR_DEBOUNCE_TIME;
  	}, [
	  	memoizedPriceLines,
	  	dispatchContext,
	  	pricePrecision,
	  	isMobile,
	  ]
	);
};
