import React, { useState, useEffect, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faStar } from '@fortawesome/pro-solid-svg-icons';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Flex from "components/Flex";
import SearchInput from "components/SearchInput";
import Toggle from "components/Toggle";
import {
	useGetAllContractsV2Query,
	// Mutations
	usePlaceOrderV2Mutation,
	usePlaceOrderMinSizeMutation,
} from 'api/client';
import useLatestTradeWs from 'api/websockets/bingxSwaps/useLatestTradeWs';
import { supportedSymbols } from 'constants/bingxSwaps';
import { getDefaultError } from 'util/error';
import { currencyFormat } from 'util/numbers';
import { escapeSpecialRegexChars } from 'util/strings';
import { AmountInput } from './index';

const MODIFY_AMOUNT_BY = 25;
const FAVORITES = new Set(Object.keys(supportedSymbols));

function NewPairTradePopup(props) {
	const { closePopup } = props;
	const [search, setSearch] = useState('');
	const [selectedContract, setSelectedContract] = useState(null);
	const [positionSide, setPositionSide] = useState('SHORT');
	const [orderSize, setOrderSize] = useState(25);
	const [placeOrderError, setPlaceOrderError] = useState(false);

	const {
		data: allContracts = [],
		isLoading,
	} = useGetAllContractsV2Query();

	const [
		latestPrices = {},
		setLatestTradeSubs,
	] = useLatestTradeWs({ onlyPrice: true });

	useEffect(() => {
		setLatestTradeSubs(
			selectedContract ? [selectedContract.symbol] : []
		);
	// eslint-disable-next-line
	}, [selectedContract?.symbol]);

	const sortedContracts = useMemo(() => {
		return isLoading ? [] : [
			...allContracts.filter(
				c => FAVORITES.has(c?.asset)
			).sort(
				(a, b) => a?.asset?.localeCompare?.(b?.asset)
			),
			...allContracts.filter(
				c => !FAVORITES.has(c?.asset)
			).sort(
				(a, b) => a?.asset?.localeCompare?.(b?.asset)
			),
		];
	}, [isLoading, allContracts]);

	const filteredContracts = useMemo(() => {
		const reg = new RegExp(escapeSpecialRegexChars(search), 'i');

		return sortedContracts.filter(
			contract => reg.test(contract.name)
		);
	}, [sortedContracts, search]);

	const minOrderSize = useMemo(() => {
		if (!selectedContract) return 0;

		const curPrice = Number(latestPrices?.[selectedContract.symbol]?.price || 0);
		return Math.round(
			(Number(selectedContract.size) * selectedContract.tradeMinLimit * curPrice) + 1.5
		);
	}, [latestPrices, selectedContract]);


	// Place order mutation
	const [placeOrder, placeOrderResult] = usePlaceOrderV2Mutation();

	useEffect(() => {
		if (placeOrderResult.isError) {
			setPlaceOrderError(placeOrderResult.error);
			setTimeout(() => placeOrderResult.reset(), 1000);
			console.error('usePlaceOrderV2Mutation error', placeOrderResult.error);
		}
		if (placeOrderResult.isSuccess) {
			setTimeout(closePopup, 500);
		}
	}, [placeOrderResult, closePopup]);

	const handlePlaceOrder = useCallback(() => {
		if (!placeOrderResult.isLoading) {
			const order = {
				symbol: selectedContract.symbol,
				side: positionSide === "SHORT" ? 'SELL' : 'BUY',
				positionSide,
				quantity: orderSize / Number(latestPrices[selectedContract.symbol]?.price),
				type: 'MARKET',
			};

			return placeOrder({ order });
		}
	}, [
		placeOrderResult?.isLoading,
		selectedContract,
		positionSide,
		orderSize,
		latestPrices,
		placeOrder
	]);

	// UI
	return isLoading ? (<Loader/>) : (
		<>
			{placeOrderError && (
				<Alert variant="danger">
					<Flex direction="column" align="center" className="w-100">
						<span>{getDefaultError(placeOrderError)}</span>
					</Flex>
				</Alert>
			)}

			<Flex direction="column" className="NewPairTradePopup">
				{!selectedContract ? (
					<Flex direction="column">
						<SearchInput
							setSearch={setSearch}
							placeholder="Search for symbol"
						/>

						{filteredContracts.map(contract => (
							<ContractRow
								key={contract.contractId}
								contract={contract}
								setSelectedContract={setSelectedContract}
							/>
						))}
					</Flex>
				) : (
					<Flex direction="column" className="px-2">
						<Flex
							justify="between"
							align="center"
							className="p-2 f-rem-0.9 fw-5"
						>
							<Flex align="center">
								<span className="text-primary">{selectedContract.name}</span>
								<span className="px-2 text-gray-400">|</span>
								<span className="text-gray-500 f-rem-0.8 fw-5">{currencyFormat(
									latestPrices?.[selectedContract.symbol]?.price,
									'$0.00',
									selectedContract.pricePrecision
								)}</span>
							</Flex>
							<span
								className="text-danger pointer"
								onClick={() => setSelectedContract(null)}
							>
								Cancel
							</span>
						</Flex>
						<Toggle
							ops={["Short", "Long"]}
							active={`${positionSide.charAt(0)}${positionSide.slice(1).toLowerCase()}`}
							setActive={(op) => setPositionSide(op.toUpperCase())}
							className="mb-2"
							activeClassName={clsx({
								'text-danger': positionSide === "SHORT",
								'text-success': positionSide === "LONG",
							})}
						/>
						<AmountInput
							value={orderSize}
							onReduce={
								() => setOrderSize(
									prev => Math.max(
										Number(orderSize) - MODIFY_AMOUNT_BY,
										minOrderSize
									)
								)
							}
							onIncrease={
								() => setOrderSize(
									prev => Number(orderSize) + MODIFY_AMOUNT_BY
								)
							}
							onChange={(e) => {
								const asInt = Number(e.target.value);

								setOrderSize(
									Math.max(
										isNaN(asInt) ? 0 : asInt,
										0
									)
								);
							}}
							className="mb-2"
						/>
						<span
							className="text-primary text-center f-rem-0.85 fw-4 mt-n1 pointer"
							onClick={() => setOrderSize(minOrderSize)}
						>
							Use min order size
						</span>
						<Button
							disabled={placeOrderResult.isLoading}
							variant={placeOrderResult.isSuccess ? 'success' : placeOrderResult.isError ? 'danger' : 'primary'}
							size="sm"
							className="mt-4 mb-2"
							onClick={() => !placeOrderResult.isLoading && handlePlaceOrder()}
						>
							{placeOrderResult.isLoading ? (
								<Spinner animation="border" variant="white" size="sm" />
							) : placeOrderResult.isSuccess ? (
								<FontAwesomeIcon icon={faCheck} className="text-white f-rem-1" />
							) : placeOrderResult.isError ? (
								<FontAwesomeIcon icon={faTimes} className="text-white f-rem-1" />
							) : 'Open'}
						</Button>
					</Flex>
				)}
			</Flex>
		</>
	);
};

export default NewPairTradePopup;


const Loader = () => (
	<Flex justify="center" align="center" className="py-4">
		<Spinner animation="border" variant="primary" />
	</Flex>
);

const ContractRow = ({contract, setSelectedContract}) => {
	// Place order mutation
	const [placeOrderMinSizeShort, placeOrderMinSizeResultShort] = usePlaceOrderMinSizeMutation();
	const [placeOrderMinSizeLong, placeOrderMinSizeResultLong] = usePlaceOrderMinSizeMutation();

	useEffect(() => {
		if (
			placeOrderMinSizeResultShort.isError ||
			placeOrderMinSizeResultShort.isSuccess
		) {
			setTimeout(() => placeOrderMinSizeResultShort.reset(), 1000);
		}

		if (placeOrderMinSizeResultShort.isError) {
			console.error('usePlaceOrderMinSizeMutation SHORT error', placeOrderMinSizeResultShort.error);
		}
	}, [placeOrderMinSizeResultShort]);

	useEffect(() => {
		if (
			placeOrderMinSizeResultLong.isError ||
			placeOrderMinSizeResultLong.isSuccess
		) {
			setTimeout(() => placeOrderMinSizeResultLong.reset(), 1000);
		}

		if (placeOrderMinSizeResultLong.isError) {
			console.error('usePlaceOrderMinSizeMutation LONG error', placeOrderMinSizeResultLong.error);
		}
	}, [placeOrderMinSizeResultLong]);

	const handlePlaceOrder = useCallback((side) => {
		const canPlaceOrder = side === "SHORT" ? !placeOrderMinSizeResultShort.isLoading
			: !placeOrderMinSizeResultLong.isLoading;

		if (canPlaceOrder) {
			const params = {
				symbol: contract.symbol,
				side: side === "SHORT" ? 'Ask' : 'Bid',
			};

			return side === "SHORT" ? placeOrderMinSizeShort(params) : placeOrderMinSizeLong(params);
		}
	}, [
		placeOrderMinSizeResultShort?.isLoading,
		placeOrderMinSizeResultLong?.isLoading,
		contract,
		placeOrderMinSizeShort,
		placeOrderMinSizeLong,
	]);

	return (
		<Flex
			justify="between"
			align="center"
			className="ContractRow"
			onClick={() => setSelectedContract(
				prev => !prev ? contract
					: prev.name === contract.name ? null
						: contract
			)}
		>
			<Flex align="center">
				{FAVORITES.has(contract.asset) && (<FontAwesomeIcon icon={faStar} className="FavoriteIcon" />)}
				<span>{contract.symbol}</span>
			</Flex>
			<Flex align="center">
				<PlaceOrderBtn
					variant="danger"
					label="Short"
					placeOrderResult={placeOrderMinSizeResultShort}
					handlePlaceOrder={() => handlePlaceOrder('SHORT')}
				/>
				<PlaceOrderBtn
					variant="success"
					label="Long"
					placeOrderResult={placeOrderMinSizeResultLong}
					handlePlaceOrder={() => handlePlaceOrder('LONG')}
					className="ml-2"
				/>
			</Flex>
		</Flex>
	);
};

const PlaceOrderBtn = ({variant, label, placeOrderResult, handlePlaceOrder, className = ""}) => {
	return (
		<Button
			disabled={placeOrderResult.isLoading}
			variant={
				placeOrderResult.isSuccess ? 'success'
					: placeOrderResult.isError ? 'danger'
						: `outline-${variant}`
			}
			size="xs"
			onClick={(e) => {
				e.stopPropagation();
				!placeOrderResult.isLoading && handlePlaceOrder()
			}}
			className={className}
		>
			{placeOrderResult.isLoading ? (
				<Spinner animation="border" variant={variant} size="sm" />
			) : placeOrderResult.isSuccess ? (
				<FontAwesomeIcon icon={faCheck} className="text-white f-rem-1" />
			) : placeOrderResult.isError ? (
				<FontAwesomeIcon icon={faTimes} className="text-white f-rem-1" />
			) : label}
		</Button>
	);
}
