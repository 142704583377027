import { PERIOD_TO_MINS_MAP } from 'components/PositionSectionNew/constants';

function contextReducer(state, action) {
	let newState = {};

	switch(action.type) {
		case 'side':
		case 'period':
			newState = {
				...state,
				[action.type]: action.payload,
			};
			break;
		case 'modifiedOrdersData':
			newState = {
				...state,
				modifiedOrdersData: Object.entries(action.payload).reduce(
					(acc, [orderId, values]) => {
						acc[orderId] = Object.entries(values).reduce(
							(_acc, [key, value]) => {
								if (value === 'DELETE') {
									delete _acc[key];
								} else {
									_acc[key] = value;
								}

								return _acc;
							}, acc[orderId] || {}
						);

						return acc;
					}, state.modifiedOrdersData
				),
			};
			break;
		case 'mouseEnterOrder':
			newState = {
				...state,
				mouseEnterOrder: action.payload,
			};
			break;
		case 'mouseLeaveOrder':
			newState = {
				...state,
				mouseEnterOrder: null
			};
			break;
		case 'mouseEnterPriceline':
			newState = {
				...state,
				mouseEnterPriceline: action.payload,
			};
			break;
		case 'mouseLeavePriceline':
			newState = {
				...state,
				mouseEnterPriceline: null
			};
			break;
		case 'updateTentativeOrder':
			newState = {
				...state,
				tentativeOrder: action.payload,
			};
			break;
		case 'removeTentativeOrder':
			newState = {
				...state,
				tentativeOrder: null,
			};
			break;
		case 'toggleSwitchPositionPopup':
			newState = {
				...state,
				showSwitchPositionPopup: !state.showSwitchPositionPopup,
			};
			break;
		case 'crosshairOnMobile':
			newState = {
				...state,
				crosshairActiveOnMobile: action.payload,
			};
			break;
		case 'updatePriceLines': 
			newState = {
				...state,
				priceLines: action.payload,
			};
			break;
		default:
			newState = { ...state };
			break;
	}

	return newState;
};

const contextInit = (searchParams) => ({
	symbol: searchParams.get('symbol') || undefined,
	side: searchParams.get('side') || 'short',
	period: searchParams.get('period') || Object.keys(PERIOD_TO_MINS_MAP)[1],
	modifiedOrdersData: {},
	mouseEnterOrder: null,
	mouseEnterPriceline: null,
	tentativeOrder: null,
	showSwitchPositionPopup: false,
	crosshairActiveOnMobile: false,
	openOrders: [],
	closeOrders: [],
	priceLines: [],
	position: null,
	pricePrecision: undefined,
});

export const reducerContext = { reducer: contextReducer, init: contextInit };
