import { EMA, SMA } from 'technicalindicators';
import { fixedAsNumber } from 'util/numbers';

const SHORT_PERIOD = 11;
const LONG_PERIOD = 22;
const SMA_PERIOD = 99;

function getEmaLines(klineData = []) {
	const shortEmaLine = EMA.calculate({
		period: SHORT_PERIOD,
		values: klineData.map(d => d.close),
	}).map(
		(ema, idx) => ({
			x: klineData[idx + SHORT_PERIOD]?.openTime,
			y: fixedAsNumber(ema, 2, undefined),
		})
	);

	const longEmaLine = EMA.calculate({
		period: LONG_PERIOD,
		values: klineData.map(d => d.close),
	}).map(
		(ema, idx) => ({
			x: klineData[idx + LONG_PERIOD]?.openTime,
			y: fixedAsNumber(ema, 2, undefined),
		})
	);

	const smaLine = SMA.calculate({
		period: SMA_PERIOD,
		values: klineData.map(d => d.close),
	}).map(
		(sma, idx) => ({
			x: klineData[idx + SMA_PERIOD]?.openTime,
			y: fixedAsNumber(sma, 2, undefined),
		})
	);

	const prediction = shortEmaLine.map(
		({ x, y }, idx) => {
			const shortEma = y;
			const twoAgoShortEma = shortEmaLine[idx - 2]?.y;
			const prevShortEma = shortEmaLine[idx - 1]?.y;
			const nextShortEma = shortEmaLine[idx + 1]?.y;

			const longEmaIdx = idx - LONG_PERIOD - SHORT_PERIOD;
			const longEma = longEmaLine[longEmaIdx]?.y;
			const twoAgoLongEma = longEmaLine[longEmaIdx - 2]?.y;
			const prevLongEma = longEmaLine[longEmaIdx - 1]?.y;
			const nextLongEma = longEmaLine[longEmaIdx + 1]?.y;

			const smaIdx = idx - SMA_PERIOD - SHORT_PERIOD;
			const sma = smaLine[smaIdx]?.y;
			const twoAgoSma = smaLine[smaIdx - 2]?.y;
			const prevSma = smaLine[smaIdx - 1]?.y;
			const nextSma = smaLine[smaIdx + 1]?.y;

			const isShortEmaSlopeDown = idx < 1 ? false
				: idx < shortEmaLine.length - 2 ? nextShortEma - prevShortEma < 0
					: shortEma - twoAgoShortEma < 0 && shortEma <= prevShortEma;
			const isShortEmaSlopeUp = idx < 1 ? false
				: idx < shortEmaLine.length - 2 ? nextShortEma - prevShortEma > 0
					: shortEma - twoAgoShortEma > 0 && shortEma >= prevShortEma;

			const isLongEmaSlopeDown = longEmaIdx < 1 ? false
				: longEmaIdx < longEmaLine.length - 2 ? nextLongEma - prevLongEma < 0
					: longEma - twoAgoLongEma < 0 && longEma <= prevLongEma;
			const isLongEmaSlopeUp = longEmaIdx < 1 ? false
				: longEmaIdx < longEmaLine.length - 2 ? nextLongEma - prevLongEma > 0
					: longEma - twoAgoLongEma > 0 && longEma >= prevLongEma;

			const isSmaSlopeDown = smaIdx < 1 ? false
				: smaIdx < smaLine.length - 2 ? nextSma - prevSma < 0
					: sma - twoAgoSma < 0 && sma <= prevSma;
			const isSmaSlopeUp = smaIdx < 1 ? false
				: smaIdx < smaLine.length - 2 ? nextSma - prevSma > 0
					: sma - twoAgoSma > 0 && sma >= prevSma;

			const isShortBelowLong = shortEma - longEma < 0;

			return {
				isShortEmaSlopeDown,
				isShortEmaSlopeUp,
				isLongEmaSlopeDown,
				isLongEmaSlopeUp,
				isShortBelowLong,
				isSmaSlopeDown,
				isSmaSlopeUp,
			}
		}
	);

	return {
		shortEmaLine,
		longEmaLine,
		smaLine,
		prediction,
	};
};

export default getEmaLines;
