export const KLINE_LIMIT = 240;

export const SYMBOLS = ['BTC', 'ETH', 'MATIC', 'SOL'];

export const TIME_INTERVAL_OPS = ['1m', '5m', '15m', '30m', '1h', '4h', '12h', '1d'];

export const WIDGETS_OPS = ['RSI', 'MACD', 'EMA', 'STOCH'];

export const CHART_COLORS = {
	WHITE: '#fff',
	PURPLE: '#8294fe',
	LIGHT_BLUE: '#11cdef',
	BLUE: '#2962FF',
	GRAY: '#d3d3d3',
	LIGHT_GRAY: '#fcfcfc',
	RED: '#f5365c',
	LIGHT_RED: '#ffcdd2',
	GREEN: '#2dce89',
	LIGHT_GREEN: '#b2dfdb',
	YELLOW: '#ffeb3b',
	ORANGE: '#f37f20',
	TURQOISE: '#00bbd4',
};

export const CHART_HEIGHT = '102px';
