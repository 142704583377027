import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Toggle from 'components/Toggle';
import PastOrders from 'components/Orders/PastOrders';
import PendingOrders from 'components/Orders/PendingOrders';
import 'components/Orders/styles.scss';

const TABS = ['PAST', 'PENDING'];

export default function Orders(props) {
  const [activeTab, setActiveTab] = useState(props.tab || TABS[0]);

  return (
    <Card className="Orders shadow">
      <Card.Header className="fw6">Orders</Card.Header>
      <Card.Body className="scroll-x">
        <Toggle
          ops={TABS}
          active={activeTab}
          setActive={setActiveTab}
          className="mb-3"
        />

        {activeTab === "PAST" ? <PastOrders /> : <PendingOrders />}
      </Card.Body>
    </Card>
  );
};
