import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import supabase from 'util/supabase';
import binanceFuturesHandlers from './binanceFutures';
import binanceSpotHandlers from './binanceSpot';
import bingxSwapsHandlers from './bingxSwaps';
import historyHandlers from './history';
import fundingHandlers from './funding';
import { binanceFuturesUri, binanceSpotUri, bingxApiUri } from './constants';

/** Bingx Swaps **/
export const bingxSwapsApiSlice = createApi({
	reducerPath: 'bingxSwaps',
	baseQuery: fetchBaseQuery({
		baseUrl: `${bingxApiUri}/bingxSwaps`,
		prepareHeaders: (headers, { getState }) => {
			headers.set('Cache-Control', 'no-cache');
			headers.set('Pragma', 'no-cache');
			headers.set('Expires', '0');

			const { access_token } = supabase.auth.session() || {};
			if (access_token) {
				headers.set('authorization', access_token);
			}

			return headers;
		},
	}),
	endpoints: builder => bingxSwapsHandlers(builder),
});

export const {
	useGetAccountBalanceQuery, useGetAccountBalanceV2Query, useGetKlineHistoryQuery: useGetBingxSwapsKlineHistoryQuery, useGetKlineLatestQuery, useGetKlineDataV2Query, useGetLeverageQuery, useGetPairPriceQuery, useGetPositionsQuery, useGetPositionsV2Query, useGetPendingOrdersQuery, useGetPendingOrdersV2Query, useGetPendingStopOrdersQuery, useGetProfitAndLossQuery, useGetFundingRateQuery, useGetFundingRateV2Query, useGetAllContractsQuery, useGetAllContractsV2Query, useGetFeeRateQuery, useGetHistoricalOrdersQuery, useGetHistoricalFilledOrdersQuery,
	// Mutations
	useCancelOrderMutation, useCancelOrdersMutation, useCancelOrdersV2Mutation, usePlaceBulkOrdersMutation, usePlaceBulkOrdersV2Mutation, usePlaceOrderMutation, usePlaceOrderV2Mutation, usePlaceOrderMinSizeMutation, useModifyOrdersMutation, usePlaceGappedStopLossOrdersV2Mutation,
} = bingxSwapsApiSlice;


/** Binance Futures **/
export const binanceFuturesApiSlice = createApi({
	reducerPath: 'binanceFutures',
	baseQuery: fetchBaseQuery({
		baseUrl: binanceFuturesUri,
		fetchFn: async (...args) => {
			const { method, url } = args[0];
			const reqUrl = `${bingxApiUri}/proxy/toronto`;
			const { access_token } = supabase.auth.session() || {};
			
			return await fetch(`${reqUrl}?` + new URLSearchParams({
				method,
				url,
			}), {
				headers: {
					'Cache-Control': 'no-cache',
					'Pragma': 'no-cache',
					'Expires': '0',
					...access_token ? {
						'authorization': access_token,
					} : {},
				},
			});
		},
	}),
	endpoints: builder => binanceFuturesHandlers(builder),
});

export const {
	useGetGlobalLongShortRatioQuery, useGetTopLongShortPositionRatioQuery, useGetTopLongShortAccountRatioQuery, useGetMarketDepthQuery, useGetKlineDataQuery, useGetFundingRateHistoryQuery: useGetBinanceFundingRateHistoryQuery, useGetMarkPriceQuery,
} = binanceFuturesApiSlice;


/** Binance Spot **/
export const binanceSpotApiSlice = createApi({
	reducerPath: 'binanceSpot',
	baseQuery: fetchBaseQuery({
		baseUrl: binanceSpotUri,
		fetchFn: async (...args) => {
			const { method, url } = args[0];
			const reqUrl = `${bingxApiUri}/proxy/toronto`;
			const { access_token } = supabase.auth.session() || {};
			
			return await fetch(`${reqUrl}?` + new URLSearchParams({
				method,
				url,
			}), {
				headers: {
					'Cache-Control': 'no-cache',
					'Pragma': 'no-cache',
					'Expires': '0',
					...access_token ? {
						'authorization': access_token,
					} : {},
				},
			});
		},
	}),
	endpoints: builder => binanceSpotHandlers(builder),
});

export const {
	useGetSpotMarketDepthQuery, useGetSpotKlineDataQuery, useGetSpotSymbolPriceTickerQuery,
} = binanceSpotApiSlice;


/** History **/
export const historyApiSlice = createApi({
	reducerPath: 'history',
	baseQuery: fetchBaseQuery({
		baseUrl: `${bingxApiUri}/history`,
		prepareHeaders: (headers, { getState }) => {
			headers.set('Cache-Control', 'no-cache');
			headers.set('Pragma', 'no-cache');
			headers.set('Expires', '0');

			const { access_token } = supabase.auth.session() || {};
			if (access_token) {
				headers.set('authorization', access_token);
			}

			return headers;
		},
	}),
	endpoints: builder => historyHandlers(builder),
});

export const {
	useGetOrderbookSnapshotQuery, useGetFundingRateHistoryOptionsQuery, useGetFundingRateHistoryQuery, useGetKlineHistoryQuery,
} = historyApiSlice;

/** Funding **/
export const fundingApiSlice = createApi({
	reducerPath: 'funding',
	baseQuery: fetchBaseQuery({
		baseUrl: `${bingxApiUri}/funding`,
		prepareHeaders: (headers, { getState }) => {
			headers.set('Cache-Control', 'no-cache');
			headers.set('Pragma', 'no-cache');
			headers.set('Expires', '0');

			const { access_token } = supabase.auth.session() || {};
			if (access_token) {
				headers.set('authorization', access_token);
			}

			return headers;
		},
	}),
	endpoints: builder => fundingHandlers(builder),
});

export const {
	//Mutations
	useGetBacktestReportMutation,
} = fundingApiSlice;
