import { useMemo } from 'react';
import { formatOrderbook } from "hooks/useFormatOrderbook";

const useFormatSnapshotForHeatmap = ({
	snapshot = [],
	grouping = 25,
	maxLines = 10000
} = {}) => useMemo(
	() => formatSnapshotForHeatmap({snapshot, grouping, maxLines}),
	// eslint-disable-next-line
	[
		// eslint-disable-next-line
		JSON.stringify(snapshot),
		grouping,
		maxLines,
	]
);

export default useFormatSnapshotForHeatmap;

export function formatSnapshotForHeatmap({ snapshot: snapshots = [], grouping = 25, maxLines = 10000 } = {}) {
	let globalMinPrice = 0;
	let globalMaxPrice = 0;
	let globalMinQty = 0;
	let globalMaxQty = 0;

	const snapsForHeatmap = snapshots.flatMap(
		snapshot => {
			const {
				asks,
				bids,
				minPrice,
				maxPrice,
				minQty,
				maxQty,
			} = formatOrderbook({
				asks: Array.isArray(snapshot?.asks) ? ordersToObj(snapshot?.asks) : snapshot?.asks,
				bids: Array.isArray(snapshot?.bids) ? ordersToObj(snapshot?.bids) : snapshot?.bids,
				maxLines,
				grouping,
				qtyAsPrice: true,
			});

			globalMinPrice = !globalMinPrice || minPrice < globalMinPrice ? minPrice : globalMinPrice;
			globalMaxPrice = !globalMaxPrice || maxPrice > globalMaxPrice ? maxPrice : globalMaxPrice;
			globalMinQty = !globalMinQty || minQty < globalMinQty ? minQty : globalMinQty;
			globalMaxQty = !globalMaxQty || maxQty > globalMaxQty ? maxQty : globalMaxQty;

			const data = Object.entries(// Dedupe any prices since bids/asks can overlap
				[...asks, ...bids].reduce(
					(acc, item) => {
						acc[item.price] = (acc[item.price] || 0) + item.qty;
						return acc;
					}, {}
				)
			).map(
				([price, qty]) => ({
					x: snapshot?.timestamp,
					y: Number(price),
					value: qty,
				})
			);

			return data;
		}
	);

	const sortedTimestamps = [...new Set(snapsForHeatmap.map(o => o.x))].sort((a, b) => b - a);
	const [maxTimestamp, minTimestamp] = [sortedTimestamps[0], sortedTimestamps[sortedTimestamps.length - 1]];
	const [timestamp1, timestamp2] = sortedTimestamps.slice(0,2);
	const colsize = !timestamp2 ? 60000 : timestamp1 - timestamp2;

	return {
		data: snapsForHeatmap,
		minPrice: globalMinPrice,
		maxPrice: globalMaxPrice,
		minQty: globalMinQty,
		maxQty: globalMaxQty,
		colsize: colsize,
		rowsize: grouping,
		minTimestamp: minTimestamp,
		maxTimestamp: maxTimestamp,
	}
};


function ordersToObj(orders = []) {
	return orders.reduce(
		(acc, [price, qty]) => {
			acc[price] = qty;
			return acc;
		}, {}
	);
};
