import React, { useReducer, useEffect, useCallback, useRef } from "react";
import clsx from 'clsx';
// import { Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackBtn from "components/BackBtn";
import CryptoIcon from 'components/CryptoIcon';
import Flex from "components/Flex";
// import FundingRateHistoryChart from 'components/FundingRateHistoryChart';
import Popup from "components/Popup";
import Toggle from "components/Toggle";
import { useGetAllContractsV2Query } from 'api/client';
import useAccountDataWs from 'api/websockets/bingxSwaps/useAccountDataWs';
import { useHistory, useParams, useLocation } from 'util/router';
import NewOrder from 'components/PositionSectionNew/NewOrder';
import OpenOrders from 'components/PositionSectionNew/OpenOrders';
import CloseOrders from 'components/PositionSectionNew/CloseOrders';
// import PastOrders from 'components/Orders/PastOrders';
import PositionSummary from 'components/PositionSectionNew/PositionSummary';
import PriceChart from 'components/PositionSectionNew/PriceChart';
import SwitchPositionPopup from 'components/PositionSectionNew/SwitchPositionPopup';
import QuickClose from 'components/PositionSectionNew/QuickClose';
import QuickOpen from 'components/PositionSectionNew/QuickOpen';
import { PositionContext } from 'components/PositionSectionNew/constants';
import { reducerContext } from 'components/PositionSectionNew/reducers';
import useGetPosition from 'components/PositionSectionNew/hooks/useGetPosition';
import useGetPendingOrders from 'components/PositionSectionNew/hooks/useGetPendingOrders';
import 'components/PositionSectionNew/styles.scss';

function PositionSection(props) {
	const { replace: replaceHistory } = useHistory();
	const { symbol } = useParams();
	const location = useLocation();
	const [
		{
			side,
			period,
			modifiedOrdersData,
			mouseEnterOrder,
			mouseEnterPriceline,
			tentativeOrder,
			showSwitchPositionPopup,
			crosshairActiveOnMobile,
			priceLines = [],
		},
		dispatchContext
	] = useReducer(reducerContext.reducer, new URLSearchParams(location.search), reducerContext.init);
	const switchPositionPopupRef = useRef();

	/** Watch for change in side and period and modify URL params **/
	useEffect(() => {
		const currentSearchParams = new URLSearchParams(location.search)
		const locationSide = currentSearchParams.get('side');
		const locationPeriod = currentSearchParams.get('period');

		if (
			!locationSide ||
			locationSide !== side ||
			locationPeriod !== period
		) {
			replaceHistory({
				pathname: location?.pathname,
				search: new URLSearchParams({
					...currentSearchParams,
					side: side,
					period: period,
				}).toString()
			})
		}
	}, [
		side,
		period,
		replaceHistory,
		location?.search,
		location?.pathname
	]);

	/* QUERIES */
	/** Position **/
	const position = useGetPosition({
		symbol,
		side,
	});

	/** Orders **/
	const {
		openOrders = [],
		closeOrders = [],
		// refetchOrders,
	} = useGetPendingOrders({
		symbol,
		side,
		positionAvgPrice: position?.avgPrice,
		positionAmt: position?.positionAmt,
		modifiedOrdersData,
		tentativeOrder,
	});

	/** All contracts for price precision **/
	const {
		data: contract = {},
	} = useGetAllContractsV2Query({symbol});

	const { pricePrecision = 2 } = contract;

	/* WEBSOCKETS */
	/* Listen for order updates; this auto modifies the pendingOrders data value from useGetPendingOrdersV2 */
	useAccountDataWs('ORDER_TRADE_UPDATE');

	/* DATA */
	const context = { 
		symbol,
		side,
		period,
		mouseEnterOrder,
		mouseEnterPriceline,
		position,
		pricePrecision,
		openOrders,
		closeOrders,
		tentativeOrder,
		crosshairActiveOnMobile,
		priceLines,
		dispatchContext,
	};

	/* CALLBACKS */
	/** Open position switcher popup **/
	const openPositionSwitcherPopup = useCallback(
		(e) => {
			if (e.charCode === 32) {
				e?.preventDefault?.();
				switchPositionPopupRef.current.openPopup();
				dispatchContext({ type: 'toggleSwitchPositionPopup' });
			}
		}, []
	);

	/** Listen for cmd+spacebar to open position switcher **/
	useEffect(() => {
		if (!showSwitchPositionPopup) {
			window.addEventListener('keypress', openPositionSwitcherPopup);
		}

		return () => window.removeEventListener('keypress', openPositionSwitcherPopup);
	}, [
		showSwitchPositionPopup,
		openPositionSwitcherPopup
	]);

	return (
		<PositionContext.Provider value={context}>
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  className="PositionSection"
			>
				<Container fluid className="px-lg-4 px-xl-5">
			  	<SectionHeader
			  	  title={(
			  	  	<Flex justify="center" align="center">
			  	  		<Flex
			  	  			className="pointer"
			  	  			onClick={() => !showSwitchPositionPopup && openPositionSwitcherPopup({ charCode: 32 })}
			  	  		>
				  	  		<CryptoIcon
				  	  			symbol={symbol}
				  	  			width={32}
				  	  			height={32}
				  	  			className="mr-1.5 show-shadow"
				  	  		/>
				  	  		<span>{symbol}</span>
			  	  		</Flex>
			  	  		<Toggle
			  	  			ops={['short', 'long']}
			  	  			active={side}
			  	  			setActive={side => dispatchContext({ type: 'side', payload: side })}
			  	  			activeClassName={clsx({
			  	  				'text-success': side === 'long',
			  	  				'text-danger': side === 'short',
			  	  			})}
			  	  			className="f-rem-0.9 fw4 ml-2 mt-0.5"
			  	  		/>
			  	  	</Flex>
			  	  )}
			  	  subtitle={props.subtitle}
			  	  size={props.headerSize}
			  	  className="text-center"
			  	/>
			  	<BackBtnWrapper />
			  	<Row>
			  		{/* Position summary, Quick Orders */}
			  		<Col xs={12} lg={3} xl={2} className="mb-3 mb-lg-0">
			  			<Flex className="PositionSummary-QuickOpen-QuickClose-wrapper">
				  			<PositionSummary />
				  			<QuickOpen />
				  			<QuickClose />
			  			</Flex>
			  		</Col>
			  		<Col xs={12} lg={5} xl={6}>
			  			{/* Price chart */}
			  			<PriceChart />
			  			<div className="py-0.5" />
			  			{/*<FundingRateHistoryChart />*/}
			  		</Col>
			  		{/* New Orders, Pending Open/Close Orders tables, Past orders table */}
			  		<Col xs={12} lg={4} xl={4} className="mt-3 mt-lg-0">
			  			<Flex className="NewOrder-OpenOrders-CloseOrders-wrapper">
				  			<NewOrder /> {/* Auto hides when !tentativeOrder */}
				  			<OpenOrders />
				  			<CloseOrders actionShort="TP" />
								<CloseOrders actionShort="SL" />
								{/*
								<PastOrdersCard>
									<PastOrders symbol={symbol.split('-')[0]} />
								</PastOrdersCard>
								*/}
				  		</Flex>
			  		</Col>
			  	</Row>
			  </Container>
				<Popup
					ref={switchPositionPopupRef}
					size="md"
					title="Positions"
					afterClose={() => dispatchContext({ type: 'toggleSwitchPositionPopup' })}
				>
					{showSwitchPositionPopup && (
						<SwitchPositionPopup
							closePopup={() => switchPositionPopupRef.current.closePopup()}
							setPositionSide={(side) => dispatchContext({ type: 'side', payload: side })}
						/>
					)}
				</Popup>
			</Section>
		</PositionContext.Provider>
	);
};

export default PositionSection;


const BackBtnWrapper = () => {
	const history = useHistory();

	return (
		<Row>
			<Col xs={12}>
				<Flex
					align="center"
					style={{marginTop: '-1rem', marginBottom: '.5rem'}}
				>
					<BackBtn
						className="text-gray-700 text-gray-700-hvr-dark"
						onClick={() => history.goBack()}
					/>
				</Flex>
			</Col>
		</Row>
	);
};

// const PastOrdersCard = (props) => (
// 	<Card className="PastOrders shadow mt-0 mt-lg-2.5">
// 		<Card.Header className="py-2 px-3">Past Orders</Card.Header>
// 		<Card.Body className="p-0 scroll-x">
// 			{props.children}
// 		</Card.Body>
// 	</Card>
// );