import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faFilterSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-bootstrap/Spinner';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import { useGetHistoricalOrdersQuery } from 'api/client';
import { currencyFormat } from 'util/numbers';
import { PAST_ORDERS_POLLING } from 'constants/bingxSwaps';
import 'components/Orders/styles.scss';

export default function PastOrders(props) {
  const [symbolFilter, setSymbolFilter] = useState(props.symbol);

  useEffect(() => {
    if (props.symbol) {
      setSymbolFilter(props.symbol);
    }
  }, [
    props.symbol
  ]);

  const {
    data: historicalOrders = [],
    isLoading: historicalOrdersIsLoading,
    // error: historicalOrdersError,
  } = useGetHistoricalOrdersQuery({
    limit: 1000,
    ...symbolFilter ? { symbol: `${symbolFilter}-USDT` } : {},
  }, {
    pollingInterval: PAST_ORDERS_POLLING,
  });

  return historicalOrdersIsLoading ? (<Spinner />) : (
    <table className="PastOrders table-stripe table-striped responsive w-100">
      <thead style={{position: 'sticky', top: '0'}}>
        <tr>
          <th>Symbol</th>
          <th>Action</th>
          <th>Value / Price</th>
          <th>Profit</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {historicalOrders.slice(0, props.limit || undefined).map(order => (
          <tr key={order.orderId}>
            {/* Symbol */}
            <td className="fw6">
              <Flex align="center">
                <span>{order.symbol.split('-')[0]}</span>
                <FontAwesomeIcon 
                  size="sm" 
                  icon={symbolFilter ? faFilterSlash : faFilter} 
                  className="px-2 py-1 text-warning pointer"
                  onClick={() => setSymbolFilter(prev => prev ? null : order.symbol.split('-')[0])}
                />
              </Flex>
            </td>
            {/* Action */}
            <td>
              <span
                className={clsx('fw6', {
                  'text-success': order.action === 'CLOSE',
                  'text-danger': order.action === 'OPEN',
                
                })}
              >
                {order.action.slice(0, 2)} {order.positionSide.slice(0, 2)}
              </span>
              <SlashDivider />
              <span className="text-muted fw5">{order.type.slice(0, 3)}</span>
            </td>
            {/* Value / Price */}
            <td>
              <span className="fw5">{currencyFormat(order.origQty * order.avgPrice)}</span>
              <SlashDivider />
              <span className="text-muted">{currencyFormat(order.avgPrice)}</span>
            </td>
            {/* Profit */}
            <td
              className={clsx({
                'text-success': order.profit > 0,
                'text-danger': order.profit < 0,
                'text-muted': order.profit === 0,
              })}
            >
              {currencyFormat(order.profit)}
            </td>
            {/* Date */}
            <td className="text-muted">{dayjs(order.updateTime).format('MM/DD/YY h:mm a')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
};