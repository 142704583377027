import React from 'react';
import clsx from 'clsx';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faBezierCurve } from '@fortawesome/pro-light-svg-icons';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';
import Chart from 'components/Analyze/widgets/RSI/Chart';
import rsiFormula from 'components/Analyze/widgets/RSI/formula';
import { KLINE_LIMIT } from 'components/Analyze/constants';

function RSI(props) {
	const { klineData = [] } = props;

	const {
		rsiLine = [],
		smaLine = [],
		prediction = [],
	} = rsiFormula(klineData.slice(KLINE_LIMIT - 75));

	const {
		isHeadingUp,
		isHeadingDown,
		isSmaSlopeUp,
		isSmaSlopeDown,
		isRsaBelowSma,
	} = prediction[prediction.length - 1] || {};

	const recommendation = isHeadingDown && isSmaSlopeDown && isRsaBelowSma ? RECOMMENDATION_MAP.SHORT
		: isHeadingUp && isSmaSlopeUp && !isRsaBelowSma ? RECOMMENDATION_MAP.LONG
			: RECOMMENDATION_MAP.WAIT;

	return (
		<Card className="Widget RSI shadow-sm">
			<Card.Header>
				<span className="title">RSI</span>
				<FontAwesomeIcon icon={recommendation.summaryIcon} className={`icon ${recommendation.color}`}/>
			</Card.Header>
			<Card.Body>
				<Chart series={{
					rsi: rsiLine,
					sma: smaLine,
				}} />
			</Card.Body>
			<Card.Footer>
				<span className="recommendation-label">RSI</span>
				<FontAwesomeIcon
					icon={isHeadingDown || isHeadingUp ? faBezierCurve : faClock}
					rotation={isHeadingUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isHeadingDown,
						'text-success': isHeadingUp,
						'text-gray-600': !isHeadingDown && !isHeadingUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">SMA</span>
				<FontAwesomeIcon
					icon={isSmaSlopeDown || isSmaSlopeUp ? faBezierCurve : faClock}
					rotation={isSmaSlopeUp ? 180 : 0}
					className={clsx('icon', {
						'text-danger': isSmaSlopeDown,
						'text-success': isSmaSlopeUp,
						'text-gray-600': !isSmaSlopeDown && !isSmaSlopeUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">{`RSA ${isRsaBelowSma ? '<' : '>'} SMA`}</span>
				<FontAwesomeIcon
					icon={isRsaBelowSma ? faArrowDown : faArrowUp}
					className={clsx('icon', {
						'text-danger': isRsaBelowSma,
						'text-success': !isRsaBelowSma,
					})}
				/>
			</Card.Footer>
		</Card>
	);
};

export default RSI;


const RECOMMENDATION_MAP = {
	SHORT: {
		summaryIcon: faArrowDown,
		color: 'text-danger',
		text: 'short',
	},
	LONG: {
		summaryIcon: faArrowUp,
		color: 'text-success',
		text: 'long',
	},
	WAIT: {
		summaryIcon: faClock,
		color: 'text-gray-600',
		text: 'wait',
	},
};
