import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from "react-bootstrap/Spinner";
import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import { useGetFundingRateHistoryOptionsQuery } from 'api/client';
import { millifyCurrency } from 'util/numbers';
import { FundingContext, ACCOUNT_VALUE_START } from 'components/FundingSection/constants';
import "react-datepicker/dist/react-datepicker.css";
dayjs.extend(dayjsUtc);

export default function OptionsCard(props) {
	const { symbols, futuresExchanges, spotExchanges, accountValueStart, minStartTime, maxEndTime, startTime, endTime, runReport, dispatchContext } = useContext(FundingContext);

	const [symbolOptions, setSymbolOptions] = useState([]);
	const [exchangeOptions, setExchangeOptions] = useState([]);
	const [showDropdowns, setShowDropdowns] = useState({
		symbols: false,
		futuresExchanges: false,
		spotExchanges: false,
	});
	const [dates, setDates] = useState({ startTime, endTime });

	const {
		data: fundingRateHistoryOptions = {},
		isLoading: fundingRateHistoryOptionsIsLoading,
	} = useGetFundingRateHistoryOptionsQuery();

	useEffect(() => {
		if (!fundingRateHistoryOptionsIsLoading) {
			setSymbolOptions(fundingRateHistoryOptions.symbols);
			fundingRateHistoryOptions.symbols.forEach(
				symbol => dispatchContext({ type: 'symbols', payload: symbol })
			);

			setExchangeOptions(fundingRateHistoryOptions.exchanges);
			fundingRateHistoryOptions.exchanges.forEach(
				exchange => dispatchContext({ type: 'futuresExchanges', payload: exchange })
			);
			fundingRateHistoryOptions.exchanges.forEach(
				exchange => dispatchContext({ type: 'spotExchanges', payload: exchange })
			);

			dispatchContext({ type: 'minStartTime', payload: fundingRateHistoryOptions.minStartTime });
			dispatchContext({ type: 'maxEndTime', payload: fundingRateHistoryOptions.maxEndTime });
		}
	}, [
		fundingRateHistoryOptionsIsLoading,
		fundingRateHistoryOptions,
		dispatchContext,
	]);

	return (
		<Card className="OptionsCard shadow">
			<Card.Header className="px-2.5 py-2">
				<span className="fw5">Options</span>
			</Card.Header>
			<Card.Body>
				{/* Symbol */}
				<OptionLabel>Symbols</OptionLabel>
				<Dropdown
					show={showDropdowns.symbols}
					onToggle={(isOpen, e, metadata) => {
						if (metadata.source !== 'select') {
							setShowDropdowns(prev => ({
								...prev,
								symbols: isOpen,
							}))
						}
					}}
				>
					<Dropdown.Toggle
						id="symbols-dropdown"
						variant={symbols.length < 1 ? 'outline-warning' : 'outline-success'}
						size="sm"
					>
						{symbols.length < 1 ? '! Symbol' : `${symbols.length} symbol${symbols.length > 1 ? 's' : ''}`}
					</Dropdown.Toggle>
					<Dropdown.Menu
						style={{
							maxHeight: '400px',
							overflowY: 'scroll',
						}}
					>
						<Dropdown.Header>Symbols to include in report</Dropdown.Header>
						{[
							'BTC',
							'ETH',
							...symbolOptions.filter(s => !['BTC', 'ETH'].includes(s))
						].map(symbol => (
							<Dropdown.Item
								key={symbol}
								active={symbols.includes(symbol)}
								onClick={() => dispatchContext({
									type: 'symbols',
									payload: symbol,
								})}
							>
								<Flex justify="between" align="center">
									<span>{symbol}</span>
									{symbols.includes(symbol) && (<FontAwesomeIcon icon={faCheck} />)}
								</Flex>
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
				{/* Futures Exchange */}
				<div className="mt-2">
					<OptionLabel>Futures exchanges</OptionLabel>
					<Dropdown
						show={showDropdowns.futuresExchanges}
						onToggle={(isOpen, e, metadata) => {
							if (metadata.source !== 'select') {
								setShowDropdowns(prev => ({
									...prev,
									futuresExchanges: isOpen,
								}))
							}
						}}
					>
						<Dropdown.Toggle
							id="exchanges-dropdown"
							variant={futuresExchanges.length < 1 ? 'outline-warning' : 'outline-success'}
							size="sm"
						>
							{futuresExchanges.length > 0 ? `${futuresExchanges.length} Futures Exchange${futuresExchanges.length > 1 ? 's' : ''}` : '! Futures Exchanges'}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Header>Futures exchanges to include in report</Dropdown.Header>
							{exchangeOptions.map(exchange => (
								<Dropdown.Item
									key={exchange}
									active={futuresExchanges.includes(exchange)}
									className="text-capitalize"
									onClick={() => dispatchContext({
										type: 'futuresExchanges',
										payload: exchange,
									})}
								>
									<Flex justify="between" align="center">
										<span>{exchange.toLowerCase()}</span>
										{futuresExchanges.includes(exchange) && (<FontAwesomeIcon icon={faCheck} />)}
									</Flex>
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				{/* Spot Exchange */}
				<div className="mt-2">
					<OptionLabel>Spot exchanges</OptionLabel>
					<Dropdown
						show={showDropdowns.spotExchanges}
						onToggle={(isOpen, e, metadata) => {
							if (metadata.source !== 'select') {
								setShowDropdowns(prev => ({
									...prev,
									spotExchanges: isOpen,
								}))
							}
						}}
					>
						<Dropdown.Toggle
							id="exchanges-dropdown"
							variant={spotExchanges.length < 1 ? 'outline-warning' : 'outline-success'}
							size="sm"
						>
							{spotExchanges.length > 0 ? `${spotExchanges.length} Spot Exchange${spotExchanges.length > 1 ? 's' : ''}` : '! Spot Exchanges'}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Header>Spot exchanges to include in report</Dropdown.Header>
							{exchangeOptions.map(exchange => (
								<Dropdown.Item
									key={exchange}
									active={spotExchanges.includes(exchange)}
									className="text-capitalize"
									onClick={() => dispatchContext({
										type: 'spotExchanges',
										payload: exchange,
									})}
								>
									<Flex justify="between" align="center">
										<span>{exchange.toLowerCase()}</span>
										{spotExchanges.includes(exchange) && (<FontAwesomeIcon icon={faCheck} />)}
									</Flex>
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				{/* Account value start */}
				<Toggle
					label="Account value start"
					labelClassName="f-rem-0.9 fw5 text-gray-600 d-block mt-2 mb-1"
					ops={ACCOUNT_VALUE_START}
					opFormatDisplay={op => millifyCurrency(op)}
					active={accountValueStart}
					setActive={op => dispatchContext({
						type: 'accountValueStart',
						payload: op,
					})}
					className="scroll-x"
					opClassName="f-rem-0.9 fw5"
				/>
				{/* Start time */}
				<Flex direction="column" className="mt-2">
					<OptionLabel>Start date</OptionLabel>
					<DatePicker
						selected={getLocalTime(dates.startTime)}
						onChange={(date) => {
							setDates(prev => ({
								...prev,
								startTime: getUtcDate(date),
							}))
						}}
						minDate={minStartTime}
						maxDate={getLocalTime(dates.endTime)}
						className="w-100"
					/>
				</Flex>
				{/* End time */}
				<Flex direction="column" className="mt-2">
					<OptionLabel>End date</OptionLabel>
					<DatePicker
						selected={getLocalTime(dates.endTime)}
						onChange={(date) => {
							setDates(prev => ({
								...prev,
								endTime: getUtcDate(date),
							}))
						}}
						minDate={getLocalTime(dates.startTime)}
						maxDate={maxEndTime}
						className="w-100"
					/>
				</Flex>
				{/* Submit */}
				<Button
					variant="primary"
					size="sm"
					className="w-100 mt-3"
					disabled={runReport}
					onClick={() => !runReport && dispatchContext({ type: 'runReport', payload: true })}
				>
					{runReport ? <Spinner animation="border" variant="white" size="sm" /> : 'Run Report'}
				</Button>
			</Card.Body>
		</Card>
	);
};

const OptionLabel = (props) => <span className="f-rem-0.9 fw5 text-gray-600 mb-1">{props.children}</span>

const getLocalTime = date => dayjs.utc(date).subtract(dayjs().utcOffset(), 'm').toDate();
const getUtcDate = date => dayjs.utc(date).add(dayjs().utcOffset(), 'm').toDate();
