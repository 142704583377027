import React from 'react';
import Highcharts from 'highcharts';
import HighchartsHistogramBellcurveModule from 'highcharts/modules/histogram-bellcurve';
import HighchartsReact from 'highcharts-react-official';
import { CHART_COLORS, CHART_HEIGHT } from 'components/Analyze/constants';
HighchartsHistogramBellcurveModule(Highcharts);

function Chart(props) {
	const options = {
		title: false,
		credits: {
			endabled: false,
			color: '#212529',
		},
		chart: {
			plotBorderWidth: 0,
			backgroundColor: '#212529',
			spacing: [4, 8, 4, 4],
		},
		xAxis: {
			title: false,
			type: 'datetime',
	  	maxPadding: 0,
	  	minPadding: 0,
	  	visible: false,
		},
		yAxis: {
			minPadding: 0,
	  	maxPadding: 0,
	  	visible: false,
  	},
  	tooltip: {
  		shared: true,
  	},
		series: [{
			type: 'line',
			name: 'Zero Line',
			color: CHART_COLORS.WHITE,
			showInLegend: false,
			lineWidth: 0.25,
			dashStyle: 'Dash',
			enableMouseTracking: false,
			data: props.series.macd.map(
				d => ({
					x: d.x,
					y: 0
				})
			),
			marker: {
				enabled: false,
			}
		}, {
			type: 'histogram',
			name: 'Histogram',
			showInLegend: false,
			borderColor: undefined,
			borderRadius: 0,
			borderWidth: 0,
			marker: {
				enabled: false,
			},
			colorByPoint: true,
			data: props.series.histogram.map(
				(d, idx, arr) => ({
					...d,
					color: d.y >= 0 ? (
						arr[idx - 1]?.y < d.y ? CHART_COLORS.GREEN : CHART_COLORS.LIGHT_GREEN
					) : (
						arr[idx - 1]?.y < d.y ? CHART_COLORS.LIGHT_RED : CHART_COLORS.RED
					)
				})
			),
		}, {
			type: 'line',
			name: 'MACD',
			color: CHART_COLORS.BLUE,
			connectNulls: true,
  		showInLegend: false,
  		lineWidth: 0.75,
  		marker: {
  			enabled: false,
	  		states: {
	  			hover: {
	  				radius: 2,
	  			}
	  		}
	  	},
			data: props.series.macd,
		}, {
			type: 'line',
			name: 'Signal',
			color: CHART_COLORS.RED,
			connectNulls: true,
  		showInLegend: false,
  		lineWidth: 0.75,
  		marker: {
  			enabled: false,
	  		states: {
	  			hover: {
	  				radius: 2,
	  			}
	  		}
	  	},
			data: props.series.signal,
		}],
	};

	return (
		<div className="Chart RSI">
			<HighchartsReact
		    highcharts={Highcharts}
		    containerProps={{
					style: {
						height: CHART_HEIGHT,
					}
				}}
		    options={options}
		  />
		</div>
	);
};

export default Chart;
