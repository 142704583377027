import React from "react";
import Meta from "components/Meta";
import BulkSection from "components/BulkSection";
import { requireAuth } from "util/auth";

function BulkPage(props) {
  return (
    <>
      <Meta title="Bulk" />
      <BulkSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Bulk"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(BulkPage);
