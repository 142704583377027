import { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import { useGetMarketDepthQuery, useGetSpotMarketDepthQuery } from "api/client";

global.dayjs = dayjs;

function useSnapshotOrderbook({
	symbol,
	marketType = 'futures',
	limit = marketType === 'futures' ? 1000 : 1000, //spot real limit is 5000
	frequency = 60 * 1000,
	onlyLatest = false,
} = {}) {
	const [snapshot, setSnapshot] = useState([]);

	// Erase snapshot on symbol or marketType change;
	useEffect(() => setSnapshot([]), [symbol, marketType]);

	const marketDepthQuery = useMemo(() => marketType === 'futures' ? useGetMarketDepthQuery : useGetSpotMarketDepthQuery, [marketType]);

	const {
		data: depth = {},
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	} = marketDepthQuery({
		symbol,
		limit,
	}, {
		skip: !symbol,
	});

	useEffect(() => {
		let interval;

		const msToNextMin = dayjs().add(1, 'm').startOf('m') - dayjs();

		const timeout = setTimeout(() => {
			interval = setInterval(() => {
				refetch();
			}, frequency);

			refetch();
		}, msToNextMin);

		return () => {
			clearTimeout(timeout);
			clearInterval(interval);
		};
	}, [frequency, refetch]);

	useEffect(() => {
		if (
			!isFetching &&
			!isError &&
			depth?.asks &&
			depth?.bids
		) {
			const date = dayjs().startOf('m').subtract(1, 'm');

			const newSnapshot = {
				date: date.toDate(),
				timestamp: +date,
				asks: depth?.asks || [],
				bids: depth?.bids || [],
			};

			setSnapshot(
				prev => onlyLatest ? newSnapshot
					: uniqBy([...prev, newSnapshot], 'timestamp')
			);
		}
	}, [
		isFetching,
		isError,
		depth,
		frequency,
		onlyLatest
	]);

	return {
		snapshot,
		isLoading,
		isFetching,
		isError,
		error,
	};
};

export default useSnapshotOrderbook;
