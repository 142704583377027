import { useState, useEffect, useMemo, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { DEFAULT_OPTIONS } from 'api/websockets/constants';
import useHandleSubs from 'api/websockets/bingxSwaps/hooks/useHandleSubs';
import { wsUrlV2 } from 'api/websockets/bingxSwaps/constants';

const DATA_TYPE_STR = '@depth';

export default function useMarketDepthWs(initSubs = []) {
	const [subs, setSubs] = useState(initSubs || []);
	const [subsToAdd, setSubsToAdd] = useState(subs);
	const [subsToRemove, setSubsToRemove] = useState([]);
	const [messages, setMessages] = useState({});
	const formatSubForMessage = useMemo((sub) => (sub) => `${sub}${DATA_TYPE_STR}5`, []);
	const [lastMessageDataStr, setLastMessageDataStr] = useState(null);
	const dataTypeRegex = useMemo(() => new RegExp(DATA_TYPE_STR, 'i'), []);
	const didUnmount = useRef(false);
	const { sendMessage, lastMessage, readyState } = useWebSocket(
		wsUrlV2,
		{
			...DEFAULT_OPTIONS('useMarketDepthWs'),
			shouldReconnect: (closeEvent) => didUnmount.current === false,
			share: true,
		}
	);

	useEffect(() => () => didUnmount.current = true, []);

	const setSubsProxy = useHandleSubs({
		subs,
		setSubs,
		subsToRemove,
		setSubsToRemove,
		subsToAdd,
		setSubsToAdd,
		readyState,
		sendMessage,
		formatSubForMessage,
		dataTypeRegex,
		lastMessageData: lastMessage?.data,
		lastMessageDataStr,
		setLastMessageDataStr,
	});

	// Main logic to get data
	useEffect(() => {
		if (lastMessageDataStr) {
			const { id, code, msg, dataType, data } = (JSON.parse(lastMessageDataStr)) || {};

			if (id || code || msg === "") {
				// Subscribe/unsubscribe success; msg will be empty string
				// console.log({id, msg});
			} else if (dataTypeRegex.test(dataType)) {
				const [pair] = dataType.split(DATA_TYPE_STR);
				// Data came in, handle
				// We want only the lowest ask for SELLS (SHORT)
				const [latestAsk] = (data.asks || []).map(
					([price, volume]) => ({
						price: Number(price),
						volume: Number(volume),
					})
				).sort(
					(a, b) => a.price - b.price
				);
				// We want only the highest bid for BUYS (LONG)
				const [latestBid] = (data.bids || []).map(
					([price, volume]) => ({
						price: Number(price),
						volume: Number(volume),
					})
				).sort(
					(a, b) => b.price - a.price
				);

				setMessages(prev => {
					// Weighted avg between latest sell and buy prices
					const latestAskPrice = latestAsk?.price || 0;
					const latestAskVol = latestAsk?.volume || 0;
					const latestBidPrice = latestBid?.price || 0;
					const latestBidVol = latestBid?.volume || 0;
					const latestTotalVol = (latestAskVol + latestBidVol) || 1;

					const price = (
						latestAskPrice * (latestAskVol / latestTotalVol)
					) + (
						latestBidPrice * (latestBidVol / latestTotalVol)
					);

					const prevInfo = prev?.[pair] || {};
					const prevPrice = prevInfo?.price || 0;
					const prevAskPrice = prevInfo?.ask?.price || 0; //Last sell price (SHORT)
					const prevBidPrice = prevInfo?.bid?.price || 0; //Last buy price (LONG)
					const prevAsk = prevInfo?.ask || {};
					const prevBid = prevInfo?.bid || {};

					return ({
						...prev,
						[pair]: {
							...prevInfo,
							price: price || prevPrice,
							askPrice: latestAskPrice || prevAskPrice,
							bidPrice: latestBidPrice || prevBidPrice,
							ask: {
								...prevAsk,
								...latestAsk,
							},
							bid: {
								...prevBid,
								...latestBid,
							},
						},
					});
				});
			}
		}
	}, [
		lastMessageDataStr,
		dataTypeRegex,
	]);

	return [
		messages,
		setSubsProxy,
		ReadyState[readyState]
	];
};
