import React, { useState, useEffect, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Form from 'react-bootstrap/Form';
import CryptoIcon from 'components/CryptoIcon';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import Toggle from 'components/Toggle';
import {
	useGetPositionsV2Query,
	useGetAllContractsV2Query,
} from 'api/client';
import { POLLING_INTERVAL } from 'constants/bingxSwaps';
import { currencyFormat } from 'util/numbers';
import { useHistory, useLocation } from 'util/router';

const SIDE_FILTERS = ['all', 'long', 'short'];

function SwitchPositionPopup(props) {
	const { closePopup, setPositionSide } = props;
	const { push: historyPush } = useHistory();
	const period = new URLSearchParams(useLocation().search).get('period');
	const [search, setSearch] = useState('');
	const [filtered, setFiltered] = useState([]);
	const [sideFilter, setSideFilter] = useState(SIDE_FILTERS[0]);
	const inputRef = useRef();

	/* QUERIES */
	/* Contracts */
	const {
		data: contracts = [],
		isLoading: isContractsLoading,
	} = useGetAllContractsV2Query();

	/* Positions */
	const {
		data: positions = [],
		isLoading: isPositionsLoading,
	} = useGetPositionsV2Query({}, {
		pollingInterval: POLLING_INTERVAL,
	});

	/* DATA */
	const isLoading = isContractsLoading || isPositionsLoading;

	const memoizedContractsWithPositions = JSON.stringify(
		contracts.flatMap(
			c => {
				const positionShort = positions.find(p => p.positionSide === 'SHORT' && p.symbol === c.symbol);
				const positionLong = positions.find(p => p.positionSide === 'LONG' && p.symbol === c.symbol);

				return !positionShort && !positionLong ? c : [
					...positionShort ? [{
						...c,
						position: positionShort,
					}] : [],
					...positionLong ? [{
						...c,
						position: positionLong,
					}] : []
				];
			}
		)
	);

	/* EFFECTS */
	/** Filter contracts by search **/
	useEffect(() => {
		const contractsWithPositions = JSON.parse(memoizedContractsWithPositions);

		if (search?.trim?.()) {
			const regExp = new RegExp(search.trim(), 'i');

			const filtered = contractsWithPositions.filter(
				c => {
					const [symbol] = c.symbol.split('-');
					const { positionSide } = c.position || {};

					// "symbol" if just contract, otherwise "symbol positionSide symbolPositionSide positionSideSymbol" to catch all ways of typing it
					const pStr = [
						symbol,
						...positionSide ? [
							positionSide,
							`${symbol}${positionSide}`,
							`${positionSide}${symbol}`,
						] : [],
					].join(' ');

					const regExpIsVaild = regExp.test(pStr);
					return regExpIsVaild && (sideFilter === 'all' || positionSide === sideFilter.toUpperCase());
				}
			);

			setFiltered(
				sortContracts(filtered)
			);
		} else {
			setFiltered(
				sortContracts(
					contractsWithPositions.filter(
						c => sideFilter === 'all' || (c.position?.positionSide === sideFilter.toUpperCase())
					)
				)
			);
		}
	}, [
		search,
		sideFilter,
		memoizedContractsWithPositions,
	]);

	/** Auto focus search input **/
	useEffect(() => inputRef.current.focus(), []);

	/* CALLBACKS */
	const handleNavigateToPosition = useCallback((symbol, positionSide) => {
		if (symbol && positionSide) {
			const side = positionSide?.toLowerCase?.();
			setPositionSide(side);

			const url = `/positions/new/${symbol}?side=${side}&period=${period}`;
			historyPush(url);
			closePopup();
		}
	}, [
		period,
		setPositionSide,
		historyPush,
		closePopup,
	]);

	return (
		<Flex
			direction="column"
			className="SwitchPositionPopup"
		>
			<Toggle 
				ops={SIDE_FILTERS}
				active={sideFilter}
				setActive={setSideFilter}
				opClassName="f-rem-0.85 fw5"
			/>
			<Form.Control
				ref={inputRef}
				aria-label="Search"
				type="text"
				className="search"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				onKeyPress={(e) => {
					if (e.charCode === 13) {
						const {
							symbol,
							position: {
								positionSide = 'SHORT'
							} = {}
						} = filtered?.[0] || {};

						handleNavigateToPosition(symbol, positionSide);
					}
				}}
			/>
			<Flex
				direction="column"
			>
				{isLoading ? (
					<FontAwesomeIcon
						icon={faSpinnerThird}
						spin={true}
						className="text-muted mt-3"
					/>
				) : (
					filtered.map(
						c => (
							<SwitchPositionRow
								key={`${c.symbol}${c.position?.positionId || ''}`.trim()}
								{...c}
								handleNavigateToPosition={handleNavigateToPosition}
							/>
						)
					)
				)}
			</Flex>
		</Flex>
	);
};

export default SwitchPositionPopup;

const SwitchPositionRow = (props) => {
	const {
		symbol,
		position: {
			positionSide = 'SHORT',
			leverage,
			avgPrice,
			positionAmt,
			realisedProfit,
			unrealizedProfit,
		} = {},
		handleNavigateToPosition,
	} = props;

	const hasPosition = !!props.position;
	const positionValue = hasPosition ? avgPrice * positionAmt : 0;

	return (
		<Flex
			justify="between"
			align="center"
			onClick={() => handleNavigateToPosition(symbol, positionSide)}
			className={clsx('SwitchPositionRow', {
				'py-2.5': !hasPosition,
			})}
		>
			<Flex
				direction="column"
				className="symbol-container"
			>
				<Flex align="center">
					<CryptoIcon
						className="show-shadow"
						symbol={symbol}
					/>
					<span className="symbol">{symbol.split('-')[0]}</span>
				</Flex>
				{hasPosition && (
					<span
						className={clsx('positionSide', {
							'text-danger': positionSide === 'SHORT',
							'text-success': positionSide === 'LONG',
						})}
					>
						{positionSide} {leverage}x
					</span>
				)}
			</Flex>
			{hasPosition && (
				<Flex
					direction="column"
					align="end"
					className="value-profit-container"
				>
					<span className="positionValue">{currencyFormat(positionValue)}</span>
					<Flex className="profit-container">
						<span
							className={clsx('realisedProfit', {
								'text-danger': realisedProfit < 0,
								'text-success': realisedProfit >= 0,
							})}
						>
							{currencyFormat(realisedProfit)}
						</span>
						<SlashDivider />
						<span
							className={clsx('unrealizedProfit', {
								'text-danger': unrealizedProfit < 0,
								'text-success': unrealizedProfit >= 0,
							})}
						>
							{currencyFormat(unrealizedProfit)}
						</span>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};


function sortContracts(contracts = []) {
	return [...contracts].sort(
		(a, b) => {
			let result = 0;

			if (a.position && b.position) {
				if (a.position.symbol === b.position.symbol) {
					// long before short
					result = a.position.positionSide.localeCompare(b.position.positionSide);
				} else {
					// a -> z
					result = a.position.symbol.localeCompare(b.position.symbol);
				}
			} else if (a.position || b.position) {
				// One of the two doesn't have a position item, the other does;
				// If `a` has a position, move it before (-1), else `b` had the position, so move it before (1)
				result = a.position ? -1 : 1;
			} else {
				result = a.symbol.localeCompare(b.symbol);
			}

			return result;
		}
	);
};
