import { configureStore } from '@reduxjs/toolkit';
import {
	binanceFuturesApiSlice,
	binanceSpotApiSlice,
	bingxSwapsApiSlice,
	historyApiSlice,
	fundingApiSlice,
	// otherApiSlice,
} from '../api/client';

const store = configureStore({
	reducer: {
		[binanceFuturesApiSlice.reducerPath]: binanceFuturesApiSlice.reducer,
		[binanceSpotApiSlice.reducerPath]: binanceSpotApiSlice.reducer,
		[bingxSwapsApiSlice.reducerPath]: bingxSwapsApiSlice.reducer,
		[historyApiSlice.reducerPath]: historyApiSlice.reducer,
		[fundingApiSlice.reducerPath]: fundingApiSlice.reducer,
		// [otherApiSlice.reducerPath]: otherApiSlice.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			binanceFuturesApiSlice.middleware
		).concat(
			binanceSpotApiSlice.middleware
		).concat(
			bingxSwapsApiSlice.middleware
		).concat(
			historyApiSlice.middleware
		).concat(
			fundingApiSlice.middleware
		).concat(
			// otherApiSlice.middleware
		)
});

export default store;

export const { dispatch } = store;
