import React, { useReducer } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Section from 'components/Section';
import SectionHeader from 'components/SectionHeader';
import { FundingContext } from 'components/FundingSection/constants';
import { reducerContext } from 'components/FundingSection/reducers';
import OptionsCard from 'components/FundingSection/OptionsCard';
// import Analysis from 'components/FundingSection/Analysis';
import Report from 'components/FundingSection/Report';
import 'components/FundingSection/styles.scss';

function FundingSection(props) {
		const [
		{
			symbols,
			futuresExchanges,
			spotExchanges,
			accountValueStart,
			minStartTime,
			maxEndTime,
			startTime,
			endTime,
			runReport,
		},
		dispatchContext
	] = useReducer(reducerContext.reducer, {}, reducerContext.init);

	const context = { symbols, futuresExchanges, spotExchanges, accountValueStart, minStartTime, maxEndTime, startTime, endTime, runReport, dispatchContext };

	return (
		<FundingContext.Provider value={context}>
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  className="FundingSection"
			>
				<Container fluid className="px-lg-4 px-xl-5">
				  <SectionHeader
	          title={props.title}
	          subtitle={props.subtitle}
	          size={3}
	          spaced={true}
	          className="text-center"
	        />

	        <Row>
	        	<Col xs={12} lg={3} xl={2} className="mb-3 mb-lg-0">
	        		<OptionsCard />
	        	</Col>
	        	<Col xs={12} lg={9} xl={10} className="mb-3 mb-lg-0">
	        		{/*<Analysis />*/}
	        		<Report />
	        	</Col>
	        </Row>
	      </Container>
	    </Section>
	  </FundingContext.Provider>
	);
};

export default FundingSection;
