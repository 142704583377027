import queryConstructor from 'util/queryConstructor';

function getGlobalLongShortRatio({symbol, period = '15m', limit = 500} = {}) {
	const queryParams = queryConstructor({symbol, period, limit});
	return `futures/data/globalLongShortAccountRatio?${queryParams}`;
};

function getTopLongShortPositionRatio({symbol, period = '15m', limit = 500} = {}) {
	const queryParams = queryConstructor({symbol, period, limit});
	return `futures/data/topLongShortPositionRatio?${queryParams}`;
};

function getTopLongShortAccountRatio({symbol, period = '15m', limit = 500} = {}) {
	const queryParams = queryConstructor({symbol, period, limit});
	return `futures/data/topLongShortAccountRatio?${queryParams}`;
};

function getMarketDepth({symbol = '', limit = 1000} = {}) {
	const queryParams = queryConstructor({symbol: symbol.toUpperCase(), limit});
	return `fapi/v1/depth?${queryParams}`;
};

function getKlineData({symbol = '', interval = '1m', startTime, endTime, limit}) {
	const queryParams = queryConstructor({symbol: symbol.toUpperCase(), interval, startTime, endTime, limit});
	return `fapi/v1/klines?${queryParams}`;
};

function getFundingRateHistory({symbol, limit} = {}) {
	const queryParams = queryConstructor({symbol: symbol.toUpperCase(), limit});
	return `fapi/v1/fundingRate?${queryParams}`;
};

function getMarkPrice({symbol} = {}) {
	const queryParams = queryConstructor({symbol: symbol.toUpperCase()});
	return `fapi/v1/premiumIndex?${queryParams}`;
};

const handlers = (builder) => ({
	getGlobalLongShortRatio: builder.query({
		query: getGlobalLongShortRatio,
		keepUnusedDataFor: 5,
	}),
	getTopLongShortPositionRatio: builder.query({
		query: getTopLongShortPositionRatio,
		keepUnusedDataFor: 5,
	}),
	getTopLongShortAccountRatio: builder.query({
		query: getTopLongShortAccountRatio,
		keepUnusedDataFor: 5,
	}),
	getMarketDepth: builder.query({
		query: getMarketDepth,
		keepUnusedDataFor: 5,
	}),
	getKlineData: builder.query({
		query: getKlineData,
		keepUnusedDataFor: 5,
		transformResponse: (res) => {
			return res.map(
				kline => ({
					openTime: kline[0],
					open: Number(kline[1]),
					high: Number(kline[2]),
					low: Number(kline[3]),
					close: Number(kline[4]),
					volume: Number(kline[5]),
					closeTime: kline[6],
					quoteAssetVolume: Number(kline[7]),
					numberOfTrades: kline[8],
					takerBuyBaseAssetVolume: kline[9],
					takerBuyQuoteAssetVolume: kline[10],
				})
			).sort(
				(a, b) => a.openTime - b.openTime //ascending
			);
		}
	}),
	getFundingRateHistory: builder.query({
		query: getFundingRateHistory,
		keepUnusedDataFor: 5,
	}),
	getMarkPrice: builder.query({
		query: getMarkPrice,
		keepUnusedDataFor: 5,
	}),
});

export default handlers;
