const isDev = process.env.NODE_ENV === 'development';

export const FEES = {
	maker: 0.00012,
	taker: 0.000375,
};

export const supportedSymbols = {
	'ADA': {
		decimals: 5,
		amountToOpen: 25,
	},
	'ARB': {
		decimals: 4,
		amountToOpen: 15,
	},
	'BCH': {
		decimals: 2,
		amountToOpen: 25,
	},
	'BNB': {
		decimals: 2,
		amountToOpen: 25,
	},
	'BTC': {
		decimals: 2,
		amountToOpen: 125,
	},
	'CRV': {
		decimals: 4,
		amountToOpen: 15,
	},
	'DYDX': {
		decimals: 3,
		amountToOpen: 15,
	},
	'DOGE': {
		decimals: 5,
		amountToOpen: 25,
	},
	'ETH': {
		decimals: 2,
		amountToOpen: 100,
	},
	'FTM': {
		decimals: 4,
		amountToOpen: 15,
	},
	'GRT': {
		decimals: 5,
		amountToOpen: 15,
	},
	'INJ': {
		decimals: 3,
		amountToOpen: 15,
	},
	'LDO': {
		decimals: 4,
		amountToOpen: 15,
	},
	'LTC': {
		decimals: 2,
		amountToOpen: 25,
	},
	'MATIC': {
		decimals: 4,
		amountToOpen: 50,
	},
	'OP': {
		decimals: 4,
		amountToOpen: 15,
	},
	'RNDR': {
		decimals: 4,
		amountToOpen: 15,
	},
	'SOL': {
		decimals: 4,
		amountToOpen: 29,
	},
	'STX': {
		decimals: 4,
		amountToOpen: 25,
	},
	'TRB': {
		decimals: 3,
		amountToOpen: 7,
	},
	'XRP': {
		decimals: 4,
		amountToOpen: 25,
	},
};

export const POLLING_INTERVAL = 1 * 1000;
export const PENDING_ORDERS_POLLING = isDev ? 5 * 1000 : 3 * 1000;
export const ACCOUNT_BALANCE_POLLING = isDev ? 5 * 1000 : 3 * 1000;
export const PAST_ORDERS_POLLING = isDev ? 60 * 1000 : 60 * 1000;
