import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { useAuth } from 'util/auth';
import { useRouter } from 'util/router';
import 'components/NavbarCustom/NavbarCustom.scss';

function NavbarCustom(props) {
	const auth = useAuth();
	const router = useRouter();
	const { bg, variant, expand } = props;

	return (
	  <Navbar bg={bg} variant={variant} expand={expand} collapseOnSelect className="NavbarCustom">
	    <Container>
	      <LinkContainer to="/">
	        <Navbar.Brand>
	          <img
	            className="d-inline-block align-top logo"
	            src={props.logo}
	            alt="Logo"
	          />
	        </Navbar.Brand>
	      </LinkContainer>
	      <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
	      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
	        <Nav className="mr-1">
	        	{!auth.user && (
	        		<>
	        		  <LinkContainer to="/auth/signin">
	        		    <Nav.Link active={false}>Sign in</Nav.Link>
	        		  </LinkContainer>
	        		</>
	        	)}

	        	{auth.user ? (
	        		<>
	        			{/*<LinkContainer to="/dashboard">
	        			  <Nav.Link active={false}>Dashboard</Nav.Link>
	        			</LinkContainer>*/}
		        		<LinkContainer to="/positions">
		        		  <Nav.Link active={false}>Positions</Nav.Link>
		        		</LinkContainer>
		        		<LinkContainer to="/analyze">
		        		  <Nav.Link active={false}>Analyze</Nav.Link>
		        		</LinkContainer>
		        		<LinkContainer to="/bulk">
		        		  <Nav.Link active={false}>Bulk</Nav.Link>
		        		</LinkContainer>
								<LinkContainer to="/orders">
		        		  <Nav.Link active={false}>Orders</Nav.Link>
		        		</LinkContainer>
		        		<LinkContainer to="/firechart">
		        		  <Nav.Link active={false}>Firechart</Nav.Link>
		        		</LinkContainer>
		        		<LinkContainer to="/funding">
		        		  <Nav.Link active={false}>Funding</Nav.Link>
		        		</LinkContainer>
		        		<LinkContainer to="/stats">
		        		  <Nav.Link active={false}>Stats</Nav.Link>
		        		</LinkContainer>
			          <LinkContainer to="/auth/signout">
			          	<Nav.Link
			          		active={false}
			          		onClick={(e) => {
			          		  e.preventDefault();
			          		  auth.signout();
			          		  router.push('/auth/signin');
			          		}}
			          	>
			          		Sign out
			          	</Nav.Link>
			          </LinkContainer>
		          </>
	        	) : (
	        		<LinkContainer to="/funding">
	        		  <Nav.Link active={false}>Funding</Nav.Link>
	        		</LinkContainer>
	        	)}
	        </Nav>
	      </Navbar.Collapse>
	    </Container>
	  </Navbar>
	);
};

export default NavbarCustom;
