import { createContext } from 'react';

export const PositionContext = createContext(null);

export const PERIOD_TO_MINS_MAP = {
	'1m': 1,
	'5m': 5,
	'15m': 15,
	'30m': 30,
	'1h': 60,
	'4h': 240,
	'6h': 360,
	'12h': 720,
	'1d': 1440,
};

export const CLOSE_ORDERS_TABLE_HEADERS = [
	'TYPE',
	'PRICE',
	'FILLED/TOTAL',
	'% OF POS',
	// 'FEE',
	'PROFIT',
	'ACTIONS',
];

export const OPEN_ORDERS_TABLE_HEADERS = [
	'TYPE',
	'PRICE',
	'FILLED/TOTAL',
	'% OF POS',
	'NEW AVG PRICE',
	// 'FEE',
	'ACTIONS',
];

export const ORDER_TYPE_COLOR = {
	'OP': 'text-default',
	'TP': 'text-success',
	'SL': 'text-danger',
};

export const ORDER_TYPE = {
	'LIMIT': 'LIMIT',
	'MARKET': 'MARKET',
	'TRIGGER': 'TRIGGER_MARKET',
};

export const ORDER_SIDE = {
	'SHORT': 'SHORT',
	'LONG': 'LONG',
};

export const ORDER_ACTION = {
	'OPEN': 'OPEN',
	'CLOSE': 'CLOSE',
};

export const QUICK_ORDER_NOTIONAL_VALUES = [250, 500, 750, 1500, 2000, 2500, 3000, 3500, 5000, 7500, 10000];
export const STOP_LOSS_OPS = [10, 15, 25, 35, 50, 75, 100, 150, 200, 500];

export const SL_GAPS_PER_SYMBOL = {
	'ARB': {
		'tightest': 0.001,
		'tight': 0.0015,
		'mid': 0.0025,
		'wide': 0.003,
	},
	'BNB': {
		'tightest': 0.15,
		'tight': 0.3,
		'mid': 0.5,
		'wide': 0.75,
	},
	'BTC': {
		'tightest': 20,
		'tight': 35,
		'mid': 50,
		'wide': 75,
	},
	'ETH': {
		'tightest': 1,
		'tight': 2.25,
		'mid': 3.5,
		'wide': 4.5,
	},
	'MATIC': {
		'tightest': 0.000125,
		'tight': 0.000225,
		'mid': 0.00035,
		'wide': 0.0005,
	},
	'SOL': {
		'tightest': 0.05,
		'tight': 0.1,
		'mid': 0.175,
		'wide': 0.2,
	},
	'STX': {
		'tightest': 0.0015,
		'tight': 0.00225,
		'mid': 0.003,
		'wide': 0.00425,
	},
	'TIA': {
		'tightest': 0.0075,
		'tight': 0.0125,
		'mid': 0.0175,
		'wide': 0.0225,
	},
	'TRB': {
		'tightest': 0.1,
		'tight': 0.15,
		'mid': 0.225,
		'wide': 0.3,
	},
};