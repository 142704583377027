import React, { useState, useEffect, useLayoutEffect, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import debounce from 'lodash/debounce';
import { createChart } from 'lightweight-charts';
import {
	useGetGlobalLongShortRatioQuery,
	useGetTopLongShortAccountRatioQuery,
} from 'api/client';
import { getLocalTs, DARK_THEME, LIGHT_THEME, BASE_OPTIONS } from "constants/lightweightCharts";
dayjs.extend(dayjsUtc);

const COLORS = {
	GLOBAL: '#ffffff', //white
	TOP_ACCOUNT: '#2dce89', //green
	TOP_POSITION: '#11cdef', //blue
};

function LongShortRatioChart(props, ref) {
	const selfRef = useRef();
	const [selfRefIsVisible, setSelfRefIsVisible] = useState(false);
	const chartRef = useRef();
	const globalRatioSeries = useRef();
	const topAccountRatioSeries = useRef();

	const {
		containerRef,
		symbol,
		period,
		height: chartHeight = 178,
		theme = 'dark',
	} = props;

	const {
		data: globalLongShortRatioData = [],
		refetch: globalLongShortRatioRefetch,
	} = useGetGlobalLongShortRatioQuery({symbol, period}, {
		pollingInterval: 1000 * 60,
	});

	const {
		data: topLongShortAccountRatioData = [],
		refetch: topLongShortAccountRatioRefetch,
	} = useGetTopLongShortAccountRatioQuery({symbol, period}, {
		pollingInterval: 1000 * 60,
	});

	// Wait for first render to init chart so ref.current isn't null
	useEffect(() => {
		const chart = createChart(selfRef.current, {
			width: containerRef?.current?.clientWidth || 400,
			height: chartHeight,
			...theme === 'light' ? LIGHT_THEME : DARK_THEME,
			...BASE_OPTIONS,
		});

		const lineSeriesOps = {
			color: '#ffffff',
			lineWidth: 1,
			crosshairMarkerVisible: false,
			priceLineVisible: false,
			lastValueVisible: false,
		};

		const _globalRatioSeries = chart.addLineSeries({
			...lineSeriesOps,
			color: COLORS.GLOBAL, //white
		});

		const _topAccountRatioSeries = chart.addLineSeries({
			...lineSeriesOps,
			color: COLORS.TOP_ACCOUNT, //green
		});

		setSelfRefIsVisible(true);
		chartRef.current = chart;
		globalRatioSeries.current = _globalRatioSeries;
		topAccountRatioSeries.current = _topAccountRatioSeries;
	// eslint-disable-next-line
	}, []);

	// Update ratio series
	useEffect(() => {
		const formatForLineSeries = item => ({
			value: Number(item.longShortRatio),
			time: getLocalTs(item.timestamp),
		});

		if (selfRefIsVisible) {
			globalLongShortRatioData.length > 0 && globalRatioSeries.current.setData(
				globalLongShortRatioData.map(formatForLineSeries)
			);

			topLongShortAccountRatioData.length > 0 && topAccountRatioSeries.current.setData(
				topLongShortAccountRatioData.map(formatForLineSeries)
			);
		}
	// eslint-disable-next-line
	}, [
		selfRefIsVisible,
		// eslint-disable-next-line
		JSON.stringify(globalLongShortRatioData),
		// eslint-disable-next-line
		JSON.stringify(topLongShortAccountRatioData),
	]);

	// Watch for window resizes to resize chart
	// Watch for div height drag-to-resize
	useLayoutEffect(() => {
		const selfRefClone = selfRef?.current; //refs are gone by cleanup time

		const debounceHandleOnResize = debounce(() => {
			const width = containerRef?.current?.clientWidth || 400;
			const height = selfRefClone?.clientHeight || 560;
			chartRef.current?.resize?.(width, height);
		}, 200);

		window.addEventListener('resize', debounceHandleOnResize);
		new ResizeObserver(debounceHandleOnResize).observe(selfRefClone);

		return () => window.removeEventListener('resize', debounceHandleOnResize);
	// eslint-disable-next-line
	}, []);

	useImperativeHandle(ref, () => ({
		refetch: () => {
			globalLongShortRatioRefetch();
			topLongShortAccountRatioRefetch();
		},
	}));

	return (
		<div
			ref={selfRef}
			style={{resize: 'vertical', overflow: 'auto'}}
		>
		</div>
	);
};

export default React.memo(forwardRef(LongShortRatioChart));
