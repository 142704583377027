export default function bullishHarami(data = [], color = 'green') {
	const marker = {
		position: "belowBar",
    color: color,
    shape: "arrowUp",
    text: "BullHarami"
	};

	for (let i = 1; i < data?.length; i++) {
		const current = data[i];
		const prev = data[i-1];

		const { open, close } = current || {};
		const { open: prevOpen, close: prevClose } = prev || {};

		const bullishHarami =
			prevOpen > prevClose &&
			open > close &&
			close <= prevOpen &&
			prevClose <= open &&
			close - open < prevOpen - prevClose;

		if (bullishHarami) {
			current.bullishHarami = true;
			current.color = color;
			current.borderColor = color;
			current.wickColor = color;
			current.marker = {
				...marker,
				time: current.time,
			}
		}
	}

	return data;
};
