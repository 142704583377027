import React, { useContext, useEffect, useState, useMemo, useCallback, useRef } from 'react';
import sum from 'lodash/sum';
import clsx from 'clsx';
import { color } from 'constants/tradingview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import ToastOnResult from 'components/ToastOnResult';
import Toggle from 'components/Toggle';
import {
	// Queries
	useGetAccountBalanceV2Query,
	// Mutations
	usePlaceOrderV2Mutation,
	usePlaceGappedStopLossOrdersV2Mutation,
} from 'api/client';
import useMarketDepthWs from 'api/websockets/bingxSwaps/useMarketDepthWs';
import { ACCOUNT_BALANCE_POLLING } from 'constants/bingxSwaps';
import { MUTATION_RESET_DELAY } from 'constants/mutations';
import { commaFormat, removeZerosAfterDecimal, currencyFormat, millifyCurrency } from 'util/numbers';
import { PositionContext, ORDER_TYPE, ORDER_ACTION, QUICK_ORDER_NOTIONAL_VALUES, SL_GAPS_PER_SYMBOL } from 'components/PositionSectionNew/constants';

const MODIFY_ORDERS_QUICK_PERCENTS = [1, 5, 10, 15, 20, 25, 40, 50, 60, 75, 80, 90, 100];

function NewOrder(props) {
	const { symbol, side, position, pricePrecision, closeOrders = [], tentativeOrder, dispatchContext, priceLines } = useContext(PositionContext);
	const symbolPure = symbol?.split?.('-')?.[0];
	const notionalValueInputRef = useRef();
	const [gappedStopLoss, setGappedStopLoss] = useState(null);

	/* QUERIES */
	/** Account info for avail margin **/
	const {
		data: accountBalance = {},
	} = useGetAccountBalanceV2Query(undefined, {
		pollingInterval: ACCOUNT_BALANCE_POLLING,
	});

	/* WEBSOCKETS */
  const [
  	latestMarketDepth = {},
  	setMarketDepthSubs,
  ] = useMarketDepthWs([symbol]);

  useEffect(
  	() => setMarketDepthSubs([symbol]),
  	// eslint-disable-next-line
  	[symbol]
  );

  /* MUTATIONS */
	/** Place order mutation **/
	const [placeOrder, placeOrderResult] = usePlaceOrderV2Mutation();
	useEffect(() => {
		placeOrderResult.isError && console.error('usePlaceOrderV2Mutation error', placeOrderResult.error);
		
		if (placeOrderResult.isSuccess) {
			setTimeout(() => {
				dispatchContext({ type: 'removeTentativeOrder' });
			}, MUTATION_RESET_DELAY);
		}
	}, [
		placeOrderResult,
		dispatchContext,
	]);
	
	/** Place gapped stop losses order mutation */
	const [placeGappedStopLossOrders, placeGappedStopLossOrdersResult] = usePlaceGappedStopLossOrdersV2Mutation();
	useEffect(() => {
		placeGappedStopLossOrdersResult.isError && console.error('usePlaceOrderV2Mutation error', placeGappedStopLossOrdersResult.error);
		
		if (placeGappedStopLossOrdersResult.isSuccess) {
			setTimeout(() => {
				dispatchContext({ type: 'removeTentativeOrder' });
				setGappedStopLoss(null);
			}, MUTATION_RESET_DELAY);
		}
	}, [
		placeGappedStopLossOrdersResult,
		dispatchContext,
	]);

  /* DATA */
  const {
		action, //OPEN|CLOSE
		type, //LIMIT|MARKET|TRIGGER_LIMIT
		price = 0,
		origQty = 0,
	} = tentativeOrder || {};
  const { positionAmt, avgPrice, leverage } = position;
  const { availableMargin = 0 } = accountBalance;
  const priceSide = side === 'short' ? 'askPrice' : 'bidPrice';
  const currentPrice = latestMarketDepth?.[symbol]?.[priceSide] || 0;

	const totalStopLossOrdersAmt = sum(
		closeOrders.filter(
			po => po.actionShort === 'SL'
		).map(
			o => o.isTentativeOrder ? 0 : (o.origQty || 0)
		)
	);
	const stopLossPercAvailable = (100 - (totalStopLossOrdersAmt / positionAmt * 100)).toFixed(2);

	const totalTakeProfitOrdersAmt = sum(
		closeOrders.filter(
			po => po.actionShort === 'TP'
		).map(
			o => o.isTentativeOrder ? 0 : (o.origQty || 0) - (o.executedQty || 0)
		)
	);
	const takeProfitPercAvailable = (100 - (totalTakeProfitOrdersAmt / positionAmt * 100)).toFixed(2);

	// const amtAvailableToClose = Number(
	// 	positionAmt - (action === 'CLOSE' ? totalCloseOrdersAmt : 0)
	// );
	const amtAvailableToOpen = (availableMargin * leverage) / (type === 'MARKET' ? currentPrice : price);
	const notionalValue = Number((origQty * (type === 'MARKET' ? currentPrice : price)).toFixed(2));

	const isOrderInvalid = useMemo(
		() => {
			if (gappedStopLoss) return false;
			if (origQty <= 0) return 'Quantity must be > 0';
			if (type === 'MARKET') return false;

			if (side === 'short') {
				if (action === 'CLOSE') {
					if (type === 'LIMIT') {
						return currentPrice >= price ? false : 'Price must be < current price';
					} else {
						return false;
					}
				} else if (action === 'OPEN') {
					if (type === 'LIMIT') {
						return currentPrice <= price ? false : 'Price must be > current price';
					} else {
						return false;
					}
				}
			} else if (side === 'long') {
				if (action === 'CLOSE') {
					if (type === 'LIMIT') {
						return currentPrice <= price ? false : 'Price must be > current price';
					} else {
						return false;
					}
				} else if (action === 'OPEN') {
					if (type === 'LIMIT') {
						return currentPrice >= price ? false : 'Price must be < current price';
					} else {
						return false;
					}
				}
			}
		}, [
			side,
			action,
			type,
			price,
			origQty,
			currentPrice,
			gappedStopLoss,
		]
	);

	const placeOrderIsDisabled = placeOrderResult.isLoading || placeOrderResult.isSuccess || isOrderInvalid;
	const isStopMarket = type === 'MARKET' && action === 'CLOSE' && (side === 'short' ? price >= currentPrice : price <= currentPrice);
	const isTakeProfit = action === 'CLOSE' && (side === 'short' ? price <= avgPrice : price >= avgPrice);

	const totalGappedStopLossLoss = useMemo(() => {
		if (!gappedStopLoss) return 0;
		const losses = generateGappedStopLosses({
			qty: gappedStopLoss.qty,
			side,
			gap: SL_GAPS_PER_SYMBOL[symbolPure]?.[gappedStopLoss.gapType] || 1,
			price: (tentativeOrder?.price || currentPrice),
			avgPrice,
			totalOrderQty: tentativeOrder?.origQty || positionAmt,
		});
		return sum(losses.map(l => l.loss));
	}, [
		gappedStopLoss,
		side,
		symbolPure,
		tentativeOrder,
		currentPrice,
		avgPrice,
		positionAmt,
	]);


	/* CALLBACKS */
	const onPriceChange = useCallback((e) => {
		const newPrice = Number(e.target.value);

		const action = side === 'short'
			? newPrice <= avgPrice ? 'CLOSE' : 'OPEN'
			: newPrice >= avgPrice ? 'CLOSE' : 'OPEN';

		dispatchContext({
			type: 'updateTentativeOrder',
			payload: {
				...tentativeOrder,
				price: Number(e.target.value) || 0,
				action: action,
	  		actionShort: action.substring(0,2) === 'CL' ? 'TP' : action.substring(0,2),
			}
		});
	}, [
		side,
		avgPrice,
		dispatchContext,
		tentativeOrder,
	]);

	const onQuantityChange = useCallback((newQty = 0) => {
		dispatchContext({
			type: 'updateTentativeOrder',
			payload: {
				...tentativeOrder,
				origQty: newQty || 0,
			}
		});
	}, [
		dispatchContext,
		tentativeOrder,
	]);

	const handlePlaceOrder = useCallback(
		() => {
			const order = {
				symbol,
				type: isStopMarket ? 'STOP_MARKET' : type,
				side: side === 'short'
					? action === 'OPEN' ? 'SELL' : 'BUY'
					: action === 'OPEN' ? 'BUY' : 'SELL',
				positionSide: side.toUpperCase(),
				quantity: origQty,
				...type === 'LIMIT' ? {
					price,
				} : {},
				...isStopMarket ? {
					stopPrice: price,
					stopGuaranteed: false,
				} : {},
				// ...isStopMarket && action === 'CLOSE' && (side === 'short' ? price > avgPrice : price < avgPrice) ? {
				// 	workingType: 'CONTRACT_PRICE',
				// 	stopGuaranteed: true,
				// } : {},
			};

			return placeOrder({ order });
		}, [
			symbol,
			action,
			type,
			side,
			price,
			origQty,
			isStopMarket,
			placeOrder,
			// avgPrice,
		]
	);

	const handlePlaceGappedStopLossOrder = useCallback(
		() => {
			const orders = generateGappedStopLosses({
				qty: gappedStopLoss.qty,
				side,
				gap: SL_GAPS_PER_SYMBOL[symbolPure]?.[gappedStopLoss.gapType] || 1,
				price: (tentativeOrder?.price || currentPrice),
				avgPrice,
				totalOrderQty: tentativeOrder?.origQty || positionAmt,
			}).map((sl, i) => ({
				symbol,
				type: 'STOP_MARKET',
				side: side === 'short' ? 'BUY' : 'SELL',
				positionSide: side.toUpperCase(),
				quantity: tentativeOrder.origQty * sl.perc / 100,
				stopPrice: sl.price,
				workingType: 'CONTRACT_PRICE',
				// stopGuaranteed: side === 'short' ? String(sl.price > avgPrice) : String(sl.price < avgPrice),
				stopGuaranteed: 'false',
			}));

			return placeGappedStopLossOrders({ orders, symbol, side: side, clearOtherStopLosses: gappedStopLoss.clearOtherStopLosses });
		}
	, [
		gappedStopLoss,
		side,
		symbol,
		tentativeOrder,
		currentPrice,
		symbolPure,
		avgPrice,
		placeGappedStopLossOrders,
		positionAmt,
	]);

	/* UI */
	useEffect(() => {
		notionalValueInputRef.current.focus();
		notionalValueInputRef.current.select();
	}, []);

	// Show gapped stop loss lines
	const memoizedPriceLines = useMemo(() => JSON.stringify(priceLines), [priceLines]);
	useEffect(() => {
		const existingPriceLines = JSON.parse(memoizedPriceLines);
		if (!gappedStopLoss) {
			dispatchContext({ 
				type: 'updatePriceLines', 
				payload: existingPriceLines.filter(line => !/gappedStopLoss/.test(line.id)),
			});
		} else {
			const newPriceLines = generateGappedStopLosses({
				qty: gappedStopLoss.qty,
				side,
				gap: SL_GAPS_PER_SYMBOL[symbolPure]?.[gappedStopLoss.gapType] || 1,
				price: (tentativeOrder?.price || currentPrice),
				avgPrice,
				totalOrderQty: tentativeOrder?.origQty || positionAmt,
			}).map((sl, i) => ({
				id: `gappedStopLoss-${i}`,
				title: `SL${i + 1} ${removeZerosAfterDecimal(sl.perc.toFixed(2))}% ${millifyCurrency(sl.loss, false, { precision: 2 })}`,
				price: sl.price,
				color: color.yellow,
			}));

			const allPriceLines = [
				...existingPriceLines.filter(line => !/gappedStopLoss/.test(line.id)),
				...newPriceLines,
			];

			dispatchContext({ 
				type: 'updatePriceLines', 
				payload: allPriceLines,
			});
		}
	}, [
		side,
		gappedStopLoss,
		memoizedPriceLines,
		tentativeOrder,
		currentPrice,
		symbolPure,
		dispatchContext,
		avgPrice,
		positionAmt,
	]);

	return (
		<>
			<Card
				className={clsx('NewOrder shadow', {
					'd-none': !tentativeOrder,
				})}
			>
				<Card.Header className="p-2">
					{/* Open/Close */}
					<Toggle
						ops={Object.keys(ORDER_ACTION)}
						active={action}
						setActive={op => {
							setGappedStopLoss(null);
							dispatchContext({
								type: 'updateTentativeOrder',
								payload: {
									...tentativeOrder,
									action: op,
								}
							})
						}}
						activeClassName={clsx({
							'text-danger': action === 'CLOSE',
							'text-success': action === 'OPEN',
						})}
					/>
					{/* Order type */}
					<Toggle
						ops={Object.keys(ORDER_TYPE)}
						active={type}
						setActive={op => {
							setGappedStopLoss(null);
							dispatchContext({
								type: 'updateTentativeOrder',
								payload: {
									...tentativeOrder,
									type: op,
								}
							})
						}}
						opClassName="f-rem-0.85 fw5"
						className="mt-1.5"
					/>
				</Card.Header>
				<Card.Body className="pt-2 pb-3 px-3">
					{/*Price input*/}
					{['LIMIT', 'TRIGGER'].includes(type) && (
						<Form.Group id="price">
							<Form.Label>{type === 'LIMIT' ? 'Order' : 'Trigger'} price</Form.Label>
							<Form.Control
								aria-label="Order price"
								type="number"
								step={Number(Math.pow(10, pricePrecision * -1).toFixed(pricePrecision))}
								min="0"
								value={price || 0}
								onChange={onPriceChange}
								onKeyPress={(e) => {
									if (
										e.charCode === 13 &&
										!placeOrderIsDisabled
									) {
										handlePlaceOrder();
									}
								}}
							/>
						</Form.Group>
					)}

					{action === 'CLOSE' && type === 'MARKET' && (
						<>
							<Form.Group id="gapped-stop-loss" className="mb-2">
								<Form.Check
									custom
									type="checkbox"
									id="gap-stop-loss"
									label="Gapped Stop Loss"
									checked={gappedStopLoss}
									onChange={() => setGappedStopLoss(prev => !prev ? {
										gapType: 'tightest',
										qty: 5,
										clearOtherStopLosses: false,
									} : null)}
									className="mt-2 f-rem-0.85"
									style={{lineHeight: '1.8'}}
								/>
							</Form.Group>
							{gappedStopLoss && (
								<>
									<Flex style={{gap: '0.75rem'}}>
										<Toggle
											ops={Object.keys(SL_GAPS_PER_SYMBOL[symbolPure] || SL_GAPS_PER_SYMBOL['BTC'])}
											active={gappedStopLoss.gapType}
											setActive={op => setGappedStopLoss(prev => ({ ...prev, gapType: op }))}
											opClassName="f-rem-0.85 fw5"
											className="w-fit mb-1"
										/>
										<Flex>
											<span>${SL_GAPS_PER_SYMBOL[symbolPure]?.[gappedStopLoss.gapType] || 1}</span>
											<SlashDivider />
											<span
												className={clsx({
													'text-success': totalGappedStopLossLoss >= 0,
													'text-danger': totalGappedStopLossLoss < 0,
												})}
											>
												{currencyFormat(totalGappedStopLossLoss)}
											</span>
										</Flex>
									</Flex>
									<Form.Group id="gapped-stop-loss-qty" className="mb-2">
										<Form.Label>Number of Stop Losses</Form.Label>
										<Form.Control
											aria-label="Number of Stop Losses"
											type="number"
											step="1"
											min="1"
											value={gappedStopLoss.qty}
											onChange={(e) => setGappedStopLoss(prev => ({ ...prev, qty: Number(e.target.value) }))}
										/>
									</Form.Group>
									<Form.Group id="gapped-stop-loss-clear-sls" className="mb-2">
										<Form.Check
											custom
											type="checkbox"
											id="gap-stop-loss-clear-other-sls"
											label="Clear Other Stop Losses?"
											checked={gappedStopLoss?.clearOtherStopLosses}
											onChange={() => setGappedStopLoss(prev => ({ ...prev, clearOtherStopLosses: !prev.clearOtherStopLosses }))}
											className="mt-2 f-rem-0.85"
											style={{lineHeight: '1.8'}}
										/>
									</Form.Group>
								</>
							)}
						</>
					)}

					<Form.Group id="qtyInput">
						<Flex
							direction="column"
							align="start"
							className="mb-1.5"
						>
							{action === 'CLOSE' ? (
								<>
									<Flex justify="between" className="mb-0.5 w-100">
										<Form.Label className="mb-0">Percentage</Form.Label>
										{isStopMarket ? (
											<Form.Label className="mb-0">{stopLossPercAvailable}% left</Form.Label>
										) : isTakeProfit ? (
											<Form.Label className="mb-0">{takeProfitPercAvailable}% left</Form.Label>
										) : null}
									</Flex>
									<Flex className="w-100">
										<Toggle
											ops={MODIFY_ORDERS_QUICK_PERCENTS}
											opFormatDisplay={op => `${op}%`}
											active={Math.round(
												origQty / positionAmt * 100
											)}
											setActive={op => {
												const qty = (op / 100) * positionAmt;
												return onQuantityChange(
													Number(qty.toFixed(6))
												);
											}}
											onOpDoubleClick={(op) => {
												if (!placeOrderIsDisabled) {
													return handlePlaceOrder();
												}
											}}
											opClassName="f-rem-0.9 fw5"
											className="scroll-x w-100"
										/>
										{isStopMarket && (
											<Toggle 
												ops={['MAX-1', 'MAX']}
												active={origQty === positionAmt ? 'MAX' : origQty / positionAmt * 100 === stopLossPercAvailable ? 'MAX-1' : false}
												setActive={(op) => {
													const qty = op === 'MAX' ? positionAmt : positionAmt * ((Math.round(stopLossPercAvailable) - 1) / 100);
													return onQuantityChange(
														Number(qty.toFixed(6))
													);
												}}
												opClassName="f-rem-0.9 fw5"
												className="scroll-x ml-1"
											/>
										)}
									</Flex>
								</>
							) : (
								<>
									<Form.Label className="pr-2 mb-0">Value</Form.Label>
									<Toggle
										ops={QUICK_ORDER_NOTIONAL_VALUES}
										opFormatDisplay={op => millifyCurrency(op, false, { precision: 2 })}
										active={notionalValue}
										setActive={op => {
											const qty = op / (type === 'MARKET' ? currentPrice : price);
											return onQuantityChange(
												Number(qty.toFixed(6))
											);
										}}
										onOpDoubleClick={(op) => {
											if (!placeOrderIsDisabled) {
												return handlePlaceOrder();
											}
										}}
										opClassName="f-rem-0.9 fw5"
										className="scroll-x w-100"
									/>
							</>
						)}
						</Flex>
						<Form.Control
							ref={notionalValueInputRef}
							aria-label="Order price"
							type="number"
							step="50"
							min="0"
							max={availableMargin}
							value={notionalValue || 0}
							onChange={e => {
								const value = Number(e.target.value);
								const qty = value / (type === 'MARKET' ? currentPrice : price);
								return onQuantityChange(
									Number(qty.toFixed(6))
								);
							}}
							onKeyPress={(e) => {
								if (
									e.charCode === 13 &&
									!placeOrderIsDisabled
								) {
									return handlePlaceOrder();
								}
							}}
						/>
					</Form.Group>

					<QuantitySlider
						action={action}
						symbolPure={symbolPure}
						origQty={origQty}
						amtAvailable={action === 'OPEN' ? amtAvailableToOpen : positionAmt}
						onQuantityChange={onQuantityChange}
					/>

					{/* Place order */}
					<Button
						size="sm"
						variant={clsx({
							'success': placeOrderResult.isSuccess || placeGappedStopLossOrdersResult.isSuccess,
							'danger': placeOrderResult.isError || placeGappedStopLossOrdersResult.isError,
							'primary': (!placeOrderResult.isSuccess && !placeOrderResult.isError) || (!placeGappedStopLossOrdersResult.isSuccess && !placeGappedStopLossOrdersResult.isError),
						})}
						block
						disabled={placeOrderIsDisabled}
						onClick={gappedStopLoss ? handlePlaceGappedStopLossOrder : handlePlaceOrder}
					>
						{(placeOrderResult.isLoading || placeGappedStopLossOrdersResult.isLoading) ? (
							<FontAwesomeIcon icon={faSpinnerThird} spin={true} />
						) : (placeOrderResult.isSuccess || placeGappedStopLossOrdersResult.isSuccess) ? (
							<FontAwesomeIcon icon={faCheck} />
						) : (placeOrderResult.isError || placeGappedStopLossOrdersResult.isError) ? (
							<FontAwesomeIcon icon={faTimes} />
						) : 'Place Order'}
					</Button>
					{/* Cancel */}
					<Button
						size="sm"
						variant="outline-danger"
						block
						className="mt-2"
						disabled={placeOrderResult.isLoading || placeOrderResult.isSuccess || placeGappedStopLossOrdersResult.isLoading || placeGappedStopLossOrdersResult.isSuccess}
						onClick={() => {
							dispatchContext({ type: 'removeTentativeOrder' })
							setGappedStopLoss(null);
						}}
					>
						Cancel
					</Button>

					{isOrderInvalid && (
						<span className="d-block text-danger mt-2.5 f-rem-0.85 fw5">{isOrderInvalid}</span>
					)}
				</Card.Body>
			</Card>

			<ToastOnResult
				success={(placeOrderResult.isSuccess || placeGappedStopLossOrdersResult.isSuccess) ? 'Orders placed' : null}
				error={placeOrderResult.error || placeGappedStopLossOrdersResult.error}
				resetSuccess={gappedStopLoss ? placeGappedStopLossOrdersResult.reset : placeOrderResult.reset}
				resetError={gappedStopLoss ? placeGappedStopLossOrdersResult.reset : placeOrderResult.reset}
				delay={MUTATION_RESET_DELAY - 1}
			/>
		</>
	);
};

export default NewOrder;


const QuantitySlider = (props) => {
	const {
		action,
		symbolPure,
		origQty,
		amtAvailable,
		onQuantityChange,
	} = props;

	return (
		<Form.Group id="qty" className="mb-1.5">
			<Flex justify="between">
				<Form.Label className="text-gray-600">
					<span className="text-gray-700">Qty: {commaFormat(origQty)}</span>
					{action === 'CLOSE' && (
						<>
							<SlashDivider />
							<span>{commaFormat(amtAvailable)}</span>
						</>
					)}
					<span className="ml-1">{symbolPure}</span>
				</Form.Label>
				<Form.Label>{Math.round((origQty || 0) / amtAvailable * 100)}%</Form.Label>
			</Flex>

			{/*Qty range slider*/}
			<Form.Control
				type="range"
				custom={true}
				value={Math.round((origQty || 0) / amtAvailable * 100) || 0}
				onChange={e => {
					const percent = Math.round(Number(e.target.value)) / 100;
					const newQty = amtAvailable * percent;
					return onQuantityChange(
						Number(newQty.toFixed(6))
					);
				}}
				onDoubleClick={e => {
					const percent = Math.round(Number(e.target.value)/5)*5 / 100; //round to nearest 5
					const newQty = amtAvailable * percent;
					return onQuantityChange(
						Number(newQty.toFixed(6))
					);
				}}
			/>
		</Form.Group>
	);
};

function generateGappedStopLosses({
	qty,
	side,
	gap,
	price,
	avgPrice,
	totalOrderQty,
}) {
	const losses = Array.from({ length: qty }, (_, i) => {
		const gapPrice = price + ((side === 'short' ? gap : -gap) * i);
		const perc = Math.round(100 / qty) - (i === qty - 1 ? 1 : 0);
		const isInProfit = (side === 'short' && avgPrice >= gapPrice) ||
			(side === 'long' && gapPrice >= avgPrice) ? 1 : -1;
		const loss = Math.abs(
			(avgPrice * (totalOrderQty * perc / 100)) - (gapPrice * (totalOrderQty * perc / 100))
		) * isInProfit;

		return {
			perc,
			loss,
			price: gapPrice,
		};
	});

	return losses;
};