import React, { useReducer } from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AnalyzeRow from 'components/Analyze/AnalyzeRow';
import ConfigCard from 'components/Analyze/ConfigCard';
import { configReducer, configDefault } from './reducers';
import './styles.scss';

function Analyze(props) {
	const [
		{
			symbol = 'BTC',
			timeIntervals = [],
			widgets = [],
		},
		dispatchConfig
	] = useReducer(configReducer, configDefault);

	return (
		<div className="Analyze">
			<Row className="justify-content-center">
				<Col xs={12} lg={4} xl={3}>
					<ConfigCard
						symbol={symbol}
						timeIntervals={timeIntervals}
						widgets={widgets}
						dispatchConfig={dispatchConfig}
					/>
				</Col>
				<Col xs={12} lg={8} xl={9} className="AnalyzeRows mt-4 mt-lg-0">
					{timeIntervals.map(
						interval => (
							<AnalyzeRow
								key={interval}
								symbol={symbol}
								interval={interval}
								widgets={widgets}
							/>
						)
					)}
				</Col>
			</Row>
		</div>
	);
};

export default Analyze;
