import max from 'lodash/max';
import min from 'lodash/min';

export default function dragonflyDoji(data = [], color = 'green') {
	const marker = {
		position: "belowBar",
    color: color,
    shape: "arrowUp",
    text: "DragonDoji"
	};

	for (let i = 1; i < data?.length; i++) {
		const current = data[i];
		const prev = data[i-1];

		const { open, high, low, close } = current || {};
		const { open: prevOpen, close: prevClose } = prev || {};

		const dragonflyDoji =
			prevOpen > prevClose &&
			Math.abs(close - open) / (high - low) < 0.1 &&
			(min([close, open]) - low) > 3 * Math.abs(close - open) &&
			high - max([close, open]) < Math.abs(close - open);

		if (dragonflyDoji) {
			current.dragonflyDoji = true;
			current.color = color;
			current.borderColor = color;
			current.wickColor = color;
			current.marker = {
				...marker,
				time: current.time,
			}
		}
	}

	return data;
};
