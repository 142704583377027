import React, { useCallback } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Flex from 'components/Flex';
import 'components/Toggle/Toggle.scss';

function Toggle(props) {
	const {
		active,
		setActive,
		ops = [],
		opFormatDisplay, //function
		isOpActive,
		loading,
		isOpLoading,
		className = "",
		label,
		labelClassName = "",
		opClassName = "",
		activeClassName = "",
		onOpClick: _onOpClick,
		onOpDoubleClick: _onOpDoubleClick,
		disabled,
		isOpDisabled,
		style = {},
		opStyle = {},
		...toggleProps
	} = props;

	const onOpClick = useCallback(
		(op) => typeof _onOpClick === 'function' ? _onOpClick(op) : null,
		[_onOpClick]
	);

	const onOpDoubleClick = useCallback(
		(op) => typeof _onOpDoubleClick === 'function' ? _onOpDoubleClick(op) : null,
		[_onOpDoubleClick]
	);

	return (
		<>
			{label && (
				<span className={`ToggleLabel ${labelClassName}`.trim()}>{label}</span>
			)}
			<Flex
				className={`Toggle ${className}`.trim()}
				style={style}
				{...toggleProps}
			>
				{ops.map((op, idx) => (
					<Option
						key={idx}
						idx={idx}
						loading={loading}
						active={active}
						setActive={setActive}
						activeClassName={activeClassName}
						op={op}
						opFormatDisplay={opFormatDisplay}
						opClassName={opClassName}
						isOpActive={isOpActive}
						isOpLoading={isOpLoading}
						onOpClick={onOpClick}
						onOpDoubleClick={onOpDoubleClick}
						disabled={disabled}
						isOpDisabled={isOpDisabled}
						style={opStyle}
					/>
				))}
				{props.children}
			</Flex>
		</>
	);
};

export default Toggle;

const Option = ({
	loading,
	active,
	setActive,
	activeClassName,
	op,
	opFormatDisplay,
	opClassName,
	isOpActive,
	isOpLoading,
	isOpDisabled,
	disabled,
	onOpClick,
	onOpDoubleClick,
	style,
}) => {
	function testState(checker, generalChecker) {
		return typeof checker === 'function' ? checker(generalChecker, op)
			: Array.isArray(generalChecker) ? generalChecker.includes(op)
				: typeof generalChecker === 'function' ? generalChecker(op)
					: generalChecker === op;
	};

	const isActive = testState(isOpActive, active);
	const isLoading = testState(isOpLoading, loading);
	const isDisabled = testState(isOpDisabled, disabled);

	return (
		<div
			className={clsx('Option', {
				'active': isActive,
				...opClassName ? {
					[opClassName]: true,
				} : {},
				...activeClassName ? {
					[activeClassName]: isActive
				} : {},
				disabled: isDisabled,
				loading: isLoading,
			})}
			onClick={() => {
				if (!disabled) {
					typeof setActive === 'function' && setActive(op);
					onOpClick(op);
				}
			}}
			onDoubleClick={() => {
				if (!disabled) {
					onOpDoubleClick(op);
				}
			}}
		>
			<span>{typeof opFormatDisplay === 'function' ? opFormatDisplay(op) : op}</span>
			{isLoading && (
				<FontAwesomeIcon icon={faSpinnerThird} spin={true} className="Loader" />
			)}
		</div>
	);
};
