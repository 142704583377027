import React from "react";
import "./../styles/global.scss";
import { Switch, Route, Router, Redirect } from "./../util/router";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import NavbarCustom from "./../components/NavbarCustom";
import Footer from "./../components/Footer";
import AuthPage from "./auth";
import AnalyzePage from "./analyze";
import BulkPage from "./bulk";
import FirechartPage from "./firechart";
import FundingPage from "./funding";
import OrdersPage from "./orders";
import PositionsPage from "./positions";
import PositionPage from "./position";
import PositionPageNew from "./positionNew";
import StatsPage from "./stats";
import SettingsPage from "./settings";
import NotFoundPage from "./404";

const logo = "/img/logo-navbar.svg";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <NavbarCustom
              bg="white"
              variant="light"
              expand="md"
              logo={logo}
            />

            <Switch>
              <Route exact path="/settings">
                <Redirect to="/settings/general" />
              </Route>
              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/">
                <Redirect to="/auth/signin" />
              </Route>
              <Route exact path="/auth/:type" component={AuthPage} />
              <Route exact path="/positions" component={PositionsPage} />
              <Route exact path="/positions/:symbol" component={PositionPage} />
              <Route exact path="/positions/new/:symbol" component={PositionPageNew} />
              <Route exact path="/analyze" component={AnalyzePage} />
              <Route exact path="/bulk" component={BulkPage} />
              <Route exact path="/orders" component={OrdersPage} />
              <Route exact path="/firechart" component={FirechartPage} />
              <Route exact path="/funding" component={FundingPage} />
              <Route exact path="/stats" component={StatsPage} />
              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bg="light"
              textColor="dark"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              copyright={`© ${new Date().getFullYear()} BingX`}
              logo={logo}
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
