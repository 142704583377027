import React, { useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Flex from "components/Flex";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Toggle from "components/Toggle";
import OrderbookChart from "components/OrderbookChart";
import Firechart from "components/Firechart";
import './FirechartSection.scss';

const SYMBOLS = ["BTC", "ETH"];
const MARKET_TYPES = ['spot', 'futures'];

function FirechartSection(props) {
	const [activeSymbol, setActiveSymbol] = useState(SYMBOLS[0]);
	const [marketType, setMarketType] = useState(MARKET_TYPES[1]);

	// UI
	return (
		<>
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			  className="FirechartSection"
			>
			  <Container fluid className="px-lg-4 px-xl-5">
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>
			  	<Row className="justify-content-center">
			  		<Col xs={12} lg={4} xl={3}>
				  		<Row className="justify-content-center">
					  		<Col xs={12}>
					  			<SymbolsList
					  				activeSymbol={activeSymbol}
					  				setActiveSymbol={setActiveSymbol}
					  				marketType={marketType}
					  				setMarketType={setMarketType}
					  			/>
					  		</Col>
					  		<Col xs={12} className="mt-4">
					  			<OrderbookChart
					  				symbol={activeSymbol}
					  				marketType={marketType}
					  			/>
					  		</Col>
					  	</Row>
				  	</Col>
			  		<Col xs={12} lg={8} xl={9} className="mt-4 mt-lg-0">
			  			<Firechart
			  				symbol={activeSymbol}
			  				marketType={marketType}
			  			/>
			  		</Col>
			  	</Row>
				</Container>
			</Section>
		</>
	);
};

export default FirechartSection;


const SymbolsList = ({activeSymbol, setActiveSymbol, marketType, setMarketType}) => {
	return (
		<Card className="SymbolsList shadow">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span>Symbols</span>
					<Toggle
						ops={MARKET_TYPES}
						active={marketType}
						setActive={setMarketType}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="px-2 py-2">
				{SYMBOLS.map(symbol => (
					<Flex
						key={symbol}
						justify="between"
						align="center"
						className={clsx('item px-2 py-1', {
							'active': activeSymbol === symbol
						})}
						onClick={() => setActiveSymbol(symbol)}
					>
						<span className="pl-1">{symbol}</span>
						{activeSymbol === symbol && (
							<FontAwesomeIcon icon={faCheck} className="pr-1 text-primary" />
						)}
					</Flex>
				))}
			</Card.Body>
		</Card>
	);
};


