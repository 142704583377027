import React, { useState, useContext, useEffect, useMemo, useCallback, useRef } from 'react';
import clsx from 'clsx';
import ShortUniqueId from 'short-unique-id';
import { color } from 'constants/tradingview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Flex from 'components/Flex';
import ToastOnResult from 'components/ToastOnResult';
import Toggle from 'components/Toggle';
import SlashDivider from 'components/SlashDivider';
import {
	// Queries
	useGetAccountBalanceV2Query,
	// Mutations
	usePlaceOrderV2Mutation,
} from 'api/client';
import useMarketDepthWs from 'api/websockets/bingxSwaps/useMarketDepthWs';
import { ACCOUNT_BALANCE_POLLING } from 'constants/bingxSwaps';
import { MUTATION_RESET_DELAY } from 'constants/mutations';
import { currencyFormat, millifyCurrency } from 'util/numbers';
import { calculateStopLoss } from 'util/trades';
import { STOP_LOSS_OPS } from 'components/PositionSectionNew/constants';
import { PositionContext, QUICK_ORDER_NOTIONAL_VALUES } from 'components/PositionSectionNew/constants';

const TYPE_OPS = ['MARKET', 'BBO'];
const uid = new ShortUniqueId({ length: 10 });

function QuickOpen(props) {
	const { side, symbol, position, priceLines = [], dispatchContext } = useContext(PositionContext);
	const [orderType, setOrderType] = useState(TYPE_OPS[0]);
	const [notionalOrderValue, setNotionalOrderValue] = useState(null);
	const [orderValueLoading, setOrderValueLoading] = useState(null);
	const [stopLossValue, setStopLossValue] = useState(null);

	/* QUERIES */
	/** Account info for avail margin **/
	const {
		data: accountBalance = {},
	} = useGetAccountBalanceV2Query(undefined, {
		pollingInterval: ACCOUNT_BALANCE_POLLING,
	});

	/* WEBSOCKETS */
	const [
  	latestPrices = {},
  	setMarketDepthSubs,
  ] = useMarketDepthWs([symbol]);
  useEffect(
  	() => setMarketDepthSubs([symbol]),
  	// eslint-disable-next-line
  	[symbol]
  );

  /* MUTATIONS */
	/** Place order mutation **/
	const [placeOrder, placeOrderResult] = usePlaceOrderV2Mutation();

	useEffect(() => {
		placeOrderResult.isError && console.error('usePlaceOrderV2Mutation error', placeOrderResult.error);

		if (
			placeOrderResult.isError ||
			placeOrderResult.isSuccess
		) {
			setOrderValueLoading(null);
		}
	}, [
		placeOrderResult,
		orderType,
	]);

	/* DATA */
  const { leverage, avgPrice } = position;
  const { availableMargin = 0 } = accountBalance;
  const valueAvailableToOpen = availableMargin * leverage;
  const priceSide = side === 'short' ? 'askPrice' : 'bidPrice';
  const currentPrice = latestPrices?.[symbol]?.[priceSide] || 0;
	const stopLossPrice = stopLossValue && notionalOrderValue ? calculateStopLoss(currentPrice, notionalOrderValue, stopLossValue, side === 'short') : 0;
	const stopLossPricePercFromCurPrice = ((currentPrice - stopLossPrice) / currentPrice) * 100;

	const placeOrderIsDisabled = placeOrderResult?.isLoading || placeOrderResult?.isSuccess || placeOrderResult?.isError;

	// Effects
	const idForPriceLine = useRef(uid.rnd());
	const memoizedPriceLines = useMemo(() => JSON.stringify(priceLines), [priceLines]);
	useEffect(() => {
		dispatchContext({ type: 'updatePriceLines', payload: [
			...JSON.parse(memoizedPriceLines).filter(line => line.id !== idForPriceLine.current),
			...stopLossPrice && stopLossPrice > 0 ? [{
				id: idForPriceLine.current,
				title: `SL -${currencyFormat(stopLossValue)}`,
				price: stopLossPrice,
				color: color.yellow,
			}] : [],
		]});
	}, [
		stopLossPrice,
		memoizedPriceLines,
		dispatchContext,
		stopLossValue, 
	]);

	/* CALLBACKS */
	const handlePlaceOpenOrder = useCallback(
		() => {
			if (!placeOrderIsDisabled) {
				setOrderValueLoading(notionalOrderValue);

				const type = orderType === 'BBO' ? 'LIMIT' : orderType;
				const quantity = notionalOrderValue / currentPrice;

				const order = {
					symbol,
					type,
					side: side === 'short' ? 'SELL' : 'BUY',
					positionSide: side.toUpperCase(),
					quantity,
					...type === 'LIMIT' ? {
						price: currentPrice,
					} : {},
					...stopLossValue ? {
						stopLossPrice,
						// stopLossGuaranteed: side === 'short' ? stopLossPrice > avgPrice : stopLossPrice < avgPrice,
						stopLossGuaranteed: false,
					} : {},
				};

				return placeOrder({ order });
			}
		}, [
			notionalOrderValue,
			side,
			symbol,
			placeOrderIsDisabled,
			orderType,
			currentPrice,
			stopLossValue,
			stopLossPrice,
			avgPrice,
			placeOrder,
		]
	);

	/* UI */
	return (
		<>
			<Card className="QuickOpen shadow">
				<Card.Header className="py-2 px-3 d-flex justify-content-between align-items-center">
					<span>Quick <span className="text-success">Open</span></span>
					{placeOrderResult?.isLoading ? (
						<FontAwesomeIcon icon={faSpinnerThird} spin={true} className="text-muted" />
					) : placeOrderResult?.isSuccess ? (
						<FontAwesomeIcon icon={faCheck} className="text-success" />
					) : placeOrderResult?.isError ? (
						<FontAwesomeIcon icon={faTimes} className="text-danger" />
					) : null}
				</Card.Header>
				<Card.Body className="py-2.5 px-3">
					<Toggle
						ops={TYPE_OPS}
						active={orderType}
						setActive={setOrderType}
						opClassName="f-rem-0.85 fw5"
						activeClassName={clsx({
							'text-success': side === 'long' && orderType === 'MARKET',
							'text-danger': side === 'short' && orderType === 'MARKET',
							'text-info': orderType === 'BBO',
						})}
						className="mb-2.5"
					/>
					<Toggle
						className="w-fit scroll-x"
						opClassName="f-rem-0.85"
						label={
							<Flex justify="between" className="f-rem-0.85 fw5 mb-0.5">
								<span className="text-gray-700">$ Value</span>
								<span className="text-gray-600 text-right">{millifyCurrency(valueAvailableToOpen || 0)} avail</span>
							</Flex>
						}
						ops={QUICK_ORDER_NOTIONAL_VALUES}
						opFormatDisplay={op => millifyCurrency(op, false, { precision: 2 })}
						isOpDisabled={(disabled, op) => {
							return placeOrderIsDisabled || op > valueAvailableToOpen;
						}}
						isOpLoading={(loading, op) => {
							return placeOrderResult?.isLoading && op === orderValueLoading;
						}}
						active={op => op === notionalOrderValue}
						setActive={op => setNotionalOrderValue(op)}
					/>
					<Form.Check
						custom
						type="checkbox"
						id="stop-loss"
						label="Stop Loss"
						checked={stopLossValue}
						onChange={() => setStopLossValue(prev => !prev ? STOP_LOSS_OPS[1] : null)}
						className="mt-2 f-rem-0.85"
						style={{lineHeight: '1.8'}}
					/>

					{stopLossValue && (
						<Toggle
							className="scroll-x mt-1"
							opClassName="f-rem-0.85"
							label={
								<Flex justify="between" className="f-rem-0.85 fw5 mb-0.5">
									<span className="text-gray-700">$ Loss</span>
									<Flex>
										<span className="text-gray-700">{currencyFormat(stopLossPrice)}</span>
										<SlashDivider />
										<span className="text-gray-600 text-right">{((side === 'short' ? 1 : -1) * stopLossPricePercFromCurPrice).toFixed(1)}%</span>
									</Flex>
								</Flex>
							}
							ops={STOP_LOSS_OPS}
							opFormatDisplay={op => millifyCurrency(op, false, { precision: 2 })}
							isOpDisabled={(disabled, op) => {
								return placeOrderIsDisabled || op > valueAvailableToOpen;
							}}
							isOpLoading={(loading, op) => {
								return placeOrderResult?.isLoading && op === orderValueLoading;
							}}
							active={op => op === stopLossValue}
							setActive={op => setStopLossValue(op)}
						/>
					)}

					{/* Place order */}
					<Button
						size="sm"
						variant={clsx({
							'success': placeOrderResult.isSuccess,
							'danger': placeOrderResult.isError,
							'outline-success': !placeOrderResult.isSuccess && !placeOrderResult.isError,
						})}
						block
						disabled={placeOrderIsDisabled}
						onClick={handlePlaceOpenOrder}
						className="mt-3"
					>
						{placeOrderResult.isLoading ? (
							<FontAwesomeIcon icon={faSpinnerThird} spin={true} />
						) : placeOrderResult.isSuccess ? (
							<FontAwesomeIcon icon={faCheck} />
						) : placeOrderResult.isError ? (
							<FontAwesomeIcon icon={faTimes} />
						) : [
							`Open ${millifyCurrency(notionalOrderValue || 0)}`,
							...stopLossValue ? [' / ', `SL ${millifyCurrency(-1 * stopLossValue)}`] : [],
						].join(' ')}
					</Button>
				</Card.Body>
			</Card>

			<ToastOnResult
				success={placeOrderResult.isSuccess ? 'Order placed' : null}
				error={placeOrderResult.error}
				resetSuccess={placeOrderResult.reset}
				resetError={placeOrderResult.reset}
				delay={MUTATION_RESET_DELAY}
			/>
		</>
	);
};

export default QuickOpen;
