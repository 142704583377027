import { useState, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'util/router';
import { color } from 'constants/tradingview';
global.Cookies = Cookies;

const DEBOUNCE_TIME = 10;

export default function useHandleDrawTrendline(props) {
	const { symbol } = useParams();
	const { intervalInMin } = props;
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);
	const [stop, setStop] = useState(false); //to tell move callback to stop
	const moveDebounceRef = useRef(Date.now() + DEBOUNCE_TIME);
	const moveTimeoutRef = useRef();

	const click = useCallback(({param, chartRef, candlestickSeries, activeTrendlineSeries, invisibleTimeSeries}) => {
		if (!param.point.y || param.point.y < 0) return;

		if (!start) {
			// Add whitespace series padded time for drawing
			const lastTimestamp = candlestickSeries.current.barsInLogicalRange(
				chartRef.current.timeScale().getVisibleLogicalRange()
			)?.to;
			const intervalInSec = intervalInMin * 60;
			const additionalTimestamps = [...Array(250).keys()].map(
				i => ({ time: lastTimestamp + (intervalInSec * (i+1)) })
			);

			invisibleTimeSeries.current.setData(additionalTimestamps);
		}

		const newPoint = {
			time: param.time || chartRef.current.timeScale().coordinateToTime(param.point.x),
			value: candlestickSeries.current.coordinateToPrice(param.point.y),
		};

		if (!start) {
			setStart(newPoint);
			activeTrendlineSeries.current.setData([newPoint]);
		} else {
			setEnd(newPoint);
			setStop(true);
			activeTrendlineSeries.current.setData([start, newPoint]);
		}
	}, [start, intervalInMin]);

	const move = useCallback(
		({param, chartRef, candlestickSeries, activeTrendlineSeries, invisibleTimeSeries}) => {
			if (moveDebounceRef.current <= Date.now()) {
				moveTimeoutRef.current = setTimeout(
					() => {
						if (
							!param.point.y ||
							param.point.y < 0 ||
							!start ||
							stop
						) return;

						const newPoint = {
							time: param.time || chartRef.current.timeScale().coordinateToTime(param.point.x),
							value: candlestickSeries.current.coordinateToPrice(param.point.y),
						};

						activeTrendlineSeries.current.setData([start, newPoint]);
					},
					DEBOUNCE_TIME
				)
			} else {
				clearTimeout(moveTimeoutRef.current);
			}

			moveDebounceRef.current = Date.now() + DEBOUNCE_TIME;
		}, [
			start,
			stop,
		]
	);

	const recordLine = useCallback(({
		chartRef,
		trendlinesSeriesRecord,
		activeTrendlineSeries,
		invisibleTimeSeries
	}) => {
		if (start && end) {
			const data = [start, end];
			const newTrendlineSeries = chartRef.current.addLineSeries({
				color: color.aqua,
				lineWidth: 1,
				crosshairMarkerVisible: false,
				priceLineVisible: false,
				lastValueVisible: false,
			});

			newTrendlineSeries.setData(data);
			trendlinesSeriesRecord.current.push(newTrendlineSeries);

			const cookieStr = `priceline-${symbol}`;
			const trendlinesForSymbol = [
				...JSON.parse(Cookies.get(cookieStr) || '[]'),
				data,
			];
			Cookies.set(cookieStr, JSON.stringify(trendlinesForSymbol));
		};

		activeTrendlineSeries.current.setData([]);
		invisibleTimeSeries.current.setData([]);

		setStart(null);
		setEnd(null);
		setStop(null);
	}, [
		start,
		end,
		symbol,
	]);

	return {
		click,
		move,
		recordLine,
	};
};
