import React, { useState, useReducer, useEffect, useMemo, useCallback } from "react";
import clsx from 'clsx';
import { LineStyle } from 'lightweight-charts';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Flex from "components/Flex";
import Spinner from "components/Spinner";
import Toggle from "components/Toggle";
import useIsMobile from 'hooks/useIsMobile';
import usePlaceStaggeredOrder from './hooks/usePlaceStaggeredOrder';
import { useGetFeeRateQuery } from 'api/client';
import { commaFormat, currencyFormat } from 'util/numbers';
import './Utilities.scss';

function Utilities(props) {
	const {
		position = {},
		positionsIsLoading,
		setCustomLines,
		pendingOrdersRefetch
	} = props;

	return !positionsIsLoading && !!position ? (
		<>
			<PlAtPriceCalculator
				positionSide={position?.positionSide}
				avgPrice={position?.avgPrice}
				realisedPNL={position?.realisedPNL}
				breakEvenPrice={position?.breakEvenPrice}
				volume={position?.volume}
			/>
			<OpenStaggeredOrder
				symbol={position?.symbol}
				positionSide={position?.positionSide}
				setCustomLines={setCustomLines}
				pendingOrdersRefetch={pendingOrdersRefetch}
			/>
		</>
	) : null;
};

export default Utilities;

const PlAtPriceCalculator = React.memo(({positionSide, breakEvenPrice, avgPrice, realisedPNL, volume}) => {
	const isMobile = useIsMobile();
	const [show, setShow] = useState(!isMobile);
	const [closePrice, setClosePrice] = useState(Math.round(Number(breakEvenPrice) || ''));
	const [customVolumePercentage, setCustomVolumePercentage] = useState(100);
	const customVolume = Math.round((customVolumePercentage/100) * volume);
	const { data: feeRate = {} } = useGetFeeRateQuery();
	const positionSize = useMemo(() => avgPrice * customVolume, [avgPrice, customVolume]);

	const calcProfit = useCallback((price) => {
		const sizeAtPrice = customVolume * price;
		const feesAtPrice = sizeAtPrice * feeRate.maker;
		return positionSide === 'Short' ? positionSize - sizeAtPrice - feesAtPrice + realisedPNL
			: sizeAtPrice - feesAtPrice - positionSize + realisedPNL;
	}, [positionSide, customVolume, positionSize, realisedPNL, feeRate?.maker]);

	const profitAtClosePrice = calcProfit(closePrice);
	const newProfitAtClosePrice = profitAtClosePrice - realisedPNL;

	const ProfitResults = React.memo(({isAddToPrice}) => {
		const surroundingMultiplier = .5;
		const multiplier = (1 * surroundingMultiplier)*(isAddToPrice ? 1 : -1);
		const newClosePrice = closePrice * (1 + (multiplier/100));
		const profit = calcProfit(newClosePrice);
		const newProfit = profit - realisedPNL;

		return (
			<Flex
				key={multiplier}
				justify="between"
				align="center"
				className={clsx('f-rem-0.85', {
					'border-bottom pb-0.5 mb-0.5': isAddToPrice,
					'border-top pt-0.5 mt-0.5': !isAddToPrice
				})}
				onDoubleClick={() => setClosePrice(Math.round(newClosePrice))}
			>
				<Flex direction="column">
					<span className="text-gray-600 f-rem-0.7 fw-5">{isAddToPrice ? '+' : ''}{multiplier}%</span>
					<span className="text-gray-700">{currencyFormat(newClosePrice, '-')}</span>
				</Flex>
				<Flex direction="column" className="text-right">
					<span
						className={clsx('f-rem-0.7', {
							'text-success': newProfit > 0,
							'text-danger': newProfit < 0
						})}
					>
						{newProfit > 0 && '+'}{currencyFormat(newProfit, '-')}
					</span>
					<span
						className={clsx({
							'text-success': profit > 0,
							'text-danger': profit < 0
						})}
					>
						{profit > 0 && '+'}{currencyFormat(profit, '-')}
					</span>
				</Flex>
			</Flex>
		);
	});

	const RangeDivider = React.memo(({left}) => (
		<div
			onClick={e => {
				e.stopPropagation();
				setCustomVolumePercentage(left);
			}}
			className={clsx('RangeDivider', {
				'd-none': customVolumePercentage === left ||
					(left === 25 && customVolumePercentage >= 22 && customVolumePercentage <= 28) ||
					(left === 50 && customVolumePercentage >= 47 && customVolumePercentage <= 53) ||
					(left === 75 && customVolumePercentage >= 72 && customVolumePercentage <= 78)
			})}
			style={{left: `calc(${left}% - 3px)`}}
		></div>
	));

	return (
		<Card className="PlAtPriceCalculator shadow">
			<Card.Header
				className={clsx({
					'border-bottom-0 overflow-hidden': !show,
				})}
				onDoubleClick={() => setShow(prev => !prev)}
			>
				<span>Profit/Loss if close at</span>
			</Card.Header>
			{show && (
				<Card.Body>
					<Form.Group controlId="range" className="mb-2" style={{position: 'relative'}}>
						<Form.Label className="w-100 d-flex justify-content-between f-rem-0.85 fw-5">
							<span className="text-gray-700">Volume</span>
							<span className="text-gray-700">{commaFormat(customVolume, undefined, '-')}
								<span className="text-gray-500"> / {commaFormat(volume, undefined, '-')} ({customVolumePercentage}%)</span>
							</span>
						</Form.Label>
						<Form.Control
							type="range"
							custom={true}
							value={customVolumePercentage}
							onChange={e => setCustomVolumePercentage(e.target.value)}
							onDoubleClick={e => {
								// Get value to nearest 10
								const { value } = e.target;
								setCustomVolumePercentage(Math.round(value/10)*10);
							}}
							style={{height: '1rem'}}
						/>
						<RangeDivider left={25}/>
						<RangeDivider left={50}/>
						<RangeDivider left={75}/>
					</Form.Group>
					<InputGroup>
						<InputGroup.Text
							className="pointer"
							onClick={() => setClosePrice(prev => Math.round(prev * 0.999))}
						>
							-
						</InputGroup.Text>
						<Form.Control
							aria-label="Amount (to the nearest dollar)"
							value={closePrice}
							type="number"
							step="1"
							onChange={e => setClosePrice(e.target.value)}
							className="text-center"
						/>
						<InputGroup.Text
							className="pointer"
							onClick={() => setClosePrice(prev => Math.round(prev * (1.001)))}
						>
							+
						</InputGroup.Text>
					</InputGroup>
					<div className="mt-3">
						{/* +.5% */}
						<ProfitResults isAddToPrice={true} />
						{/* At cur price */}
						<Flex justify="between" align="center" className="my-1.5">
							<span>{currencyFormat(closePrice, '-')}</span>
							<Flex direction="column" className="text-right">
								<span
									className={clsx('f-rem-0.8', {
										'text-success': newProfitAtClosePrice > 0,
										'text-danger': newProfitAtClosePrice < 0
									})}
								>
									{newProfitAtClosePrice > 0 && '+'}{currencyFormat(newProfitAtClosePrice, '-')}
								</span>
								<span
									className={clsx('fw-5', {
										'text-success': profitAtClosePrice > 0,
										'text-danger': profitAtClosePrice < 0
									})}
								>
									{profitAtClosePrice > 0 && '+'}{currencyFormat(profitAtClosePrice, '-')}
								</span>
							</Flex>
						</Flex>
						{/* -.5% */}
						<ProfitResults isAddToPrice={false} />
					</div>
				</Card.Body>
			)}
		</Card>
	);
});

function staggeredOrderPricesReducer(state, {which, value}) {
	return which === 'reset' ? {
		lowPrice: 0,
		highPrice: 0,
		ordersCount: 3,
		size: 20,
	} : {
		...state,
		[which]: Number(value)
	}
};

const OpenStaggeredOrder = React.memo(({symbol, positionSide, setCustomLines, pendingOrdersRefetch}) => {
	const [show, setShow] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [distributionSkew, setDistributionSkew] = useState(50);
	const [sizeSkew, setSizeSkew] = useState(50);

	// const convertSkew = (skew) => Math.floor(
	// 	((skew / 50) - 1) * 100
	// ) / 100;

	const convertSkew = (skew) => Math.floor(
		((2 * skew / 100) - 1) * 100
	) / 100;

	const [
		{
			lowPrice = 0,
			highPrice = 0,
			ordersCount = 3,
			size = 20,
		},
		dispatchStaggeredOrderPricesReducer
	] = useReducer(staggeredOrderPricesReducer, {});

	const staggeredOrders = useMemo(() => {
		if (
			highPrice > lowPrice &&
			ordersCount >= 2 &&
			size >= 20
		) {
			const convertedDistributionSkew = convertSkew(distributionSkew);
			const convertedSizeSkew = convertSkew(sizeSkew);

			const limitPriceRange = highPrice - lowPrice;
			const totalOrderSize = size * ordersCount;
			//the numerator needs to be the max size of x where f(x) = 1 since the formula x^2 is changing based on dist skew
			// And we need a separate step for disk skew and size skew
			const diskSkewNumerator = Math.sqrt(1/(1 + Math.abs(convertedDistributionSkew)));
			const diskSkewStep = diskSkewNumerator / (ordersCount - 1);
			const sizeSkewNumerator = Math.sqrt(1/(1 + Math.abs(convertedSizeSkew)));
			const sizeSkewStep = sizeSkewNumerator / (ordersCount - 1);
			const step = 1/(ordersCount - 1);

			console.log({
				step, diskSkewStep, diskSkewNumerator, denom: ordersCount - 1
			})

			const limitPrices = [];
			const sizes = [];

			// Push start value
			limitPrices.push(lowPrice);
			sizes.push(size);
			for (let i = 1; i < ordersCount - 1; i++) {
				const priceInc = convertedDistributionSkew === 0 ? 0
					: convertedDistributionSkew > 0
						? (1 + Math.abs(convertedDistributionSkew)) * Math.pow(1 - (diskSkewStep * i), 2)
						: (1 + Math.abs(convertedDistributionSkew)) * Math.pow(diskSkewStep * i, 2);

				const straightLineInc = step * i * limitPriceRange;
				const priceBeforeSkew = lowPrice + straightLineInc;
				const skewValue = limitPriceRange * priceInc;

				console.log({priceBeforeSkew, skewValue, priceInc});

				const orderLimitPrice = convertedDistributionSkew > 0
					? priceBeforeSkew + skewValue
					: priceBeforeSkew - skewValue;

				const sizeInc = convertedSizeSkew * Math.pow(sizeSkewStep * i, 2);
				const orderSize = totalOrderSize * sizeInc || size;

				limitPrices.push(orderLimitPrice);
				sizes.push(orderSize);
			}
			// Push end value
			limitPrices.push(highPrice);
			sizes.push(size);

			const orders = [];

			for (let i = 0; i < ordersCount; i++) {
				const orderLimitPrice = limitPrices[i];
				const orderSize = sizes[i];

				orders.push({
					symbol: symbol,
					side: positionSide === 'Short' ? 'Ask' : 'Bid',
					price: orderLimitPrice,
					volume: orderSize / orderLimitPrice,
					size: orderSize,
				});
			}

			return orders;


			// const totalValue = ordersCount * size;
			// const priceRange = highPrice - lowPrice;
			// // Calculate the increment for each order based on the distribution skew
			// const increment = priceRange / (ordersCount - 1) * (1 + convertedDistributionSkew);
			// // Calculate the order sizes based on the size skew
			// let sizes = [];
			// for (let i = 0; i < ordersCount; i++) {
			//   let orderSize = size * (1 + convertedSizeSkew * (i - (ordersCount - 1) / 2) / ordersCount);
			//   sizes.push(orderSize);
			// }
			// // Calculate the limit prices for each order
			// let limitPrices = [];
			// for (let i = 0; i < ordersCount; i++) {
			//   let limitPrice = lowPrice + i * increment;
			//   limitPrices.push(limitPrice);
			// }
			// // Ensure that the first and last orders are at the lower and upper price boundaries, respectively
			// limitPrices[0] = lowPrice;
			// limitPrices[ordersCount - 1] = highPrice;
			// // Round the limit prices to two decimal places
			// limitPrices = limitPrices.map(price => Math.round(price * 100) / 100);
			// // Ensure that the order sizes are at least $20
			// sizes = sizes.map(size => Math.max(size, 20));
			// // Ensure that the total value of the orders is not exceeded
			// const totalSize = sizes.reduce((acc, val) => acc + val, 0);
			// if (totalSize > totalValue) {
			//   const scaleFactor = totalValue / totalSize;
			//   sizes = sizes.map(size => size * scaleFactor);
			// }
			// // Round the order sizes to two decimal places
			// sizes = sizes.map(size => Math.round(size * 100) / 100);
			// // Return the limit prices and order sizes as an object

			// const orders = [];
			// const chunkSize = (highPrice - lowPrice) / (ordersCount-1);

			// for (let i = 0; i < ordersCount; i++) {
			// 	const price = limitPrices[i];
			// 	const orderSize = sizes[i];

			// 	orders.push({
			// 		symbol: symbol,
			// 		side: positionSide === 'Short' ? 'Ask' : 'Bid',
			// 		price: price,
			// 		volume: orderSize / price,
			// 		size: orderSize,
			// 	});
			// }

			// return orders;
		} else {
			return [];
		}
	}, [symbol, positionSide, highPrice, lowPrice, ordersCount, size, distributionSkew, sizeSkew]);

	useEffect(() => {
		if (!showPreview) {
			setCustomLines(prev => prev.filter(l => l.type !== 'staggeredOrderPreview'));
		} else {
			const lines = staggeredOrders.map(o => ({
				title: `Op ${positionSide}: ${currencyFormat(o.size)}`,
				price: o.price,
				color: 'lightgrey',
				lineStyle: LineStyle.SparseDotted,
				type: 'staggeredOrderPreview'
			}));

			setCustomLines(prev => [
				...prev.filter(l => l.type !== 'staggeredOrderPreview'),
				...lines,
			]);
		}
	}, [positionSide, showPreview, setCustomLines, staggeredOrders]);

	const handleReset = useCallback(() => {
		dispatchStaggeredOrderPricesReducer({which: 'reset'});
		setShowPreview(false);
		setDistributionSkew(50);
		setSizeSkew(50);
	}, []);

	const placeStaggeredOrderOnComplete = useCallback(() => {
		pendingOrdersRefetch();
		dispatchStaggeredOrderPricesReducer({which: 'reset'});
		setShowPreview(false);
	}, [pendingOrdersRefetch]);

	const {
		handlePlaceStaggeredOrder,
		isLoading: placeStaggeredOrderIsLoading,
	} = usePlaceStaggeredOrder(placeStaggeredOrderOnComplete);

	return (
		<Card className="OpenStaggeredOrder shadow mt-3">
			<Card.Header
				className={clsx({
					'border-bottom-0 overflow-hidden': !show,
				})}
				onDoubleClick={() => setShow(prev => !prev)}
			>
				<Flex justify="between" align="center">
					<span>Open staggered {positionSide?.toLowerCase?.()} order</span>
					<Toggle
						ops={['Preview']}
						active={showPreview ? 'Preview' : null}
						setActive={() => setShowPreview(prev => !prev)}
						className="f-rem-0.85"
					/>
				</Flex>
			</Card.Header>
			{show && (
				<Card.Body>
					<Flex justify="between" align="center" className="border-bottom pb-2 mb-3">
						<span>Total order size:</span>
						<span className="fw-5">{currencyFormat(ordersCount * size)}</span>
					</Flex>
					<Row className="no-gutters">
						<Col xs={12} md={6} className="px-0 pr-md-1">
							<Form.Group controlId="lowPrice" className="mb-2">
								<Form.Label className="mb-1 f-rem-0.9 text-muted">Low price</Form.Label>
								<Form.Control
									placeholder="Low price"
									type="number"
									value={lowPrice}
									onChange={e => dispatchStaggeredOrderPricesReducer({which: 'lowPrice', value: e.target.value})}
								/>
								<Form.Text className="text-danger">{lowPrice > highPrice && 'Low price must be lower than high price'}</Form.Text>
							</Form.Group>
						</Col>
						<Col xs={12} md={6} className="px-0 pl-md-1">
							<Form.Group controlId="highPrice" className="mb-2">
								<Form.Label className="mb-1 f-rem-0.9 text-muted">High price</Form.Label>
								<Form.Control
									placeholder="High price"
									type="number"
									value={highPrice}
									onChange={e => dispatchStaggeredOrderPricesReducer({which: 'highPrice', value: e.target.value})}
								/>
								<Form.Text className="text-danger">{highPrice < lowPrice && 'High price must be higher than low price'}</Form.Text>
							</Form.Group>
						</Col>
						<Col xs={12} md={6} className="px-0 pr-md-1">
							<Form.Group controlId="orders" className="mb-2">
								<Form.Label className="mb-1 f-rem-0.9 text-muted">Number of orders</Form.Label>
								<Form.Control
									placeholder="Number of orders"
									type="number"
									value={ordersCount}
									onChange={e => dispatchStaggeredOrderPricesReducer({which: 'ordersCount', value: e.target.value})}
								/>
								<Form.Text className="text-danger">{ordersCount < 2 && 'Order count must be >= 2'}</Form.Text>
							</Form.Group>
						</Col>
						<Col xs={12} md={6} className="px-0 pl-md-1">
							<Form.Group controlId="size" className="mb-2">
								<Form.Label className="mb-1 f-rem-0.9 text-muted">Size per order (in $)</Form.Label>
								<Form.Control
									placeholder="$ size of each order"
									type="number"
									value={size}
									onChange={e => dispatchStaggeredOrderPricesReducer({which: 'size', value: e.target.value})}
								/>
								<Form.Text className="text-danger">{size < 20 && 'Order size must be >= 20'}</Form.Text>
							</Form.Group>
						</Col>
					</Row>
					<Form.Group controlId="range" className="my-2" style={{position: 'relative'}}>
						<Form.Label className="w-100 d-flex justify-content-between f-rem-0.85 fw-5">
							<Flex align="start">
								<span className="text-gray-700">Distribution</span>
								<span
									className="ml-2 fw-4 f-rem-0.8 text-info pointer"
									onClick={() => setDistributionSkew(50)}
								>
									Reset
								</span>
							</Flex>
							<span className="text-gray-700">{convertSkew(distributionSkew)}</span>
						</Form.Label>
						<Form.Control
							type="range"
							custom={true}
							value={distributionSkew}
							onChange={e => setDistributionSkew(e.target.value)}
							style={{height: '1rem'}}
						/>
					</Form.Group>
					<Form.Group controlId="range" className="my-2" style={{position: 'relative'}}>
						<Form.Label className="w-100 d-flex justify-content-between f-rem-0.85 fw-5">
							<Flex align="start">
								<span className="text-gray-700">Size</span>
								<span
									className="ml-2 fw-4 f-rem-0.8 text-info pointer"
									onClick={() => setSizeSkew(50)}
								>
									Reset
								</span>
							</Flex>
							<span className="text-gray-700">{convertSkew(sizeSkew)}</span>
						</Form.Label>
						<Form.Control
							type="range"
							custom={true}
							value={sizeSkew}
							onChange={e => setSizeSkew(e.target.value)}
							style={{height: '1rem'}}
						/>
					</Form.Group>
					<Flex justify="between" align="center" className="mt-3.5">
						<Button
							size="sm"
							disabled={placeStaggeredOrderIsLoading}
							onClick={() => !placeStaggeredOrderIsLoading && handlePlaceStaggeredOrder(staggeredOrders)}
						>
							{placeStaggeredOrderIsLoading ? <Spinner /> : 'Place Orders'}
						</Button>
						<span
							className="text-info pointer"
							onClick={handleReset}
						>
							Reset
						</span>
					</Flex>
				</Card.Body>
			)}
		</Card>
	);
});
