import { useState, useEffect } from 'react';

export default function useIsKeyPressed() {
	const [keys, setKeys] = useState({});

	useEffect(() => {
		function onKeyup(e) {
			setKeys(prev => ({
				...prev,
				[e.key]: false,
			}))
		};

		function onKeydown(e) {
			setKeys(prev => ({
				...prev,
				[e.key]: true,
			}))
		};

		window.addEventListener('keyup', onKeyup);
		window.addEventListener('keydown', onKeydown);

		return () => {
			window.removeEventListener('keyup', onKeyup);
			window.removeEventListener('keydown', onKeydown);
		}
	}, []);

	return keys;
};
