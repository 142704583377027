import queryConstructor from 'util/queryConstructor';

function getAccountBalance() {
	return `getAccountBalance`;
};

function getAccountBalanceV2() {
	return `getAccountBalanceV2`;
};

function getKlineHistory({symbol, type, startTs, endTs} = {}) {
	const queryParams = queryConstructor({symbol, type, startTs, endTs});
	return `getKlineHistory?${queryParams}`;
};

function getKlineLatest({symbol, type, startTs, endTs} = {}) {
	const queryParams = queryConstructor({symbol, type, startTs, endTs});
	return `getKlineLatest?${queryParams}`;
};

function getKlineDataV2({symbol, period, startTime, endTime} = {}) {
	const queryParams = queryConstructor({symbol, period, startTime, endTime});
	return `getKlineData?${queryParams}`;
};

function getLeverage({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getLeverage?${queryParams}`;
};

function getPairPrice({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getPairPrice?${queryParams}`;
};

function getPositions({symbols, includeCurrentPrice} = {}) {
	const queryParams = queryConstructor({symbols, includeCurrentPrice});
	return `getPositions?${queryParams}`;
};

function getPositionsV2({symbol, side, includeCurrentPrice} = {}) {
	const queryParams = queryConstructor({symbol, side, includeCurrentPrice});
	return `getPositionsV2?${queryParams}`;
};

function getPendingOrders({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getPendingOrders?${queryParams}`;
};

function getPendingOrdersV2({symbol, side} = {}) {
	const queryParams = queryConstructor({symbol, side});
	return `getPendingOrdersV2?${queryParams}`;
};

function getPendingStopOrders({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getPendingStopOrders?${queryParams}`;
};

function getProfitAndLoss({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getProfitAndLoss?${queryParams}`;
};

function getFundingRate({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getFundingRate?${queryParams}`;
};

function getFundingRateV2({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getFundingRateV2?${queryParams}`;
};

function getAllContracts() {
	return `getAllContracts`;
};

function getAllContractsV2({symbol} = {}) {
	const queryParams = queryConstructor({symbol});
	return `getAllContractsV2?${queryParams}`;
};

function getFeeRate() {
	return `getFeeRate`;
};

function getHistoricalOrders({symbol, orderId, startTime, endTime, limit = 1000, filled = true} = {}) {
	const queryParams = queryConstructor({symbol, orderId, startTime, endTime, limit, filled});
	return `getHistoricalOrders?${queryParams}`;
};

function getHistoricalFilledOrders({orderId, tradingUnit = 'COIN', startTs, endTs} = {}) {
	const queryParams = queryConstructor({orderId, tradingUnit, startTs, endTs});
	return `getHistoricalFilledOrders?${queryParams}`;
};

// Mutations
function cancelOrder({orderId, symbol} = {}) {
	const queryParams = queryConstructor({orderId, symbol});
	return `cancelOrder?${queryParams}`;
};

function cancelOrders({orderIds, symbol} = {}) {
	const queryParams = queryConstructor({orderIds, symbol});
	return `cancelOrders?${queryParams}`;
};

function cancelOrdersV2({orderIds, symbol} = {}) {
	return {
		url: `cancelOrdersV2`,
		method: 'DELETE',
		body: { orderIds, symbol },
	}
};

function placeBulkOrders({orders = []} = {}) {
	return {
		url: `placeBulkOrders`,
		method: 'POST',
		body: { orders },
	}
};

function placeBulkOrdersV2({orders = []} = {}) {
	return {
		url: `placeBulkOrdersV2`,
		method: 'POST',
		body: { orders },
	}
};

function modifyOrders({orders = [], symbol} = {}) {
	return {
		url: `modifyOrders`,
		method: 'POST',
		body: { orders, symbol },
	}
};

function placeOrder({symbol, side, price, volume, type, action} = {}) {
	const queryParams = queryConstructor({symbol, side, price, volume, type, action});
	return `placeOrder?${queryParams}`;
};

function placeOrderV2({order = {}, test = false} = {}) {
	return {
		url: `placeOrderV2`,
		method: 'POST',
		body: { order, test },
	}
};

function placeOrderMinSize({symbol, side} = {}) {
	const queryParams = queryConstructor({symbol, side});
	return `placeOrderMinSize?${queryParams}`;
};

function placeGappedStopLossOrdersV2({orders = [], symbol, side, clearOtherStopLosses} = {}) {
	return {
		url: `placeGappedStopLossOrdersV2`,
		method: 'POST',
		body: { orders, symbol, side, clearOtherStopLosses },
	}
};

const handlers = (builder) => ({
	getAccountBalance: builder.query({
		query: getAccountBalance,
		keepUnusedDataFor: 1,
	}),
	getAccountBalanceV2: builder.query({
		query: getAccountBalanceV2,
		keepUnusedDataFor: 1,
	}),
	getKlineHistory: builder.query({
		query: getKlineHistory,
		keepUnusedDataFor: 1,
	}),
	getKlineLatest: builder.query({
		query: getKlineLatest,
		keepUnusedDataFor: 1,
	}),
	getKlineDataV2: builder.query({
		query: getKlineDataV2,
		keepUnusedDataFor: 1,
	}),
	getLeverage: builder.query({
		query: getLeverage,
		keepUnusedDataFor: 1,
	}),
	getPairPrice: builder.query({
		query: getPairPrice,
		keepUnusedDataFor: 1,
	}),
	getPositions: builder.query({
		query: getPositions,
		keepUnusedDataFor: 1,
	}),
	getPositionsV2: builder.query({
		query: getPositionsV2,
		keepUnusedDataFor: 1,
	}),
	getPendingOrders: builder.query({
		query: getPendingOrders,
		keepUnusedDataFor: 1,
	}),
	getPendingOrdersV2: builder.query({
		query: getPendingOrdersV2,
		keepUnusedDataFor: 1,
	}),
	getPendingStopOrders: builder.query({
		query: getPendingStopOrders,
		keepUnusedDataFor: 1,
	}),
	getProfitAndLoss: builder.query({
		query: getProfitAndLoss,
		keepUnusedDataFor: 60 * 10,
	}),
	getFundingRate: builder.query({
		query: getFundingRate,
		keepUnusedDataFor: 1,
	}),
	getFundingRateV2: builder.query({
		query: getFundingRateV2,
		keepUnusedDataFor: 1,
	}),
	getAllContracts: builder.query({
		query: getAllContracts,
		keepUnusedDataFor: 1,
	}),
	getAllContractsV2: builder.query({
		query: getAllContractsV2,
		keepUnusedDataFor: 60 * 60, //1hr
	}),
	getFeeRate: builder.query({
		query: getFeeRate,
		keepUnusedDataFor: 60 * 60, //1hr
	}),
	getHistoricalOrders: builder.query({
		query: getHistoricalOrders,
		keepUnusedDataFor: 59, //59sec
	}),
	getHistoricalFilledOrders: builder.query({
		query: getHistoricalFilledOrders,
		keepUnusedDataFor: 1,
	}),

	// Mutations
	cancelOrder: builder.mutation({
		query: cancelOrder,
	}),
	cancelOrders: builder.mutation({
		query: cancelOrders,
	}),
	cancelOrdersV2: builder.mutation({
		query: cancelOrdersV2,
	}),
	placeBulkOrders: builder.mutation({
		query: placeBulkOrders,
	}),
	placeBulkOrdersV2: builder.mutation({
		query: placeBulkOrdersV2,
	}),
	placeOrder: builder.mutation({
		query: placeOrder,
	}),
	placeOrderV2: builder.mutation({
		query: placeOrderV2,
	}),
	placeOrderMinSize: builder.mutation({
		query: placeOrderMinSize,
	}),
	modifyOrders: builder.mutation({
		query: modifyOrders,
	}),
	placeGappedStopLossOrdersV2: builder.mutation({
		query: placeGappedStopLossOrdersV2,
	}),
});

export default handlers;
