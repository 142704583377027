import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import { ACCOUNT_VALUE_START, START_TIME, END_TIME } from 'components/FundingSection/constants';
dayjs.extend(dayjsUtc);

function contextReducer(state, action) {
	let newState = {};

	switch(action.type) {
		case 'accountValueStart':
		case 'minStartTime':
		case 'maxEndTime':
		case 'runReport':
			newState = {
				...state,
				[action.type]: action.payload,
			};
			break;
		case 'symbols':
		case 'futuresExchanges':
		case 'spotExchanges':
			newState = {
				...state,
				[action.type]: state[action.type].includes(action.payload) ? state[action.type].filter(
					o => o !== action.payload
				) : [action.payload, ...state[action.type]],
			};
			break;
		default:
			newState = { ...state };
			break;
	}

	return newState;
};

const contextInit = () => ({
	symbols: [],
	futuresExchanges: [],
	spotExchanges: [],
	accountValueStart: ACCOUNT_VALUE_START[4],
	minStartTime: undefined,
	maxEndTime: undefined,
	startTime: START_TIME,
	endTime: END_TIME,
	runReport: false,
});

export const reducerContext = { reducer: contextReducer, init: contextInit };
