import max from 'lodash/max';
import min from 'lodash/min';

export default function gravestoneDoji(data = [], color = 'red') {
	const marker = {
		position: "aboveBar",
    color: color,
    shape: "arrowDown",
    text: "GraveDoji"
	};

	for (let i = 1; i < data?.length; i++) {
		const current = data[i];
		const prev = data[i-1];

		const { open, high, low, close } = current || {};
		const { open: prevOpen, close: prevClose } = prev || {};

		const gravestoneDoji =
			prevClose > prevOpen &&
			Math.abs(close - open) / (high - low) < 0.1 &&
			(high - max([close, open])) > 3 * Math.abs(close - open) &&
			(min([close, open]) - low) <= Math.abs(close - open);

		if (gravestoneDoji) {
			current.gravestoneDoji = true;
			current.color = color;
			current.borderColor = color;
			current.wickColor = color;
			current.marker = {
				...marker,
				time: current.time,
			}
		}
	}

	return data;
};
