import sum from 'lodash/sum';

export default function useGetOrdersTotals(props) {
	const {
		orders = [],
		// positionAvgPrice,
	} = props;

	const totalOrdersAmt = sum(
		orders.map(
			po => (po.origQty || 0) - (po.executedQty || 0)
		)
	);

	const totalOrdersValue = sum(
		orders.map(
			po => (
				(po.origQty || 0) * (po.price || 0)
			) - (
				(po.executedQty || 0) * (po.avgPrice || 0)
			)
		)
	);

	// I dont know why i used waClosingPrice and positionAvgPrice here instead of just summing the profit values like now.
	// const waClosingPrice = sum(
	// 	orders.map(
	// 		po => po.price * po.origQty / totalOrdersAmt
	// 	)
	// );
	// const totalProfitFromOrders = Math.abs((positionAvgPrice - waClosingPrice) * totalOrdersAmt);
	const totalProfitFromOrders = sum(
		orders.map(
			po => po.totalProfit
		)
	);

	return {
		totalOrdersAmt,
		totalOrdersValue,
		totalProfitFromOrders,
	};
};
