export default function bearishHarami(data = [], color = 'red') {
	const marker = {
		position: "aboveBar",
    color: color,
    shape: "arrowDown",
    text: "BearHarami"
	};

	for (let i = 1; i < data?.length; i++) {
		const current = data[i];
		const prev = data[i-1];

		const { open, close } = current || {};
		const { open: prevOpen, close: prevClose } = prev || {};

		const bearishHarami =
			prevClose > prevOpen &&
			close < open &&
			prevOpen <= close &&
			open <= prevClose &&
			open - close < prevClose - prevOpen;

		if (bearishHarami) {
			current.bearishHarami = true;
			current.color = color;
			current.borderColor = color;
			current.wickColor = color;
			current.marker = {
				...marker,
				time: current.time,
			}
		}
	}

	return data;
};
