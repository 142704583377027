import React from "react";
import Meta from "components/Meta";
import FirechartSection from "components/FirechartSection";
import { requireAuth } from "util/auth";

function FirechartPage(props) {
  return (
    <>
      <Meta title="Firechart" />
      <FirechartSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Firechart"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(FirechartPage);
