import React from 'react';
import clsx from 'clsx';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faClock } from '@fortawesome/pro-duotone-svg-icons';
import Chart from 'components/Analyze/widgets/STOCH/Chart';
import stochFormula from 'components/Analyze/widgets/STOCH/formula';
import { KLINE_LIMIT } from 'components/Analyze/constants';

function STOCH(props) {
	const { klineData = [] } = props;

	const {
		kLine = [],
		dLine = [],
		prediction = [],
	} = stochFormula(klineData.slice(KLINE_LIMIT - 75));

	const {
		kIsHeadingUp,
		kIsHeadingDown,
		dIsHeadingUp,
		dIsHeadingDown,
		isKBelowD,
	} = prediction[prediction.length - 1] || {};

	const recommendation = kIsHeadingDown && dIsHeadingDown && isKBelowD ? RECOMMENDATION_MAP.SHORT
		: kIsHeadingUp && dIsHeadingUp && !isKBelowD ? RECOMMENDATION_MAP.LONG
			: RECOMMENDATION_MAP.WAIT;

	return (
		<Card className="Widget STOCH shadow-sm">
			<Card.Header>
				<span className="title">STOCH</span>
				<FontAwesomeIcon icon={recommendation.summaryIcon} className={`icon ${recommendation.color}`}/>
			</Card.Header>
			<Card.Body>
				<Chart series={{
					k: kLine,
					d: dLine,
				}} />
			</Card.Body>
			<Card.Footer>
				<span className="recommendation-label">K</span>
				<FontAwesomeIcon
					icon={kIsHeadingDown ? faArrowDown : kIsHeadingUp ? faArrowUp : faClock}
					className={clsx('icon', {
						'text-danger': kIsHeadingDown,
						'text-success': kIsHeadingUp,
						'text-gray-600': !kIsHeadingDown && !kIsHeadingUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">D</span>
				<FontAwesomeIcon
					icon={dIsHeadingDown ? faArrowDown : dIsHeadingUp ? faArrowUp : faClock}
					className={clsx('icon', {
						'text-danger': dIsHeadingDown,
						'text-success': dIsHeadingUp,
						'text-gray-600': !dIsHeadingDown && !dIsHeadingUp,
					})}
				/>
			</Card.Footer>
			<Card.Footer>
				<span className="recommendation-label">{`K ${isKBelowD ? '<' : '>'} D`}</span>
				<FontAwesomeIcon
					icon={isKBelowD ? faArrowDown : faArrowUp}
					className={clsx('icon', {
						'text-danger': isKBelowD,
						'text-success': !isKBelowD,
					})}
				/>
			</Card.Footer>
		</Card>
	);
};

export default STOCH;


const RECOMMENDATION_MAP = {
	SHORT: {
		summaryIcon: faArrowDown,
		color: 'text-danger',
		text: 'short',
	},
	LONG: {
		summaryIcon: faArrowUp,
		color: 'text-success',
		text: 'long',
	},
	WAIT: {
		summaryIcon: faClock,
		color: 'text-gray-600',
		text: 'wait',
	},
};
