import React from "react";
import Meta from "components/Meta";
import AnalyzeSection from "components/AnalyzeSection";
import { requireAuth } from "util/auth";

function AnalyzePage(props) {
  return (
    <>
      <Meta title="Analyze" />
      <AnalyzeSection
        bg="light"
        textColor="dark"
        size="sm"
        title="Analyze"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(AnalyzePage);
