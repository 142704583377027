import React, { useState, useEffect, useMemo, useCallback } from "react";
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Flex from "components/Flex";
import StripeTable from 'components/StripeTable';
import {
	// Queries
	useGetFeeRateQuery,
	// Mutations
	useCancelOrdersMutation,
} from 'api/client';
import { supportedSymbols } from 'constants/bingxSwaps';
import { commaFormat, currencyFormat } from 'util/numbers';
import { ShowCancelOrderResult } from './index';

const PENDING_ORDERS_HEADERS = [
	{
		key: 'tradeType',
		name: 'Type',
	}, {
		key: 'entrustPrice',
		name: 'Limit price',
	}, {
		key: 'filledVolume_entrustVolume',
		name: 'Filled/Total Vol'
	}, {
		key: 'fee',
		name: 'Fee',
	}, {
		key: 'cancel',
		name: 'Cancel'
	}
];

function PendingOrdersCard(props) {
	const {
		pendingOrders: _pendingOrders = [],
		pendingOrdersRefetch,
		symbol,
		avgPrice,
		volume,
		activePositionSide,
		availableVolumeToOpen,
		// availableVolumeToClose,
		currentPrice,
		setCreateOrderPrice,
		openCreateOrderPopup,
	} = props;

	const decimals = supportedSymbols[symbol.split('-')[0]]?.decimals || 2;
	const [collapseOpenOrders, setCollapseOpenOrders] = useState(false);
	const [collapseCloseOrders, setCollapseCloseOrders] = useState(false);
	const [showCancelOrderError, setShowCancelOrderError] = useState(false);
	const [showCancelOrderSuccess, setShowCancelOrderSuccess] = useState(false);
	const { data: feeRate = {} } = useGetFeeRateQuery();

	const [cancelOrders, cancelOrdersResult] = useCancelOrdersMutation();

	const handleCancelOrders = useCallback((orderIds) => {
		if (window.confirm('Are you sure?')) {
			cancelOrders({symbol, orderIds});
		}
	}, [symbol, cancelOrders]);

	useEffect(() => {
		if (cancelOrdersResult.isSuccess || cancelOrdersResult.isError) {
			cancelOrdersResult.reset();
			pendingOrdersRefetch();
		}

		if (cancelOrdersResult.isError) {
			setShowCancelOrderError(cancelOrdersResult.error);
			console.error('useCancelOrdersMutation error', cancelOrdersResult.error);
		}
		if (cancelOrdersResult.isSuccess) setShowCancelOrderSuccess(true);
	}, [cancelOrdersResult, pendingOrdersRefetch]);

	const [pendingOpenOrders, pendingCloseOrders] = useMemo(() => {
		const isShort = activePositionSide === 'Short';

		const { pendingOpenOrders, pendingCloseOrders } = _pendingOrders.reduce((acc, o) => {
			const fee = (o.entrustVolume * o.entrustPrice * feeRate.maker)*-1;
			const profit = o.action !== 'Close' ? null
				: isShort ? (avgPrice * o.entrustVolume) - (o.entrustPrice * o.entrustVolume) - fee
					: (o.entrustPrice * o.entrustVolume) - (avgPrice * o.entrustVolume) - fee;

			const order = {
				id: o.orderId,
				orig: {
					...o,
					...o.action === 'Close' ? {
						profit,
					} : {},
				},
				values: [
					// Trade Type
					{ value: o.tradeType },
					// Limit execution price
					{ value: currencyFormat(o.entrustPrice, '-', decimals) },
					// Filled / Total Volume
					{ value: (
						<Flex>
							<span className="text-gray-600">{commaFormat(o.filledVolume, undefined, '-')}</span>
							<span className="px-1 text-gray-400">/</span>
							<span>{commaFormat(o.entrustVolume, undefined, '-')}</span>
						</Flex>
					) },
					// Fee
					{
						value: currencyFormat(fee, '-'),
						className: 'text-danger'
					},
					// Profit
					...o.action === 'Close' ? [{
						value: currencyFormat(profit, '-'),
						className: profit >= 0 ? 'text-success' : 'text-danger',
					}] : [],
					// Cancel button
					{
						value: (
							<Button
								size="xs"
								onClick={() => handleCancelOrders([o.orderId])}
								disabled={cancelOrdersResult.isLoading}
							>
								Cancel
							</Button>
						),
					}
				],
				callbackValue: o,
			};

			if (o.action === 'Open') {
				acc.pendingOpenOrders.push(order);
			} else {
				acc.pendingCloseOrders.push(order);
			}

			return acc;
		}, {pendingOpenOrders: [], pendingCloseOrders: []});

		const sortedPendingOpen = sortBy(pendingOpenOrders, 'orig.entrustPrice');
		const sortedPendingClose = sortBy(pendingCloseOrders, 'orig.entrustPrice');

		return isShort ? [
			sortedPendingOpen,
			sortedPendingClose.reverse(),
		] : [
			sortedPendingOpen.reverse(),
			sortedPendingClose
		];
	}, [_pendingOrders, activePositionSide, avgPrice, handleCancelOrders, cancelOrdersResult?.isLoading, decimals, feeRate?.maker]);

	const totalVolumeToOpen = sumBy(pendingOpenOrders, 'orig.entrustVolume');
	const totalVolumeToClose = sumBy(pendingCloseOrders, 'orig.entrustVolume');
	const availableVolumeToOpenOnSide = availableVolumeToOpen[activePositionSide];
	const totalProfitFromCloseOrders = sumBy(pendingCloseOrders, 'orig.profit');

	return (
		<>
			<Card className="shadow">
				<Card.Header
					className={clsx({
						'border-bottom-0 overflow-hidden': collapseOpenOrders,
					})}
					onDoubleClick={() => setCollapseOpenOrders(prev => !prev)}
				>
					<Flex justify="between">
						<Flex direction="column" className="pr-3">
							<span>Open orders</span>
							<small>
								<span className="fw-5 text-gray-700">Avail to open: {commaFormat(availableVolumeToOpenOnSide, undefined, '-')}</span>
								<span className="px-1 text-gray-400">/</span>
								<span className="text-gray-600">{commaFormat(availableVolumeToOpenOnSide-totalVolumeToOpen, 2, '-')} avail after</span>
							</small>
						</Flex>
						<Flex className="text-nowrap">
							<span
								className="text-primary hvr-text-darken-15 pointer f-rem-0.8 fw-5"
								onClick={() => {
									const priceModifier = activePositionSide === 'Short' ? 0.05 : -0.05;
									setCreateOrderPrice(currentPrice + priceModifier);
									openCreateOrderPopup();
								}}
							>
								New Order
							</span>
							<span
								className="text-danger hvr-text-darken-15 pointer f-rem-0.8 fw-5 ml-3"
								onClick={() => handleCancelOrders(pendingOpenOrders.map(o => o.id))}
							>
								Cancel all ({pendingOpenOrders?.length})
							</span>
						</Flex>
					</Flex>
				</Card.Header>
				<Card.Body
					className={clsx('p-0', {
						'd-none': collapseOpenOrders
					})}
				>
					<StripeTable
						headers={PENDING_ORDERS_HEADERS}
						data={pendingOpenOrders}
						noneFoundText="No pending open orders found"
					/>
				</Card.Body>
			</Card>

			<Card className="shadow mt-2">
				<Card.Header
					className={clsx({
						'border-bottom-0 overflow-hidden': collapseCloseOrders,
					})}
					onDoubleClick={() => setCollapseCloseOrders(prev => !prev)}
				>
					<Flex justify="between">
						<Flex direction="column" className="pr-3">
							<span>Close orders</span>
							<small>
								<span className="fw-5 text-gray-700">Total to close: {commaFormat(totalVolumeToClose, undefined, '-')}</span>
								<span className="px-1 text-gray-400">/</span>
								<span className="text-gray-600">{commaFormat(volume-totalVolumeToClose, undefined, '-')} left</span>
								<span className="px-1 text-gray-400">/</span>
								<span className="text-gray-600">{commaFormat(availableVolumeToOpenOnSide+totalVolumeToClose, 2, '-')} avail after</span>
							</small>
						</Flex>
						<Flex direction="column" align="end" className="text-right">
							<Flex className="text-nowrap">
								<span
									className="text-primary hvr-text-darken-15 pointer f-rem-0.8 fw-5"
									onClick={() => {
										const priceModifier = activePositionSide === 'Short' ? -0.05 : 0.05;
										setCreateOrderPrice(currentPrice + priceModifier);
										openCreateOrderPopup();
									}}
								>
									New Order
								</span>
								<span
									className="text-danger hvr-text-darken-15 pointer f-rem-0.8 fw-5 ml-3"
									onClick={() => handleCancelOrders(pendingCloseOrders.map(o => o.id))}
								>
									Cancel all ({pendingCloseOrders?.length})
								</span>
							</Flex>
							<Flex className="f-rem-0.95 fw-5 mt-0.5">
								<span
									className={clsx('text-right', {
										'text-success': totalProfitFromCloseOrders > 0,
										'text-gray-600': !totalProfitFromCloseOrders,
									})}
								>
									{totalProfitFromCloseOrders > 0 && '+'}{currencyFormat(totalProfitFromCloseOrders, '-')}
								</span>
								<span className="text-gray-500 ml-1">({commaFormat(pendingCloseOrders.length, undefined, '-')})</span>
							</Flex>
						</Flex>
					</Flex>
				</Card.Header>
				<Card.Body
					className={clsx('p-0', {
						'd-none': collapseCloseOrders
					})}
				>
					<StripeTable
						headers={[...PENDING_ORDERS_HEADERS.slice(0, -1), { key: 'profit', name: 'Profit' }, PENDING_ORDERS_HEADERS[PENDING_ORDERS_HEADERS.length-1]]}
						data={pendingCloseOrders}
						noneFoundText="No pending close orders found"
					/>
				</Card.Body>
			</Card>

			<ShowCancelOrderResult
				showCancelOrderSuccess={showCancelOrderSuccess}
				setShowCancelOrderSuccess={setShowCancelOrderSuccess}
				showCancelOrderError={showCancelOrderError}
				setShowCancelOrderError={setShowCancelOrderError}
			/>
		</>
	);
};

export default PendingOrdersCard;
