import React from "react";
import Meta from "components/Meta";
import FundingSection from "components/FundingSection";
// import { requireAuth } from "util/auth";

function FundingPage(props) {
  return (
    <>
      <Meta title="Funding" />
      <FundingSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Funding"
      	subtitle=""
      />
    </>
  );
}

export default FundingPage;
// export default requireAuth(FundingPage);
