import { MACD } from 'technicalindicators';
import { fixedAsNumber } from 'util/numbers';

const FAST = 12;
const SLOW = 26;
const SIGNAL = 9;

function getMacdLines(klineData = []) {
	const series = {
		macd: [],
		signal: [],
		histogram: [],
		prediction: [],
	};

	MACD.calculate({
		values: klineData.map(o => o.close),
		fastPeriod: FAST,
		slowPeriod: SLOW,
		signalPeriod: SIGNAL,
		simpleMAOscillator: false,
		simpleMASignal: false,
	}).forEach(
		({ MACD, signal, histogram }, idx) => {
			series.macd.push({ x: klineData[idx].openTime, y: fixedAsNumber(MACD, 2, undefined), value: MACD });
			series.signal.push({ x: klineData[idx].openTime, y: fixedAsNumber(signal, 2, undefined), value: signal });
			series.histogram.push({ x: klineData[idx].openTime, y: fixedAsNumber(histogram, 2, undefined), value: histogram });
		}
	);

	series.macd.forEach(
		({ x, y }, idx) => {
			const macd = series.macd[idx]?.y;
			const twoAgoMacd = series.macd[idx - 2]?.y;
			const prevMacd = series.macd[idx - 1]?.y;
			const nextMacd = series.macd[idx + 1]?.y;

			const isMacdSlopeDown = idx < 1 ? false
				: idx < series.macd.length - 2 ? nextMacd - prevMacd < 0
					: macd - twoAgoMacd < 0 && macd <= prevMacd;
			const isMacdSlopeUp = idx < 1 ? false
				: idx < series.macd.length - 2 ? nextMacd - prevMacd > 0
					: macd - twoAgoMacd > 0 && macd >= prevMacd;

			const signal = series.signal[idx]?.y;
			const twoAgoSignal = series.signal[idx - 2]?.y;
			const prevSignal = series.signal[idx - 1]?.y;
			const nextSignal = series.signal[idx + 1]?.y;

			const isSignalSlopeDown = idx < 1 ? false
				: idx < series.signal.length - 2 ? nextSignal - prevSignal < 0
					: signal - twoAgoSignal < 0 && signal <= prevSignal;
			const isSignalSlopeUp = idx < 1 ? false
				: idx < series.signal.length - 2 ? nextSignal - prevSignal > 0
					: signal - twoAgoSignal > 0 && signal >= prevSignal;

			const histogram = series.histogram[idx]?.y;
			const prevHistogram = series.histogram[idx - 1]?.y;

			series.prediction.push({
				isMacdHeadingDown: prevMacd > 0 && isMacdSlopeDown,
				isMacdHeadingUp: prevMacd < 0 && isMacdSlopeUp,
				isSignalHeadingDown: prevSignal > 0 && isSignalSlopeDown,
				isSignalHeadingUp: prevSignal < 0 && isSignalSlopeUp,
				isHistogramHeadingDown: prevHistogram > 0 && histogram < prevHistogram,
				isHistogramHeadingUp: prevHistogram < 0 && histogram > prevHistogram,
			});
		}
	);

	return series;
};

export default getMacdLines;
