import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useGetPendingOrdersV2Query } from 'api/client';

export default function PendingOrders(props) {
  const {
    data: pendingOrders = [],
    isLoading: pendingOrdersIsLoading,
    // error: pendingOrdersError,
  } = useGetPendingOrdersV2Query({
    ...props.symbol ? { symbol: props.symbol } : {},
  }, {
    pollingInterval: 1000 * 60,
  });

  return pendingOrdersIsLoading ? (<Spinner />) : (
    <table className="stripe-table responsive">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Side</th>
          <th>Price</th>
          <th>Quantity</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {pendingOrders.map(order => (
          <tr key={order.orderId}>
            <td>{order.symbol}</td>
            <td>{order.side}</td>
            <td>{order.price}</td>
            <td>{order.quantity}</td>
            <td>{order.time}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
};