import { Buffer } from 'buffer';
import { ungzip } from 'pako';
import { useEffect, useMemo, useCallback } from 'react';
import { ReadyState } from 'react-use-websocket';

export default function useHandleSubs(props) {
	const {
		subs = [],
		setSubs,
		subsToRemove = [],
		setSubsToRemove,
		subsToAdd = [],
		setSubsToAdd,
		readyState,
		sendMessage,
		formatSubForMessage,
		dataTypeRegex,
		lastMessageData,
		lastMessageDataStr,
		setLastMessageDataStr,
	} = props;

	// Log which pairs need to be subscribed to, removed, and reset main pairs state
	const setSubsProxy = useCallback((newSubs = []) => {
		const dedupedNewSubs = [...new Set(newSubs)];

		setSubsToRemove(
			subs.filter(p => !dedupedNewSubs.includes(p))
		);

		setSubsToAdd(
			dedupedNewSubs.filter(np => !subs.includes(np))
		);

		setSubs(dedupedNewSubs);
	}, [
		subs,
		setSubs,
		setSubsToAdd,
		setSubsToRemove,
	]);

	// Subscribe and remove subscriptions to pairs
	useEffect(() => {
		if (ReadyState[readyState] === 'OPEN') {
			if (subsToRemove.length > 0) {
				// console.log('REMOVING', subsToRemove.join(', '));
				[...new Set(subsToRemove)].forEach(p => sendMessage(JSON.stringify({
					id: p,
					reqType: 'unsub',
					dataType: formatSubForMessage(p), //ex. BTC-USDT -> BTC-USDT@trade
				})));

				typeof setSubsToRemove === 'function' && setSubsToRemove([]);
			}

			if (subsToAdd.length > 0) {
				// console.log('SUBSCRIBING', subsToAdd.join(', '));
				[...new Set(subsToAdd)].forEach(p => sendMessage(JSON.stringify({
					id: p,
					reqType: 'sub',
					dataType: formatSubForMessage(p), //ex. BTC-USDT -> BTC-USDT@trade
				})));
			}
		} else if (ReadyState[readyState] === 'CONNECTING') {
			typeof setSubsToAdd === 'function' && setSubsToAdd(subs);
		}
	}, [
		subs,
		subsToRemove,
		setSubsToRemove,
		subsToAdd,
		setSubsToAdd,
		readyState,
		sendMessage,
		formatSubForMessage,
	]);

	// Decompress data, handle ping/pong, and store it if not ping
	useEffect(() => {
		if (lastMessageData) {
			const fr = new FileReader();

			fr.onload = () => {
				const decompressed = ungzip(new Uint8Array(fr.result));
				const asStr = Buffer.from(decompressed.buffer).toString();

				if (asStr.toLowerCase() === 'ping') {
					// console.log('RESPONDING TO PING');
					sendMessage('Pong');
				} else {
					setLastMessageDataStr(asStr);
				}
			};

			fr.readAsArrayBuffer(lastMessageData);
		}
	}, [
		lastMessageData,
		setLastMessageDataStr,
		sendMessage,
	]);

	// Some weird issue on mobile when browser is closed causes readyState
	// to stay 'OPEN' but the subs dissapear. So we never go through to
	// connecting state which would re-sub. Solution: re-sub every 2 seconds
	// if no new data comes in.
	const memoizedSubs = useMemo(() => JSON.stringify(subs), [subs]);
	useEffect(() => {
		let interval;

		const lastMessageDataStrInvalid = !lastMessageDataStr;
		const dataTypeInvalid = !lastMessageDataStr || !dataTypeRegex.test(JSON.parse(lastMessageDataStr)?.dataType || '');

		if (
			lastMessageDataStrInvalid ||
			dataTypeInvalid
		) {
			interval = setInterval(() => {
				const subs = JSON.parse(memoizedSubs);

				if (subs.length > 0) {
					setSubsToAdd([
						...new Set(subs)
					]);
				}
			}, 2000);
		}

		return () => clearTimeout(interval);
	}, [
		lastMessageDataStr,
		dataTypeRegex,
		memoizedSubs,
		setSubsToAdd
	]);

	// useEffect(() => {
	// 	const interval = setInterval(() => {
	// 		setSubsToAdd([...new Set(subs)]);
	// 	}, 2000);

	// 	return () => clearInterval(interval);
	// }, [
	// 	subs,
	// 	setSubsToAdd
	// ]);

	return setSubsProxy;
};
