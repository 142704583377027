export const color = {
	"aqua": "#00BCD4",
	"black": "#363A45",
	"blue": "#2196F3",
	"fuchsia": "#E040FB",
	"gray": "#787B86",
	"green": "#4CAF50",
	"lime": "#00E676",
	"maroon": "#880E4F",
	"navy": "#311B92",
	"olive": "#808000",
	"orange": "#FF9800",
	"purple": "#9C27B0",
	"red": "#FF5252",
	"silver": "#B2B5BE",
	"teal": "#00897B",
	"white": "#FFFFFF",
	"yellow": "#FFEB3B"
};
