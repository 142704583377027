import React from "react";
import Meta from "components/Meta";
import PositionsSection from "components/PositionsSection";
import { requireAuth } from "util/auth";

function PositionsPage(props) {
  return (
    <>
      <Meta title="Positions" />
      <PositionsSection
      	bg="light"
      	textColor="dark"
      	size="sm"
      	title="Positions"
      	subtitle=""
      />
    </>
  );
}

export default requireAuth(PositionsPage);
