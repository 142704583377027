export default function queryConstructor(params = {}) {
	const queryStr = Object.entries(params).filter(
		([key, val]) => Array.isArray(val)
			? val.length > 0 ? val.join(',')
				: false
			: !!val || val === 0 || val === -1 || typeof val === 'boolean'
	).map(
		([key, val]) => `${key}=${val}`
	).join('&');

	return queryStr;
};
