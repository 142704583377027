import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { useGetPendingOrdersV2Query } from 'api/client';
import { PENDING_ORDERS_POLLING } from 'constants/bingxSwaps';

export default function useGetPendingOrders({
	symbol,
	side,
	positionAvgPrice = 0,
	positionAmt = 0,
	modifiedOrdersData,
	tentativeOrder,
} = {}) {
	/* QUERIES */
	/** Pending orders **/
	const {
		data: _pendingOrders = [],
		refetch: refetchOrders,
	} = useGetPendingOrdersV2Query({
		symbol,
		side,
	}, {
		pollingInterval: PENDING_ORDERS_POLLING,
	});

	// if we have useAccountDataWs active with 'ORDER_TRADE_UPDATE' as one of
	// the events, then when an order is pulled, it gets set to null since
	// there is no other way to remove an item from the cache; so, we must
	// filter it out.
	const pendingOrders = uniqBy(
		_pendingOrders.filter(po => !!po),
		'orderId'
	);

	/* DATA */
	const transformedPendingOrders = [
		...pendingOrders,
		...tentativeOrder ? [tentativeOrder] : [],
	].map(
		po => ({
			_orig: po,
			...po,
			...modifiedOrdersData[po.orderId] || {},
		})
	).map(
		po => {
			const isInProfit = (side === 'short' && positionAvgPrice >= po.price) ||
				(side === 'long' && po.price >= positionAvgPrice) ? 1 : -1;

			return {
				...po,
				totalProfit: Math.abs(
					(positionAvgPrice * po.origQty) - (po.price * po.origQty)
				) * isInProfit,
			}
		}
	).sort(
		(a, b) => b.price - a.price
	);

	// Some values only relevant to openOrders
	const openOrders = transformedPendingOrders.filter(
		po => po.action === 'OPEN'
	).map(
		(po, idx, arr) => {
			function calculateAvgPrice(totalAmt, avgPrice, orderPrice, orderQty) {
				const newTotalAmt = totalAmt + orderQty;
				const newAvgPrice = sum([
					avgPrice * totalAmt / (totalAmt + orderQty),
					orderPrice * orderQty / (totalAmt + orderQty),
				]);

				return {
					newTotalAmt,
					newAvgPrice
				};
			}

			let priorOrders;

			if (side === 'short') {
				// Array order is Price is high -> low, and SHORTS open in order from low -> high.
				// So, we need all the SHORTS opening before this one, i.e. the SUBSEQUENT items in the array.
				// These need to then be reversed so the price order is low -> high.
				priorOrders = [...arr.slice(idx + 1, arr.length).reverse(), po];
			} else {
				// Array order is Price is high -> low, and LONGS open in order from high -> low.Ž
				// So, we need all the LONGS opening before this one, i.e. the PREVIOUS items in the array.
				// No reverse, since the price order is already high -> low.
				priorOrders = [...arr.slice(0, idx), po];
			}

			const { newAvgPrice } = priorOrders.reduce(
				(acc, order) => {
					const {
						newTotalAmt,
						newAvgPrice,
					} = calculateAvgPrice(
						acc.newTotalAmt,
						acc.newAvgPrice,
						order.price,
						order.origQty
					);

					return {
						newTotalAmt,
						newAvgPrice,
					};
				}, {
					newTotalAmt: positionAmt,
					newAvgPrice: positionAvgPrice,
				}
			);

			const newAvgPricePositionAvgPriceDiff = newAvgPrice - positionAvgPrice;

			return {
				...po,
				newAvgPrice,
				newAvgPricePositionAvgPriceDiff,
			};
		}
	);

	// Some values only relevant to closeOrders
	const closeOrders = transformedPendingOrders.filter(
		po => po.action === 'CLOSE'
	).map(
		(po, idx, arr) => {
			const orderPercentOfPosition = (po.origQty || 0) / positionAmt * 100;

			return {
				...po,
				percentOfPosition: orderPercentOfPosition,
			}
		}
	);

	return {
		openOrders,
		closeOrders,
		refetchOrders,
	};
};
