import React from "react";
import Meta from "components/Meta";
import StatsSection from "components/StatsSection";

function StatsPage(props) {
  return (
    <>
      <Meta title="Stats" />
      <StatsSection
        bg="light"
        textColor="dark"
        size="sm"
        title="Stats"
        subtitle=""
      />
    </>
  );
}

export default StatsPage;
