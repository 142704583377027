import React, { useState, useEffect, useMemo, useReducer, useRef, useCallback } from "react";
import clsx from 'clsx';
import Cookies from 'js-cookie';
import xor from 'lodash/xor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight, faCheck, faTimes, faPlus } from '@fortawesome/pro-solid-svg-icons';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import Toast from 'react-bootstrap/Toast';
import CryptoIcon from 'components/CryptoIcon';
import Flex from "components/Flex";
import Popup from "components/Popup";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Toggle from "components/Toggle";
import { AccountBalanceCard } from 'components/StatsSection/StatsSection';
import LongShortRatioChart from 'components/LongShortRatioChart';
// import PastOrders from 'components/Orders/PastOrders';
import {
	// Queries
	useGetPositionsV2Query,
	useGetAccountBalanceV2Query,
	useGetAllContractsV2Query,
	useGetFeeRateQuery,
	useGetFundingRateV2Query,
} from 'api/client';
import useMarketDepthWs from 'api/websockets/bingxSwaps/useMarketDepthWs';
import { POLLING_INTERVAL, supportedSymbols } from 'constants/bingxSwaps';
import useIsMobile from 'hooks/useIsMobile';
import { getDefaultError } from 'util/error';
import { commaFormat, currencyFormat, millifyCurrency } from 'util/numbers';
import { useHistory, useLocation } from 'util/router';
import NewPairTradePopup from './NewPairTradePopup';
import './BulkSection.scss';
import usePlaceBulkOrders from "./hooks/usePlaceBulkOrders";
import { STOP_LOSS_OPS } from 'components/PositionSectionNew/constants';

const DEFAULT_AMOUNT_PER_ORDER = 25;
const DEFAULT_CLOSE_PER_ORDER = 10;
const DEFAULT_MODIFY_AMOUNT_BY = 1.25;
const POSITION_SIDES = ['all', 'short', 'long'];
const WINNERS_OR_LOSERS = ['all', 'winners', 'losers'];
const INIT_FILTERS_REDUCER = {
	positionSizeFilter: {
		filter: false,
		value: 0,
	},
	pnlSizeFilter: {
		filter: false,
		value: 0,
		negative: false,
	}
};
const FILTER_OPS = ['<', '>'];
const PERIODS = ['5m', '15m', '30m', '1h', '4h', '12h', '1d'];
const MAIN_SYMBOLS = ['BTC', 'ETH', 'MATIC', 'SOL'];

function filtersReducer(state, { which, value }) {
	return which === 'reset' ? INIT_FILTERS_REDUCER : {
		...state,
		[which]: value,
	}
};

export default function BulkSection(props) {
	const history = useHistory();
	const location = useLocation();
	const [activeSymbols, setActiveSymbols] = useState(new URLSearchParams(location.search).get('symbols')?.split?.(',') || []);
	const [positionSide, setPositionSide] = useState(new URLSearchParams(location.search).get('side') || 'all');
	const [winnersOrLosers, setWinnersOrLosers] = useState(new URLSearchParams(location.search).get('winners') || 'all');
	const [isOpeningOrders, setIsOpeningOrders] = useState(new URLSearchParams(location.search).get('opening') !== 'false');
	const [modifyAmountBy, setModifyAmountBy] = useState(
		!isNaN(Cookies.get('bulk_order_amount-modifyAmountBy')) ? Cookies.get('bulk_order_amount-modifyAmountBy')
			: DEFAULT_MODIFY_AMOUNT_BY
	);
	const [amountPerOrder, setAmountPerOrder] = useState({});
	const [percToClose, setPercToClose] = useState(DEFAULT_CLOSE_PER_ORDER);
	const [closePerOrder, setClosePerOrder] = useState({});
	const [stopLossValue, setStopLossValue] = useState(null);
	const [showOrderSuccess, setShowOrderSuccess] = useState(false);
	const [showOrderError, setShowOrderError] = useState(false);
	const [showNewPairTradePopup, setShowNewPairTradePopup] = useState(false);
	const [openOnEnoughMargin, setOpenOnEnoughMargin] = useState(false); //if ticked, auto open 1 order set on next time hasEnoughMargin is true
	const [autoOpenCounter, setAutoOpenCounter] = useState(0);
	const [preventKillOrder, setPreventKillOrder] = useState(true); //if true, don't close order if % close would kill it
	const [longShortRatioPeriod, setLongShortRatioPeriod] = useState(PERIODS[0]);
	const longShortRatioChartRef = useRef();
	const longShortRatioChartContainerRef = useRef();
	const newPairTradePopupRef = useRef();
	const symbolsInitialized = useRef(false);
	const isMobile = useIsMobile();

	const [
		{
			positionSizeFilter,
			pnlSizeFilter,
		},
		dispatchFilters
	] = useReducer(filtersReducer, INIT_FILTERS_REDUCER);

	// Handle searchParams state
	useEffect(() => {
		const params = new URLSearchParams({
			side: positionSide,
			winners: winnersOrLosers,
			opening: !!isOpeningOrders,
			symbols: activeSymbols.join(','),
		});

  	history.replace({ pathname: location.pathname, search: params.toString() });
	}, [
		positionSide,
		winnersOrLosers,
		isOpeningOrders,
		activeSymbols,
		history,
		location?.pathname
	]);

	// QUERIES
	const {
		data: positions = [],
		isLoading: positionsIsLoading,
		refetch: positionsRefetch,
		isError: positionsIsError,
		error: positionsError,
	} = useGetPositionsV2Query({
		includeCurrentPrice: false
	}, {
  	pollingInterval: POLLING_INTERVAL,
  });

  const {
  	data: accountBalance = {},
  } = useGetAccountBalanceV2Query({}, {
  	pollingInterval: POLLING_INTERVAL,
  });

  const {
  	data: allContracts = [],
  } = useGetAllContractsV2Query();

  const {
  	data: fundingRates = [],
  	refetch: fundingRatesRefetch,
  } = useGetFundingRateV2Query();

  // DATA
  const fundingRatesMap = fundingRates.reduce(
  	(acc, fr) => {
  		acc[fr.symbol] = fr;
  		return acc;
  	}, {}
  );

  const [
  	latestPrices = {},
  	setLatestTradeSubs,
  ] = useMarketDepthWs();

	const allSymbols = useMemo(() => {
		if (positionsIsLoading || !positions) return [];

		const symbols = positions?.map?.(
			p => getSymbol(p.symbol)
		).sort();

		setLatestTradeSubs(
			symbols.map(
				s => `${s}-USDT`
			)
		);

		return symbols;
	// eslint-disable-next-line
	}, [positionsIsLoading, positions]);

	// Set all symbols (or url search param symbols) on initial load
	useEffect(() => {
		if (!positionsIsLoading && !symbolsInitialized.current) {
			symbolsInitialized.current = true;

			const urlSymbols = new URLSearchParams(location.search).get('symbols').split(',');
			const symbolsToSetActive = urlSymbols.length > 0 && allSymbols.some(s => urlSymbols.includes(s)) ? allSymbols.filter(
				s => urlSymbols.includes(s)
			) : allSymbols;

			setActiveSymbols([...new Set(symbolsToSetActive)]);

			setAmountPerOrder(
				allSymbols.reduce(
					(acc, s) => ({
						...acc,
						[s]: Number(
							Cookies.get(`bulk_order_amount-${s}`) ??
							supportedSymbols[s]?.amountToOpen ??
							DEFAULT_AMOUNT_PER_ORDER
						),
					}), {}
				)
			);

			setClosePerOrder(
				allSymbols.reduce(
					(acc, s) => ({
						...acc,
						[s]: DEFAULT_CLOSE_PER_ORDER,
					}), {}
				)
			);
		}
	}, [
		positionsIsLoading,
		location?.search,
		allSymbols
	]);

	// Auto refetch funding rates on expiry
	useEffect(() => {
		const symbolsSet = new Set(allSymbols);

		if (
			fundingRates.some(
				fr => symbolsSet.has(fr.symbol.split('-')[0]) && new Date().getTime() > fr.nextFundingTime
			)
		) {
			fundingRatesRefetch();
		}
	}, [
		fundingRates,
		allSymbols,
		fundingRatesRefetch,
	]);

	const positionsFiltered = useMemo(() => {
		if (!positions) return [];

		return positions.filter(
			p => {
				const positionSize = Number(p.positionAmt) * Number(latestPrices[p?.symbol]?.price || 1);

				return activeSymbols.includes(getSymbol(p.symbol)) &&
					(positionSide === 'all' || p.positionSide.toLowerCase() === positionSide) &&
					(
						winnersOrLosers === 'all' ? true
							: winnersOrLosers === 'winners' ? Number(p.unrealizedProfit) >= 0
								: Number(p.unrealizedProfit < 0)
					) &&
					(
						positionSizeFilter.filter === '>' ? positionSize >= positionSizeFilter.value
							: positionSizeFilter.filter === '<' ? positionSize <= positionSizeFilter.value
								: true
					) &&
					(
						pnlSizeFilter.filter === '>' ? Number(p.unrealizedProfit) >= (pnlSizeFilter.value * (pnlSizeFilter.negative ? -1 : 1))
							: pnlSizeFilter.filter === '<' ? Number(p.unrealizedProfit) <= (pnlSizeFilter.value * (pnlSizeFilter.negative ? -1 : 1))
								: true
					)
			}
		).sort(
			(a, b) => a.symbol.localeCompare(b.symbol)
		);
	}, [
		positions,
		activeSymbols,
		positionSide,
		winnersOrLosers,
		latestPrices,
		positionSizeFilter,
		pnlSizeFilter,
	]);

	const [
		totalRealisedProfit,
		totalUnrealizedProfit,
		totalCombinedProfit,
		totalPositionSize,
	] = useMemo(() => {
		const {
			totalRealisedProfit,
			totalUnrealizedProfit,
			totalPositionSize,
		} = positionsFiltered.reduce(
			(acc, p) => {
				acc.totalRealisedProfit += Number(p.realisedProfit);
				acc.totalUnrealizedProfit += Number(p.unrealizedProfit);
				acc.totalPositionSize += Number(p.positionAmt) * Number(latestPrices[p?.symbol]?.price || 1);
				return acc;
			}, {
				totalRealisedProfit: 0,
				totalUnrealizedProfit: 0,
				totalPositionSize: 0,
			}
		);

		return [
			totalRealisedProfit,
			totalUnrealizedProfit,
			totalRealisedProfit + totalUnrealizedProfit,
			totalPositionSize,
		];
	}, [
		positionsFiltered,
		latestPrices,
	]);

	const totalAmountToOpen = useMemo(() => {
		return positionsFiltered.reduce(
			(acc, p) => {
				acc += amountPerOrder[getSymbol(p.symbol)] || 0;
				return acc;
			}, 0
		);
	}, [
		positionsFiltered,
		amountPerOrder
	]);

	const [
		requiredMargin,
		availableMargin,
		hasEnoughMargin,
		totalProfitPercentage,
	] = useMemo(() => {
		const requiredMargin = positionsFiltered.reduce(
			(acc, p) => {
				const symbol = getSymbol(p.symbol);
				const margin = amountPerOrder[symbol]/p.leverage;
				return acc += margin;
			}, 0
		);

		const totalProfitPercentage = Math.abs(totalUnrealizedProfit) / accountBalance.balance * 100;

		return [
			requiredMargin,
			accountBalance?.availableMargin,
			requiredMargin <= accountBalance?.availableMargin,
			totalProfitPercentage,
		];
	}, [
		positionsFiltered,
		amountPerOrder,
		accountBalance?.availableMargin,
		accountBalance?.balance,
		totalUnrealizedProfit,
	]);

	const resetAmountPerOrderToDefault = useCallback(() => {
		setModifyAmountBy(DEFAULT_MODIFY_AMOUNT_BY);
		Cookies.set('bulk_order_amount-modifyAmountBy', DEFAULT_MODIFY_AMOUNT_BY);

		setAmountPerOrder(
			allSymbols.reduce(
				(acc, s) => {
					const value = Number(
						supportedSymbols[s]?.amountToOpen ??
						DEFAULT_AMOUNT_PER_ORDER
					);
					Cookies.set(`bulk_order_amount-${s}`, value);

					return {
						...acc,
						[s]: value,
					};
				}, {}
			)
		);
	}, [allSymbols]);

	// Show totalUnrealizedProfit totalCombinedProfit in tab
	useEffect(() => {
		const title = `Bulk: ${currencyFormat(totalUnrealizedProfit, '-')} | ${currencyFormat(totalCombinedProfit, '-')}`;
		document.title = title;
	}, [totalUnrealizedProfit, totalCombinedProfit]);

	// MUTATIONS
	const {
		placeBulkOrders,
		placeBulkOrdersResult,
		placeBulkOrdersResultIsUnfinished,
	} = usePlaceBulkOrders({
		positionsRefetch,
		setShowOrderError,
		setShowOrderSuccess,
		isOpeningOrders,
		positionsFiltered,
		amountPerOrder,
		closePerOrder,
		latestPrices,
		allContracts,
		preventKillOrder,
		stopLossValue,
	});

	useEffect(() => {
		if (
			isOpeningOrders &&
			hasEnoughMargin &&
			openOnEnoughMargin &&
			!placeBulkOrdersResultIsUnfinished
		) {
			setAutoOpenCounter(prev => {
				if (prev > 0) {
					console.log('Auto placing order - openOnEnoughMargin');
					placeBulkOrders('Open');

					if (prev === 1) {
						setOpenOnEnoughMargin(false);
					}

					return prev - 1;
				}
			});
		}
	}, [
		isOpeningOrders,
		hasEnoughMargin,
		openOnEnoughMargin,
		placeBulkOrdersResultIsUnfinished,
		placeBulkOrders
	]);


	// UI
	return (
		<>
			{positionsIsError && (
				<Alert variant="danger">
					<Flex direction="column" align="center" className="w-100">
						<span>{getDefaultError(positionsError)}</span>
					</Flex>
				</Alert>
			)}

			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			  className="BulkSection"
			>
			  <Container fluid className="px-lg-4 px-xl-5">
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>
			  	{positionsIsLoading ? (
			  		<Flex justify="center" className="pt-4">
			  			<Spinner animation="border" variant="primary" />
			  		</Flex>
			  	) : (
			  		<>
					  	<Row className="justify-content-center">
					  		{/*Account summary | Utils card*/}
					  		<Col xs={12} lg={4} xl={3}>
					  			<Flex className="UtilsWrapper">
					  				{!isMobile && (
						  				<FiltersCard
						  					positionSizeFilter={positionSizeFilter}
						  					pnlSizeFilter={pnlSizeFilter}
						  					dispatchFilters={dispatchFilters}
						  				/>
					  				)}
					  				<UtilsCard
					  					positionSide={positionSide}
					  					setPositionSide={setPositionSide}
					  					winnersOrLosers={winnersOrLosers}
					  					setWinnersOrLosers={setWinnersOrLosers}
					  					preventKillOrder={preventKillOrder}
					  					setPreventKillOrder={setPreventKillOrder}
					  					openOnEnoughMargin={openOnEnoughMargin}
					  					setOpenOnEnoughMargin={setOpenOnEnoughMargin}
					  					autoOpenCounter={autoOpenCounter}
					  					setAutoOpenCounter={setAutoOpenCounter}
					  					modifyAmountBy={modifyAmountBy}
					  					setModifyAmountBy={setModifyAmountBy}
					  					requiredMargin={requiredMargin}
					  					availableMargin={availableMargin}
					  					hasEnoughMargin={hasEnoughMargin}
					  					totalAmountToOpen={totalAmountToOpen}
					  					totalUnrealizedProfit={totalUnrealizedProfit}
					  					totalPositionSize={totalPositionSize}
					  					isOpeningOrders={isOpeningOrders}
					  					setIsOpeningOrders={(op) => {
					  						if (op === 'Close') setOpenOnEnoughMargin(false);
					  						setIsOpeningOrders(op === 'Open')
					  					}}
					  					setAmountPerOrder={setAmountPerOrder}
					  					percToClose={percToClose}
					  					setPercToClose={setPercToClose}
					  					setClosePerOrder={setClosePerOrder}
					  					placeBulkOrders={placeBulkOrders}
					  					placeBulkOrdersResult={placeBulkOrdersResult}
											placeBulkOrdersResultIsUnfinished={placeBulkOrdersResultIsUnfinished}
					  					resetAmountPerOrderToDefault={resetAmountPerOrderToDefault}
											stopLossValue={stopLossValue}
											setStopLossValue={setStopLossValue}
					  				/>
					  				{isMobile && (
						  				<FiltersCard
						  					positionSizeFilter={positionSizeFilter}
						  					pnlSizeFilter={pnlSizeFilter}
						  					dispatchFilters={dispatchFilters}
						  				/>
					  				)}
						  			<AccountBalanceCard />
					  			</Flex>
					  		</Col>
					  		<Col xs={12} lg={8} xl={9} className="mt-3 mt-lg-0">
					  			<Row className="justify-content-center">
							  		{/*Symbols selector | Positions table*/}
							  		<Col xs={12} xl={9}>
							  			{/*Symbols selector*/}
							  			<Flex
							  				justify="between"
							  				align="center"
							  				className="mb-2"
							  			>
							  				<Flex
							  					className="w-fit"
							  					style={{
							  						overflowX: 'scroll',
							  					}}
							  				>
							  					{/*All/Main selector*/}
							  					<Toggle
							  						ops={['all', 'main']}
							  						active={[
							  							...xor(activeSymbols, allSymbols).length === 0 ? [
							  								'all'
							  							] : [],
							  							...xor(activeSymbols, MAIN_SYMBOLS).length === 0 ? [
							  								'main'
							  							] : [],
							  						]}
							  						onOpClick={(op) => {
							  							switch(op) {
							  								case 'all':
							  									setActiveSymbols(
									  								allSymbols.length === activeSymbols.length ? []
									  									: allSymbols
									  							);
							  									break;
							  								case 'main':
							  									setActiveSymbols(
							  										xor(activeSymbols, MAIN_SYMBOLS).length === 0 ? allSymbols.filter(
							  											s => !MAIN_SYMBOLS.includes(s)
							  										) : MAIN_SYMBOLS
							  									);
							  									break;
							  								default:
							  									return;
							  							}
							  						}}
							  						className="mr-2"
							  					/>
							  					{/*Symbols selector*/}
									  			<Toggle
									  				ops={[...new Set(allSymbols)]}
									  				active={activeSymbols}
									  				setActive={op => setActiveSymbols(
									  					prev => prev.includes(op) ? prev.filter(symbol => symbol !== op) : [...prev, op].sort()
									  				)}
									  				className="w-fit"
									  				style={{
									  					maxWidth: '100%',
									  					overflowX: 'scroll',
									  				}}
									  			/>
							  				</Flex>
							  				{/*New trade symbols list popup*/}
								  			<Button
								  				variant="primary"
								  				size="xs"
								  				className="ml-2 py-1.5"
								  				onClick={() => {
								  					setShowNewPairTradePopup(true);
								  					newPairTradePopupRef.current.openPopup()
								  				}}
								  			>
								  				<FontAwesomeIcon icon={faPlus} />
								  			</Button>
							  			</Flex>
							  			{/*Positions table*/}
							  			<Card className="shadow noscroll">
							  				<Card.Header className="Header d-flex justify-content-between">
							  					<Row className="no-gutters w-100">
							  						<Col xs={12} lg={3}>
							  							<span>Active positions</span>
							  						</Col>
							  						<Col xs={12} lg={9} className="text-left text-md-right">
									  					<Flex
									  						className="justify-content-start justify-content-lg-end"
									  					>
									  						<span className="text-gray-700">{currencyFormat(totalPositionSize)}</span>
									  						<span className="px-2 text-gray-400">|</span>
									  						<Flex
									  							align="center"
									  							className="w-fit"
									  						>
									  							<Flex
									  								direction="column"
									  								className="f-rem-0.7 mr-1.5 text-left text-lg-right"
									  								style={{lineHeight: 1}}
									  							>
									  								<span
									  									className={clsx({
												  							'text-danger': totalRealisedProfit < 0,
												  							'text-success': totalRealisedProfit >= 0,
												  						})}
									  								>
									  									{currencyFormat(totalRealisedProfit)}
									  								</span>
									  								<span
									  									className={clsx({
												  							'text-danger': totalCombinedProfit < 0,
												  							'text-success': totalCombinedProfit >= 0,
												  						})}
									  								>
									  									{currencyFormat(totalCombinedProfit)}
									  								</span>
									  							</Flex>
											  					<span
											  						className={clsx({
											  							'text-danger': totalUnrealizedProfit < 0,
											  							'text-success': totalUnrealizedProfit >= 0,
											  						})}
											  					>
											  						{currencyFormat(totalUnrealizedProfit)}
											  					</span>
									  						</Flex>
										  					<span className="px-2 text-gray-400">|</span>
										  					<span
										  						className={clsx({
										  							'text-danger': totalUnrealizedProfit < 0,
										  							'text-success': totalUnrealizedProfit >= 0,
										  						})}
										  					>
										  						{commaFormat(
										  							totalProfitPercentage * (totalUnrealizedProfit < 0 ? -1 : 1),
										  							2
										  						)}%
										  					</span>
									  					</Flex>
							  						</Col>
							  					</Row>
							  				</Card.Header>
							  				<Card.Body>
							  					{positionsFiltered.map(p => (
							  						<PositionRow
							  							key={p.positionId}
							  							position={p}
							  							currentPrice={latestPrices[p?.symbol]?.price || 0}
							  							contract={allContracts.find(c => c.symbol === p?.symbol)}
							  							fundingRate={fundingRatesMap[p?.symbol]}
							  							isOpeningOrders={isOpeningOrders}
							  							amountPerOrder={
							  								amountPerOrder[getSymbol(p.symbol)] ??
							  								supportedSymbols[getSymbol(p.symbol)]?.amountToOpen ??
							  								DEFAULT_AMOUNT_PER_ORDER
							  							}
							  							setAmountPerOrder={setAmountPerOrder}
							  							closePerOrder={
							  								closePerOrder[getSymbol(p.symbol)] ??
							  								DEFAULT_CLOSE_PER_ORDER
							  							}
							  							setClosePerOrder={setClosePerOrder}
							  							modifyAmountBy={modifyAmountBy}
							  							setActiveSymbols={setActiveSymbols}
							  							placeBulkOrdersResult={placeBulkOrdersResult}
															placeBulkOrdersResultIsUnfinished={placeBulkOrdersResultIsUnfinished}
							  							hasEnoughMargin={hasEnoughMargin}
							  							placeBulkOrders={placeBulkOrders}
							  							isMobile={isMobile}
							  						/>
							  					))}
							  				</Card.Body>
							  				<Card.Footer className="d-flex justify-content-between align-items-center">
							  					<Flex className="flex-column flex-md-row">
							  						{isOpeningOrders ? (
							  							<>
									  						<span>Total to open: {currencyFormat(totalAmountToOpen)}</span>
									  						<span className="px-2 text-gray-400 d-none d-md-block">|</span>
									  						<span className="text-gray-600">({currencyFormat(requiredMargin)} req, {currencyFormat(availableMargin)} avail)</span>
									  					</>
							  						) : (
							  							<>
							  								<span>Total to close: {currencyFormat(totalPositionSize * percToClose / 100)}</span>
							  								<span className="px-2 text-gray-400 d-none d-md-block">|</span>
							  								<Flex>
							  									<span>Profit:&nbsp;</span>
								  								<span
																		className={clsx({
																			'text-success': totalUnrealizedProfit >= 0,
																			'text-danger': totalUnrealizedProfit < 0
																		})}
																	>
																		{currencyFormat(totalUnrealizedProfit * percToClose / 100)}
																	</span>
																</Flex>
																<span className="px-2 text-gray-400 d-none d-md-block">|</span>
																<span className="text-gray-600">After close: {currencyFormat(totalPositionSize - (totalPositionSize * percToClose / 100))}</span>
							  							</>
							  						)}
							  					</Flex>
							  					<Button
							  					  variant={isOpeningOrders ?
							  					  	!hasEnoughMargin ? 'secondary' : 'primary'
							  					  	: 'danger'
							  					 	}
							  					  size="sm"
							  					  disabled={
							  					  	placeBulkOrdersResultIsUnfinished ? true
							  					  		: isOpeningOrders ? !hasEnoughMargin
							  					  			: false
							  					 	}
							  					  onClick={() => {
							  					  	if (!placeBulkOrdersResultIsUnfinished) {
							  					  		if (isOpeningOrders) {
							  					  			if (hasEnoughMargin) {
							  					  				placeBulkOrders('Open');
							  					  			}
							  					  		} else {
							  					  			placeBulkOrders('Close');
							  					  		}
							  					  	}
							  					  }}
							  					>
							  						{placeBulkOrdersResultIsUnfinished ? (
							  							<Spinner animation="border" variant="white" size="sm" />
							  						) : isOpeningOrders
							  								? !hasEnoughMargin ? 'Not enough margin' : 'Open orders'
							  								: 'Close orders'
							  						}
							  					</Button>
							  				</Card.Footer>
							  			</Card>
							  		</Col>
							  		{/*Long short ratio chart | Past orders */}
							  		<Col xs={12} xl={3} className="mt-3 mt-xl-0">
							  			<Flex
							  				justify="between"
							  				align="center"
							  				className="mb-2"
							  			>
							  				<Flex
							  					className="w-fit"
							  					style={{
							  						overflowX: 'scroll',
							  					}}
							  				>
									  			<Toggle
									  				ops={PERIODS}
									  				active={longShortRatioPeriod}
									  				setActive={setLongShortRatioPeriod}
									  			/>
									  		</Flex>
								  			<Button
								  				variant="primary"
								  				size="xs"
								  				className="ml-2 py-1.5"
								  				onClick={() => longShortRatioChartRef.current.refetch()}
								  			>
								  				<FontAwesomeIcon icon={faArrowRotateRight} />
								  			</Button>
							  			</Flex>
							  			<div
							  				ref={longShortRatioChartContainerRef}
							  				className="rounded noscroll"
							  			>
								  			<LongShortRatioChart
								  				ref={longShortRatioChartRef}
								  				containerRef={longShortRatioChartContainerRef}
								  				symbol="BTCUSDT"
								  				period={longShortRatioPeriod}
								  				height={278}
								  			/>
							  			</div>

											{/* Past orders */}
											{/* <Card className="shadow mt-3">
												<Card.Header className="px-3 py-2">Past orders</Card.Header>
												<Card.Body className="p-0 scroll-x">
													<PastOrders limit={25} />
												</Card.Body>
											</Card> */}
							  		</Col>
							  	</Row>
						  	</Col>
					  	</Row>
			  		</>
			  	)}
			  </Container>
			</Section>

			<ToastOnOrderResult
				showOrderSuccess={showOrderSuccess}
				setShowOrderSuccess={setShowOrderSuccess}
				showOrderError={showOrderError}
				setShowOrderError={setShowOrderError}
			/>

			<Popup
				ref={newPairTradePopupRef}
				title="New Trade"
				size="sm"
				afterClose={() => {
					setShowNewPairTradePopup(false);
					positionsRefetch();
				}}
			>
				{showNewPairTradePopup && (
					<NewPairTradePopup
						closePopup={() => newPairTradePopupRef.current.closePopup()}
					/>
				)}
			</Popup>
		</>
	);
};


// Components
export const AmountInput = ({value, defaultValue, onReduce, onIncrease, onChange, className = ""}) => {
	return (
		<InputGroup className={`AmountInput ${className}`.trim()}>
			<InputGroup.Text
				className="f-rem-0.85 pointer"
				onClick={onReduce}
			>
				-
			</InputGroup.Text>
			<Form.Control
				aria-label="USDT Amount per Order"
				type="number"
				min="20"
				onChange={onChange}
				className="f-rem-0.85 text-center"
				{...value || typeof value === 'number' ? { value } : { defaultValue }}
			/>
			<InputGroup.Text
				className="f-rem-0.85 pointer"
				onClick={onIncrease}
			>
				+
			</InputGroup.Text>
		</InputGroup>
	);
};

const UtilsCard = ({
	positionSide,
	setPositionSide,
	winnersOrLosers,
	setWinnersOrLosers,
	preventKillOrder,
	setPreventKillOrder,
	openOnEnoughMargin,
	setOpenOnEnoughMargin,
	autoOpenCounter,
	setAutoOpenCounter,
	hasEnoughMargin,
	totalAmountToOpen,
	totalUnrealizedProfit,
	totalPositionSize,
	modifyAmountBy,
	setModifyAmountBy,
	requiredMargin,
	availableMargin,
	isOpeningOrders,
	setIsOpeningOrders,
	setAmountPerOrder,
	percToClose,
	setPercToClose,
	setClosePerOrder,
	placeBulkOrders,
	placeBulkOrdersResult,
	placeBulkOrdersResultIsUnfinished,
	resetAmountPerOrderToDefault,
	stopLossValue,
	setStopLossValue,
}) => {
	const { data: feeRate = {} } = useGetFeeRateQuery();
	const [closeToMin, setCloseToMin] = useState(false);

	useEffect(() => {
		if (percToClose < 100) {
			setCloseToMin(false);
		}
	}, [percToClose]);

	const reqMarginPercOfAvail = useMemo(() => {
		return Math.round(
			requiredMargin / availableMargin * 10000
		) / 100;
	}, [requiredMargin, availableMargin]);

	const [
		totalToClose = 0,
		totalProfit = 0,
		feesToClose = 0,
	] = useMemo(() => {
		if (isOpeningOrders) return [];

		const totalToClose = totalPositionSize * percToClose / 100;
		const totalProfit = totalUnrealizedProfit * percToClose / 100;
		const feesToClose = totalToClose * feeRate.taker;

		return [
			totalToClose,
			totalProfit,
			feesToClose
		];
	}, [
		isOpeningOrders,
		totalPositionSize,
		totalUnrealizedProfit,
		percToClose,
		feeRate?.taker,
	]);

	const feesToOpen = useMemo(() => {
		return !isOpeningOrders ? 0 : totalAmountToOpen * feeRate.taker;
	}, [
		isOpeningOrders,
		totalAmountToOpen,
		feeRate?.taker,
	]);

	return (
		<Card className="UtilsCard shadow noscroll mt-0 mt-lg-3">
			<Card.Body className="px-3">
				<Toggle
					ops={['Open', 'Close']}
					active={isOpeningOrders ? 'Open' : 'Close'}
					setActive={setIsOpeningOrders}
				/>
				<Toggle
					ops={POSITION_SIDES.map(p => formatPositionSide(p.toUpperCase()))}
					active={formatPositionSide(positionSide.toUpperCase())}
					setActive={(op) => setPositionSide(op.toLowerCase())}
					activeClassName={positionSide === 'long' ? 'text-success'
						: positionSide === 'short' ? 'text-danger'
						: ''
					}
					className="mt-2"
				/>
				<Toggle
					ops={WINNERS_OR_LOSERS}
					active={winnersOrLosers}
					setActive={(op) => setWinnersOrLosers(op)}
					activeClassName={winnersOrLosers === 'winners' ? 'text-success'
						: winnersOrLosers === 'losers' ? 'text-danger'
						: ''
					}
					className="mt-2"
				/>
				{isOpeningOrders && (
					<Flex
						justify="between"
						align="center"
						className="mt-2"
					>
						<Form.Check
							custom
							type="checkbox"
							id="openOnEnoughMarginCheckbox"
							label="Auto open"
							checked={openOnEnoughMargin}
							onChange={() => setOpenOnEnoughMargin(prev => {
								if (!prev) {
									setAutoOpenCounter(autoOpenPrev => autoOpenPrev < 1 ? 1 : autoOpenPrev);
								}

								return !prev;
							})}
						/>
						<InputGroup
							style={{
								width: '78px',
								height: '28px'
							}}
						>
							<Form.Control
								aria-label="Auto open"
								type="number"
								min="0"
								value={autoOpenCounter}
								onChange={(e) => {
									const asInt = Number(e.target.value);
									setAutoOpenCounter(asInt);
								}}
								className="f-rem-0.85 h-100"
							/>
						</InputGroup>
					</Flex>
				)}
				<hr className="my-1.5" />

				{isOpeningOrders && (
					<Flex direction="column">
						<Form.Check
							custom
							type="checkbox"
							id="stop-loss"
							label="Stop Loss"
							checked={stopLossValue}
							onChange={() => setStopLossValue(prev => !prev ? STOP_LOSS_OPS[1] : null)}
						/>
						{stopLossValue && (
							<Toggle
								className="scroll-x mt-1 mb-1.5"
								labelClassName="f-rem-0.85 fw5 mt-2"
								opClassName="f-rem-0.85"
								label="$ Loss Per Order"
								ops={STOP_LOSS_OPS}
								opFormatDisplay={op => millifyCurrency(op, false, { precision: 2 })}
								active={op => op === stopLossValue}
								setActive={op => setStopLossValue(op)}
							/>
						)}
					</Flex>
				)}
				<hr className="my-2" />

				{isOpeningOrders ? (
					//Inc/Dec amount to open
					<Form.Group className="mb-0">
						<Flex justify="between" align="center">
							<Form.Label className="f-rem-0.85 fw5">
								<span className="text-gray-700">USDT Multiplyer per Order</span>
							</Form.Label>
							<FontAwesomeIcon
								icon={faArrowRotateRight}
								className="reset-to-default-amounts-icon"
								onClick={resetAmountPerOrderToDefault}
							/>
						</Flex>
						<AmountInput
							value={modifyAmountBy}
							onReduce={() => setAmountPerOrder(
								prev => Object.keys(prev).reduce(
									(acc, symbol) => {
										const newAmount = Math.max(
											Number(
												prev[symbol] ??
												Cookies.get(`bulk_order_amount-${symbol}`) ??
												supportedSymbols[symbol]?.amountToOpen ??
												DEFAULT_AMOUNT_PER_ORDER
											) * (modifyAmountBy < 1 ? modifyAmountBy : (1/modifyAmountBy)),
											0
										);

										const roundedAmount = Math.round(newAmount);

										acc[symbol] = roundedAmount;
										Cookies.set(`bulk_order_amount-${symbol}`, roundedAmount);
										return acc;
									}, {}
								)
							)}
							onIncrease={() => setAmountPerOrder(
								prev => Object.keys(prev).reduce(
									(acc, symbol) => {
										const newAmount = Number(
											prev[symbol] ??
											Cookies.get(`bulk_order_amount-${symbol}`) ??
											supportedSymbols[symbol]?.amountToOpen ??
											DEFAULT_AMOUNT_PER_ORDER
										) * (modifyAmountBy < 1 ? (1 + modifyAmountBy) : modifyAmountBy);

										const roundedAmount = Math.round(newAmount);

										acc[symbol] = roundedAmount;
										Cookies.set(`bulk_order_amount-${symbol}`, roundedAmount);
										return acc;
									}, {}
								)
							)}
							onChange={(e) => {
								const asInt = Number(e.target.value);
								Cookies.set('bulk_order_amount-modifyAmountBy', asInt);
								setModifyAmountBy(asInt);
							}}
						/>
						<Button
						  variant={!hasEnoughMargin ? 'secondary' : 'primary'}
						  size="sm"
						  className="w-100 mt-2.5"
						  disabled={placeBulkOrdersResultIsUnfinished || !hasEnoughMargin}
						  onClick={
						  	() => !placeBulkOrdersResultIsUnfinished &&
						  		hasEnoughMargin &&
						  		placeBulkOrders('Open')
						  }
						>
							{placeBulkOrdersResultIsUnfinished ? (
								<Spinner animation="border" variant="white" size="sm" />
							) : !hasEnoughMargin ? 'Not enough margin'
								: 'Open orders'
							}
						</Button>
						<Flex className="flex-column mt-2.5 border rounded px-2 py-1">
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Total to open:</span>
								<span>{currencyFormat(totalAmountToOpen)}</span>
							</Flex>
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Fees:</span>
								<span className="text-danger">-{currencyFormat(feesToOpen)}</span>
							</Flex>
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Required:</span>
								<span className="text-gray-600">
									<small className="mr-1">({reqMarginPercOfAvail}%)</small>
									{currencyFormat(requiredMargin)}
								</span>
							</Flex>
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Available:</span>
								<span className="text-gray-600">{currencyFormat(availableMargin)}</span>
							</Flex>
  					</Flex>
					</Form.Group>
				) : (
					//Perc to close
					<Form.Group className="mb-0">
						<Form.Label className="w-100 d-flex justify-content-between f-rem-0.85 fw5">
							<span className="text-gray-700">Bulk Close</span>
							<span>{percToClose}%</span>
						</Form.Label>
						<Form.Control
							className="BulkCloseRangeSlider"
							type="range"
							custom={true}
							value={percToClose}
							onChange={e => {
								const newPercToClose = Math.floor(Number(e.target.value));

								setPercToClose(newPercToClose);

								setClosePerOrder(
									prev => Object.keys(prev).reduce(
										(acc, s) => ({
											...acc,
											[s]: newPercToClose
										}), {}
									)
								);
							}}
							onDoubleClick={e => {
								const newPercToClose = Math.round(Number(e.target.value)/10)*10;

								setPercToClose(newPercToClose);

								setClosePerOrder(
									prev => Object.keys(prev).reduce(
										(acc, s) => ({
											...acc,
											[s]: newPercToClose
										}), {}
									)
								);
							}}
						/>
						<Form.Check
							custom
							type="checkbox"
							id="preventKillCheckbox"
							label="Prevent kill"
							checked={preventKillOrder}
							onChange={() => setPreventKillOrder(prev => !prev)}
						/>
						{percToClose === 100 && (
							<Form.Check
								custom
								type="checkbox"
								id="closeToMinCheckbox"
								label="Close to min"
								checked={closeToMin}
								onChange={() => setCloseToMin(prev => !prev)}
							/>
						)}
						<Button
						  variant="danger"
						  size="sm"
						  className="w-100 mt-2"
						  disabled={placeBulkOrdersResultIsUnfinished}
						  onClick={
							  () => !placeBulkOrdersResultIsUnfinished && placeBulkOrders('Close', { closeToMin })
							}
						>
							{placeBulkOrdersResultIsUnfinished ? (
								<Spinner animation="border" variant="white" size="sm" />
							) : closeToMin ? 'Close to min' : 'Close'}
						</Button>
						<Flex className="flex-column mt-2.5 border rounded px-2 py-1">
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Total to close:</span>
								<span>{currencyFormat(totalToClose)}</span>
							</Flex>
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Fees:</span>
								<span className="text-danger">
									-{currencyFormat(feesToClose)}
								</span>
							</Flex>
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Profit:</span>
								<span
									className={clsx({
										'text-success': totalUnrealizedProfit >= 0,
										'text-danger': totalUnrealizedProfit < 0
									})}
								>
									{currencyFormat(totalProfit)}
								</span>
							</Flex>
							<Flex justify="between">
								<span className="f-rem-0.9 text-gray-700">Profit after fees:</span>
								<span
									className={clsx({
										'text-success': totalProfit - feesToClose >= 0,
										'text-danger': totalProfit - feesToClose < 0
									})}
								>
									{totalProfit - (feesToClose * 2) > 0 ? (<FontAwesomeIcon icon={faCheck} className="mr-1.5" />) : ''}{currencyFormat(totalProfit - feesToClose)}
								</span>
							</Flex>
							<Flex justify="between" className="text-gray-600">
								<span className="f-rem-0.9">After close:</span>
								<span>{currencyFormat(totalPositionSize - (totalToClose))}</span>
							</Flex>
  					</Flex>
					</Form.Group>
				)}
			</Card.Body>
		</Card>
	);
};

const FiltersCard = ({positionSizeFilter, pnlSizeFilter, dispatchFilters}) => {
	return (
		<Card className="UtilsCard shadow noscroll mt-0 mt-lg-3">
			<Card.Header>
				<Flex
					justify="between"
					align="center"
				>
					<span>Filters</span>
					<FontAwesomeIcon
						icon={faArrowRotateRight}
						className="reset-to-default-amounts-icon pb-0"
						onClick={() => {
							dispatchFilters({which: 'reset'});
						}}
					/>
				</Flex>
			</Card.Header>
			<Card.Body className="px-3 pt-2.5">
				{[
					{label: 'Pos Size', filterKey: 'positionSizeFilter', filter: positionSizeFilter},
					{label: 'PNL', filterKey: 'pnlSizeFilter', filter: pnlSizeFilter}
				].map(({label, filterKey, filter}, i) => (
					<Flex
						key={label}
						direction="column"
						className={clsx({
							'mt-2.5': i > 0
						})}
					>
						<Flex
							justify="between"
							align="center"
							className="mb-1.5"
						>
							<span>{label}</span>
							<Flex
								justify="end"
								align="center"
							>
								{filterKey === 'pnlSizeFilter' && (
									<Toggle
										ops={['-']}
										active={filter.negative ? '-' : ''}
										setActive={op => {
											dispatchFilters({which: filterKey, value: {
												...filter,
												negative: filter.negative ? op !== '-' : op === '-'
											}})
										}}
										opClassName="px-2"
										className="mr-1"
									/>
								)}
								<Toggle
									ops={FILTER_OPS}
									active={filter.filter}
									setActive={op => {
										dispatchFilters({which: filterKey, value: {
											...filter,
											filter: filter.filter === op ? false : op,
										}});
									}}
									opClassName="px-2"
									className="h-fit"
								/>
							</Flex>
						</Flex>
						<AmountInput
							value={filter.value}
							onChange={(e) => {
								dispatchFilters({which: filterKey, value: {
									...filter,
									value: Number(e.target.value || 0),
								}});
							}}
							onReduce={(e) => {
								e.stopPropagation();

								dispatchFilters({which: filterKey, value: {
									...filter,
									value: filter.value - 100,
								}});
							}}
							onIncrease={(e) => {
								e.stopPropagation();

								dispatchFilters({which: filterKey, value: {
									...filter,
									value: filter.value + 100,
								}});
							}}
						/>
					</Flex>
				))}
			</Card.Body>
		</Card>
	);
};

const PositionRow = ({
	position,
	currentPrice,
	contract,
	fundingRate,
	isOpeningOrders,
	amountPerOrder,
	setAmountPerOrder,
	closePerOrder,
	setClosePerOrder,
	modifyAmountBy,
	setActiveSymbols,
	placeBulkOrdersResult,
	placeBulkOrdersResultIsUnfinished,
	hasEnoughMargin,
	placeBulkOrders,
	isMobile
}) => {
	const history = useHistory();
	const hasCustomAmount = useRef(false);
	const { positionSide = '' } = position;
	const { pricePrecision = 2 } = contract || {};

	const {
		symbol,
		unrealizedProfit,
		realizedProfit,
		// margin,
		unrealizedProfitPercentage,
	} = useMemo(() => {
		// const profit = Number(position.realisedProfit) + Number(position.unrealizedProfit);
		const unrealizedProfit = Number(position.unrealizedProfit);
		const realizedProfit = Number(position.realisedProfit);
		const margin = Number(position?.initialMargin) - Number(position?.unrealizedProfit);
		const unrealizedProfitPercentage = Math.abs(unrealizedProfit) / margin * 100;

		return {
			symbol: getSymbol(position?.symbol),
			unrealizedProfit: unrealizedProfit,
			realizedProfit: realizedProfit,
			// margin: margin,
			unrealizedProfitPercentage: unrealizedProfitPercentage,
		};
	}, [
		position?.symbol,
		position?.initialMargin,
		position?.realisedProfit,
		position?.unrealizedProfit,
	]);

	const positionIsInBulkOrder = placeBulkOrdersResult?.originalArgs?.orders?.some?.(
		o => o?.symbol?.toLowerCase() === position?.symbol?.toLowerCase() &&
			o?.positionSide?.toLowerCase() === positionSide?.toLowerCase()
	);

	const positionIsLoadingInBulkOrder = placeBulkOrdersResult?.isLoading && positionIsInBulkOrder;
	const positionIsSuccessInBulkOrder = !placeBulkOrdersResult?.isLoading && positionIsInBulkOrder && placeBulkOrdersResult?.data?.orders?.some?.(
		o => o?.symbol?.toLowerCase() === position?.symbol?.toLowerCase()
	);
	const positionIsErrorInBulkOrder = !placeBulkOrdersResult?.isLoading && positionIsInBulkOrder && placeBulkOrdersResult?.data?.orders?.every?.(
		o => o?.symbol?.toLowerCase() !== position?.symbol?.toLowerCase()
	);

	const lastFundingRate = Number(fundingRate?.lastFundingRate);
	const isPayingFundingRate = positionSide === 'SHORT' ? lastFundingRate < 0 : lastFundingRate > 0;
	const realFundingRate = Number(position?.availableAmt) * Number(fundingRate?.markPrice) * lastFundingRate * (isPayingFundingRate ? -1 : 1);

	// Handle click vs double click
	const clickCount = useRef(0);
	const clickTimeout = useRef();
	const handleClick = useCallback((e) => {
		clickCount.current += 1;

		if (clickCount.current === 1) {
			clickTimeout.current = setTimeout(() => {
				clickCount.current = 0;

				if (
					!isMobile &&
					!positionIsLoadingInBulkOrder &&
					e.target.nodeName !== 'INPUT'
				) {
					setActiveSymbols(prev => prev.filter(s => s !== symbol))
				}
			}, 250);
		} else if (clickCount.current === 2) {
			clearTimeout(clickTimeout.current);
			clickCount.current = 0;
			history.push(`/positions/new/${position?.symbol}?side=${positionSide?.toLowerCase?.()}`);
		}
	}, [
		isMobile,
		positionIsLoadingInBulkOrder,
		position?.symbol,
		positionSide,
		symbol,
		history,
		setActiveSymbols,
	]);

	return (
		<Row
			className="PositionRow align-items-center"
			onClick={handleClick}
		>
			{/* Symbol, position side-leverage */}
			<Col xs={3} md={2}>
				<Flex direction="column">
					<Flex align="center" className="mb-0.5">
						<CryptoIcon
							symbol={symbol}
	  	  			width={18}
	  	  			height={18}
	  	  			className="mr-1 show-shadow"
						/>
						<span className="fw6">{symbol}</span>
					</Flex>
					<span
						className={clsx('f-rem-0.8 fw5 mt-n1', {
							'text-danger': positionSide === 'SHORT',
							'text-success': positionSide !== 'SHORT',
						})}
					>
						{positionSide?.charAt?.(0)?.toLowerCase?.()}{position.leverage}x
					</span>
					<span
						className={clsx('f-rem-0.8 fw5', {
							'text-danger': isPayingFundingRate,
							'text-success': !isPayingFundingRate
						})}
						style={{lineHeight: 1.1}}
					>
						{commaFormat(lastFundingRate * 100, 3)}% ({currencyFormat(realFundingRate)})
					</span>
				</Flex>
			</Col>
			{/* Margin, Avg price, Current price */}
			<Col xs={6} md={3}>
				<Flex direction="column" align="center" className="align-items-md-start">
					{/* Margin */}
					<span className="text-gray-700 fw5 text-truncate">
						{currencyFormat(
							Number(position?.positionAmt) * Number(currentPrice || 1)
						)}
					</span>
					{/* Avg price */}
					<Flex direction={isMobile ? 'column' : 'row'} align="center" className="mt-n1">
						<span className="text-gray-600 f-rem-0.8 fw5">
							${commaFormat(position?.avgPrice, pricePrecision, '0')}
						</span>
						{!isMobile && (<span className="px-1 text-gray-400">|</span>)}
						<span className="text-gray-500 f-rem-0.8 fw5 mt-n1 mt-md-0">
							${commaFormat(currentPrice, pricePrecision, '0')}
						</span>
					</Flex>
				</Flex>
			</Col>
			{/*unrealizedProfit, unrealizedProfit perc, realizedProfit*/}
			<Col xs={3} md={2}>
				<Flex
					direction="column"
					align="end"
					className={clsx('align-items-md-start', {
						'text-danger': unrealizedProfit < 0,
						'text-success': unrealizedProfit >= 0,
					})}
				>
					<span className="text-truncate">{currencyFormat(unrealizedProfit)}</span>
					<span className="f-rem-0.8 mt-n1">
						({commaFormat(
							unrealizedProfitPercentage * (unrealizedProfit < 0 ? -1 : 1), 2
						)}%)
					</span>
					<span
						className={clsx('f-rem-0.8 text-truncate', {
							'text-danger': realizedProfit < 0,
							'text-success': realizedProfit >= 0,
						})}
						style={{
							marginTop: '-3px'
						}}
					>
						({currencyFormat(realizedProfit)})
					</span>
				</Flex>
			</Col>
			{/*Open/close amounts*/}
			<Col xs={12} md={4} className="ml-auto mt-2 mt-md-0">
				{isOpeningOrders ? (
					<Flex>
						<AmountInput
							value={amountPerOrder}
							onReduce={(e) => {
								e.stopPropagation();

								const newAmount = Math.max(
									Number(
										amountPerOrder ??
										Cookies.get(`bulk_order_amount-${symbol}`) ??
										supportedSymbols[symbol]?.amountToOpen ??
										DEFAULT_AMOUNT_PER_ORDER
									) * (modifyAmountBy < 1 ? modifyAmountBy : (1/modifyAmountBy)),
									0
								);

								const roundedAmount = Math.round(newAmount);

								setAmountPerOrder(prev => ({
									...prev,
									[symbol]: roundedAmount,
								}));

								Cookies.set(`bulk_order_amount-${symbol}`, roundedAmount);
							}}
							onIncrease={(e) => {
								e.stopPropagation();

								const newAmount = Number(
									amountPerOrder ??
									Cookies.get(`bulk_order_amount-${symbol}`) ??
									supportedSymbols[symbol]?.amountToOpen ??
									DEFAULT_AMOUNT_PER_ORDER
								) * (modifyAmountBy < 1 ? (1 + modifyAmountBy) : modifyAmountBy);

								const roundedAmount = Math.round(newAmount);

								setAmountPerOrder(prev => ({
									...prev,
									[symbol]: roundedAmount,
								}));

								Cookies.set(`bulk_order_amount-${symbol}`, roundedAmount);
							}}
							onChange={(e) => {
								hasCustomAmount.current = true;

								const asInt = Number(e.target.value);
								const newAmount = Math.max(
									isNaN(asInt) ? 0 : asInt,
									0
								);

								setAmountPerOrder(prev => ({
									...prev,
									[symbol]: newAmount,
								}));

								Cookies.set(`bulk_order_amount-${symbol}`, newAmount);
							}}
						/>
						<Button
							variant={clsx({
								'secondary': !hasEnoughMargin,
								'warning': hasEnoughMargin && positionIsErrorInBulkOrder,
								'success': hasEnoughMargin && positionIsSuccessInBulkOrder,
								'primary': hasEnoughMargin,
							})}
						  size="sm"
							disabled={placeBulkOrdersResultIsUnfinished || !isOpeningOrders ? false : !hasEnoughMargin}
						  onClick={(e) => {
						  	e.stopPropagation();

					  		placeBulkOrders('Open', {
					  			positionsOverride: [position]
					  		});
						  }}
						  className="ml-2 px-2.5"
						>
							{positionIsLoadingInBulkOrder ? (
								<Spinner animation="border" variant="white" size="xs" />
							) : positionIsSuccessInBulkOrder ? (
								<FontAwesomeIcon icon={faCheck} className="text-white" />
							) : positionIsErrorInBulkOrder ? (
								<FontAwesomeIcon icon={faTimes} className="text-white" />
							) : 'O'}
						</Button>
					</Flex>
				) : (
					<Flex className="w-100">
						<Flex direction="column" justify="center" align="center" className="w-100">
							<Form.Group className="mb-0 w-100" style={{height: '32px'}}>
								<Form.Control
									className="PositionCloseRangeSlider"
									type="range"
									custom={true}
									value={closePerOrder}
									onChange={e => {
										const newPercToClose = Math.floor(Number(e.target.value));

										setClosePerOrder(
											prev => Object.keys(prev).reduce(
												(acc, s) => ({
													...acc,
													[s]: s === symbol ? newPercToClose : prev[s]
												}), {}
											)
										);
									}}
									onDoubleClick={e => {
										const newPercToClose = Math.round(Number(e.target.value)/10)*10;

										setClosePerOrder(
											prev => Object.keys(prev).reduce(
												(acc, s) => ({
													...acc,
													[s]: s === symbol ? newPercToClose : prev[s]
												}), {}
											)
										);
									}}
								/>
							</Form.Group>
							<Flex justify="between" className="f-rem-0.85 fw5 w-100">
								<span>{closePerOrder}%</span>
								<span className="text-gray-600 fw4">({
									currencyFormat(
										(closePerOrder/100) * Number(position.positionAmt) * currentPrice
									)
								})</span>
							</Flex>
						</Flex>
						<Button
							variant={clsx({
								'danger': !positionIsErrorInBulkOrder && !positionIsSuccessInBulkOrder,
								'warning': positionIsErrorInBulkOrder,
								'success': positionIsSuccessInBulkOrder,
							})}
						  size="sm"
						  disabled={placeBulkOrdersResultIsUnfinished}
						  onClick={(e) => {
						  	e.stopPropagation();

					  		placeBulkOrders('Close', {
					  			positionsOverride: [position]
					  		});
						  }}
						  className="ml-3 px-2.5 align-self-center"
							style={{height: '32px'}}
						>
							{positionIsLoadingInBulkOrder ? (
								<Spinner animation="border" variant="white" size="xs" />
							) : positionIsSuccessInBulkOrder ? (
								<FontAwesomeIcon icon={faCheck} className="text-white" />
							) : positionIsErrorInBulkOrder ? (
								<FontAwesomeIcon icon={faTimes} className="text-white" />
							) : 'C'}
						</Button>
					</Flex>
				)}
			</Col>
		</Row>
	);
};

const ToastOnOrderResult = ({showOrderSuccess, setShowOrderSuccess, showOrderError, setShowOrderError}) => {
	return (
		<>
			{/*Order success*/}
			<Toast
				onClose={() => setShowOrderSuccess(false)}
				show={!!showOrderSuccess}
				delay={3000}
				autohide
				style={{
					position: 'fixed',
					top: 16,
					right: 16,
				}}
			>
				<Toast.Header>
					<strong className="mr-auto text-success">Success</strong>
				</Toast.Header>
				<Toast.Body>
					{showOrderSuccess}
				</Toast.Body>
			</Toast>
			{/*Order error*/}
			<Toast
				onClose={() => setShowOrderError(false)}
				show={showOrderError}
				delay={3000}
				autohide
				style={{
					position: 'fixed',
					top: 16,
					right: 16,
				}}
			>
				<Toast.Header>
					<strong className="mr-auto text-danger">Failed</strong>
				</Toast.Header>
				<Toast.Body>{getDefaultError(showOrderError)}</Toast.Body>
			</Toast>
		</>
	);
};


// Utils
function formatPositionSide(positionSide) {
	return `${positionSide.charAt(0)}${positionSide.slice(1).toLowerCase()}`;
};

function getSymbol(symbol) {
	return symbol?.split('-')[0];
};
