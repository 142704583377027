import max from 'lodash/max';
import min from 'lodash/min';

export default function doji(data = []) {
	const marker = (prevIsUp) => ({
		position: prevIsUp ? 'aboveBar' : 'belowBar',
		color: prevIsUp ? 'red' : 'green',
		shape: prevIsUp ? 'arrowDown' : 'arrowUp',
		text: 'Doji',
		// text: prevIsUp ? 'BearDoji' : 'BullDoji',
	});

	for (let i = 1; i < data?.length; i++) {
		const current = data[i];
		const prev = data[i-1];

		const { open, high, low, close } = current || {};
		const { open: prevOpen, close: prevClose } = prev || {};
		const prevIsUp = prevOpen < prevClose;

		const doji =
			Math.abs(close - open) / (high - low) < 0.1 &&
			high - max([close, open]) > 3 * Math.abs(close - open) &&
			min([close, open]) - low > 3 * Math.abs(close - open);

		if (doji) {
			const color = prevIsUp ? 'red' : 'green';

			current.doji = true;
			current.dojiType = prevIsUp ? 'bearish' : 'bullish';
			current.color = color;
			current.borderColor = color;
			current.wickColor = color;
			current.marker = {
				...marker(prevIsUp),
				time: current.time,
			}
		}
	}

	return data;
};
