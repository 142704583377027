export default function bearishEngulfing(data = [], color = 'red') {
	const marker = {
		position: "aboveBar",
    color: color,
    shape: "arrowDown",
    text: "BearEng"
	};

	for (let i = 0; i < data?.length; i++) {
		if (i >= 3) {
			const prevThreeAreUp =
				data?.[i-3]?.open < data?.[i-3]?.close &&
				data?.[i-2]?.open < data?.[i-2]?.close &&
				data?.[i-1]?.open < data?.[i-1]?.close;

			if (prevThreeAreUp) {
				const current = data[i];
				const prev = data[i-1];

				const { open, close } = current || {};
				const { open: prevOpen, close: prevClose } = prev || {};

				const isBearishEngulfing =
					open > close &&
					open >= prevClose &&
					prevOpen >= close &&
					open - close > prevClose - prevOpen;

				if (isBearishEngulfing) {
					current.bearishEngulfing = true;
					current.color = color;
					current.borderColor = color;
					current.wickColor = color;
					current.marker = {
						...marker,
						time: current.time,
					}
				}
			}
		}
	}

	return data;
};
