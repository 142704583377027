import { useState, useEffect } from 'react';
import { useGetSpotSymbolPriceTickerQuery } from 'api/client';

function useStreamLatestPrice(props) {
	const [price, setPrice] = useState(0);
	const [prevPrice, setPrevPrice] = useState(0);
	const { symbol, frequency = 1000 } = props;

	const {
		data: _price = 0,
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	} = useGetSpotSymbolPriceTickerQuery({
		symbol
	}, {
		...frequency ? {
			pollingInterval: typeof frequency === 'number' ? frequency : 1000,
		} : {},
		skip: !symbol,
	});

	useEffect(() => {
		setPrice(prev => {
			setPrevPrice(prev);
			return _price;
		})
	}, [_price]);

	return {
		price,
		prevPrice,
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	}
};

export default useStreamLatestPrice;
