import { LineStyle } from 'lightweight-charts';
import { color } from 'constants/tradingview';
import { millifyCurrency, removeZerosAfterDecimal } from 'util/numbers';

export default function useGetPriceLines({symbol, side, position, orders = [], mouseEnterOrder, currentPrice, additionalPriceLines = []}) {
	const { breakEvenPrice, avgPrice = 0, positionAmt, positionValue, liquidationPrice } = position;
	const currentValue = currentPrice * positionAmt;
	const unrealizedProfit = positionValue - currentValue;
	const totalProfit = (unrealizedProfit * (side === 'short' ? 1 : -1));

	/*** LINES ***/
	const priceLines = [];

	if (avgPrice) {
		priceLines.push({
			title: `E (${millifyCurrency(totalProfit, true)})`,
			price: avgPrice,
			color: !avgPrice || !currentPrice ? color.green
				: side === 'short'
					? avgPrice >= currentPrice ? color.green : color.orange
					: avgPrice <= currentPrice ? color.green : color.orange,
			lineStyle: LineStyle.LargeDashed,
		});
	}

	if (breakEvenPrice) {
		priceLines.push({
			title: 'BE',
			price: breakEvenPrice,
			lineStyle: LineStyle.LargeDashed,
			color: !currentPrice ? color.green
				: side === 'short'
					? breakEvenPrice >= currentPrice ? color.green : color.orange
					: breakEvenPrice <= currentPrice ? color.green : color.orange
		});
	}

	if (liquidationPrice) {
		priceLines.push({
			title: 'LIQ',
			price: liquidationPrice,
			lineStyle: LineStyle.LargeDashed,
			color: Math.abs(currentPrice - liquidationPrice) / currentPrice < 0.05
				? Math.round(Date.now() / 1000) % 2 === 0 ? color.red : color.orange //flash
				: color.red,
		});
	}

	priceLines.push(
		...orders.map(
			po => {
				let title = `${po.actionShort}`;

				if (po.action === 'CLOSE') {
					const percentage = (po.origQty / positionAmt * 100).toFixed(1);
					title += ` ${removeZerosAfterDecimal(percentage)}%`;

					const isInProfit = (side === 'short' && avgPrice >= po.price) ||
						(side === 'long' && po.price >= avgPrice) ? 1 : -1;

					const profit = Math.abs(
						(avgPrice * po.origQty) - (po.price * po.origQty)
					) * isInProfit;

					title += ` ${millifyCurrency(profit, true)}`;
				} else {
					title += ` ${millifyCurrency((po.origQty - po.executedQty) * po.price)}`;
				}

				return {
					title,
					price: po.price,
					color: mouseEnterOrder === po.orderId ? color.blue
						: po.isTentativeOrder ? color.purple
						: po.executedQty > 0 ? color.orange
							: po.action === 'CLOSE' ?
								po.actionShort === 'SL' ? 
									po.totalProfit >= 0 ? color.lime
										: color.fuchsia
								: color.green
							: color.red,
					type: 'order',
					action: po.action,
					qty: po.origQty,
					order: po,
				};
			}
		)
	);

	return [
		...priceLines,
		...additionalPriceLines
	];
};
