import max from 'lodash/max';
import min from 'lodash/min';

export default function shootingStar(data = [], color = 'red') {
	const marker = {
		position: "aboveBar",
    color: color,
    shape: "arrowDown",
    text: "ShootStar"
	};

	for (let i = 1; i < data?.length; i++) {
		const current = data[i];
		const prev = data[i-1];

		const { open, high, low, close } = current || {};
		const { open: prevOpen, close: prevClose } = prev || {};

		const shootingStar =
			prevOpen < prevClose &&
			prevClose < open &&
			high - max([open, close]) >= 3 * Math.abs(open - close) &&
			min([close, open]) - low <= Math.abs(open - close);

		if (shootingStar) {
			current.shootingStar = true;
			current.color = color;
			current.borderColor = color;
			current.wickColor = color;
			current.marker = {
				...marker,
				time: current.time,
			}
		}
	}

	return data;
};
