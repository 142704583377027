import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import millify from 'millify';
import sum from 'lodash/sum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from "react-bootstrap/Table";
import Flex from 'components/Flex';
import SlashDivider from 'components/SlashDivider';
import ToastOnResult from 'components/ToastOnResult';
import Toggle from 'components/Toggle';
import {
	// Mutations
	useCancelOrdersV2Mutation,
	useModifyOrdersMutation,
} from 'api/client';
import { commaFormat, removeZerosAfterDecimal, millifyCurrency } from 'util/numbers';
import useGetOrdersTotals from 'components/PositionSectionNew/hooks/useGetOrdersTotals';
import { PositionContext, CLOSE_ORDERS_TABLE_HEADERS, ORDER_TYPE_COLOR } from 'components/PositionSectionNew/constants';

const MODIFY_ORDERS_QUICK_ACTIONS = ['equalize', 'round'];
const MODIFY_ORDERS_QUICK_PERCENTS = [10, 25, 50, 75, 100];

function CloseOrders(props) {
	const { symbol, position, closeOrders: _closeOrders, dispatchContext } = useContext(PositionContext);
	const [{showBody, showModOrdersPerc}, setShow] = useState({showBody: true, showModOrdersPerc: false});
	const [modOrdersQtyPerc, setModOrdersQtyPerc] = useState(null);

	/* DATA */
	const closeOrders = _closeOrders.filter(
		order => order.actionShort === props.actionShort
	);
	const { positionAmt = 0, avgPrice = 0, realisedProfit = 0 } = position;

	const {
		totalOrdersAmt,
		totalOrdersValue,
		totalProfitFromOrders,
	} = useGetOrdersTotals({
		orders: closeOrders,
		positionAvgPrice: avgPrice,
	});

	/* CALLBACKS */
	const handleToggleShowMod = useCallback(() => {
		if (showModOrdersPerc) {
			// We need to clear the modified origQty values before hiding orders
			dispatchContext({
				type: 'modifiedOrdersData',
				payload: closeOrders.reduce(
					(acc, order) => {
						acc[order.orderId] = {
							origQty: 'DELETE',
						};

						return acc;
					}, {}
				)
			});
		}

		// Reset to null so when toggled again, it auto sets to default value equal to current orders total percentage
		setModOrdersQtyPerc(null);

		setShow(prev => ({
			...prev,
			showModOrdersPerc: !showModOrdersPerc,
		}));
	}, [
		showModOrdersPerc,
		dispatchContext,
		closeOrders,
	]);

	/* MUTATIONS */
	/** Modify Orders Mutation **/
	const [modifyOrders, modifyOrdersResult] = useModifyOrdersMutation();

	useEffect(() => {
		modifyOrdersResult.isError && console.error('useModifyOrdersMutation error', modifyOrdersResult.error);
		if (modifyOrdersResult.isSuccess) {
			modifyOrdersResult.reset();
			handleToggleShowMod();
		}
	}, [
		modifyOrdersResult,
		handleToggleShowMod,
	]);

	/** Modify Orders Mutation **/
	const [cancelOrders, cancelOrdersResult] = useCancelOrdersV2Mutation();

	useEffect(() => {
		cancelOrdersResult.isError && console.error('useCancelOrdersV2Mutation error', cancelOrdersResult.error);
	}, [
		cancelOrdersResult,
	]);

	/* CALLBACKS */
	const handleApplyModOrders = useCallback(() => {
		const orders = closeOrders.filter(
			order => !order.isTentativeOrder &&
				order.origQty !== order?._orig?.origQty //only orders that qty changed
		).map(
			order => ({
				orderId: order.orderId,
				symbol: order.symbol,
				type: order.type,
				side: order.side,
				positionSide: order.positionSide,
				quantity: order.origQty,
				price: order.price,
				...order.type === 'STOP_MARKET' ? {
					stopPrice: order.stopPrice,
					stopGuaranteed: 'false',
				} : {},
				// ...order.type === 'STOP_MARKET' && order.stopGuaranteed === 'true' ? {
				// 	workingType: order.workingType,
				// 	stopGuaranteed: order.stopGuaranteed,
				// } : {},
			})
		);

		modifyOrders({ symbol, orders });
	}, [
		symbol,
		closeOrders,
		modifyOrders,
	]);

	/* UI */
	const title = props.actionShort === 'SL' ? 'Stop Loss' : 'Take Profit';

	return (
		<>
			<Card className="CloseOrders shadow">
				<Card.Header
					className={clsx('d-flex flex-column py-2 px-3', {
						'border-bottom-0 overflow-hidden': !showBody,
					})}
					onDoubleClick={() => setShow(prev => ({
						...prev,
						showBody: !prev.showBody,
					}))}
				>
					<Flex justify="between">
						<span className="pr-3">{title} orders <small className="text-gray-600">({commaFormat(closeOrders?.length)} • {commaFormat(totalOrdersAmt / positionAmt * 100, 2, '0')}%)</small></span>
						<Flex className="text-nowrap small fw5">
							<Button
								variant="link"
								size="sm"
								className="p-0 text-primary hvr-text-darken-15 text-decoration-none"
							>
								New Order
							</Button>
							<Button
								variant="link"
								size="sm"
								className="p-0 ml-2 text-danger hvr-text-darken-15 text-decoration-none"
								disabled={cancelOrdersResult.isLoading || closeOrders.length < 1}
								onClick={() => {
									if (window.confirm('Are you sure?')) {
										cancelOrders({
											orderIds: closeOrders.map(
												order => order.orderId
											),
											symbol: symbol,
										})
									}
								}}
							>
								{cancelOrdersResult.isLoading ? (
									<FontAwesomeIcon icon={faSpinnerThird} spin={true} />
								) : (
									`Cancel all (${closeOrders?.length})`
								)}
							</Button>
						</Flex>
					</Flex>

					<Flex justify="between flex-wrap">
						<Flex className="small">
							<span className="fw5 text-gray-700">Closing: {millify(totalOrdersAmt)}</span>
							<span className="text-gray-600 ml-1">({millifyCurrency(totalOrdersValue)})</span>
							<SlashDivider />
							<span className="text-gray-600">{millify(positionAmt-totalOrdersAmt)} left</span>
							<SlashDivider />
							<Flex className="close-profit-wrapper">
								<span
									className={clsx('profit-on-close fw5', {
										'text-success': totalProfitFromOrders >= 0,
										'text-danger': totalProfitFromOrders < 0,
										'text-gray-600': !totalProfitFromOrders,
									})}
								>
									{millifyCurrency(totalProfitFromOrders, true)}
								</span>
								{/*realisedProfit + totalProfit from orders; Shown only on hover*/}
								<span
									className={clsx('total-profit fw5', {
										'text-success': (realisedProfit + totalProfitFromOrders) > 0,
										'text-gray-600': !(realisedProfit + totalProfitFromOrders),
										'text-danger': (realisedProfit + totalProfitFromOrders) < 0,
									})}
								>
									{millifyCurrency((realisedProfit + totalProfitFromOrders), true)}
								</span>
							</Flex>
						</Flex>

						{showBody && (
							<Flex className="small fw5">
								<Button
									variant="link"
									size="sm"
									className={clsx('p-0 ml-2 hvr-text-darken-15 text-decoration-none text-right', {
										'text-info': !showModOrdersPerc,
										'text-danger': showModOrdersPerc,
									})}
									onClick={handleToggleShowMod}
								>
									{!showModOrdersPerc ? 'Show % Mod' : 'Hide % Mod'}
								</Button>
							</Flex>
						)}
					</Flex>
				</Card.Header>
				{showBody && showModOrdersPerc && (
					<Card.Body className="px-3 pt-2 pb-3">
						<ModifyOrdersPercentages
							modOrdersQtyPerc={modOrdersQtyPerc}
							setModOrdersQtyPerc={setModOrdersQtyPerc}
							memoizedCloseOrders={JSON.stringify(closeOrders)}
							handleApplyModOrders={handleApplyModOrders}
							cancel={handleToggleShowMod}
							modifyIsLoading={modifyOrdersResult.isLoading}
						/>
					</Card.Body>
				)}
				<Card.Body
					className={clsx('p-0 scroll-x', {
						'd-none': !showBody
					})}
				>
					<OrdersTable
						memoizedCloseOrders={JSON.stringify(closeOrders)}
						positionAmt={positionAmt}
						modifyOrdersIsLoading={modifyOrdersResult.isLoading}
						cancelOrders={cancelOrders}
						cancelOrdersResult={cancelOrdersResult}
						showModOrdersPerc={showModOrdersPerc}
						setModOrdersQtyPerc={setModOrdersQtyPerc}
						dispatchContext={dispatchContext}
					/>
				</Card.Body>
			</Card>

			{/*Modify orders result*/}
			<ToastOnResult
				success={modifyOrdersResult.isSuccess ? 'Orders modified' : null}
				error={modifyOrdersResult.error}
				resetSuccess={modifyOrdersResult.reset}
				resetError={modifyOrdersResult.reset}
			/>

			{/*Cancel orders result*/}
			<ToastOnResult
				success={cancelOrdersResult.isSuccess ? 'Order(s) canceled' : null}
				error={cancelOrdersResult.error}
				resetSuccess={cancelOrdersResult.reset}
				resetError={cancelOrdersResult.reset}
			/>
		</>
	);
};

export default CloseOrders;


const ModifyOrdersPercentages = (props) => {
	const { position, dispatchContext } = useContext(PositionContext);
	const { positionAmt } = position;

	const {
		modOrdersQtyPerc,
		setModOrdersQtyPerc,
		memoizedCloseOrders,
		handleApplyModOrders,
		cancel,
		modifyIsLoading
	} = props;

	const orders = JSON.parse(memoizedCloseOrders);

	const totalOrigOrdersAmt = sum(
		orders.map(
			po => po._orig?.origQty
		)
	) - sum(
		orders.map(
			po => po._orig?.executedQty
		)
	);

	const newTotalOrdersAmt = sum(
		orders.map(
			o => o.origQty
		)
	);

	/* CALLBACKS */
	const updateOrdersQty = useCallback((newModOrdersQtyPerc) => {
		setModOrdersQtyPerc(newModOrdersQtyPerc);

		const orders = JSON.parse(memoizedCloseOrders);

		const newTotalOrdersAmt = newModOrdersQtyPerc / 100 * positionAmt;

		const newOrdersOrigQtyMap = orders.reduce(
			(acc, {_orig, orderId, origQty}) => {
				const orderPercOfTotalOrigOrdersAmt = _orig.origQty / totalOrigOrdersAmt;
				const newModOrderAmt = orderPercOfTotalOrigOrdersAmt * newTotalOrdersAmt;

				acc[orderId] = {
					origQty: newModOrderAmt,
				};

				return acc;
			}, {}
		);

		dispatchContext({
			type: 'modifiedOrdersData',
			payload: newOrdersOrigQtyMap,
		});
	}, [
		memoizedCloseOrders,
		positionAmt,
		totalOrigOrdersAmt,
		setModOrdersQtyPerc,
		dispatchContext,
	]);

	const handleEqualizeAmts = useCallback(() => {
		const orders = JSON.parse(memoizedCloseOrders);
		const equalOrderAmt = newTotalOrdersAmt / (orders.length || 1);

		const newOrdersOrigQtyMap = orders.reduce(
			(acc, {orderId}) => {
				acc[orderId] = {
					origQty: equalOrderAmt,
				};

				return acc;
			}, {}
		);

		dispatchContext({
			type: 'modifiedOrdersData',
			payload: newOrdersOrigQtyMap,
		});
	}, [
		memoizedCloseOrders,
		newTotalOrdersAmt,
		dispatchContext,
	]);

	const handleRoundAmts = useCallback(() => {
		const orders = JSON.parse(memoizedCloseOrders);
		const newOrdersOrigQtyMap = orders.reduce(
			(acc, { orderId, origQty }) => {
				const currentPercentage = Math.floor(origQty / positionAmt * 100);
				const newPercentage = currentPercentage - (currentPercentage % 5);
				const newQty = newPercentage / 100 * positionAmt;
				acc[orderId] = {
					//Round down to nearest 5
					origQty: newQty > 0 ? newQty : origQty,
				};

				return acc;
			}, {}
		);

		dispatchContext({
			type: 'modifiedOrdersData',
			payload: newOrdersOrigQtyMap,
		});
	}, [
		memoizedCloseOrders,
		positionAmt,
		dispatchContext,
	]);

	/* EVENTS */
	/** Set initial modOrdersQtyPerc value **/
	useEffect(() => {
		if (
			totalOrigOrdersAmt &&
			positionAmt
		) {
			const newModOrdersQtyPerc = Math.round(totalOrigOrdersAmt / positionAmt * 100);
			setModOrdersQtyPerc(newModOrdersQtyPerc);
		}
	// eslint-disable-next-line
	}, []);

	return (
		<Flex
			direction="column"
			className="ModifyOrdersPercentages"
		>
			{/* Slider */}
			<Form.Group className="mb-n1 mt-1">
				<Form.Label className="w-100 d-flex justify-content-between f-rem-0.85 fw5 mb-0">
					<span className="text-gray-700">Modify close %</span>
					<Flex>
						<span>{commaFormat(newTotalOrdersAmt, undefined, '0')}</span>
						<SlashDivider />
						<span className="text-gray-600">{commaFormat(positionAmt, undefined, '0')}</span>
					</Flex>
					<span>{modOrdersQtyPerc}%</span>
				</Form.Label>
				<Form.Control
					type="range"
					custom={true}
					value={modOrdersQtyPerc || 0}
					onChange={e => {
						const newPercOfPosition = Math.floor(Number(e.target.value));
						updateOrdersQty(newPercOfPosition);
					}}
					onDoubleClick={e => {
						const newPercOfPosition = Math.round(Number(e.target.value)/5)*5; //round to nearest 5
						updateOrdersQty(newPercOfPosition);
					}}
				/>
			</Form.Group>
			{/* Quick options */}
			<Flex className="mt-1.5">
				{/* Quick functions */}
				<Toggle
					ops={MODIFY_ORDERS_QUICK_ACTIONS}
					opClassName="f-rem-0.8 fw5"
					setActive={op => {
						switch(op) {
							case 'equalize':
								handleEqualizeAmts();
								break;
							case 'round':
								handleRoundAmts();
								break;
							default:
								break;
						}
					}}
				/>
				{/* Quick perc setting */}
				<Toggle
					ops={MODIFY_ORDERS_QUICK_PERCENTS}
					opFormatDisplay={op => `${op}%`}
					opClassName="f-rem-0.8 fw5"
					setActive={op => updateOrdersQty(op)}
					className="ml-1.5"
				/>
			</Flex>
			{/* Action btns */}
			<Flex className="mt-2.5">
				<Button
					variant="primary"
					size="xs"
					disabled={modifyIsLoading}
					onClick={handleApplyModOrders}
				>
					{modifyIsLoading ? (
						<FontAwesomeIcon icon={faSpinnerThird} spin={true} />
					) : 'Apply'}
				</Button>
				<Button
					variant="outline-danger"
					size="xs"
					className="ml-1.5"
					disabled={modifyIsLoading}
					onClick={cancel}
				>
					Cancel
				</Button>
			</Flex>
		</Flex>
	);
};

const OrdersTable = (props) => {
	const {
		memoizedCloseOrders,
		positionAmt,
		modifyOrdersIsLoading,
		cancelOrders,
		cancelOrdersResult,
		showModOrdersPerc,
		setModOrdersQtyPerc,
		dispatchContext,
	} = props;

	const orders = JSON.parse(memoizedCloseOrders);

	const handleUpdateOrdersQty = useCallback((newModOrderQtyPerc, orderId) => {
		const orders = JSON.parse(memoizedCloseOrders);

		const order = orders.find(o => o.orderId === orderId);
		const newOrderAmt = (newModOrderQtyPerc / 100) * positionAmt;
		const newOrderAmtDelta = newOrderAmt - order.origQty;

		const totalCurrentOrdersAmt = sum(
			orders.map(
				po => po?.origQty
			)
		) - sum(
			orders.map(
				po => po?.executedQty
			)
		);

		const newRemainingPostitionAmt = positionAmt - totalCurrentOrdersAmt - newOrderAmtDelta;

		const amtNeededFromOtherOrders = newRemainingPostitionAmt >= 0 ? 0 : Math.abs(newRemainingPostitionAmt);
		const totalAmtFromOtherOrders = sum(
			orders.map(
				o => o.orderId === orderId ? 0 : o.origQty
			)
		);
		const newOrdersOrigQtyMap = orders.reduce(
			(acc, order) => {
				const percOfOrderOfOtherOrders = order.origQty / totalAmtFromOtherOrders;
				const amtToReduceFromOrder = percOfOrderOfOtherOrders * amtNeededFromOtherOrders;
				const newOrigQty = order.orderId === orderId ? newOrderAmt
					: order.origQty - amtToReduceFromOrder;

				acc[order.orderId] = {
					origQty: newOrigQty,
				};

				return acc;
			}, {}
		);

		const newTotalOrdersAmt = sum(
			Object.values(newOrdersOrigQtyMap).map(
				o => o.origQty
			)
		);

		const newTotalOrdersPerc = Math.floor(newTotalOrdersAmt / positionAmt * 100);

		dispatchContext({
			type: 'modifiedOrdersData',
			payload: newOrdersOrigQtyMap,
		});

		setModOrdersQtyPerc(newTotalOrdersPerc);
	}, [
		memoizedCloseOrders,
		positionAmt,
		setModOrdersQtyPerc,
		dispatchContext,
	]);

	return (
		<Table className="OrdersTable table-stripe">
			<thead>
				<tr>
					{CLOSE_ORDERS_TABLE_HEADERS.map(
						header => (
							<th key={header} scope="col">{header}</th>
						)
					)}
				</tr>
			</thead>
			<tbody>
				{orders?.length > 0 ? (
					orders?.map(
						order => (
							<OrderRow
								key={order.orderId}
								order={order}
								cancelOrder={() => cancelOrders({
									orderIds: [order.orderId],
									symbol: order.symbol,
								})}
								cancelOrdersResult={cancelOrdersResult}
								positionAmt={positionAmt}
								modifyOrdersIsLoading={modifyOrdersIsLoading}
								showModOrdersPerc={showModOrdersPerc}
								handleUpdateOrdersQty={handleUpdateOrdersQty}
							/>
						)
					)
				) : (
					<tr>
						<td colSpan={CLOSE_ORDERS_TABLE_HEADERS.length} className="text-center text-gray-700">
							No close orders found
						</td>
					</tr>
				)}
			</tbody>
		</Table>
	);
};

const OrderRow = (props) => {
	const { pricePrecision, mouseEnterPriceline, dispatchContext } = useContext(PositionContext);

	const {
		order,
		cancelOrder,
		cancelOrdersResult,
		positionAmt,
		modifyOrdersIsLoading,
		showModOrdersPerc,
		handleUpdateOrdersQty,
	} = props;

	const isCanceling = cancelOrdersResult?.originalArgs?.orderIds?.some?.(
		orderId => orderId === order?.orderId
	);

	return (
		<>
			<tr
				className={clsx('OrderRow CloseOrderRow', {
					'mouseEnterPriceline': mouseEnterPriceline === order.orderId,
					'isTentativeOrder': order.isTentativeOrder,
				})}
				onMouseEnter={() => dispatchContext({ type: 'mouseEnterOrder', payload: order.orderId })}
				onMouseLeave={() => dispatchContext({ type: 'mouseLeaveOrder' })}
			>
				{/*TYPE*/}
				<td
					className={ORDER_TYPE_COLOR[order.actionShort]}
				>
					{order.actionShort}
				</td>
				{/*PRICE*/}
				<td>${commaFormat(order.price, pricePrecision, '-')}</td>
				{/*FILLED/TOTAL*/}
				<td>
					<span
						className={clsx({
							'text-gray-600': order.executedQty <= 0,
							'text-warning': order.executedQty > 0,
						})}
					>
						{millifyCurrency(order.avgPrice * order.executedQty)}
					</span>
					<SlashDivider />
					<span>{millifyCurrency(order.price * order.origQty)}</span>
				</td>
				{/*% OF POS*/}
				<td>
					<span className="text-gray-600">{removeZerosAfterDecimal(commaFormat(order.percentOfPosition, 2, '0'))}%</span>
				</td>
				{/*PROFIT*/}
				<td>
					<span
						className={clsx({
							'text-success': order.totalProfit >= 0,
							'text-danger': order.totalProfit < 0,
						})}
					>
						{millifyCurrency(order.totalProfit)}
					</span>
				</td>
				{/*ACTIONS*/}
				<td>
					<Button
						size="xs"
						variant="outline-danger"
						disabled={order.isTentativeOrder || cancelOrdersResult.isLoading || modifyOrdersIsLoading}
						onClick={cancelOrder}
					>
						<FontAwesomeIcon icon={isCanceling ? faSpinnerThird : faTimes} spin={isCanceling} />
					</Button>
				</td>
			</tr>
			{showModOrdersPerc && (
				<tr>
					<td
						colSpan={CLOSE_ORDERS_TABLE_HEADERS.length}
						className="border-top-0"
					>
						<Form.Group className="mb-n1 mt-0 w-100">
							<Form.Label className="w-100 d-flex justify-content-between f-rem-0.85 fw5 mb-0">
								<span className="text-gray-700">Modify close %</span>
								<Flex>
									<span>{millify(order.origQty)}</span>
									<SlashDivider />
									<span className="text-gray-600">{millify(positionAmt)}</span>
								</Flex>
								<span>{Math.floor(order.percentOfPosition)}%</span>
							</Form.Label>
							<Form.Control
								type="range"
								custom={true}
								value={order.percentOfPosition || 0}
								onChange={e => {
									const newPercOfPosition = Math.floor(Number(e.target.value));
									handleUpdateOrdersQty(newPercOfPosition, order.orderId);
								}}
								onDoubleClick={e => {
									const newPercOfPosition = Math.round(Number(e.target.value)/5)*5; //round to nearest 5
									handleUpdateOrdersQty(newPercOfPosition, order.orderId);
								}}
							/>
						</Form.Group>
					</td>
				</tr>
			)}
		</>
	);
};
