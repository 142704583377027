import React, { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from 'dayjs';
import clsx from 'clsx';
import Card from "react-bootstrap/Card";
import Flex from "components/Flex";
import Spinner from "components/Spinner";
import StripeTable from "components/StripeTable";
import { supportedSymbols } from 'constants/bingxSwaps';
import useGetBingxSwapsFundingRate from 'hooks/useGetBingxSwapsFundingRate';
import useIsMobile from 'hooks/useIsMobile';
import { commaFormat, currencyFormat } from 'util/numbers';
import './PositionsTable.scss';

const HEADERS = [
	{
		key: 'positionSide',
		name: 'Type',
	}, {
		key: 'avgPrice',
		name: 'Avg Price',
	}, {
		key: 'currentPrice',
		name: 'Cur Price',
	}, {
		key: 'breakEvenPrice',
		name: 'Break Even',
	}, {
		key: 'volume_size',
		name: 'Qty/Size',
	}, {
		key: 'realisedPNL_unrealisedPNL_feesToClose',
		name: 'Real/Unreal/Close Fees',
	}, {
		key: 'totalPNL',
		name: 'P&L',
	},
];

const DEFAULT_SORT_COL = 'positionId';
const DEFAULT_SORT_DIRECTION = 'DESC';

const getTextColor = value => !value || value === 0 || isNaN(value) ? 'text-default'
	: value < 0 ? 'text-danger'
		: 'text-success';

function PositionsTable(props) {
	const isMobile = useIsMobile();

  const {
  	positions = [],
  	useMobileTable,
  	forceUseMobileTable,
		...rest
	} = props;

	const { fundingRate, leftSeconds: fundingRateTTS } = useGetBingxSwapsFundingRate(positions?.[0]?.symbol);

	const data = useMemo(() => positions.map(
		p => {
			const {
				positionSide,
				symbol,
				leverage = 0,
				avgPrice = 0,
				currentPrice = 0,
				volume = 0,
				realisedPNL = 0,
				unrealisedPNL = 0,
				liquidatedPrice = 0,
				feesToClose,
				breakEvenPrice,
			} = p;

			const decimals = supportedSymbols[symbol.split('-')[0]]?.decimals || 2;
			const type = `${symbol.split('-')[0]}/${positionSide || '-'} ${leverage}x`.trim();
			const isShort = positionSide === 'Short';
			const typeColor = isShort ? 'danger' : 'success';
			const size = volume * avgPrice;
			const PNL = realisedPNL + unrealisedPNL + (feesToClose || 0);

			const priceDelta = isShort ? Number(currentPrice) - breakEvenPrice : breakEvenPrice - Number(currentPrice);
			const pricePercDelta = isShort ? (100-(breakEvenPrice/Number(currentPrice)*100)) : (100-(Number(currentPrice)/breakEvenPrice*100));

			return {
				...p,
				positionSide,
				symbol,
				leverage,
				avgPrice,
				currentPrice,
				volume,
				realisedPNL,
				unrealisedPNL,
				liquidatedPrice,
				feesToClose,
				breakEvenPrice,
				type,
				isShort,
				typeColor,
				size,
				PNL,
				priceDelta,
				pricePercDelta,
				decimals,
			}
		}
	), [positions]);

	const formatPositionForTable = useCallback((p) => {
		return {
			// key: `${user?.id}-${i}`, //To pass custom key
			id: p?.positionId,
			orig: p,
			values: [
				// Position side
				{
					value: p.type || '-',
					className: `text-nowrap text-${p.typeColor}`,
				},
				// Avg price
				{
					value: currencyFormat(p.avgPrice, '-', p.decimals),
					className: 'text-nowrap'
				},
				// Current Price
				{
					value: (
						<Flex>
							<span>{currencyFormat(p.currentPrice, '-', p.decimals)}</span>
							<span
								className={clsx('pl-1', {
									'text-success': p.isShort ? p.currentPrice < p.breakEvenPrice : p.currentPrice > p.breakEvenPrice,
									'text-danger': !p.isShort ? p.currentPrice < p.breakEvenPrice : p.currentPrice > p.breakEvenPrice
								})}
								style={{opacity: .65}}
							>
								({currencyFormat(p.priceDelta, '-', p.decimals)}, {commaFormat(p.pricePercDelta, 2, '-')}%)
							</span>
						</Flex>
					)
				},
				// Break even price
				{
					value: currencyFormat(p.breakEvenPrice, '-', p.decimals),
					className: clsx(p.currentPrice ? {
						'text-danger': p.isShort ? p.currentPrice >= p.breakEvenPrice : p.currentPrice <= p.breakEvenPrice,
						'text-success': p.isShort ? p.currentPrice <= p.breakEvenPrice : p.currentPrice >= p.breakEvenPrice,
					} : {}),
				},
				// Volume(qty) / Size
				{
					value: (
						<Flex>
							<span>{commaFormat(p.volume, undefined, '-')}</span>
							<span className="px-1 text-gray-400">/</span>
							<span>{currencyFormat(p.size, '-')}</span>
						</Flex>
					)
				},
				// Real/Unreal P&L
				{
					value: (
						<Flex>
							<span className={getTextColor(p.realisedPNL)}>{currencyFormat(p.realisedPNL, '-')}</span>
							<span className="px-1 text-gray-400">/</span>
							<span className={getTextColor(p.unrealisedPNL)}>{currencyFormat(p.unrealisedPNL, '-')}</span>
							<span className="px-1 text-gray-400">/</span>
							<span className="text-danger">{currencyFormat(p.feesToClose, '-')}</span>
						</Flex>
					),
					className: 'text-nowrap',
				},
				// Total PNL
				{
					value: currencyFormat(p.PNL, '-'),
					className: `text-nowrap ${getTextColor(p.PNL)}`,
				},
			],
			callbackValue: p, //Pass custom callback value
		};
	}, []);

	return forceUseMobileTable || (isMobile && useMobileTable) ? (
		<MobileCard
			data={data}
			fundingRate={fundingRate}
			fundingRateTTS={fundingRateTTS}
			{...rest}
		/>
	) : (
		<StripeTable
			headers={HEADERS}
			defaultSortCol={DEFAULT_SORT_COL}
			defaultSortDir={DEFAULT_SORT_DIRECTION}
			data={data.map(formatPositionForTable)}
			noneFoundText="No positions found"
			{...rest}
		/>
	);
};

const MobileCard = ({data, fundingRate, fundingRateTTS, isLoading, handleRowClick, noneFoundText}) => {
	return isLoading ? (
		<Card className="shadow mt-2">
			<Spinner className="py-3 text-muted w-100" />
		</Card>
	) : (
		<Flex direction="column" className="PositionsTable-Mobile">
			{data.map((p, i) => (
				<Card
					key={p.id || i}
					className={clsx('shadow', {
						'mt-2': i > 0
					})}
					onClick={() => typeof handleRowClick === 'function' && handleRowClick(p)}
				>
					<Card.Header>
						<Flex justify="between">
							<span className={`text-${p.typeColor}`}>{p.type}</span>
							<span className={`text-nowrap ${getTextColor(p.PNL)}`}>{currencyFormat(p.PNL, '-')}</span>
						</Flex>
					</Card.Header>
					<Card.Body>
						{/*Qty, Size*/}
						<Flex justify="between" className="item-wrapper">
							<Flex direction="column">
								<span className="label">Qty</span>
								<span className="value">{commaFormat(p.volume, undefined, '-')}</span>
							</Flex>
							<Flex direction="column">
								<span className="label">Size</span>
								<span className="value">{currencyFormat(p.size, '-')}</span>
							</Flex>
						</Flex>
						{/*Avg price, Break even*/}
						<Flex justify="between" className="item-wrapper">
							<Flex direction="column">
								<span className="label">Avg price</span>
								<span className="value">{currencyFormat(p.avgPrice, '-', p.decimals)}</span>
							</Flex>
							<Flex direction="column">
								<span className="label">Break even</span>
								<span
									className={clsx('value', {
										'text-danger': p.isShort ? p.currentPrice >= p.breakEvenPrice : p.currentPrice <= p.breakEvenPrice,
										'text-success': p.isShort ? p.currentPrice <= p.breakEvenPrice : p.currentPrice >= p.breakEvenPrice,
									})}
								>
									{currencyFormat(p.breakEvenPrice, '-', p.decimals)}
								</span>
							</Flex>
						</Flex>
						{/*Current price*/}
						<Flex justify="between" className="item-wrapper">
							<Flex direction="column">
								<span className="label">Cur price</span>
								<Flex className="value">
									<span>{currencyFormat(p.currentPrice, '-', p.decimals)}</span>
									<span
										className={clsx('pl-1', {
											'text-success': p.isShort ? p.currentPrice < p.breakEvenPrice : p.currentPrice > p.breakEvenPrice,
											'text-danger': !p.isShort ? p.currentPrice < p.breakEvenPrice : p.currentPrice > p.breakEvenPrice
										})}
										style={{opacity: .65}}
									>
										({currencyFormat(p.priceDelta, '-', p.decimals)}, {commaFormat(p.pricePercDelta, 2, '-')}%)
									</span>
								</Flex>
							</Flex>
							<Flex direction="column">
								<span className="label">Liq Price</span>
								<span className="value">{currencyFormat(p.liquidatedPrice, '-', p.decimals)}</span>
							</Flex>
						</Flex>
						{/*Real, unreal, close fees*/}
						<Flex justify="between" className="item-wrapper">
							<Flex direction="column">
								<span className="label">Real/Unreal P&L</span>
								<div className="value">
									<span className={getTextColor(p.realisedPNL)}>{currencyFormat(p.realisedPNL, '-')}</span>
									<span className="px-1 text-gray-400">/</span>
									<span className={getTextColor(p.unrealisedPNL)}>{currencyFormat(p.unrealisedPNL, '-')}</span>
								</div>
							</Flex>
							<Flex direction="column">
								<span className="label">Fees to Close</span>
								<span className="value text-danger">{currencyFormat(p.feesToClose, '-')}</span>
							</Flex>
						</Flex>
						{/*Funding rate info*/}
						<FundingRate
							positionType={p?.type}
							positionSize={p?.size}
							fundingRate={fundingRate}
							fundingRateTTS={fundingRateTTS}
						/>
					</Card.Body>
				</Card>
			))}
		</Flex>
	);
};

const FundingRate = ({positionType, positionSize, fundingRate, fundingRateTTS}) => {
	// "If the funding rate is positive, long position holders shall pay funding fees to short position holders, and vice versa."
	// Funding Fee = Total Trading Volume * Funding Rate * Position Direction.
	const feeValue = positionSize * fundingRate / 100;
	const feeValueForPositionSide = positionType === 'Short'
		? feeValue > 0 ? feeValue : feeValue * -1
		: feeValue > 0 ? feeValue * -1 : feeValue;

	const [TTS, setTTS] = useState();
	const settlementTimeMs = useMemo(() => new Date().getTime() + (fundingRateTTS * 1000), [fundingRateTTS]);

	useEffect(() => {
		function setCountdown() {
			const now = dayjs();
			const settlementTime = dayjs(settlementTimeMs);
			const hrs = settlementTime.diff(now, 'h');
			const mins = settlementTime.diff(now, 'm');
			const secs = settlementTime.diff(now, 's');

			const hrsLeft = String(hrs).padStart(2, '0');
			const minsLeft = mins % (hrs * 60);
			const secsLeft = secs % (mins * 60);

			const time = [hrsLeft, minsLeft, secsLeft].filter(o => o).join(':');
			setTTS(time);
		};

		setCountdown();
		const interval = setInterval(setCountdown, 1000);
		return () => clearInterval(interval);
	}, [settlementTimeMs]);

	return (
		<Flex justify="between" className="item-wrapper">
			<Flex direction="column">
				<span className="label">Funding rate</span>
				<span
					className={clsx('value', {
						'text-danger': feeValueForPositionSide < 0,
						'text-success': feeValueForPositionSide >= 0,
					})}
				>
					{fundingRate || '-'}% / {TTS}
				</span>
			</Flex>
			<Flex direction="column">
				<span className="label">Funding Fee</span>
				<span
					className={clsx('value', {
						'text-danger': feeValueForPositionSide < 0,
						'text-success': feeValueForPositionSide >= 0,
					})}
				>
					{currencyFormat(feeValueForPositionSide, '-')}
				</span>
			</Flex>
		</Flex>
	);
}

export default PositionsTable;
