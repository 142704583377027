import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import Highcharts from 'highcharts';
import HighchartsHeatmapModule from 'highcharts/modules/heatmap';
import HighchartsReact from 'highcharts-react-official';
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Flex from "components/Flex";
import Toggle from "components/Toggle";
import { STATIC_GROUPING_BY_SYMBOL } from "components/OrderbookChart/OrderbookChart";
import { currencyFormat } from "util/numbers";
import {
	useGetKlineDataQuery,
	useGetSpotKlineDataQuery,
	useGetOrderbookSnapshotQuery,
} from "api/client";
import useStreamMarkPrice from "api/websockets/binanceFutures/useStreamMarkPrice";
// import useStreamOrderbook from "api/websockets/binanceFutures/useStreamOrderbook";
import useStreamLatestPrice from "api/websockets/binanceSpot/useStreamLatestPrice";
import useSnapshotOrderbook from "hooks/useSnapshotOrderbook";
// import useFormatOrderbook, { formatOrderbook } from "hooks/useFormatOrderbook";
import useFormatSnapshotForHeatmap from './hooks/useFormatSnapshotForHeatmap';
import "./Firechart.scss";

HighchartsHeatmapModule(Highcharts);
dayjs.extend(dayjsUtc);

const MAX_LENGTH_OPS = [16,32,48,64,72,98];
const TIME_INTERVAL_OPS = ['1m', '5m', '15m', '30m', '1h', '4h', '12h', '1d'];
const SNAPSHOT_FREQUENCY = 1000;
const STATIC_MIN_MAX = {
	spot: {
		min: 0,
		max: 4000000,
	},
	futures: {
		min: 0,
		max: 65000000
	},
};
const TIME_INTERVAL_IN_MS = {
	'1m': 1000 * 60,
	'5m': 1000 * 60 * 5,
	'15m': 1000 * 60 * 15,
	'30m': 1000 * 60 * 30,
	'1h': 1000 * 60 * 60,
	'4h': 1000 * 60 * 60 * 4,
	'12h': 1000 * 60 * 60 * 12,
	'1d': 1000 * 60 * 60 * 24,
};

function Firechart(props) {
	const { symbol, marketType } = props;
	const [grouping, setGrouping] = useState(6);
	const [timeInterval, setTimeInterval] = useState(TIME_INTERVAL_OPS[0]);
	const [staticMinMax, setStaticMinMax] = useState(false);
	const [maxLength, setMaxLength] = useState(MAX_LENGTH_OPS[0]);

	// eslint-disable-next-line
	const setGroupingDebounce = useCallback(
		debounce(setGrouping, 250),
		[]
	);

	return (
		<>
			<Options
				symbol={symbol}
				grouping={grouping}
				setGrouping={setGroupingDebounce}
				timeInterval={timeInterval}
				setTimeInterval={setTimeInterval}
				staticMinMax={staticMinMax}
				setStaticMinMax={setStaticMinMax}
				maxLength={maxLength}
				setMaxLength={setMaxLength}
			/>
			<Card
				className="Firechart shadow"
				style={{overflow: 'hidden'}}
			>
				<Card.Header>
					<span>Firechart</span>
				</Card.Header>
				<Card.Body
					className="p-0"
					style={{
						backgroundColor: '#212529',
						overflow: 'hidden',
					}}
				>
					<Chart
						symbol={symbol}
						marketType={marketType}
						grouping={grouping}
						timeInterval={timeInterval}
						staticMinMax={staticMinMax}
						maxLength={maxLength}
					/>
				</Card.Body>
			</Card>
		</>
	);
};

export default Firechart;


export const Options = ({
	symbol,
	grouping,
	setGrouping,
	timeInterval,
	setTimeInterval,
	staticMinMax,
	setStaticMinMax,
	maxLength,
	setMaxLength
}) => {
	const groupingRef = useRef();

	useEffect(() => {
		const newGrouping = STATIC_GROUPING_BY_SYMBOL[symbol.toUpperCase()];
		setGrouping(newGrouping);
		groupingRef.current.value = newGrouping;
	}, [
		symbol,
		setGrouping
	]);

	return (
		<Flex
			justify="between"
			align="center"
			wrap="wrap"
			className="Options mb-2"
		>
			<Flex>
				<Toggle
					ops={['s', 'd']}
					active={staticMinMax ? 's' : 'd'}
					setActive={op => setStaticMinMax(op === 's')}
				/>

				<Toggle
					ops={MAX_LENGTH_OPS}
					active={maxLength}
					setActive={setMaxLength}
					className="ml-2"
				/>

				<Toggle
					ops={TIME_INTERVAL_OPS}
					active={timeInterval}
					setActive={setTimeInterval}
					className="ml-2"
				/>
			</Flex>

			<InputGroup
				size="sm"
				className="w-fit"
			>
				<InputGroup.Prepend className="border-left">
		      <InputGroup.Text id="inputGroup-sizing-sm">Group</InputGroup.Text>
		    </InputGroup.Prepend>
				<FormControl
					ref={groupingRef}
					aria-label="Group"
					aria-describedby="inputGroup-sizing-sm"
					defaultValue={grouping}
					style={{maxWidth: '56px'}}
					onChange={e => {
						const asInt = Number(e.target.value);

						if (!!asInt && !isNaN(asInt)) {
							setGrouping(asInt);
						}
					}}
				/>
			</InputGroup>
		</Flex>
	);
};

const Chart = ({symbol, marketType, grouping, timeInterval, staticMinMax, maxLength}) => {
	const chartRef = useRef();
	const realtimeChartRef = useRef();
	// const [chartOptions, setChartOptions] = useState(INIT_CHART_OPS);
	const [chartRendered, setChartRendered] = useState(false);
	const [realtimeChartRendered, setRealtimeChartRendered] = useState(false);

	/** STREAMS **/
	// Stream mark price
	const { price: streamSpotPrice } = useStreamLatestPrice(marketType === 'spot' ? {
		symbol: `${symbol}USDT`,
		frequency: SNAPSHOT_FREQUENCY,
	} : {});
	const { price: streamFuturesPrice } = useStreamMarkPrice(marketType === 'futures' ? `${symbol}USDT` : '');

	const [streamPrice = 0] = useMemo(
		() => [marketType === 'futures' ? streamFuturesPrice : streamSpotPrice],
		[
			marketType,
			streamSpotPrice,
			streamFuturesPrice
		]
	);

	/** FETCH **/
	// Fetch snapshots
	const {
		data: snapshotHistory = [],
		refetch: snapshotHistoryRefetch,
	} = useGetOrderbookSnapshotQuery({
		symbol: symbol.toUpperCase(),
		type: marketType.toUpperCase(),
		limit: maxLength,
		interval: timeInterval,
	});

	// Stream snapshot every SNAPSHOT_FREQUENCY ms
	const {
		snapshot: streamedSnapshot = [],
		isFetching: streamedSnapshotIsFetching,
	} = useSnapshotOrderbook({
		symbol: `${symbol}USDT`,
		marketType,
		limit: marketType === 'futures' ? 1000 : 5000,
		frequency: SNAPSHOT_FREQUENCY,
		onlyLatest: true,
	});

	// Fetch kLine Data
	const getKlineQuery = useMemo(
		() => marketType === 'futures' ? useGetKlineDataQuery : useGetSpotKlineDataQuery,
		[marketType]
	);

	const {
		data: klineData = [],
		refetch: klineDataRefetch,
	} = getKlineQuery({
		symbol: `${symbol}USDT`,
		interval: timeInterval,
		limit: maxLength + 1,
	});

	// Refetch snapshotHistory and klineData at 2s past the start of each minute
	useEffect(() => {
		let interval;

		const msToNextMin = dayjs().add(1, 'm').startOf('m') - dayjs() + 2000;

		const timeout = setTimeout(() => {
			interval = setInterval(() => {
				snapshotHistoryRefetch();
				klineDataRefetch();
			}, 60 * 1000);

			snapshotHistoryRefetch();
			klineDataRefetch();
		}, msToNextMin);

		return () => {
			clearTimeout(timeout);
			clearInterval(interval);
		}
	}, [
		snapshotHistoryRefetch,
		klineDataRefetch
	]);

	const [
		snapshotData,
		minPrice,
		maxPrice,
		minQty,
		maxQty,
		minTimestamp,
		maxTimestamp,
	] = useMemo(() => {
		const snapshotHistoryData = Object.values(
			groupBy(snapshotHistory, 'date')
		).flatMap(
			snapshots => {
				return Object.entries(
					snapshots.reduce(
						(acc, s) => {
							const value = Number(s.value);
							acc[s.price] = (acc[s.price] || 0) + value;
							return acc;
						}, {}
					)
				).map(
					([price, value]) => ({
						x: +dayjs(snapshots[0]?.date),
						y: Number(price),
						value: value,
					})
				)
			}
		).sort(
			(a, b) => a.x - b.x
		);

		const minPrice = Math.min(...snapshotHistoryData.map(s => s.y)) || 0;
		const maxPrice = Math.max(...snapshotHistoryData.map(s => s.y)) || 0;
		const minQty = Math.min(...snapshotHistoryData.map(s => s.value)) || 0;
		const maxQty = Math.max(...snapshotHistoryData.map(s => s.value)) || 0;
		const minTimestamp = snapshotHistoryData[0]?.x || 0;
		const maxTimestamp = snapshotHistoryData[snapshotHistoryData.length - 1]?.x || 0;

		return [
			snapshotHistoryData,
			minPrice,
			maxPrice,
			minQty,
			maxQty,
			minTimestamp,
			maxTimestamp
		];
	// eslint-disable-next-line
	}, [
		// eslint-disable-next-line
		JSON.stringify(snapshotHistory),
	]);

	// Format streamed snapshot
	const {
		data: streamedSnapshotData,
		minPrice: streamedMinPrice,
		maxPrice: streamedMaxPrice,
		minQty: streamedMinQty,
		maxQty: streamedMaxQty,
	} = useFormatSnapshotForHeatmap({
		snapshot: [streamedSnapshot],
		grouping
	});

	const heatmapTimestamps = useMemo(() => {
		return [...new Set(snapshotData.map(o => o.x))];
	// eslint-disable-next-line
	}, [
		// eslint-disable-next-line
		JSON.stringify(snapshotData),
	]);


	/** UPDATE CHART EXTREMES/AXIS **/
	// handle colorAxis Extremes dynamic vs standard
	useEffect(() => {
		if (
			chartRendered &&
			realtimeChartRendered
		) {
			const min = staticMinMax ? STATIC_MIN_MAX[marketType].min : Math.min(
				(minQty || 0), (streamedMinQty || 0)
			);

			let max = Math.max(
				(maxQty || 0), (streamedMaxQty || 0)
			);

			if (staticMinMax) {
				// There's a chance max is higher than STATIC_FUTURES_MAX in which case we need
				// to set max to the higher value, even if staticMinMax === true.
				max = max > STATIC_MIN_MAX[marketType].max ? max : STATIC_MIN_MAX[marketType].max;
			}

			const minIsUniq = min !== chartRef.current.colorAxis[0].min;
			const maxIsUniq = max !== chartRef.current.colorAxis[0].max;

			if (minIsUniq || maxIsUniq) {
				chartRef.current.colorAxis[0].setExtremes(min, max);
			}

			const minIsUniqRealtime = min !== realtimeChartRef.current.colorAxis[0].min;
			const maxIsUniqRealtime = max !== realtimeChartRef.current.colorAxis[0].max;

			if (minIsUniqRealtime || maxIsUniqRealtime) {
				realtimeChartRef.current.colorAxis[0].setExtremes(min, max);
			}
		}
	}, [
		chartRendered,
		realtimeChartRendered,
		staticMinMax,
		marketType,
		minQty,
		maxQty,
		streamedMinQty,
		streamedMaxQty,
	]);

	// Handle yAxis Extremes based on snapshot and realtime snapshot prices
	useEffect(() => {
		if (
			chartRendered &&
			realtimeChartRendered
		) {
			const min = Math.min(
				(minPrice || 0), (streamedMinPrice || 0)
			);

			const max = Math.max(
				(maxPrice || 0), (streamedMaxPrice || 0)
			);

			const minIsUniq = min !== chartRef.current.yAxis[0].min;
			const maxIsUniq = max !== chartRef.current.yAxis[0].max;

			if (minIsUniq || maxIsUniq) {
				chartRef.current.yAxis[0].setExtremes(min, max);
			}

			const minIsUniqRealtime = min !== realtimeChartRef.current.yAxis[0].min;
			const maxIsUniqRealtime = max !== realtimeChartRef.current.yAxis[0].max;

			if (minIsUniqRealtime || maxIsUniqRealtime) {
				realtimeChartRef.current.yAxis[0].setExtremes(min, max);
			}
		}
	}, [
		chartRendered,
		realtimeChartRendered,
		minPrice,
		maxPrice,
		streamedMinPrice,
		streamedMaxPrice,
		grouping,
	]);

	// Handle xAxis Extremes based on snapshot
	useEffect(() => {
		if (
			chartRendered &&
			realtimeChartRendered
		) {
			const minIsUniq = minTimestamp !== chartRef.current.xAxis[0].min;
			const maxIsUniq = maxTimestamp !== chartRef.current.xAxis[0].max;

			if (minIsUniq || maxIsUniq) {
				chartRef.current.xAxis[0].setExtremes(minTimestamp, maxTimestamp);
			}
		}
	}, [
		chartRendered,
		realtimeChartRendered,
		minTimestamp,
		maxTimestamp,
	]);

	// Update heatmap chart col/rowsize
	useEffect(() => {
		if (
			chartRendered &&
			realtimeChartRendered
		) {
			const curColsize = chartRef.current.series[0].colsize;
			const curRowsize = chartRef.current.series[0].rowsize;

			if (curColsize !== TIME_INTERVAL_IN_MS[timeInterval] || curRowsize !== STATIC_GROUPING_BY_SYMBOL[symbol]) {
				chartRef.current.series[0].update({
					colsize: TIME_INTERVAL_IN_MS[timeInterval],
					rowsize: STATIC_GROUPING_BY_SYMBOL[symbol],
				}, true);
			}

			const realtimeCurColsize = realtimeChartRef.current.series[0].colsize;
			const realtimeCurRowsize = realtimeChartRef.current.series[0].rowsize;

			if (realtimeCurColsize !== 60 * 1000 || realtimeCurRowsize !== grouping) {
				realtimeChartRef.current.series[0].update({
					colsize: 60 * 1000, //realtime colsize is always 1m
					rowsize: grouping,
				}, true);
			}
		}
	}, [
		symbol,
		chartRendered,
		realtimeChartRendered,
		timeInterval,
		grouping,
	]);


	/** UPDATE CHART DATA **/
	// Update chart orderbook/heatmap data
	useEffect(() => {
		if (
			chartRendered &&
			snapshotData.length > 0
		) {
			chartRef.current.series[0].setData(snapshotData, true);
		}
	// eslint-disable-next-line
	}, [
		chartRendered,
		// eslint-disable-next-line
		JSON.stringify(snapshotData),
	]);

	// Update chart price line
	useEffect(() => {
		if (
			chartRendered &&
			klineData.length > 0
		) {
			const klineDataForChart = klineData.filter(
				kline => kline.openTime >= heatmapTimestamps[0] &&
					kline.openTime <= heatmapTimestamps[heatmapTimestamps.length - 1]
			).map(
				kline => ({
					x: kline.openTime,
					y: kline.close,
					value: kline.close,
				})
			);

			chartRef.current.series[1].setData(klineDataForChart, true);
		}
	// eslint-disable-next-line
	}, [
		chartRendered,
		// eslint-disable-next-line
		JSON.stringify(klineData),
		// eslint-disable-next-line
		JSON.stringify(heatmapTimestamps),
	]);

	/** UPDATE CHART REALTIME DATA **/

	// Update chart heatmap with realtime orderbook data
	useEffect(() => {
		if (
			realtimeChartRendered &&
			!streamedSnapshotIsFetching
		) {
			const now = +dayjs();
			const timestamp = +dayjs().startOf('m');

			if (now - timestamp >= 500) {
				realtimeChartRef.current.series[0].setData(
					streamedSnapshotData.map(d => ({
						...d,
						x: timestamp,
					})),
					true
				);
			}
		}
	// eslint-disable-next-line
	}, [
		realtimeChartRendered,
		streamedSnapshotIsFetching,
		// eslint-disable-next-line
		JSON.stringify(streamedSnapshotData),
	]);

	// Update chart priceline with realtime price
	useEffect(() => {
		if (
			realtimeChartRendered &&
			streamPrice
		) {
			const now = +dayjs();
			const timestamp = +dayjs().startOf('m');

			if (now - timestamp >= 500) {
				realtimeChartRef.current.series[1].setData([{
					x: timestamp,
					y: streamPrice,
					value: streamPrice,
				}], true);
			}
		}
	}, [
		realtimeChartRendered,
		streamPrice,
	]);


	/** UI **/
	return (
		<Flex
			style={{resize: 'vertical', overflow: 'auto'}}
		>
			<HighchartsReact
				highcharts={Highcharts}
				containerProps={{
					style: {
						width: '92%',
						height: '100%',
						minHeight: '640px',
					}
				}}
				options={INIT_CHART_OPS(false)}
				callback={(chart) => {
					chartRef.current = chart;
					setChartRendered(true);
				}}
			/>
			<HighchartsReact
				highcharts={Highcharts}
				containerProps={{
					style: {
						width: '8%',
						height: '100%',
						minHeight: '640px',
					}
				}}
				options={INIT_CHART_OPS(true)}
				callback={(chart) => {
					realtimeChartRef.current = chart;
					setRealtimeChartRendered(true);
				}}
			/>
		</Flex>
	);
};


// HELPERS
const INIT_CHART_OPS = (isRealtime = false) => ({
	title: false,
	credits: {
		endabled: false,
	},
	chart: {
		plotBorderWidth: 0,
		backgroundColor: '#212529',
		animation: {
			duration: 100,
		},
		spacing: isRealtime ? [10, 10, 78, 0] : [10, 0, 15, 10],
	},
	boost: {
    useGPUTranslations: true
  },
  xAxis: {
  	type: 'datetime',
  	labels: {
  		// format: '{value:%b %e %H:%M}'
  		formatter() {
  			const startOfDay = +dayjs().startOf('d');
  			const date = this.value < startOfDay ? dayjs(this.value).format('D h:mm a') : dayjs(this.value).format('h:mm');
  			return this.value < startOfDay ? date.replace(':00', '') : date;
  		},
  		style: {
  			color: '#e6e6e6',
  		}
  	},
  	lineColor: '#e6e6e6',
  	tickColor: '#e6e6e6',
  	minTickInterval: 60 * 1000,
  	maxPadding: 0,
  	minPadding: 0,
  	startOnTick: true,
  	endOnTick: true,
  },
  yAxis: {
  	title: false,
  	minPadding: 0,
    maxPadding: 0,
    gridLineWidth: 0,
  	tickWidth: 0,
  	startOnTick: false,
  	endOnTick: false,
  	labels: {
  		formatter() {
  			return currencyFormat(this.value);
  		},
  		style: {
  			color: '#e6e6e6',
  		}
  	},
  	...isRealtime ? {
  		visible: false,
  	} : {},
  },
  colorAxis: {
  	stops: [
  		[0, 	 '#361212'], //dark
  		[0.12, '#531616'], //dark red
  		[0.31, '#ce2328'], //red
  		[0.48, '#f16327'], //dark orange
  		[0.72, '#fbaa27'], //orange
  		[0.9,  '#f8ed47'], //yellow
  		[1, 	 '#ffffff'], //white
  	],
  	labels: {
  		style: {
  			color: '#e6e6e6',
  		},
  	},
  	startOnTick: false,
    endOnTick: false,
    ...isRealtime ? {
  		visible: false,
  	} : {},
  },
  series: [{
  	type: 'heatmap',
  	name: 'Liquidity Heatmap',
  	boostThreshold: 100,
    nullColor: '#212529',
    tooltip: {
    	pointFormatter() {
    		const {
    			x: timestamp,
    			y: price,
    			value: liqValue,
    		} = this;

    		return `
    			<div>
    				${dayjs(timestamp).format('MMM D, h:mm')}
    				</br>
    				Price: ${currencyFormat(price)}
    				</br>
    				<b>Liq: ${Intl.NumberFormat('en', { notation: 'compact' }).format(liqValue)}</b>
    			</div>
    		`.trim();
    	},
    },
    dataLabels: {
    	enabled: true,
    	y: -1,
    	padding: 0,
    	formatter() {
    		let returnValue = undefined;

  			const topValsInCurrentX = new Set(
  				this.series.data.filter(
	  				d => d?.x === this.point.x
	  			).map(
    				d => d?.value
    			).sort(
    				(a, b) => a - b
    			).slice(-3)
    		);

    		if (topValsInCurrentX.has(this.point.value)) {
    			// returnValue = currencyFormat(this.point.value);
    			returnValue = Intl.NumberFormat('en', {
    				notation: 'compact',
    				minimumFractionDigits: 1,
    				maximumFractionDigits: 1
    			}).format(this.point.value);
    		}

	    	return returnValue;
    	},
    }
  }, {
  	type: 'line',
  	name: 'Price Line',
  	colorAxis: false,
  	color: '#11cdef',
  	connectNulls: true,
  	showInLegend: false,
  	lineWidth: 2,
  	marker: {
  		radius: 4,
  	},
  	tooltip: {
  		headerFormat: `
  			<div>
  				<span style="color:{point.color}">●</span> <span style="font-size: 0.8em"> {series.name}</span>
  				<br/>
  			</div>
  		`.trim(),
  		pointFormatter() {
  			const {
  				x: timestamp,
  				y: price,
  			} = this;

  			return `
    			<div class="d-flex flex-column">
    				${dayjs(timestamp).format('MMM D, h:mm')}
    				</br>
    				<b>${currencyFormat(price)}</b>
    			</div>
    		`.trim();
  		},
  	},
  	...isRealtime ? {
	  	dataLabels: {
	  		enabled: true,
	  		// backgroundColor: 'auto',
	  		borderColor: 'auto',
	  		borderWidth: 1,
	  		borderRadius: 2,
	  		y: -8,
	  		formatter() {
	  			if (this.point.index === this.series.data.length - 1) {//only show if last point
	  				return currencyFormat(this.y);
	  			} else {
	  				return undefined;
	  			}
	  		}
	  	}
  	} : {},
  }],
});
