import React from 'react';
import Card from "react-bootstrap/Card";
import Toggle from 'components/Toggle';
import { SYMBOLS, TIME_INTERVAL_OPS, WIDGETS_OPS } from './constants';

function ConfigCard(props) {
	const { symbol, timeIntervals = [], widgets = [], dispatchConfig } = props;

	return (
		<Card className="ConfigCard shadow">
			<Card.Header>Config</Card.Header>
			<Card.Body>
				{/*Symbol*/}
				<Toggle
					label="Symbol"
					labelClassName={TOGGLE_LABEL_CLASS}
					ops={SYMBOLS}
					active={symbol}
					setActive={op => dispatchConfig({
						type: 'symbol',
						payload: op
					})}
					className="w-fit scroll-x"
				/>
				{/*Interval ops*/}
				<Toggle
					label="Intervals"
					labelClassName={`${TOGGLE_LABEL_CLASS} mt-2.5`}
					ops={TIME_INTERVAL_OPS}
					active={timeIntervals}
					setActive={op => dispatchConfig({
						type: 'timeIntervals',
						payload: op
					})}
					className="scroll-x"
				/>
				{/*Widgets*/}
				<Toggle
					label="Widgets"
					labelClassName={`${TOGGLE_LABEL_CLASS} mt-2.5`}
					ops={WIDGETS_OPS}
					active={widgets}
					setActive={op => dispatchConfig({
						type: 'widgets',
						payload: op
					})}
					className="w-fit scroll-x"
				/>
			</Card.Body>
		</Card>
	);
};

export default ConfigCard;


const TOGGLE_LABEL_CLASS = "d-block f-rem-0.9 fw5 text-gray-700 mb-1";
