import bearishEngulfing from './bearishEngulfing';
import bearishHarami from './bearishHarami';
import bullishEngulfing from './bullishEngulfing';
import bullishHarami from './bullishHarami';
import doji from './doji';
import dragonflyDoji from './dragonflyDoji';
import gravestoneDoji from './gravestoneDoji';
import shootingStar from './shootingStar';

const patternsMap = {
	'bearishEngulfing': bearishEngulfing,
	'bearishHarami': bearishHarami,
	'bullishEngulfing': bullishEngulfing,
	'bullishHarami': bullishHarami,
	'doji': doji,
	'dragonflyDoji': dragonflyDoji,
	'gravestoneDoji': gravestoneDoji,
	'shootingStar': shootingStar,
};

export default function applyPatternDetection(data = [], patterns = Object.keys(patternsMap), options = {}) {
	const {
		labels: {
			value: showMarkerLabels,
		},
		colors: {
			value: showMarkerColors,
		},
	} = options;

	const allKeys = Object.keys(patternsMap);
	const dedupedAndFilteredKeys = [...new Set(patterns)].filter(
		key => allKeys.some(k => k === key)
	);

	const dataWithPatterns = dedupedAndFilteredKeys.reduce((acc, patternKey) => {
		if (patternsMap[patternKey]) {
			acc = patternsMap[patternKey](acc);
		}

		return acc;
	}, data);

	return dataWithPatterns.map(
		d => {
			if (d.marker) {
				if (!showMarkerLabels) {
					delete d.marker.text;
					delete d.marker.shape;
				}

				if (!showMarkerColors) {
					delete d.color;
					delete d.borderColor;
				}
			}

			return d;
		}
	);
}
