import { Buffer } from 'buffer';
import { ungzip } from 'pako';
import { useState, useEffect, useCallback, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { DEFAULT_OPTIONS } from 'api/websockets/constants';
import { wsUrlV2 } from './constants';

function useKlineDataWsV2(initSubs = []) {
	const [subs, setSubs] = useState(initSubs || []);
	const [subsToAdd, setSubsToAdd] = useState(subs);
	const [subsToRemove, setSubsToRemove] = useState([]);
	const [messages, setMessages] = useState({});
	const didUnmount = useRef(false);
	const { sendMessage, lastMessage, readyState } = useWebSocket(
		wsUrlV2,
		{
			...DEFAULT_OPTIONS('useKlineDataWsV2'),
			shouldReconnect: (closeEvent) => didUnmount.current === false,
			share: true,
		}
	);

	useEffect(() => () => didUnmount.current = true, []);

	// Log which pairs need to be subscribed to, removed, and reset main pairs state
	const setSubsProxy = useCallback((newSubs = []) => {
		const dedupedNewSubs = [...new Set(newSubs)];

		setSubsToRemove(
			subs.filter(p => !dedupedNewSubs.includes(p))
		);

		setSubsToAdd(
			dedupedNewSubs.filter(np => !subs.includes(np))
		);

		setSubs(dedupedNewSubs);
	}, [subs, setSubs]);

	// Subscribe and remove subscriptions to pairs
	useEffect(() => {
		if (ReadyState[readyState] === 'OPEN') {
			if (subsToRemove.length > 0) {
				// console.log('REMOVING', subsToRemove.join(', '));
				[...new Set(subsToRemove)].forEach(p => sendMessage(JSON.stringify({
					id: p,
					reqType: 'unsub',
					dataType: p, //ex. BTC-USDT@kline_1m
				})));

				setSubsToRemove([]);
			}

			if (subsToAdd.length > 0) {
				// console.log('SUBSCRIBING', subsToAdd.join(', '));
				[...new Set(subsToAdd)].forEach(p => sendMessage(JSON.stringify({
					id: p,
					reqType: 'sub',
					dataType: p, //ex. BTC-USDT@kline_1m
				})));
			}
		} else if (ReadyState[readyState] === 'CONNECTING') {
			setSubsToAdd(subs);
		}
	}, [subs, subsToRemove, subsToAdd, readyState, sendMessage]);

	// Some weird issue on mobile when browser is closed causes readyState
	// to stay 'OPEN' but the subs dissapear. So we never go through to
	// connecting state which would re-sub. Solution: re-sub every 2 seconds.
	useEffect(() => {
		const interval = setInterval(() => {
			setSubsToAdd([...new Set(subs)]);
		}, 2000);

		return () => clearInterval(interval);
	}, [subs]);

	// Decompress data and store it
	useEffect(() => {
		if (lastMessage?.data) {
			const fr = new FileReader();

			fr.onload = () => {
				const decompressed = ungzip(new Uint8Array(fr.result));
				const asStr = Buffer.from(decompressed.buffer).toString();

				if (asStr.toLowerCase() === 'ping') {
					// console.log('RESPONDING TO PING');
					sendMessage('Pong');
				} else {
					const { id, msg, data, dataType, s: symbol } = (JSON.parse(asStr)) || {};
					if (id || msg === "") {
						// Subscribe/unsubscribe success; msg will be empty string
						// console.log({id, msg});
					} else if (/@kline/i.test(dataType)) {
						// Data came in, handle
						const latestKline = Array.isArray(data) && data?.[0] ? {
							open: Number(data?.[0]?.o) || 0,
							high: Number(data?.[0]?.h) || 0,
							low: Number(data?.[0]?.l) || 0,
							close: Number(data?.[0]?.c) || 0,
							volume: Number(data?.[0]?.v) || 0,
							time: data?.[0]?.T,
						} : null;

						if (symbol && latestKline) {
							setMessages(prev => ({
								...prev,
								[symbol]: latestKline,
							}));
						}
					}
				}
			};

			fr.readAsArrayBuffer(lastMessage?.data);
		}
	}, [
		lastMessage?.data,
		sendMessage
	]);

	return [
		messages,
		setSubsProxy,
		ReadyState[readyState]
	];
};

export default useKlineDataWsV2;
