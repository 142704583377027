import { useState, useEffect, useCallback, useRef } from 'react';
import {
	// Mutations
	usePlaceOrderMutation,
} from 'api/client';

function usePlaceStaggeredOrder(onCompleteCallback) {
	const [orders, setOrders] = useState([]);
	const orderIdxRef = useRef(0);
	const [isSubmittingOrders, setIsSubmittingOrders] = useState(false);
	// Place order mutation
	const [placeOrder, placeOrderResult] = usePlaceOrderMutation();

	const handlePlaceOrderCallback = useCallback(order => {
		if (!order) {
			setOrders([]);
			setIsSubmittingOrders(false);
			orderIdxRef.current = 0;
			typeof onCompleteCallback === 'function' && onCompleteCallback();
		} else {
			setIsSubmittingOrders(true);

			const params = {
				symbol: order.symbol,
				side: order.side,
				price: order.price,
				volume: order.volume,
				type: 'Limit',
				action: 'Open',
			};

			placeOrder(params);
		}
	}, [onCompleteCallback, placeOrder]);

	useEffect(() => {
		if (placeOrderResult.isSuccess || placeOrderResult.isError) {
			placeOrderResult.reset();

			const curOrder = orders[orderIdxRef.current];
			const nextOrder = orders[orderIdxRef.current + 1];
			orderIdxRef.current += 1;
			handlePlaceOrderCallback(nextOrder);

			if (placeOrderResult.isError) {
				console.error('usePlaceOrderMutation error', placeOrderResult.error, curOrder);
			} else if (placeOrderResult.isSuccess) {
				console.log('usePlaceOrderMutation success', curOrder);
			}
		}
	}, [orders, placeOrderResult, handlePlaceOrderCallback]);

	const handlePlaceStaggeredOrder = useCallback((_orders) => {
		if (!isSubmittingOrders) {
			setOrders(_orders);
			orderIdxRef.current = 0;
			handlePlaceOrderCallback(_orders[0]);
		}
	}, [isSubmittingOrders, handlePlaceOrderCallback]);

	return {
		handlePlaceStaggeredOrder,
		isLoading: isSubmittingOrders,
	};
};

export default usePlaceStaggeredOrder;
