import { useState, useEffect } from 'react';
import { useGetFundingRateQuery } from 'api/client';

function useGetBingxSwapsFundingRate(symbol) {
	const [rate, setRate] = useState({});

	const {
		data: fundingRateInfo = {},
		isLoading,
		refetch,
	} = useGetFundingRateQuery({ symbol });

	useEffect(() => {
		let timeout;

		if (!isLoading && fundingRateInfo?.fundingRate) {
			const fundingRate = Number(fundingRateInfo?.fundingRate);
			const leftSeconds = Number(fundingRateInfo?.leftSeconds);

			setRate({ fundingRate, leftSeconds });
			timeout = setTimeout(refetch, leftSeconds + 5000);
		}

		return () => clearTimeout(timeout);
	}, [isLoading, fundingRateInfo, refetch]);

	return rate;
};

export default useGetBingxSwapsFundingRate;
