import React, { useState, useEffect, useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Flex from "components/Flex";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Toggle from "components/Toggle";
import PositionsTable from 'components/PositionsTable';
import { useGetPositionsQuery } from 'api/client';
import { supportedSymbols, POLLING_INTERVAL } from 'constants/bingxSwaps';
import useIsMobile from 'hooks/useIsMobile';
import { getDefaultError } from 'util/error';
import { useHistory, useLocation } from 'util/router';

const SYMBOLS = Object.keys(supportedSymbols);
const getSymbolsForQuery = activeSymbol => activeSymbol === 'ALL' ? SYMBOLS.map(o => `${o}-USDT`) : [`${activeSymbol}-USDT`];

function PositionsSection(props) {
	const history = useHistory();
	const location = useLocation();
	const isMobile = useIsMobile();
  const [sort, setSort] = useState('id');
  const [sortDir, setSortDir] = useState('ASC');
  const [activeSymbol, setActiveSymbol] = useState(new URLSearchParams(location.search).get('symbol') || SYMBOLS[0]);

  const {
  	data: positions = [],
  	isLoading: positionsIsLoading,
  	isError: positionsIsError,
  	error: positionsError,
  } = useGetPositionsQuery({
  	symbols: getSymbolsForQuery(activeSymbol),
  	includeCurrentPrice: true,
  }, {
  	pollingInterval: POLLING_INTERVAL,
  });

  // if (positionsIsError) {
  // 	console.log(positionsError);
  // } else {
  // 	console.log(positions[0])
  // }

  const setActiveSymbolProxy = useCallback((symbol) => {
  	setActiveSymbol(symbol);
  	const params = new URLSearchParams({symbol});
  	history.replace({ pathname: location.pathname, search: params.toString() });
  }, [history, location.pathname]);

  // Set url params on init
  useEffect(() => {
  	if (!location.search) {
  		setActiveSymbolProxy(activeSymbol);
  	}
  // eslint-disable-next-line
  }, []);

	const handleNavigateToPosition = useCallback((position) => {
		history.push(`/positions/${position?.symbol}`);
	}, [history]);

	return (
		<>
			{positionsIsError && (
				<Alert variant="danger">
					<Flex direction="column" align="center" className="w-100">
						<span>{getDefaultError(positionsError)}</span>
					</Flex>
				</Alert>
			)}
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
			  <Container>
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>
			  	<Toggle
			  		ops={['ALL', ...SYMBOLS]}
			  		active={activeSymbol}
			  		setActive={setActiveSymbolProxy}
			  		className="mb-2 w-fit"
			  		style={{
			  			maxWidth: '100%',
			  			overflowX: 'scroll',
			  		}}
			  	/>
  		  	{!isMobile ? (
  		  		<Card className="shadow">
	  		  		<Card.Body className="p-0">
	  	  				<div>
	  	  					<PositionsTable
	  	  						positions={positions}
	  	  						isLoading={positionsIsLoading}
	  	  						hasLocalSort={false}
	  	  						sort={sort}
	  	  						setSort={setSort}
	  	  						sortDir={sortDir}
	  	  						setSortDir={setSortDir}
	  	  						handleRowClick={handleNavigateToPosition}
	  	  						useMobileTable={true}
	  	  					/>
	  					  </div>
	  		  		</Card.Body>
  		  		</Card>
  		  	) : (
  		  		<PositionsTable
  		  			positions={positions}
  		  			isLoading={positionsIsLoading}
  		  			hasLocalSort={false}
  		  			sort={sort}
  		  			setSort={setSort}
  		  			sortDir={sortDir}
  		  			setSortDir={setSortDir}
  		  			handleRowClick={handleNavigateToPosition}
  		  			useMobileTable={true}
  		  		/>
  		  	)}
			  </Container>
			</Section>
		</>
	);
};

export default PositionsSection;
