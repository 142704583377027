import { LineStyle, CrosshairMode } from 'lightweight-charts';

export const getLocalTs = timeInS => {
	const d = new Date(timeInS);
	return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;
};

export const DARK_THEME = {
	layout: {
		backgroundColor: "#000",
		lineColor: "#2B2B43",
		textColor: "#D9D9D9",
	},
	grid: {
		vertLines: {
			// color: "#151515",
			color: "#000",
		},
		horzLines: {
			// color: "#151515",
			color: "#000",
		},
	},
};

export const LIGHT_THEME = {
	layout: {
		backgroundColor: "#FFFFFF",
		lineColor: "#2B2B43",
		textColor: "#191919",
	},
	grid: {
		vertLines: {
			color: "#e1ecf2",
		},
		horzLines: {
			color: "#e1ecf2",
		},
	},
};

export const TIME_SCALE = {
  rightOffset: 6,
  barSpacing: 7, // base horizontal zoom
  fixLeftEdge: true,
  lockVisibleTimeRangeOnResize: true,
  rightBarStaysOnScroll: true,
  borderVisible: false,
  borderColor: "#fff000",
  visible: true,
  timeVisible: true,
  secondsVisible: true,
};

export const LOCALIZATION = {
	// priceFormatter: price => currencyFormat(price, '-'),
};

export const CROSSHAIR_OPTIONS = {
	mode: CrosshairMode.Normal,
};

export const BASE_OPTIONS = {
	timeScale: TIME_SCALE,
	localization: LOCALIZATION,
	crosshair: CROSSHAIR_OPTIONS,
};

export const PRICE_LINE_OPTIONS = {
	color: 'green',
	lineWidth: 1,
	lineStyle: LineStyle.Dashed,
	axisLabelVisible: true,
};

export const CANDLE_DOWN_COLOR = '#00bcd4';
export const CANDLE_UP_COLOR = '#ffffff';
