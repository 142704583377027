import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

function Spinner(props) {
	return (
		<FontAwesomeIcon
			icon={faSpinnerThird}
			spin
			className={clsx('Spinner', {
				...props.className ? {
					[props.className]: true
				} : {}
			})}
		/>
	);
}

export default Spinner;
