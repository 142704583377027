import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from 'react-bootstrap/Toast';
import BackBtn from "components/BackBtn";
import Flex from "components/Flex";
import Section from "components/Section";
import SectionHeader from "components/SectionHeader";
import Popup from "components/Popup";
import PositionsTable from 'components/PositionsTable';
import Toggle from "components/Toggle";
import {
	useGetAccountBalanceQuery,
	useGetLeverageQuery,
	useGetPositionsQuery,
	useGetPendingOrdersQuery,
	// useGetPendingStopOrdersQuery,
	// Mutations
	useCancelOrdersMutation,
} from 'api/client';
import { POLLING_INTERVAL } from 'constants/bingxSwaps';
import { getDefaultError } from 'util/error';
import { useHistory, useParams } from 'util/router';
import { commaFormat, currencyFormat } from 'util/numbers';
import CreateOrderPopup from './CreateOrderPopup';
import PendingOrdersCard from './PendingOrdersCard';
import PriceChart from './PriceChart';
import Utilities from './Utilities';

const ACTIVE_POSITION_SIDES = ['Short', 'Long'];
const ACTIVE_DETAIL_TOGGLE_OPS = [
	'pending',
	'stops'
];

function PositionSection(props) {
	const history = useHistory();
	const { symbol } = useParams();
	// const isMobile = useIsMobile();
	const [activePositionSide, setActivePositionSide] = useState(ACTIVE_POSITION_SIDES[0]);
	const [activeDetailsSection, setActiveDetailsSection] = useState(ACTIVE_DETAIL_TOGGLE_OPS[0]);
	const createOrderPopupRef = useRef();
	const [createOrderPrice, setCreateOrderPrice] = useState(null);
	const [showCancelOrderError, setShowCancelOrderError] = useState(false);
	const [showCancelOrderSuccess, setShowCancelOrderSuccess] = useState(false);
	const [customLines, setCustomLines] = useState([]);

	const {
		data: positions = [],
		isLoading: positionsIsLoading,
		isError: positionsIsError,
		error: positionsError,
	} = useGetPositionsQuery({
		symbols: [symbol],
		includeCurrentPrice: true,
	}, {
		pollingInterval: POLLING_INTERVAL,
	});

	const {
		data: accountBalance = {},
		isLoading: accountBalanceIsLoading,
	} = useGetAccountBalanceQuery(undefined, {
		pollingInterval: POLLING_INTERVAL,
	});

	const {
		data: leverage = {},
		isLoading: leverageIsLoading,
	} = useGetLeverageQuery({
		symbol: symbol,
	}, {
		pollingInterval: POLLING_INTERVAL*10,
	});

	const {
		data: pendingOrders = [],
		refetch: pendingOrdersRefetch,
	} = useGetPendingOrdersQuery({
		symbol: symbol,
	}, {
		pollingInterval: POLLING_INTERVAL,
	});

	const filteredPendingOrdersForActiveSide = useMemo(() => pendingOrders.filter(
		po => activePositionSide === 'Short'
			? po.action === 'Open' ? po.side === 'Ask' : po.side === 'Bid' //If we're short, then we only are opening positions with Ask side closing with Bid side
			: po.action === 'Open' ? po.side === 'Bid' : po.side === 'Ask' //vice versa for longs
	), [activePositionSide, pendingOrders]);

	// Run to set initial active type toggle
	useEffect(() => {
		if (!positionsIsLoading) {
			const positionTypes = positions.map(p => p.positionSide);

			if (positionTypes.length === 1) {
				setActivePositionSide(positionTypes[0]);
			}
		}
	// eslint-disable-next-line
	}, [positionsIsLoading]);

	// Data
	const position = useMemo(() => {
		if (!positionsIsLoading && positions.length > 0) {
			return positions.find(p => p?.positionSide === activePositionSide);
		}
	}, [positionsIsLoading, positions, activePositionSide]);

	const availableVolumeToOpen = useMemo(() => {
		if (!positionsIsLoading && !accountBalanceIsLoading && !leverageIsLoading) {
			const currentPrice = position?.currentPrice;
			const availableMargin = accountBalance?.availableMargin;
			const { longLeverage, shortLeverage } = leverage;

			return {
				Long: (availableMargin * longLeverage) / currentPrice,
				Short: (availableMargin * shortLeverage) / currentPrice,
			};
		} else {
			return {};
		}
	}, [positionsIsLoading, accountBalanceIsLoading, leverageIsLoading, accountBalance?.availableMargin, position?.currentPrice, leverage]);

	// Mutations

	// Cancel order mutation
	const [cancelOrders, cancelOrdersResult] = useCancelOrdersMutation();

	useEffect(() => {
		if (cancelOrdersResult.isSuccess || cancelOrdersResult.isError) {
			cancelOrdersResult.reset();
			pendingOrdersRefetch();
		}

		if (cancelOrdersResult.isError) {
			setShowCancelOrderError(cancelOrdersResult.error);
			console.error('useCancelOrdersMutation error', cancelOrdersResult.error);
		}
		if (cancelOrdersResult.isSuccess) setShowCancelOrderSuccess(true);
	}, [cancelOrdersResult, pendingOrdersRefetch]);

	// Callbacks
	const handleChartClick = useCallback(({param, chartRef, candlestickSeries}) => {
		if (param.point.y < 0) return;

		const priceRaw = candlestickSeries.current.coordinateToPrice(param.point.y);
		const price = Math.round(priceRaw * 100) / 100;

		// Cancel order
		const isNearPendingOrder = pendingOrders.find(po => Math.abs(po.entrustPrice - price) < .5);

		if (isNearPendingOrder) {
			const { orderId, action, entrustVolume, entrustPrice } = isNearPendingOrder;

			if (window.confirm(`Cancel: ${action} ${commaFormat(entrustVolume)} @ ${currencyFormat(entrustPrice)}?`)) {
				cancelOrders({symbol: symbol, orderIds: [orderId]});
			}

			return;
		}

		setCreateOrderPrice(price);
		createOrderPopupRef.current.openPopup();
	}, [symbol, cancelOrders, pendingOrders]);

	return (
		<>
			{positionsIsError && (
				<Alert variant="danger">
					<Flex direction="column" align="center" className="w-100">
						<span>{getDefaultError(positionsError)}</span>
					</Flex>
				</Alert>
			)}
			<Section
			  bg={props.bg}
			  textColor={props.textColor}
			  size={props.size}
			  bgImage={props.bgImage}
			  bgImageOpacity={props.bgImageOpacity}
			>
			  <Container fluid className="px-lg-4 px-xl-5">
			  	<SectionHeader
			  	  title={props.title}
			  	  subtitle={props.subtitle}
			  	  size={3}
			  	  className="text-center"
			  	/>
			  	<Row>
			  		{/*Back btn*/}
			  		<Col xs={12}>
			  			<Flex
			  				align="center"
			  				style={{marginTop: '-1rem', marginBottom: '.5rem'}}
			  			>
			  				<BackBtn
			  					className="text-gray-700 text-gray-700-hvr-dark"
			  					onClick={() => history.goBack()}
			  				/>
			  			</Flex>
			  		</Col>
			  	</Row>
			  	<Row>
			  		{/*Position summary*/}
			  		<Col xs={12} lg={5} xl={3} className="mb-3 mb-lg-0">
				  		<Toggle
				  			ops={ACTIVE_POSITION_SIDES}
				  			active={activePositionSide}
				  			setActive={setActivePositionSide}
				  			className="w-fit mb-2"
				  		/>
	  					<PositionsTable
	  						positions={position ? [position] : []}
	  						isLoading={positionsIsLoading}
	  						useMobileTable={true}
	  						forceUseMobileTable={true}
	  					/>
			  		</Col>
			  		{/*Pending open and close orders tables*/}
			  		<Col xs={12} lg={7} xl={9}>
			  			<Toggle
			  				ops={ACTIVE_DETAIL_TOGGLE_OPS}
			  				active={activeDetailsSection}
			  				setActive={setActiveDetailsSection}
			  				className="w-fit mb-2"
			  			/>
			  			{activeDetailsSection === 'pending' ? (
			  				<PendingOrdersCard
			  					pendingOrders={filteredPendingOrdersForActiveSide}
			  					pendingOrdersRefetch={pendingOrdersRefetch}
			  					symbol={symbol}
			  					avgPrice={position?.avgPrice}
			  					volume={position?.volume}
			  					activePositionSide={activePositionSide}
			  					availableVolumeToOpen={availableVolumeToOpen}
			  					availableVolumeToClose={position?.availableVolume}
			  					currentPrice={Number(position?.currentPrice)}
			  					setCreateOrderPrice={setCreateOrderPrice}
			  					openCreateOrderPopup={() => createOrderPopupRef.current.openPopup()}
			  				/>
			  			) : null}
			  		</Col>
			  	</Row>
			  	<Row className="mt-3">
			  		{/*Utilities*/}
			  		<Col xs={12} lg={5} xl={3} className="mb-3 mb-lg-0">
			  			<Utilities
			  				position={position}
			  				positionsIsLoading={positionsIsLoading}
			  				setCustomLines={setCustomLines}
			  				pendingOrdersRefetch={pendingOrdersRefetch}
			  			/>
			  		</Col>
			  		{/*Price chart*/}
			  		<Col xs={12} lg={7} xl={9}>
			  			<PriceChart
			  				symbol={symbol}
			  				avgPrice={position?.avgPrice}
			  				breakEvenPrice={position?.breakEvenPrice}
			  				activePositionSide={activePositionSide}
			  				pendingOrders={filteredPendingOrdersForActiveSide}
			  				customLines={customLines}
			  				handleChartClick={handleChartClick}
			  			/>
			  		</Col>
			  	</Row>
			  </Container>
			</Section>

			<Popup
				ref={createOrderPopupRef}
				title="Create Order"
				size="sm"
				afterClose={() => {
					setCreateOrderPrice(null)
				}}
			>
				{createOrderPrice && (
					<CreateOrderPopup
						symbol={symbol}
						defaultPrice={createOrderPrice}
						positionSide={position?.positionSide}
						positionAvgPrice={position?.avgPrice}
						currentPrice={Number(position?.currentPrice)}
						pendingOrdersRefetch={pendingOrdersRefetch}
						availableVolumeToOpen={availableVolumeToOpen?.[position?.positionSide]}
						availableVolumeToClose={position?.availableVolume}
						closePopup={() => createOrderPopupRef.current.closePopup()}
					/>
				)}
			</Popup>

			<ShowCancelOrderResult
				showCancelOrderSuccess={showCancelOrderSuccess}
				setShowCancelOrderSuccess={setShowCancelOrderSuccess}
				showCancelOrderError={showCancelOrderError}
				setShowCancelOrderError={setShowCancelOrderError}
			/>
		</>
	);
};

export default PositionSection;

export const ShowCancelOrderResult = ({showCancelOrderSuccess, setShowCancelOrderSuccess, showCancelOrderError, setShowCancelOrderError}) => (
	<>
		{/*Cancel order success*/}
		<Toast
			onClose={() => setShowCancelOrderSuccess(false)}
			show={showCancelOrderSuccess}
			delay={3000}
			autohide
			style={{
				position: 'fixed',
				top: 16,
				right: 16,
			}}
		>
			<Toast.Header>
				<strong className="mr-auto text-success">Success</strong>
			</Toast.Header>
			<Toast.Body>
				Your order was canceled
			</Toast.Body>
		</Toast>
		{/*Cancel order error*/}
		<Toast
			onClose={() => setShowCancelOrderError(false)}
			show={showCancelOrderError}
			delay={3000}
			autohide
			style={{
				position: 'fixed',
				top: 16,
				right: 16,
			}}
		>
			<Toast.Header>
				<strong className="mr-auto text-danger">Failed</strong>
			</Toast.Header>
			<Toast.Body>{getDefaultError(showCancelOrderError)}</Toast.Body>
		</Toast>
	</>
);

// const ShowPlaceOrderResult = ({showPlaceOrderSuccess, setShowPlaceOrderSuccess, showPlaceOrderError, setShowPlaceOrderError}) => (
// 	<>
// 		{/*Cancel order success*/}
// 		<Toast
// 			onClose={() => setShowPlaceOrderSuccess(false)}
// 			show={showPlaceOrderSuccess}
// 			delay={3000}
// 			autohide
// 			style={{
// 				position: 'fixed',
// 				top: 16,
// 				right: 16,
// 			}}
// 		>
// 			<Toast.Header>
// 				<strong className="mr-auto text-success">Success</strong>
// 			</Toast.Header>
// 			<Toast.Body>
// 				Your order was placed
// 			</Toast.Body>
// 		</Toast>
// 		{/*Cancel order error*/}
// 		<Toast
// 			onClose={() => setShowPlaceOrderError(false)}
// 			show={showPlaceOrderError}
// 			delay={3000}
// 			autohide
// 			style={{
// 				position: 'fixed',
// 				top: 16,
// 				right: 16,
// 			}}
// 		>
// 			<Toast.Header>
// 				<strong className="mr-auto text-danger">Failed</strong>
// 			</Toast.Header>
// 			<Toast.Body>{getDefaultError(showPlaceOrderError)}</Toast.Body>
// 		</Toast>
// 	</>
// );
