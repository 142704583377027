import React, { useContext, useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import CandlestickChart from 'components/CandlestickChart';
import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import useIsMobile from 'hooks/useIsMobile';
import useGetChartData from 'components/PositionSectionNew/hooks/useGetChartData';
import useGetPriceLines from 'components/PositionSectionNew/hooks/useGetPriceLines';
import useHandleChartClick from 'components/PositionSectionNew/hooks/useHandleChartClick';
import useHandleCrosshairMove from 'components/PositionSectionNew/hooks/useHandleCrosshairMove';
import { PositionContext, PERIOD_TO_MINS_MAP } from 'components/PositionSectionNew/constants';

function PriceChart(props) {
	const { 
		symbol,
		side,
		period,
		position,
		mouseEnterOrder,
		pricePrecision,
		openOrders,
		closeOrders,
		tentativeOrder,
		crosshairActiveOnMobile,
		priceLines: additionalPriceLines = [], //[{ id, title, price, color? }]
		dispatchContext,
	} = useContext(PositionContext);
	const isMobile = useIsMobile();
	const chartContainerRef = useRef();
	const chartRef = useRef(); //use with imperative handle;

	/* QUERIES */
	/** kLine chart data **/
	const {
		chartData = [],
		chartDataIsFetching,
		currentPrice = 0,
	} = useGetChartData({
		symbol,
		period,
	});

	/** Price lines **/
	const priceLines = useGetPriceLines({
		symbol,
		side,
		position,
		orders: [...openOrders, ...closeOrders],
		mouseEnterOrder,
		currentPrice,
		additionalPriceLines,
	});


  /* CALLBACKS */
  const { origQty = 0, action, type } = tentativeOrder || {};
  const handleChartClick = useHandleChartClick({
  	pricePrecision,
		side,
		symbol,
		dispatchContext,
		currentPrice,
		origQty,
		action,
		type,
  });

  const memoizedPriceLines = JSON.stringify(priceLines);
	const handleCrosshairMove = useHandleCrosshairMove({
		memoizedPriceLines,
		dispatchContext,
		pricePrecision,
	});

	/* EVENTS */
	/** Handle crosshair move on mobile to toggle new order **/
	useEffect(() => {
		if (
			isMobile &&
			crosshairActiveOnMobile
		) {
			handleChartClick({ priceOverride: crosshairActiveOnMobile });
		}
	}, [
		isMobile,
		crosshairActiveOnMobile,
		handleChartClick,
	]);

	/** Reset the price scale if symbol or period changed **/
	useEffect(() => {
		chartRef.current?.resetPriceScale?.();
	}, [
		symbol,
		period,
	]);

	return (
		<div className="PriceChart">
			<Flex justify="between">
				<Toggle
					ops={Object.keys(PERIOD_TO_MINS_MAP)}
					active={period}
					setActive={op => dispatchContext({ type: 'period', payload: op })}
					loading={chartDataIsFetching ? period : false}
					className="w-fit scroll-x mb-2 mr-4 f-rem-0.85 fw5"
				/>
				<Toggle
					ops={Object.keys(chartRef.current?.getChartOptions() || {})}
					active={op => chartRef.current?.getChartOptionValueByName(op)}
					setActive={op => chartRef.current?.[chartRef.current?.getChartOptions?.()?.[op]?.toggle]()}
					className="w-fit scroll-x mb-2 f-rem-0.85 fw5"
				/>
			</Flex>
			<Card className="Chart shadow noscroll">
				<Card.Body ref={chartContainerRef} className="p-0">
					<CandlestickChart
						ref={chartRef}
						containerRef={chartContainerRef}
						symbol={symbol}
						data={chartData}
						priceLines={priceLines}
						height={isMobile ? 628 : 796}
						intervalInMin={PERIOD_TO_MINS_MAP[period]}
						handleChartClick={handleChartClick}
						handleCrosshairMove={handleCrosshairMove}
						decimals={pricePrecision}
						defaultChartOptions={{
							marker: {
								labels: {
									value: false,
								}
							}
						}}
					/>
				</Card.Body>
			</Card>
		</div>
	);
};

export default PriceChart;
