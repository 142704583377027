import { useState, useEffect, useMemo } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { DEFAULT_OPTIONS } from 'api/websockets/constants';
import { wsUrl } from './constants';

function useStreamMarkPrice(symbol, speed = '1s') {
	const [socketUrl, setSocketUrl] = useState(wsUrl);
	const [markPrice, setMarkPrice] = useState(0);
	const [prevMarkPrice, setPrevMarkPrice] = useState(0);
	const [indexPrice, setIndexPrice] = useState(0);
	const [prevIndexPrice, setPrevIndexPrice] = useState(0);
	const { lastMessage, readyState } = useWebSocket(socketUrl, DEFAULT_OPTIONS('useStreamMarkPrice'));

	useEffect(() => {
		if (symbol) {
			setSocketUrl(`${wsUrl}${symbol.toLowerCase()}@markPrice${speed === '1s' ? '@1s' : ''}`.trim()); //either 1s or 3s. If 3s, don't append anything.
		}
	}, [symbol, speed]);

	useEffect(() => {
		if (lastMessage?.data) {
			const {
				// e: eventType,
				// E: eventTime,
				// s: symbol,
				p: markPrice,
				i: indexPrice,
				// P: estimatedSettlePrice, //only useful in the last hour before the settlement starts
				// r: fundingRate,
				// T: nextFundingTime,
			} = JSON.parse(lastMessage?.data);

			setMarkPrice(prev => {
				setPrevMarkPrice(prev);
				return Number(markPrice);
			});

			setIndexPrice(prev => {
				setPrevIndexPrice(prev);
				return Number(indexPrice);
			});
		}
	}, [lastMessage]);

	return useMemo(() => {
		return ({
			price: markPrice,
			prevPrice: prevMarkPrice,
			indexPrice: indexPrice,
			prevIndexPrice: prevIndexPrice,
			readyState: ReadyState[readyState],
		})
	}, [
		markPrice,
		prevMarkPrice,
		indexPrice,
		prevIndexPrice,
		readyState
	]);
};

export default useStreamMarkPrice;
